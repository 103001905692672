
obeta.barcode = (function () {

    var bindWatchOverlayInput = function () {

        $('.overlay__input').on('keyup', function(e) {
            e.preventDefault();
            var $this = $(this), labels = $this.val(), url = $this.data('url'), $input = $('.overlay__input');

            if($input.val().length > 2){
                labels = parseInt(labels.toString().slice($input.val().length - 1));
                $this.val(labels);
            }

            if (typeof labels !== 'undefined' && labels != null) {
                if (labels >= 40) {
                    labels = 39;
                    $this.val(labels);
                }
                if (labels >= 0 && labels < 40) {
                    _clearBarcodeLabel();
                    _updateBarcode4780(labels);
                    _updateBarcode3490(labels);
                    _updateBarcode4781(labels);
                    _updateBarcodeC32258(labels);

                    var dataToSend = {
                        positions: _getSelectedArticles(),
                        usedLabels: labels
                    };
                    obeta.helpers.ajax({
                        type: "POST",
                        url: url,
                        data: dataToSend
                    }, function(data) {
                    }, function() {});
                }
            }
            if($input.val().length === 2) {
                $('.overlay__input').select();
            }

        });
    },
        _clearBarcodeLabel = function() {
            _updateBarcode4780(0);
            _updateBarcode3490(0);
            _updateBarcode4781(0);
            _updateBarcodeC32258(0);
        },

        _updateBarcode4780 = function(labels) {
            var tds = $('.overlay__barcode-table--4780').find('td');
            if (labels == 0) {
                $.each(tds, function(key, td) {
                    $(td).removeClass('overlay__barcode-cell--crossed');
                });
            } else if (labels < 40) {
                $.each(tds, function(key, td) {
                    if (key < labels) {
                        $(td).addClass('overlay__barcode-cell--crossed');
                    }
                });
            }
        },

        _updateBarcode3490 = function(labels) {
            var tds = $('.overlay__barcode-table--3490').find('td');
            if (labels == 0) {
                $.each(tds, function(key, td) {
                    $(td).removeClass('overlay__barcode-cell--crossed');
                });
            } else if (labels < 24) {
                $.each(tds, function(key, td) {
                    if (key < labels) {
                        $(td).addClass('overlay__barcode-cell--crossed');
                    }
                });
            }
        },

        _updateBarcode4781 = function(labels) {
            var tds = $('.overlay__barcode-table--4781').find('td');
            if (labels == 0) {
                $.each(tds, function(key, td) {
                    $(td).removeClass('overlay__barcode-cell--crossed');
                });
            } else if (labels < 12) {
                $.each(tds, function(key, td) {
                    if (key < labels) {
                        $(td).addClass('overlay__barcode-cell--crossed');
                    }
                });
            }
        },

        _updateBarcodeC32258 = function(labels) {
            var tds = $('.overlay__barcode-table--C32258').find('td');
            if (labels == 0) {
                $.each(tds, function(key, td) {
                    $(td).removeClass('overlay__barcode-cell--crossed');
                });
            } else if (labels < 14) {
                var idx = 14 - labels;
                $.each(tds, function(key, td) {
                    if (key >= idx) {
                        $(td).addClass('overlay__barcode-cell--crossed');
                    }
                });
            }
        },

        _getSelectedArticles = function () {
            var positions = $('.artBox ').map(function () {
                var $artBox = $(this);
                if ($(this).find('.check-selected').length) {
                    return {
                        'position': $(this).data('position'),
                        'amount': ($artBox.hasClass('is-order-page')) ? $(this).find('#article-amount-on-order-page').val() : $(this).find('.inp-amount input').val(),
                        'ring': $(this).find('.article-data-storage').data('ring'),
                        'articleId': $(this).data('article-id')
                    }
                } else {
                    return null;
                }
            }).get();

            return positions;
        },


        bindPrintBarcode = function () {
            $('.btn-dd__item-barcode').on('click', function (e) {
                e.preventDefault();

                var positions = $('.artBox ').map(function () {
                    var $artBox = $(this);
                    if ($(this).find('.check-selected').length) {
                        return {
                            'position': $(this).data('position'),
                            'amount': ($artBox.hasClass('is-order-page')) ? $(this).find('#article-amount-on-order-page').val() : $(this).find('.inp-amount input').val(),
                            'ring': $(this).find('.article-data-storage').data('ring'),
                            'articleId': $(this).data('article-id')
                        }
                    } else {
                        return null;
                    }
                }).get();

                if (positions.length == 0) {
                    // show overlay dialog box 1
                    _showOverlayDialogBox1();
                } else {
                    // show overlay dialog box 2
                    _showOverlayDialogBox2();
                }


            });
        },

        _showOverlayDialogBox1 = function() {
            var overlay = {
                containerId: "js-print-barcode",
                afterShow: function() {

                    $('.overlay__button-all').on('click', function(e) {
                        e.preventDefault();
                        $('.select-all-articles').trigger('click');
                        _showOverlayDialogBox2();
                    });

                    $('.overlay__button-select').on('click', function(e) {
                        e.preventDefault();
                        $.fancybox.close();
                    });
                }
            }
            obeta.messages.showConfirmation(overlay);
        },

        _showOverlayDialogBox2 = function() {
            $.fancybox.close();
            var overlay2 = {
                containerId: "js-print-barcode-label",
                afterShow: function() {
                    $('.overlay__input').focus().trigger('select');
                    var dataToSend = {
                        positions: _getSelectedArticles(),
                        usedLabels: 0
                    }
                    obeta.helpers.ajax({
                        type: "POST",
                        url: $('.overlay__input').attr('data-url'),
                        data: dataToSend
                    }, function(data) {
                    }, function() {});
                }
            }
            obeta.messages.showConfirmation(overlay2);
        };

    return {
        bindWatchOverlayInput: bindWatchOverlayInput,
        bindPrintBarcode: bindPrintBarcode
    };
})();

module.exports = obeta.barcode;