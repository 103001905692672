
obeta.cookieconsent = (function () {
    var initCookieConsent = function () {
        //get cookieconsent value
        var value = '; ' + document.cookie;
        var parts = value.split('; cookieconsent_status_v1=');
        var answer = parts.length < 2 ?
            undefined :
            parts
                .pop()
                .split(';')
                .shift();
        if ($('html').data('cookieconsent') == '1') {
            if (answer !== "allow") {
                //reset cookie if it does not exist anymore
                var exdate = new Date();
                exdate.setDate(exdate.getDate() + (365));

                var cookie = [
                    'cookieconsent_status_v1=dismiss',
                    'expires=' + exdate.toUTCString(),
                    'path=/'
                ];
                document.cookie = cookie.join(';');
            }
        } else {
            //cookie set but not flag in db->set db flag
            if (answer == 'allow' && !$('#btn_login').length) {
                data = {
                    'consent': true
                };
                obeta.helpers.ajax({
                    type: "POST",
                    url: '/setCookieConsent',
                    data: data
                }, function (data) {
                }, function (data) {
                }, {});
            }
            window.addEventListener("load", function () {
                var message = "<span style='font-weight: bold; font-size: 20px;'>Verwendung von Cookies</span><br/>"
                    + "<div style='float: left; width: 49%;'>"
                    + "Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. "
                    + "Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser "
                    + "speichert. Die meisten der von uns verwendeten Cookies sind so genannte “Session-Cookies”. "
                    + "Sie werden nach Ende Ihres Besuchs automatisch gelöscht."
                    + "</div>"
                    + "<div style='float: left; width: 1%;'>&nbsp;</div>"
                    + "<div style='float: left; width: 49%;'>"
                    + "Wir respektieren Ihr Recht auf den Schutz Ihrer Daten. Deshalb können Sie entscheiden, "
                    + "bestimmte Arten von Cookies nicht zu akzeptieren. Sie können diese Einstellungen jederzeit "
                    + "ändern, wenn Sie unsere <a href='/datenschutzhinweise'>Datenschutzhinweise</a> aufrufen.</div>";
                window.cookieconsent.initialise({
                    onStatusChange: function (status, chosenBefore) {
                        if (status == "allow") {
                            data = {
                                'consent': true
                            };
                            obeta.helpers.ajax({
                                type: "POST",
                                url: '/setCookieConsent',
                                data: data
                            }, function (data) {
                            }, function (data) {
                            }, {});
                        }
                    },
                    "palette": {
                        "popup": {
                            "background": "#e20721",
                            "text": "#fff"
                        },
                        "button": {
                            "background": "#fff"
                        }
                    },
                    "content": {
                        "message": message
                    }
                })
            });
        }
    };
    return {
        initCookieConsent: initCookieConsent
    };
})();

module.exports = obeta.cookieconsent;