

obeta.events = (function() {
    var bindButtonsForEventPage = function() {

    $('.button-event').on('click',function() {
        $(this).parents(".event-box-wrapper").find(".close-button").toggle();
        var eventNo = $(this).attr('data-event');


        $("#event-" + eventNo).animate({"height": "toggle"}, {duration: 600});
    });

    $('.close-button').on('click',function() {
        $(this).toggle();
      
        var eventNo = $(this).attr('data-event');
        $("#event-" + eventNo).animate({"height": "toggle"}, {duration: 600});
    });
    }
 return {
        bindButtonsForEventPage: bindButtonsForEventPage
    }
})();

module.exports = obeta.events;