
obeta.oxomi = (function () {

    var bindOxomiReadyEvent = function () {
        $(document).bind('oxomi-ready', function (e, oxomi) {
        });
    },
        initOxomiCompanyAd = function () {
                $('.hide-helper').on('click',function(){
                    $('#text-play-button').hide();
                    $('#company-video-image').hide();
                    oxomi.embedVideo({
                        video: '12980',
                        mode: 'embed',
                        width: 'inherit',
                        target: '#company-video',
                        autoplay: true
                    });
                });
            },
        initXpressTrailer = function () {
            $('.hide-helper').on('click', function () {
                $('#text-xpress-play-button').hide();
                $('#xpress-tutorial-video-image').hide();
                oxomi.embedVideo({
                    video: '9456',
                    mode: 'embed',
                    width: 'inherit',
                    height: '490',
                    target: '#xpress-tutorial-video-wrapper',
                    autoplay: true
                });
            });
        },
            handleCompanyFilm = function () {
                var loaded = $('#traileractive').attr('loaded');
                if (!loaded) {
                    $('#traileractive').attr('loaded', true);
                    $('#oxomi-vids>div').hide();
                    var $videos = $('#oxomi-vids>.oxomi-floater'),
                            $trailer = $('#trailer-inner'),
                            activevideo = $('#traileractive').attr('activevideo');
                    var usefirstvideos = false;
                    if (activevideo.length < 1) {
                        usefirstvideos = true;
                    }
                    $trailer.html("");

                    $videos.each(function (e) {
                        var $this = $(this),
                                title = $this.find('.oxomi-video-title').text(),
                                addid = "",
                                addclass = "";

                        if (title == activevideo || usefirstvideos) {
                            addid = "id='film-tab-active'";
                            $this.show();
                            usefirstvideos = false;
                        }
                        if (title.length > 16 && (title.indexOf(" ") > 0 || title.indexOf("-") > 0)) {
                            addclass = " doubleheight";
                        }

                        var name = title;
                        if(title === 'Imagesspot 2021'){
                            name = 'Wer wir sind';
                        }
                        var html = "<a href='/trailer/" + title + "?embedMode=1'><span " + addid + " class='film-tab" + addclass + "'>" + name + "</span></a><span class='film-tab-inner'></span>";

                        if(title !== 'Nah dran' && title !== 'Wer wir sind'){
                            $trailer.append(html);
                        }
                    });

                    $('#oxomi-vids .oxomi-video-title').addClass('text-bold');
                    $('#oxomi-vids .oxomi-floater').addClass('oxomi-floater-obeta');
                    $('.oxomi-video-play-img').on('click', function () {
                        $('.oxomi-video-title').hide();
                    });
                }
            },
            getOxomiBrand = function () {
                var companyId = $('.icn_obeta').attr('data-company');
                if (companyId == 8) {
                    return "Heinrich Häusler";
                }
                if (companyId == 5) {
                    return "eldis RR";
                }
                return "OBETA";
            },
            oxomiCatalogCompletion = function () {
                $('.oxomi-catalog-container').addClass('catalog-nav').removeClass('oxomi-border');
                $('.oxomi-catalog').addClass('catalog-inner');

                $('.catalog-inner').each(function () {
                    var $this = $(this),
                            $a = $this.find("a"),
                            onclick = $a.attr('onclick'),
                            title = $a.attr('title');
                    $this.after('<br><div class="big-text-bold left">' + title + '</div><br><br><div class="float-left"><a ><button class="more-info" onclick="' + onclick + '">öffnen</button></a>')

                });
            }, oxomiSingleCatalogCompletion = function () {
        var searchTitle = $('#oxomi-single-advertisement').data('title');

        $('.oxomi-catalog-container a').each(function () {
            var $a = $(this),
                    onclick = $a.attr('onclick'),
                    page = "page: '" + $('#oxomi-single-advertisement').data('page') + "', catalog";
            var title = $a.attr('title');
            var onclickmod = onclick.replace("catalog", page);
            $a.attr('onclick', onclickmod);
            if (title == searchTitle) {
                $a.click();
            }
        });
    },
            initOxomiJCaroussel = function () {
                var imgwidth = $('.more-images-list').width(),
                        imgcount = $('.more-images-list li').length,
                        vidwidth = $('#oxomi-videos>div').length * 66;
                if (vidwidth > 0 || imgcount > 1 || $('#oxomi-images a').length > 0) {
                    $(".more-images-wrapper").css('height', '66px');
                    $(".more-image-single-link").removeClass('display-none');
                    if (!$('#fancybox').length) {
                        $('#main-image').wrap('<a id="fancybox" href="javascript:void(0);" title="Klicken für größere Ansicht"></a>')
                    }
                }
                if (imgcount > 6) {
                    $("#more-images").css('float', 'left').addClass('caroussel-on');
                    $('#more-images-prev-button, #more-images-next-button').show();
                    $('#more-images').jCarouselLite({
                        btnNext: "#more-images-next-button",
                        btnPrev: "#more-images-prev-button",
                        visible: 6,
                        afterEnd: function () {
                            obeta.search.bindThumbnailClick();
                        }
                    });
                    obeta.search.turntableThumbInit();
                }
                obeta.search.bindThumbnailClick();

                $('.more-images-list .oxomi-video-play-img').on('click', function () {
                    var $this = $(this),
                            orig = $this.prev().data('original');
                    $('#oxomi-videos img[data-original="' + orig + '"]').click();
                });
                obeta.userInterface.bindMoreButton();
                $('.fancybox-outer .oxomi-video-play-img').addClass('more-image-single-link-fancybox');
                $('#more-images .oxomi-video-play-img').on('mouseover', function (e) {
                    var $this = $(this).prev(),
                            vidsrc = $this.data('original'),
                            bigImageUrl = $this.attr('src');

                    $('#oxomi-play').unbind('click');
                    $('#oxomi-play').show().on('click', function () {
                        if ($('#main-image').hasClass('oxomi-video-prev')) {
                            $('.oxomi-click-video').first().click();
                            return;
                        }
                    });
                    $('.oxomi-click-video').removeClass('oxomi-click-video');
                    $(this).addClass('oxomi-click-video');

                    $('#main-image').attr('src', vidsrc);
                    $('#main-image').attr('data-high', bigImageUrl);
                    $('#main-image').addClass('oxomi-video-prev');
                });
            }
    ,
            initOxomiAttachments = function () {
                if ($(".oxomi-attachment-list div").length > 0) {
                    $('.oxomi-attachment-list').before('<div class="oxomi-files-name">Downloads</div>');
                    var attachmenttype = "";
                    var attachmentcounter = 0;
                    $(".oxomi-attachment-list .oxomi-attachment").each(function () {
                        var $this = $(this);
                        var classList = $this.attr('class').split(/\s+/),
                                classtoadd = "";
                        classtoadd = "";
                        $.each(classList, function (index, item) {
                            if (item != 'oxomi-attachment') {
                                var classname = "." + item;
                                if ($(classname).length > 1) {
                                    classtoadd = "multiple-attachments";
                                }
                            }
                        });
                        var localtype = $this.find('.oxomi-attachment-type').text();
                        if (localtype != "" && !(localtype.localeCompare(attachmenttype) === 0)) {
                            $this.before('<div class="oxomi-type">' + localtype + '</div>');
                            attachmenttype = localtype;
                        }
                        $this.addClass(classtoadd);
                    });
                }
            },
            initOxomiVideos = function () {
                $('.oxomi-video-img').each(function () {
                    var $this = $(this);
                    var htmltext = $this.parent().html();
                    $("#more-images ul").append('<li>' + htmltext + '</li>');
                    $(".more-images-list-fancybox").append('<li>' + htmltext + '</li>');
                });
               
            }, initOxomiImages = function () {
        if ($(".more-images-list li img").length) {
            var imgindex = $(".more-images-list li:last-child img").data('index');
        } else {
            var imgindex = 0;
        }
        var turntable = $('.turntable-thumb').length;
        var $firstImage = $('.more-images-list li:first-child img');
        $firstImage.attr('data-index', "0");
        if (((turntable && imgindex == 1) || (!turntable && imgindex == 0)) && $('#oxomi-images a').length > 0 && $firstImage.attr("src")!=='/public/img/360icon.png') {
            $(".more-images-wrapper").css('height', '66px');
            $(".more-image-single-link").removeClass('display-none');

            if (typeof $firstImage.attr("src")!=='undefined') {
                var htmlfancybox = '<div><img class="more-image-fancybox more-image-thumb-fancybox" src="' + $firstImage.attr("src") + '" data-detail="' + $firstImage.data("high") + '" data-high="' + $firstImage.data("high") + '" data-index="0"/></div>';
                $(".more-images-list-fancybox").append('<li class="more-image-single-link-fancybox">' + htmlfancybox + '</li>');
            }
            imgindex = 0;
        }
        if (turntable) {
            var $lastli = $("#more-images li.turntable-thumb").clone();
            $("#more-images li.turntable-thumb").remove();
            var $lastlifancybox = $(".more-images-list-fancybox li:last").clone();
            $(".more-images-list-fancybox li:last").remove();
        }
        $('#oxomi-images a').each(function () {
            imgindex = imgindex + 1;
            var $this = $(this);
            var href = $this.attr('href'),
                    $img = $this.find('img'),
                    src = $img.attr('src'),
                    htmltext = "<div><img class='more-image more-image-thumb' src='" + src + "' data-detail='" + href + "' data-index='" + imgindex + "' data-high='" + href + "'></div>",
                    htmlfancybox = '<div><img class="more-image-fancybox more-image-thumb-fancybox" src="' + src + '" data-detail="' + href + '" data-index="' + imgindex + '" data-high="' + href + '" /></div>';
            ;
            $("#more-images ul").append('<li class="more-image-single-link">' + htmltext + '</li>');
            $(".more-images-list-fancybox").append('<li class="more-image-single-link-fancybox">' + htmlfancybox + '</li>');
        });
        if (turntable) {
            $("#more-images ul").append($lastli);
            $(".more-images-list-fancybox").append($lastlifancybox);
            $('.turntable-thumb').addClass('thumb-show');
            $('.thumb-show img').attr('data-index', imgindex + 1);
            obeta.search.turntableInit();
            obeta.search.turntableThumbInit();
            $('.more-image-single-link').removeClass('display-none');
        }
        // show oxomi image if no obeta image is available (logo is default)
        var logoImageExists = $('#image-box').find('.logo-only-big').length > 0;
        var ghostImageExists = $('#image-box').find('.ghost-image-big').length > 0;
        var showSupplierLogo = false;
        var displayLogoBig = $('#main-image').hasClass('logo-only-big');
        if ((logoImageExists || ghostImageExists) && (imgindex > 0)) {
            var src = $('.more-images-list').first().find('img').attr('src');
            var dataHigh = $('.more-images-list').first().find('img').attr('data-high');
            var $mainImage = $('#main-image');
            $mainImage.attr('src', dataHigh);
            $mainImage.attr('data-high', dataHigh);
            $mainImage.attr('data-index', 1);
            $mainImage.removeClass('logo-only-big');
            if (dataHigh != '') {
                showSupplierLogo = true;
            }
        }

        if ($('#main-image').attr('data-logo-exist')) {
            showSupplierLogo = true;
        }

        if (showSupplierLogo && !displayLogoBig) {
            $('#supplier-logo').show();
        }

        if($('#main-image').attr('src').includes('https://oxomi.com') && !$('#main-image').hasClass('logo-only-big') && showSupplierLogo){
            $('#supplier-logo').show();
        }


        bindOxomiUrlToDatasheet();

        if (imgindex > 1) {
            $('.more-image-single-link').removeClass('display-none');
            $('.more-images-wrapper').css('height', '52px');
        }
        $('#more-images-number').attr('value', imgindex + 1);
    },
            bindOxomiFunction = function () {
                if ($('#supplier-filter').length > 0 || $('#product-view').length > 0) {
                    if ($('.oxomi-open-video').length > 0) {
                        var $this = $('.oxomi-open-video'),
                                supplierNumber = $this.data('suppliernumber'),
                                itemNumber = $this.data('itemnumber'),
                                catalogStatus = $this.data('catalogstatus');
                        if (supplierNumber != 999 && catalogStatus == 1) {

                            oxomi.itemVideos({
                                supplierNumber: supplierNumber,
                                itemNumber: itemNumber,
                                target: '#oxomi-videos',
                                interceptor: function () {
                                    oxomi.itemImages({
                                        supplierNumber: supplierNumber,
                                        itemNumber: itemNumber,
                                        target: '#oxomi-images',
                                        interceptor: function () {
                                            oxomi.itemAttachments({
                                                supplierNumber: supplierNumber,
                                                itemNumber: itemNumber,
                                                target: '#oxomi-attachments',
                                                interceptor: function () {
                                                    oxomi.itemPages({
                                                        supplierItemNumber: itemNumber,
                                                        supplierNumber: supplierNumber,
                                                        type: 'catalog',
                                                        target: '#oxomi-catalogs',
                                                        interceptor: function () {
                                                            setTimeout(function () {
                                                                if ($('#oxomi-catalogs div').length > 0) {
                                                                    $('.oxomi-item').show().css('visibility', 'visible');
                                                                }
                                                                initOxomiImages();
                                                                initOxomiVideos();
                                                                initOxomiJCaroussel();
                                                                initOxomiAttachments();
                                                            }, 0);
                                                        }
                                                    });
                                                }
                                            });
                                        }
                                    });
                                }
                            });
                        } else {
                            initOxomiImages();
                        }
                    }
                } else {
                    if ($("#trailer").length > 0) {
                        oxomi.videos({
                            "target": "#oxomi-vids",
                            "type": "image",
                            "brand": "OBETA",
                            "mode": "in-place",
                            "width": "640px",
                            "height": "360px",
                            //"size": "medium",
                            "showDetails": true,
                            completionHandler: handleCompanyFilm
                        });

                    }
                    if ($('#oxomi-advertisement').length > 0) {
                        oxomi.catalogs({
                            brand: getOxomiBrand(),
                            brandCategory: 'Monatswerbung',
                            target: '#oxomi-advertisement',
                            type: 'flyer',
                            sortBy: 'date',
                            completionHandler: oxomiCatalogCompletion
                        });
                    }
                    if ($('#oxomi-single-advertisement').length > 0) {
                        oxomi.catalogs({
                            brand: getOxomiBrand(),
                            brandCategory: 'Monatswerbung',
                            target: '#oxomi-single-advertisement',
                            type: 'flyer',
                            sortBy: 'date',
                            completionHandler: oxomiSingleCatalogCompletion
                        });
                    }
                    initOxomiCompanyAd();
                    initXpressTrailer();
                }

                $('.oxomi-supplier').on('click', function (e) {
                    e.preventDefault();
                    var supplierNumber = $(this).data('suppliernumber');
                    $("#oxomi-catalogs").remove();
                    $(this).after('<div style="display:none" id="oxomi-outer"><div id="oxomi-catalogs"></div></div>');
                    oxomi.catalogs({
                        supplierNumber: supplierNumber,
                        target: '#oxomi-catalogs',
                        completionHandler: function () {
                            $.fancybox({
                                href: '#oxomi-catalogs',
                                'closeBtn': true,
                                'modal': false
                            });
                        }
                    });
                });
                $('.oxomi-item').on('click', function (e) {
                    e.preventDefault();

                    $.fancybox({
                        href: '#oxomi-catalogs',
                        'closeBtn': true,
                        'modal': false
                    });
                });

            },
            bindOxomiFallback = function () {
                var items = [];
                //update all img tags with loadOxomi class
                $('.loadOxomi').each(function () {
                    var $this = $(this);
                    var supplierNumber = $this.data('suppliernumber');
                    var itemNumber = $this.data('itemnumber');
                    //check if itemNumber is already used for oxomi call (it is already in items)->only call once
                    if (supplierNumber && itemNumber && items.indexOf(itemNumber)<0) {

                        $(document).bind('oxomi-ready', function (e, oxomi) {

                            oxomi.itemImages({
                                supplierNumber: supplierNumber,
                                itemNumber: itemNumber,
                                renderer: function (json) {
                                    //no image from obeta exists->use oxomi
                                    if (json.mainImage.thumb != 'undefined') {
                                        //change the image for all occurences of this item in dom
                                        $('img[data-itemNumber='+itemNumber+'][data-supplierNumber='+supplierNumber+']').each(function() {
                                            $(this).attr('src', json.mainImage.thumb);
                                            $(this).parent().next().attr('style', 'display:block');
                                        });
                                    }

                                    if ($this.hasClass('article-grid-pic')) {
                                        $this.next().attr('style', 'display:block');
                                    }
                                }
                            })
                        });
                    }
                    items.push(itemNumber);
                });
            },

            /**
             * Set correct correct styling for the product carousel if there is an image from Oxomi
             * Let all the existing Javascript occur to load and change DOM and then do the specific Oxomi-related work
             * after 3 seconds.
             */
            showSupplierLogoInStartpageCarousel = function () {
                    setTimeout(function () {
                        let oxomiPattern = new RegExp('/.*oxomi.*/');
                          $('.article-grid-pic').each(function () {
                            if ( $(this).attr('src') != '' && oxomiPattern.test($(this).attr('src')) ){
                                $(this).removeClass('logo-only');
                                $(this).next('img').filter('.logo-grid-pic').css('display', '');
                            }
                        });
                    }, 3000);
            },

            bindOxomiUrlToDatasheet = function() {
                    var vidsrc;
                    vidsrc = $('#main-image').attr('src');
                    setTimeout(function () {
                        if (vidsrc.indexOf('oxomi.com') >= 0) {
                            $('.print-datasheet-article-detail').each(function () {
                                var standardDatasheet;
                                standardDatasheet = $(this).attr('href') + '?pictureUrl=' + vidsrc;
                                $(this).attr('href', standardDatasheet);
                            });
                        }
                    }, 500);
            };
    return {
        bindOxomiReadyEvent: bindOxomiReadyEvent,
        bindOxomiFunction: bindOxomiFunction,
        bindOxomiFallback: bindOxomiFallback,
        initOxomiImages: initOxomiImages,
        showSupplierLogoInStartpageCarousel: showSupplierLogoInStartpageCarousel,
        bindOxomiUrlToDatasheet: bindOxomiUrlToDatasheet
    };
})();

module.exports = obeta.oxomi;