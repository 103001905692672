
obeta.user = (function () {
    var bindDeleteUserAddress = function () {
        $('.remove-address-link').on('click', function (e) {
            e.preventDefault();
            var $this = $(this);
            var url = $this.attr('href');
            var isUsedInCart = $this.data('used-cart');
            var isUsedInProject = $this.data('used-project');
            if (isUsedInCart == 1) {
                confirmationParams = {
                    containerId: "remove-address-unable-by-shopping-cart",
                    callbacks: [function () {
                    }]
                };
                obeta.messages.showConfirmation(confirmationParams);
            }else if (isUsedInProject == 1) {
                confirmationParams = {
                    containerId: "remove-address-unable-by-project",
                    callbacks: [function () {
                    }]
                };
                obeta.messages.showConfirmation(confirmationParams);
            } else {
                confirmationParams = {
                    containerId: "remove-address-confirmation",
                    callbacks: [
                        function () {
                        },
                        function () {
                            removeAddressRequest = obeta.helpers.ajax({
                                type: "POST",
                                url: url
                            },
                                    function () {
                                        location.href = $this.data('hrefsuccess');
                                    },
                                    function () {
                                        location.href = $this.data('hrefsuccess');
                                    }
                            );
                        }
                    ]
                };
                obeta.messages.showConfirmation(confirmationParams);
            }
        });
    },
        bindChangeEmail = function () {
            $('#btn-change-email').on('click', function (e) {
                e.preventDefault();
                var $this = $(this);
                if ($this.hasClass('button-deactivated')) {
                    confirmationParams = {
                        containerId: "js-change-email-notification-link-is-sent-message",
                        callbacks: [function () {}]
                    };
                    obeta.messages.showConfirmation(confirmationParams);
                } else {
                    var email = $('#js-user-email-change-input').val(),
                        url = $('#btn-change-email').attr('href');

                    if (!$this.hasClass('button-deactivated')) {
                        $this.addClass('button-deactivated');
                    }

                    obeta.helpers.ajax({
                        type: "POST",
                        url: url,
                        data: {email: email}
                    }, function (data) {
                        confirmationParams = {
                            containerId: "js-change-email-notification-message",
                            callbacks: [function () {}]
                        };
                        obeta.messages.showConfirmation(confirmationParams);
                    }, function (data) {
                        confirmationParams = {
                            containerId: "js-email-not-valid",
                            callbacks: [function () {}]
                        };
                        obeta.messages.showConfirmation(confirmationParams);
                    });
                }
            });

            $('input[name=js-user-email-change-input]').change(function (e) {
                e.preventDefault();
                if ($('#btn-change-email').hasClass('button-deactivated')) {
                    $('#btn-change-email').removeClass('button-deactivated');
                }
            });
        },
        bindChangeSubuserEmail = function () {
            $('#btn-change-subuser-email').on('click', function (e) {
                e.preventDefault();
                var email = $('#js-subuser-email-change-input').val(),
                    url = $('#btn-change-subuser-email').attr('href'),
                    subuserId = $('#userToDisplayId').val();

                obeta.helpers.ajax({
                    type: "POST",
                    url: url,
                    data: {
                        subuserId: subuserId,
                        subuserEmail: email
                    }
                }, function (data) {
                    confirmationParams = {
                        containerId: "js-change-subuser-email-notification-message",
                        callbacks: [function () {}]
                    };
                    obeta.messages.showConfirmation(confirmationParams);
                }, function (data) {
                    confirmationParams = {
                        containerId: "js-email-not-valid",
                        callbacks: [function () {}]
                    };
                    obeta.messages.showConfirmation(confirmationParams);
                });
            });
        },
            bindChangePassword = function () {
                $('.password-empty').focus();
                var $userPasswordField = $('#user-password'),
                        $userPasswordField2 = $('#user-password-2'),
                        $changeUserPasswordButton = $('#change-user-pw');

                $userPasswordField.on('keyup', function (e) {
                    if (e.which == 13) {
                        $userPasswordField2.focus();
                    }
                    if ($userPasswordField.val() == $userPasswordField2.val()) {
                        $changeUserPasswordButton.removeClass('obtooltip');
                    } else {
                        $changeUserPasswordButton.addClass('obtooltip');
                    }
                });

                $userPasswordField2.on('keyup', function (e) {
                    if (e.which == 13 && $userPasswordField.val() == $userPasswordField2.val()) {
                        $changeUserPasswordButton.trigger('click');
                    }
                    if ($userPasswordField.val() == $userPasswordField2.val()) {
                        $changeUserPasswordButton.removeClass('obtooltip');
                    } else {
                        $changeUserPasswordButton.addClass('obtooltip');
                    }
                }).on('click', function (e) {
                    if ($changeUserPasswordButton.hasClass('obtooltip')) {
                        $changeUserPasswordButton.removeClass('obtooltip').attr('data-original-title', 'Passwort speichern');
                    }
                    if ($changeUserPasswordButton.hasClass('button-deactivated')) {
                        $changeUserPasswordButton.removeClass('button-deactivated').addClass('form-submit-button');
                    }
                });

                $changeUserPasswordButton.on('click', function (e) {
                    e.preventDefault();
                    if ($('#change-user-pw').hasClass('button-deactivated')) {
                        alert('Bitte tippen Sie zuerst Ihr neues Passwort ein');
                    } else {
                        if ($('.meterFail').length > 0 || $('.meterWarn').length > 0 || $('#user-password').val() == "Passwort" || $('#user-password').val() == $('.username-section>input').val()) {
                            var confirmationParams = {
                                containerId: "js-pw-not-safe-message",
                                callbacks:
                                        [
                                            function () {

                                            },
                                            function () {

                                            }
                                        ]};
                            obeta.messages.showConfirmation(confirmationParams);
                            return;
                        }
                        if ($('#user-password-2').val() != $('#user-password').val()) {
                            var confirmationParams = {
                                containerId: "js-pw-not-identical-message",
                                callbacks:
                                        [
                                            function () {

                                            },
                                            function () {

                                            }
                                        ]};
                            obeta.messages.showConfirmation(confirmationParams);
                            return;
                        }
                        var url = $('#user-password').attr('href'),
                                newPassword = $('#user-password').val(),
                                subuserId = $('#subuser-id').val();

                        changePasswordRequest = obeta.helpers.ajax({
                            type: "POST",
                            url: url,
                            data: {password: newPassword, subuserId: subuserId}
                        },
                                function (data) {
                                    if (data == '"fail"') {
                                        alert("Passwort konnte nicht geändert werden.");
                                    } else {
                                        location.reload();
                                    }
                                },
                                function (data) {
                                    location.reload();
                                }
                        );
                    }
                });
            },
            bindChangeAccountName = function () {
                $('#account-name-button,#ids-new-button').on('click', function (e) {
                    e.preventDefault();
                    var $input = $('#js-input-accountname-modal'),
                            confirmationParams = {
                                containerId: "js-account-name-change-input",
                                callbacks:
                                        [
                                            function () {},
                                            function () {
                                                var tempname = $input.val();
                                                $('#account-name-form-input').val(tempname);
                                                $('#account-name-form').submit();
                                            }
                                        ],
                                afterShow: function () {
                                    $input.focus();
                                },
                                afterShowOnce: function () {
                                    $input.on('keypress', function (e) {
                                        if (e.which == 13) {
                                            e.preventDefault();
                                            if ($input.val() != '') {
                                                $('.fancybox-outer #confirmation-1').trigger('click');
                                            }
                                        }
                                    });
                                }
                            };
                    obeta.messages.showConfirmation(confirmationParams);
                });
            },
            bindNewUser = function () {
                $('#btn-new-user').on('click', function (e) {
                    e.preventDefault();
                    var emailNotValidatedHref = $('#btn-new-user').data('email-not-validated-href');
                    if (emailNotValidatedHref != "") {
                        var confirmationParams = {
                            containerId: "js-email-not-validated-message",
                            callbacks:
                                    [
                                        function () {
                                            location.href = emailNotValidatedHref;
                                        },
                                        function () {}
                                    ]
                        };
                        obeta.messages.showConfirmation(confirmationParams);
                        return;
                    }
                    var $input = $('#js-input-modal'),
                            confirmationParams = {
                                containerId: "js-user-name-input",
                                callbacks:
                                        [
                                            function () {},
                                            submitNewUser
                                        ],
                                afterShow: function () {
                                    $input.focus();
                                },
                                afterShowOnce: function () {
                                    $input.on('keypress', function (e) {
                                        if (e.which == 13) {
                                            e.preventDefault();
                                            if ($input.val() != '') {
                                                $('.fancybox-outer #confirmation-1').trigger('click');
                                            }
                                        }
                                    });
                                }
                            };
                    obeta.messages.showConfirmation(confirmationParams);
                });
            },
            submitNewUser = function() {
                var tempname = $('#js-input-modal').val(),
                url = $('#btn-new-user').attr('href'),
                interfaceVal = $('#btn-new-user').data('interface');
                if (tempname != '') {
                    obeta.helpers.ajax({
                        type: "POST",
                        url: url,
                        data: {name: tempname,
                            'interface': interfaceVal}
                    },
                            function (data) {
                                var reloadLink = $('#btn-new-user').data('reload');
                                if (reloadLink) {
                                    location.href = reloadLink;
                                    return;
                                }
                                if (data.data != '') {
                                    location.href = data.data;
                                } else {
                                    location.reload();
                                }
                            },
                            function () {});
                } else {
                    return 'prevent-fancybox-close';
                }
            },
            bindDeleteUser = function () {
                $('.delete-user').on('click', function (e) {
                    e.preventDefault();
                    url = $(this).attr('href');
                    confirmationParams = {
                        containerId: "js-user-delete",
                        callbacks: [function () {
                            }, ff = function () {
                                newTemplateRequest = obeta.helpers.ajax({
                                    type: "POST",
                                    url: url
                                },
                                        function (data) {
                                            if (data == '"fail"') {
                                                alert("Benutzer konnte nicht gelöscht werden.");
                                            } else {
                                                location.reload();
                                            }
                                        },
                                        function (data) {
                                        }
                                );
                            }]
                    };
                    obeta.messages.showConfirmation(confirmationParams);
                });
            },
            bindPasswordMeter = function () {
                var spmreq = {//simplepassmeter requirements
                    'container': '#password-meter-container',
                    'showOnFocus': true,
                    'minLength': 12,
                    'defaultText': 'Bitte Passwort eingeben',
                    'ratings': [
                        {'minScore': 0, // NUMBER  The minimum score to hit to trigger this rating.
                            'className': 'meterFail', // STRING  The CSS class to place on the "simplePassMeter" div when this rating is used.
                            'text': 'Das Passwort ist zu unsicher' // STRING  The text to place in the meter UI when this rating is triggered.
                        },
                        {'minScore': 25,
                            'className': 'meterGood',
                            'text': 'Das Passwort ist sicher'
                        },
                        {'minScore': 50,
                            'className': 'meterExcel',
                            'text': 'Das Passwort ist sehr sicher!'
                        }
                    ],
                    requirements: {
                        'minLength': {
                            'value': 12,
                            'message': 'Bitte mindestens 12 Zeichen eingeben'
                        },
                        // Note the remainder of the requirements use a "regex" option versus a "callback" option. If the user's input matches the regex, the requirement is considered passed.
                        'noSpaces': {'value': true, 'regex': '^[^\\s].+[^\\s]$', 'message': 'Das Passwort darf nicht mit Leerzeichen anfangen oder enden.'},
                        'lower': {'value': true, 'regex': '[a-z]+', 'message': 'Das Passwort muss mindestens 1 Kleinbuchstaben enthalten'},
                        'upper': {'value': true, 'regex': '[A-Z]+', 'message': 'Das Passwort muss mindestens 1 Großbuchstaben enthalten'},
                        'numbers': {'value': true, 'regex': '[0-9]+', 'message': 'Das Passwort muss mindestens 1 Ziffer enthalten'}
                    }
                };
                $('#user-password').simplePassMeter(jQuery.extend(spmreq));

            }, bindSelectRolesDropdown = function () {
        $('#user-role-dd').on('change', function (e) {
            e.preventDefault();
            var jsondata = $('#user-role-dd').data('roles');
            var selectedUserName = $(this).val();
            if (selectedUserName == "") {
                return;
            }
            var rights = jsondata[selectedUserName];
            $('.user-permission-checkbox').each(function () {
                var $this = $(this);
                $this.addClass('user-check-unselected');
                $this.removeClass('user-check-selected');
            });

            $(rights).each(function () {
                var className = ".user-permission-checkbox[data-name=" + this + "]";
                $(className).addClass('user-check-selected');
                $(className).removeClass('user-check-unselected');
            });
        });
    },
            bindSelectProjectDropdown = function () {
                $('#standard-project').on('change', function () {

                    if ($(this).find('option:selected').val() >= 0) {
                        $('#user-standard-project-checkbox').hasClass('user-check-unselected')
                                && $('#user-standard-project-checkbox').trigger('click');
                    } else {
                        $('#user-standard-project-checkbox').hasClass('user-check-selected')
                                && $('#user-standard-project-checkbox').trigger('click');
                    }

                });
            },
            bindOpeningTimesCheckbox = function () {
                $('.js-openingtimes-day').on('click', function (e) {
                    e.preventDefault();
                    $("#edit-user-settings").attr("changed", "1");
                    var $this = $(this);

                    if ($this.hasClass("user-check-selected")) {
                        $this.addClass('user-check-unselected');
                        $this.removeClass('user-check-selected');
                        dayname = $this.data('name');
                        $('.settings-weekday-time[name=' + dayname + ']').css('visibility', 'hidden');
                    } else {
                        $this.removeClass('user-check-unselected');
                        $this.addClass('user-check-selected');
                        dayname = $this.data('name');
                        $('.settings-weekday-time[name=' + dayname + ']').css('visibility', 'inherit');

                    }
                });
            },
            bindSubmitSettings = function () {
                $('#abort-button').on('click', function (e) {
                    e.preventDefault();
                    $("#edit-user-settings").attr("changed", "0");
                    location.reload();
                }
                );
                $('.settings-day-time').on('blur', function (e) {
                    e.preventDefault();
                    var $this = $(this);
                    if ($this.val() == '') {
                        $this.val('00');
                    }
                    inpVal = parseInt($this.val());
                    if ($this.hasClass('opentime-hour1') || $this.hasClass('opentime-hour2')) {
                        if (inpVal > 24) {
                            var showValidationOpentime = {
                                containerId: 'js-setting-view-check-opentime',
                                callbacks:
                                        [
                                            function () {}
                                        ]
                            };
                            obeta.messages.showConfirmation(showValidationOpentime);
                            $this.val(23);
                        }
                    } else if ($this.hasClass('opentime-minute1') || $this.hasClass('opentime-minute2')) {
                        if (inpVal > 59) {
                            var showValidationOpentime = {
                                containerId: 'js-setting-view-check-opentime',
                                callbacks:
                                        [
                                            function () {}
                                        ]
                            };
                            obeta.messages.showConfirmation(showValidationOpentime);
                            $this.val(59);
                        }
                    }
                    inpName = $this.attr('name');
                    inpNameParts = inpName.split('_');
                    inpDayName = inpNameParts[0];
                    inpElement = inpNameParts[1];
                    switch (inpElement) {
                        case '1':
                            var hour2Val = $this.parents('.settings-weekday-time').find('.opentime-hour2').val();
                            var min1Val = $this.parents('.settings-weekday-time').find('.opentime-minute1').val();
                            var min2Val = $this.parents('.settings-weekday-time').find('.opentime-minute2').val();
                            if (hour2Val != '') {
                                var hour2 = parseInt(hour2Val);
                                if (inpVal > hour2) {
                                    var showValidationOpentime = {
                                        containerId: 'js-setting-view-check-opentime',
                                        callbacks:
                                                [
                                                    function () {}
                                                ]
                                    };
                                    obeta.messages.showConfirmation(showValidationOpentime);
                                    $this.val(hour2);
                                } else if (inpVal == hour2 && min1Val != '' & min2Val != '') {
                                    var min1 = parseInt(min1Val);
                                    var min2 = parseInt(min2Val);
                                    if (min1 > min2) {
                                        var showValidationOpentime = {
                                            containerId: 'js-setting-view-check-opentime',
                                            callbacks:
                                                    [
                                                        function () {}
                                                    ]
                                        };
                                        obeta.messages.showConfirmation(showValidationOpentime);
                                        $this.parents('.settings-weekday-time').find('.opentime-minute1').val(min2);
                                    }
                                }
                            }
                            break;
                        case '2':
                            var hour2Val = $this.parents('.settings-weekday-time').find('.opentime-hour2').val();
                            var hour1Val = $this.parents('.settings-weekday-time').find('.opentime-hour1').val();
                            var min2Val = $this.parents('.settings-weekday-time').find('.opentime-minute2').val();
                            if (hour1Val != '' && hour2Val != '' && min2Val != '') {
                                hour2 = parseInt(hour2Val);
                                hour1 = parseInt(hour1Val);
                                min2 = parseInt(min2Val);
                                if (hour1 == hour2 && inpVal > min2) {
                                    var showValidationOpentime = {
                                        containerId: 'js-setting-view-check-opentime',
                                        callbacks:
                                                [
                                                    function () {}
                                                ]
                                    };
                                    obeta.messages.showConfirmation(showValidationOpentime);
                                    $this.val(min2);
                                }
                            }
                            break;
                        case '3':
                            var hour1Val = $this.parents('.settings-weekday-time').find('.opentime-hour1').val();
                            var min1Val = $this.parents('.settings-weekday-time').find('.opentime-minute1').val();
                            var min2Val = $this.parents('.settings-weekday-time').find('.opentime-minute2').val();
                            if (hour1Val != '' && min1Val != '') {
                                var hour1 = parseInt(hour1Val);
                                if (hour1 > inpVal) {
                                    var showValidationOpentime = {
                                        containerId: 'js-setting-view-check-opentime',
                                        callbacks:
                                                [
                                                    function () {}
                                                ]
                                    };
                                    obeta.messages.showConfirmation(showValidationOpentime);
                                    $this.val(hour1);
                                } else if (hour1 == inpVal) {
                                    min1 = parseInt(min1Val);
                                    min2 = parseInt(min2Val);
                                    if (min2 < min1) {
                                        var showValidationOpentime = {
                                            containerId: 'js-setting-view-check-opentime',
                                            callbacks:
                                                    [
                                                        function () {}
                                                    ]
                                        };
                                        obeta.messages.showConfirmation(showValidationOpentime);
                                        $this.parents('.settings-weekday-time').find('.opentime-minute2').val(min1);
                                    }
                                }

                            }
                            break;
                        case '4':
                            var hour1Val = $this.parents('.settings-weekday-time').find('.opentime-hour1').val();
                            var min1Val = $this.parents('.settings-weekday-time').find('.opentime-minute1').val();
                            var hour2Val = $this.parents('.settings-weekday-time').find('.opentime-hour2').val();
                            if (min1Val != '' && hour1Val != '' && hour2Val != '') {
                                var hour1 = parseInt(hour1Val);
                                var hour2 = parseInt(hour2Val);
                                var min1 = parseInt(min1Val);
                                if (min1 > inpVal && hour1 == hour2) {
                                    var showValidationOpentime = {
                                        containerId: 'js-setting-view-check-opentime',
                                        callbacks:
                                                [
                                                    function () {}
                                                ]
                                    };
                                    obeta.messages.showConfirmation(showValidationOpentime);
                                    $this.val(min1);
                                }
                            }
                            break;
                    }

                });
                $('#submit-user-settings').on('click', function (e) {
                    e.preventDefault();
                    $("#edit-user-settings").attr("changed", "0");
                    if ($('#input-postcode').val() == "Ihre Postleitzahl") {
                        $('#input-postcode').val('');
                    }
                    if ($('#js-input-city').val() == "Ihre Stadt") {
                        $('#js-input-city').val('');
                    }
                    if ($('#js-input-street').val() == "Ihre Strasse und Hausnummer") {
                        $('#js-input-street').val('');
                    }
                    if ($('#js-input-name').val() == "Ihr Name") {
                        $('#js-input-name').val('');
                    }
                    if ($('#user-input-name').val() == "Vorname Name") {
                        $('#user-input-name').val('');
                    }
                    if ($('#user-input-domainname').val() == "http://www.domainname.de") {
                        $('#user-input-domainname').val('');
                    }
                    if ($('#user-input-email').val() == "info@domainname.de") {
                        $('#user-input-email').val('');
                    }
                    if ($('#user-input-tel').val() == "030 0000 000") {
                        $('#user-input-tel').val('');
                    }
                    if ($('#user-input-fax').val() == "030 0000 000") {
                        $('#user-input-fax').val('');
                    }
                    var $form = $('#edit-user-settings');
                    if ($('#js-show-company-contact').hasClass('user-check-selected')) {
                        $form.parsley('validate');
                    }
                    if ($('input.parsley-error').length > 0) {
                        return;
                    }
                    $('.user-permission-checkbox').add('.js-openingtimes-day').each(function () {
                        var $this = $(this);
                        var rightName = $this.data('name');
                        if ($this.hasClass('user-check-selected')) {
                            $('[name="' + rightName + '"]').val(1);
                        } else {
                            $('[name="' + rightName + '"]').val(0);
                        }
                    });

                    $form.submit();

                })
            },
            bindSubmitMyDatasheet = function () {
                $('#mydatasheet-submit').on('click', function (e) {
                    e.preventDefault();
                    var showlogo = '', articleprice = '';

                    $('#mydatasheet-settings .user-permission-checkbox').each(function () {
                        var $this = $(this),
                                rightName = $this.data('name');
                        if ($this.hasClass('user-check-selected')) {
                            $('[name="' + rightName + '"]').val(1);
                        } else {
                            $('[name="' + rightName + '"]').val(0);
                        }
                    });

                    $('#mydatasheet-settings .datasheet-logo-area .showlogo').each(function () {
                        $this = $(this);
                        if ($this.find('span').hasClass('radio-checked')) {
                            showlogo = $this.prev('#showlogo').val();
                        }
                    });

                    $('#mydatasheet-settings .articleprice').each(function () {
                        $this = $(this);
                        if ($this.find('span').hasClass('radio-checked')) {
                            articleprice = $this.prev('#articleprice').val();
                        }
                    });

                    $('#showlogo').val(showlogo);
                    $('#articleprice').val(articleprice);
                    $('#mydatasheet-settings').submit();

                });
            },
            bindSubmitUser = function () {
                $('#submit-user-roles').on('click', function (e) {
                    e.preventDefault();
                    if ($('#user-password').hasClass('password-empty')) {
                        var noPasswordMessage = {
                            containerId: 'js-no-password-message',
                            callbacks: [
                                function () {
                                    $('.password-empty').focus();

                                }
                            ]
                        };
                        obeta.messages.showConfirmation(noPasswordMessage);
                        return;
                    }
                    var $form = $('#edit-user-rights');
                    $('.user-permission-checkbox').each(function () {
                        var $this = $(this);
                        var rightName = $this.data('name');
                        if ($this.hasClass('user-check-selected')) {
                            $('[name="p_' + rightName + '"]').val(true);
                        } else {
                            $('[name="p_' + rightName + '"]').val(false);
                        }
                    });

                    if ($('#permission-notify-shoppingcart').hasClass('user-check-selected')) {
                        $('#email-notify-shoppingcart').parsley('validate');
                        if ($form.find('.parsley-error').length > 0) {
                            return;
                        }
                    }

                    submitUserRights = $.ajax({
                        type: "POST",
                        url: $form.attr('action'),
                        data: $form.serialize()
                    })
                            .done(function () {
                                location.reload();
                            })
                            .fail(function () {});
                });
            },
            bindProjectRadios = function () {
                var $changeProjects = $('#permission-change-projects'),
                        $showProjects = $('#permission-show-projects'),
                        $useMyProject = $('#permission-use-my-project'),
                        $defaultProjectSelector = $('#edit-user-rights').find('a.selectBox'),
                        $userProjectCheckbox = $('#user-standard-project-checkbox'),
                        $changeCommission = $('#permission-change-commission'),
                        $commissionIsObligatory = $('#permission-commission-is-obligatory'),
                        $notifyShoppingcart = $('#permission-notify-shoppingcart'),
                        $emailNotifyShoppingcart = $('#email-notify-shoppingcart'),
                        $showPurchasePrices = $('#permission-showPurchasePrices'),
                        $showCatalogPrices = $('#permission-showCatalogPrices'),
                        $showListenPrices = $('#permission-showListenPrices'),
                        $piggyBankOptIn = $('#piggyBankOptIn');

                $changeProjects.on('click', function (e) {
                    $(this).hasClass('user-check-selected')
                            && !$showProjects.hasClass('user-check-selected')
                            && $showProjects.trigger('click');
                });
                $showProjects.on('click', function (e) {
                    !$(this).hasClass('user-check-selected')
                            && $changeProjects.hasClass('user-check-selected')
                            && $changeProjects.trigger('click');
                });
                $showPurchasePrices.on('click', function (e) {
                    e.preventDefault();
                    if ($(this).hasClass('user-check-selected')) {
                        $showCatalogPrices.prop('class', '');
                        $showListenPrices.prop('class', '');
                        $showCatalogPrices.addClass('user-permission-checkbox user-check-selected');
                        $showListenPrices.addClass('user-permission-checkbox user-check-selected');
                    }
                });
                $showCatalogPrices.on('click', function (e) {
                    e.preventDefault();
                    if ($(this).hasClass('user-check-selected')) {
                        $showListenPrices.prop('class', '');
                        $showListenPrices.addClass('user-permission-checkbox user-check-selected');
                    }
                });
                $useMyProject.on('click', function (e) {
                    e.preventDefault();
                    // after click this element has immediately class "user-check-selected" => radio is selected
                    if ($(this).hasClass('user-check-selected')) {
                        $defaultProjectSelector.addClass('selectBox-disabled');
                        $showProjects.removeClass('user-check-selected');
                        $showProjects.addClass('user-check-unselected');
                        $showProjects.addClass('checkbox-disabled');
                        $userProjectCheckbox.removeClass('user-check-selected');
                        $userProjectCheckbox.addClass('user-check-unselected');
                        $userProjectCheckbox.addClass('checkbox-disabled');
                        $changeProjects.removeClass('user-check-selected');
                        $changeProjects.addClass('user-check-unselected');
                        $changeProjects.addClass('checkbox-disabled');
                        $showProjects.next('.user-text').addClass('text-grey');
                        $userProjectCheckbox.next('.user-text').addClass('text-grey');
                        $changeProjects.next('.user-text').addClass('text-grey');
                    } else { // radio of $useMyProject is not selected
                        $defaultProjectSelector.removeClass('selectBox-disabled');
                        $userProjectCheckbox.removeClass('checkbox-disabled');
                        $userProjectCheckbox.removeClass('user-check-selected');
                        $userProjectCheckbox.addClass('user-check-unselected');
                        $changeProjects.removeClass('checkbox-disabled');
                        $showProjects.removeClass('checkbox-disabled');
                        $showProjects.next('.user-text').removeClass('text-grey');
                        $userProjectCheckbox.next('.user-text').removeClass('text-grey');
                        $changeProjects.next('.user-text').removeClass('text-grey');
                    }
                });

                $notifyShoppingcart.on('click', function (e) {
                    e.preventDefault();
                    if ($(this).hasClass('user-check-selected')) {
                        $emailNotifyShoppingcart.removeAttr('disabled');
                    } else {
                        $emailNotifyShoppingcart.removeClass('parsley-validated parsley-error');
                        $emailNotifyShoppingcart.next('ul').remove();
                        $emailNotifyShoppingcart.attr('disabled', 'disabled');
                    }
                });

                $changeCommission.on('click', function (e) {
                    e.preventDefault();
                    if ($(this).hasClass('user-check-selected')) {
                        $commissionIsObligatory.removeClass('checkbox-disabled');
                        $commissionIsObligatory.removeClass('user-check-selected');
                        $commissionIsObligatory.addClass('user-check-unselected');
                        $commissionIsObligatory.next('.user-text').removeClass('text-grey');
                    } else {
                        $commissionIsObligatory.removeClass('user-check-selected');
                        $commissionIsObligatory.addClass('user-check-unselected');
                        $commissionIsObligatory.addClass('checkbox-disabled');
                        $commissionIsObligatory.next('.user-text').addClass('text-grey');
                    }
                });

                $commissionIsObligatory.on('click', function (e) {
                    e.preventDefault();
                    if ($(this).hasClass('user-check-selected')) {
                        $changeCommission.prop('class', '');
                        $changeCommission.addClass('user-permission-checkbox user-check-selected');
                    }
                });

                $('#email-notify-shoppingcart').on('blur', function (e) {
                    e.preventDefault();
                    $(this).parsley('validate');
                });


                $piggyBankOptIn.on('click', function (e) {
                    $(this).hasClass('user-check-selected')
                    && !$showProjects.hasClass('user-check-selected')
                    && $showProjects.trigger('click');
                });
            },
            bindSettingAdditionalText = function () {
                currentUrl = window.location.href;
                if (currentUrl.indexOf('einstellungen') !== -1) {
                    var max = parseInt($('#dataSheetAdditionalText').attr('maxlength'));
                    if ($('#dataSheetAdditionalText').val() && $('#dataSheetAdditionalText').val().length > 0) {
                        $('.ds-charleft').html(max - ($('#dataSheetAdditionalText').val().length));
                    }
                    $('#dataSheetAdditionalText').keyup(function () {
                        if ($(this).val().length > max) {
                            $(this).val($(this).val().substr(0, max));
                        }
                        $('.ds-charleft').html(max - $(this).val().length);
                    });
                }
                if ($('#mydatasheet-submit').is(':visible')) {
                    var max = parseInt($('#myDsAdditionalText').attr('maxlength'));
                    if ($('#myDsAdditionalText').val().length > 0) {
                        $('.myDs-charleft').html(max - ($('#myDsAdditionalText').val().length));
                    }
                    $('#myDsAdditionalText').keyup(function () {
                        if ($(this).val().length > max) {
                            $(this).val($(this).val().substr(0, max));
                        }
                        $('.myDs-charleft').html(max - $(this).val().length);
                    });
                }
                obeta.helpers.initCharCounter();
                if ($('#js-edit-project-form').find('.form-submit-button').is(':visible')) {
                    var $textObjProject = $('#js-edit-project-form').find('#js-project-additional-input'),
                            $charleftObjProject = $('#charleft-project'),
                            maxLengthProject = parseInt($textObjProject.attr('maxlength'));

                    if ($textObjProject.val().length > 0) {
                        $charleftObjProject.html(maxLengthProject - $textObjProject.val().length);
                    }
                    $textObjProject.keyup(function () {
                        if ($(this).val().length > maxLengthProject) {
                            $(this).val($(this).val().substr(0, maxLengthProject));
                        }
                        $charleftObjProject.html(maxLengthProject - $(this).val().length);
                    });
                }

            },
            detectFormChange = function () {
                if ($("#edit-user-settings").length) {
                    $("#edit-user-settings input").change(function () {
                        $("#edit-user-settings").attr("changed", "1");
                    });
                    $('#edit-user-settings .user-permission-checkbox').on('click',function(){
                        $("#edit-user-settings").attr("changed", "1");
                    });
                    $('#edit-user-settings .custom-dd').on('click',function(){
                        $("#edit-user-settings").attr("changed", "1");
                    });
                    $(window).on('beforeunload', function (e) {
                        if ($('#edit-user-settings').attr("changed") == "0") {

                        } else {
                            e.preventDefault();
                            if ($('#spinner').is(':visible')) {
                                obeta.helpers.hideSpinner();
                            }
                            return 'Möchten Sie wirklich Ihre Änderungen verwerfen ? Sie haben nicht auf Speichern geklickt.';
                        }
                    });
                }
            },
            bindDeleteSubUser = function () {
                $('.user-permission-checkbox').on('click', function (e) {
                    e.preventDefault();
                    var $this = $(this);
                    if (!$this.hasClass('checkbox-disabled')) {
                        $this.toggleClass('user-check-selected');
                        $this.toggleClass('user-check-unselected');
                    }
                });
            };

    return {
        bindDeleteUserAddress: bindDeleteUserAddress,
        bindSelectRolesDropdown: bindSelectRolesDropdown,
        bindSubmitUser: bindSubmitUser,
        bindSubmitSettings: bindSubmitSettings,
        bindDeleteSubUser: bindDeleteSubUser,
        bindPasswordMeter: bindPasswordMeter,
        bindNewUser: bindNewUser,
        bindDeleteUser: bindDeleteUser,
        bindChangePassword: bindChangePassword,
        bindOpeningTimesCheckbox: bindOpeningTimesCheckbox,
        bindSubmitMyDatasheet: bindSubmitMyDatasheet,
        bindChangeEmail: bindChangeEmail,
        bindChangeSubuserEmail: bindChangeSubuserEmail,
        bindSettingAdditionalText: bindSettingAdditionalText,
        bindProjectRadios: bindProjectRadios,
        bindSelectProjectDropdown: bindSelectProjectDropdown,
        bindChangeAccountName: bindChangeAccountName,
        submitNewUser: submitNewUser,
        detectFormChange: detectFormChange
    };
})();

module.exports = obeta.user;