
obeta.shoppingCartTemplates = (function () {

    let getShoppingCartSummaryLoading = function () {
        let scTemplateData = window.obeta.scTemplateData;

        let summaryHead =
            '<div class="loader">' +
                '<div class="order-loading-icon spin"></div>' +
            '</div>' +
            '<p class="sc-summary-title-cart" id="sc-summary-loading">' +
                'Ihr Auftrag wird angelegt...' +
            '</p>';

        let summaryBody = getItemHtml('Positionsanzahl', scTemplateData.positionCount);
        // only render prices if present (customer has permission to see them)
        if (scTemplateData.nettoPrice) {
            summaryBody += getItemHtml('Auftragswert', scTemplateData.nettoPrice + ' €');
        }
        if (scTemplateData.sum) {
            summaryBody += getItemHtml('Summe', scTemplateData.sum + ' € brutto');
        }
        if (scTemplateData.hasSpecialOrDiversePosition) {
            summaryBody += getItemHtml('Gewünschtes Lieferdatum', 'Bei Sonderartikeln ist das tatsächliche Lieferdatum abhängig vom Hersteller.');
        } else {
            summaryBody += getItemHtml('Gewünschtes Lieferdatum', scTemplateData.deliveryData);
        }
        summaryBody += getItemHtml('Kommission', scTemplateData.commission);

        return getSummaryBoxHtml(summaryHead, summaryBody);
    };

    let getShoppingCartSummarySuccess = function (orderData) {
        let data = (typeof orderData.data === 'undefined')
            ? orderData
            : orderData.data;
        let orderId = data.orderId;
        let date = data.date;
        let time = data.time;
        let printWithPrices = "/warenkorb/" + orderId + "/auftragsbestaetigung/1";
        let printWithOutPrices = "/warenkorb/" + orderId + "/auftragsbestaetigung/0";

        let summaryHead =
            '<div class="loader">' +
                '<div class="order-success-icon"></div>' +
            '</div>' +
            '<p class="sc-summary-title-cart">' +
                'Danke für Ihre Bestellung...' +
            '</p>';

        let summaryBody =
            '<div class="sc-summary-success-info">' +
                '<p>Wir haben Ihren Auftrag erhalten und werden ihn umgehend bearbeiten.<p>' +
            '</div>' +
            '<div class="sc-summary-details">' +
                '<p>Auftragsdetails</p>' +
                '<ul class="sc-summary-details-list">' +
                    '<li class="sc-summary-details-item">' +
                        'Nr.:' +
                        '<span>' + orderId + '</span>' +
                    '</li>' +
                    '<li class="sc-summary-details-item">' +
                        'Datum:' +
                        '<span>' + date + '</span>' +
                    '</li>' +
                    '<li class="sc-summary-details-item">' +
                        'Uhrzeit:' +
                        '<span>' + time + '</span>' +
                    '</li>' +
                '</ul>' +
            '</div>';

        let summaryPrint =
            '<div class="sc-summary-print">' +
                '<a href="' + printWithPrices + '" target="_blank" class="sc-summary-button-print-pdf">' +
                    '<p>Auftrag mit Preisen drucken</p>' +
                '</a>' +
                '<a href="' + printWithOutPrices + '" target="_blank" class="sc-summary-button-print-pdf">' +
                    '<p>Auftrag ohne Preise drucken</p>' +
                '</a>' +
            '</div>';

        return getSummaryBoxHtml(summaryHead, summaryBody, summaryPrint);
    };

    let getShoppingCartSummaryFailure = function () {
        let summaryHead =
            '<div class="loader">' +
                '<div class="order-failure-icon"></div>' +
            '</div>' +
            '<p class="sc-summary-title-cart-failure">' +
                'Leider ist ein Problem aufgetretenen' +
            '</p>';

        let summaryBody =
            '<div class="sc-summary-failure-info">' +
                '<p>Ihre Bestellung wurde an unseren Support übermittelt und wird dort weiter bearbeitet.</p>' +
                '<div class="sc-summary-contact">' +
                    'T:' +
                    '<span>030 720 94 777</span>' +
                '</div>' +
            '</div>';

        return getSummaryBoxHtml(summaryHead, summaryBody);
    };
    let getShoppingCartSummaryFailureForOrderIdMissingInResponse = function (reason) {
        let summaryHead =
            '<div class="loader">' +
                '<div class="order-failure-icon"></div>' +
            '</div>' +
            '<p class="sc-summary-title-cart-failure">' +
                'Leider ist ein Problem aufgetreten...' +
            '</p>';

        let summaryBody =
            '<div class="sc-summary-failure-info">' +
                '<p>Bitte wenden Sie sich an den Shop Support</p>' +
                '<p style="font-weight: normal">' + reason + '</p>' +
                '<div class="sc-summary-contact">' +
                    'T:' +
                    '<span>030 720 94 777</span>' +
                '</div>' +
            '</div>';

        return getSummaryBoxHtml(summaryHead, summaryBody);
    };

    /* HTML build Helper Functions */

    let getItemHtml = function (name, item) {
        if (item === '') {
            return '';
        }
        return  '<span class="sc-summary-left">' +
                    '<span>' +
                        name +
                    '</span>' +
                    '</span>' +
                    '<span class="sc-summary-right">' +
                        item +
                '</span>';

    };
    let getSummaryBoxHtml = function (summaryHead, summaryBody, summaryPrint) {
        if (summaryPrint === null || summaryPrint === undefined) {
            summaryPrint = '';
        }
        return  '<div class="sc-summary-body">' +
                    '<div id="sc-summary-box-head" class="sc-summary-box-head">' +
                            summaryHead +
                    '</div>' +
                    summaryBody +
                '</div>' +
                '<form class="sc-summary-form" name="printPdf" id="printPdfForm" method="post">' +
                    summaryPrint +
                    '<div class="sc-summary-button" id="sc-summary-button">' +
                        '<b>Weiter einkaufen</b>' +
                    '</div>' +
                '</form>';
    };
    return {
        getShoppingCartSummaryLoading: getShoppingCartSummaryLoading,
        getShoppingCartSummarySuccess: getShoppingCartSummarySuccess,
        getShoppingCartSummaryFailure: getShoppingCartSummaryFailure,
        getShoppingCartSummaryFailureForOrderIdMissingInResponse: getShoppingCartSummaryFailureForOrderIdMissingInResponse,
        getSummaryBoxHtml: getSummaryBoxHtml
    };
})();

module.exports = obeta.shoppingCartTemplates;