

obeta.userInterface = (function () {

    var bindFancyboxClicks = function () {

            $("#main-image").click(function (e) {
                e.preventDefault();
                $('#moreImagesFancyboxContainer').removeClass('oxomi-show-video');
                var $this = $(this);
                if ($this.hasClass('oxomi-video-prev')) {
                    $('.oxomi-click-video').click();
                    return;
                }
                var imagesLimit = 5; // number of visible images on thumbnail list
                var bigImageUrl = $this.attr('data-high'),
                    imageIndex = parseInt($this.attr('data-index')),
                    moreImagesCount = parseInt($('#more-images-number').val());

                if (typeof (bigImageUrl) != 'undefined' || $this.hasClass('turntable-clicked') || $this.hasClass('turntable-show')) {
                    $('#fancybox-more-images-container #main-image-fancybox').attr('src', bigImageUrl);
                    $('#fancybox-more-images-container #main-image-fancybox').attr('data-index', imageIndex);

                    $moreImagesFancybox = $('#fancybox-more-images-container .more-images-container');
                    $moreImagesFancyboxOriginal = $moreImagesFancybox.clone(true);
                    $('#moreImagesFancyboxContainer').html('');
                    $moreImagesFancyboxOriginal.appendTo('#moreImagesFancyboxContainer');
                    if ($this.hasClass('turntable-clicked') || $this.hasClass('turntable-show')) {
                        $('.main-image-wrapper-fancybox').hide();
                        $('.turntable').show();
                    } else {
                        $('.main-image-wrapper-fancybox').show();
                        $('.turntable').hide();
                    }
                    $.fancybox({
                        prevEffect: 'none',
                        nextEffect: 'none',
                        href: '#moreImagesFancyboxContainer',
                        closeBtn: true,
                        afterShow: function () {
                            if (moreImagesCount > imagesLimit) {
                                $('#more-images-fancybox-prev-button,#more-images-fancybox-next-button').show();
                                // start position of image on slider so that selected image is always shown on list
                                var startPos = (imageIndex >= imagesLimit) ? imageIndex - imagesLimit + 1 + 2 : ((imageIndex <= 2) ? 0 : imageIndex - 2);
                                $('#moreImagesFancyboxContainer #more-images-fancybox').jCarouselLite({
                                    btnNext: "#moreImagesFancyboxContainer #more-images-fancybox-next-button",
                                    btnPrev: "#moreImagesFancyboxContainer #more-images-fancybox-prev-button",
                                    visible: imagesLimit,
                                    start: startPos
                                });
                                obeta.search.turntableThumbInit();
                            }
                            if ($('#main-image').hasClass('turntable-clicked') || $('#main-image').hasClass('turntable-show')) {
                                $('#main-image').removeClass('turntable-clicked');
                                $('.turntable-thumbnail').trigger('click');
                            }
                            obeta.userInterface.bindMoreImagesFancybox();
                        }
                    });
                }
            });

            $('#history-slider .fancybox').on('click', function () {
                var bigImageUrl = $(this).find('img').attr('data-high');
                $.fancybox.open(bigImageUrl);
            });

            $("#page-print").on('click', function (e) {
                e.preventDefault();
                var bindPrintDatasheet = {
                    containerId: 'js-datasheet-template',
                    callbacks: [
                        function () {},
                        function () {}
                    ]
                }
                obeta.messages.showConfirmation(bindPrintDatasheet);
            });

            $('.user-toogle-button').on('click', function (e) {
                $(".ids-oci-permission-inner").animate({
                    "height": "toggle"
                }, {
                    duration: 600
                });
            });

            $("#calculate-metal-addition").on('click', function (e) {
                e.preventDefault();
                var bindCalculateMetalAdditon = {
                    containerId: 'js-calculate-metal-addition',
                    callbacks: [
                        function () {}
                    ]
                }
                obeta.messages.showConfirmation(bindCalculateMetalAdditon);
            });

            $("#discontinued-and-replaced-article").on('click', function (e) {
                e.preventDefault();
                var discontinuedAndReplacedArticle = {
                    containerId: 'js-discontinued-and-replaced-article',
                    fancyboxOptions: {
                        closeBtn: false
                    },
                    callbacks: [
                        function () {}
                    ]
                }
                obeta.messages.showConfirmation(discontinuedAndReplacedArticle);
            });

            $('#new-customer-link').on('click', function (e) {
                e.preventDefault();
                var bindNewCustomerAsking = {
                    containerId: 'js-asking-for-new-customer-template',
                    callbacks: [
                        function () {},
                        function () {
                            $('.new-customer-radio').each(function (e) {
                                var $this = $(this);
                                if ($this.find('b').hasClass('radButSel')) {
                                    var url = $this.data('url');
                                    location.href = url;
                                }
                            })
                        }
                    ]
                }
                obeta.messages.showConfirmation(bindNewCustomerAsking);
            });

            $('#registered-customer').on('click', function (e) {
                var $this = $(this);
                if ($this.find('b').hasClass('radBut')) {
                    $this.find('b').removeClass('radBut');
                    $this.find('b').addClass('radButSel');
                }
                if ($('#unregistered-customer').find('b').hasClass('radButSel')) {
                    $('#unregistered-customer').find('b').removeClass('radButSel');
                }
                $('#unregistered-customer').find('b').addClass('radBut');
            });

            $('#unregistered-customer').on('click', function (e) {
                var $this = $(this);
                if ($this.find('b').hasClass('radBut')) {
                    $this.find('b').removeClass('radBut');
                    $this.find('b').addClass('radButSel');
                }
                if ($('#registered-customer').find('b').hasClass('radButSel')) {
                    $('#registered-customer').find('b').removeClass('radButSel');
                }
                $('#registered-customer').find('b').addClass('radBut');
            });

            /**
             * Einzureichende Unterlagen und AGB sowie Werbung Newsletter
             */
            $('.page-nc-checkbox').on('click', function (e) {
                e.preventDefault();
                $this = $(this);
                if ($this.find('b').hasClass('radio-normal')) {
                    $this.find('b').removeClass('radio-normal');
                    $this.find('b').addClass('radio-check-selected');
                    if ($this.attr('id') == 'page-nc-branch-assign') {
                        $('.page-nc-branch-company').css('display', 'block');
                    }
                    if ($this.attr('id') == 'page-nc-payment-invoice') {
                        $('.page-nc-payment-detail').css('display', 'block');
                    }
                    if ($this.attr('id') == 'page-nc-payment-cash') {
                        if ($('#page-nc-payment-invoice').find('b').hasClass('radio-normal')) {
                            $('.page-nc-payment-detail').css('display', 'none');
                        }
                    }
                    if ($this.attr('id') == 'page-nc-terms-checkbox') {
                        $('#read-terms-conditions').val('1');
                        $('.obeta-newcustomer-term').removeClass('obeta-newcustomer-warning');
                    }
                    if ($this.attr('id') == 'page-nc-sendcopydocu-checkbox') {
                        $('#read-terms-conditions').val('1');
                        $('.obeta-newcustomer-sendcopydocu').removeClass('obeta-newcustomer-warning');
                    }
                    if ($this.attr('id') == 'page-nc-newsletter-post' || $this.attr('id') == 'page-nc-newsletter-fax' || $this.attr('id') == 'page-nc-newsletter-email') {
                        $('#page-nc-newsletter-checkbox').find('b').removeClass('radio-normal');
                        $('#page-nc-newsletter-checkbox').find('b').addClass('radio-check-selected');
                    }
                } else {
                    $this.find('b').removeClass('radio-check-selected');
                    $this.find('b').addClass('radio-normal');
                    if ($this.attr('id') == 'page-nc-newsletter-checkbox') {
                        $('#page-nc-newsletter-post').find('b').removeClass('radio-check-selected');
                        $('#page-nc-newsletter-fax').find('b').removeClass('radio-check-selected');
                        $('#page-nc-newsletter-email').find('b').removeClass('radio-check-selected');

                        $('#page-nc-newsletter-post').find('b').addClass('radio-normal');
                        $('#page-nc-newsletter-fax').find('b').addClass('radio-normal');
                        $('#page-nc-newsletter-email').find('b').addClass('radio-normal');
                    }
                    if ($this.attr('id') == 'page-nc-branch-assign') {
                        $('.page-nc-branch-company').css('display', 'none');
                    }
                    if ($this.attr('id') == 'page-nc-payment-cash') {
                        $('.page-nc-payment-detail').css('display', 'block');
                    }
                    if ($this.attr('id') == 'page-nc-payment-invoice') {
                        if ($('#page-nc-payment-cash').find('b').hasClass('radio-check-selected')) {
                            $('.page-nc-payment-detail').css('display', 'none');
                        }
                    }
                    if ($this.attr('id') == 'page-nc-terms-checkbox') {
                        $('#read-terms-conditions').val('');
                    }
                }
            });

            /**
             * Details zu Ihrer Branche
             */
            $('#page-nc-branch-electric').on('click', function (e) {
                e.preventDefault();
                var $this = $(this);
                if ($this.find('b').hasClass('radBut')) {
                    $this.find('b').removeClass('radBut');
                    $this.find('b').addClass('radButSel');
                }
                $('.page-nc-branch-name-detail').css('display', 'none');
                if ($('#page-nc-branch-other').find('b').hasClass('radButSel')) {
                    $('#page-nc-branch-other').find('b').removeClass('radButSel');
                }
                $('#page-nc-branch-other').find('b').addClass('radBut');
            });

            $('#page-nc-branch-other').on('click', function (e) {
                e.preventDefault();
                var $this = $(this);
                if ($this.find('b').hasClass('radBut')) {
                    $this.find('b').removeClass('radBut');
                    $this.find('b').addClass('radButSel');
                }
                $('.page-nc-branch-name-detail').css('display', 'block');
                if ($('#page-nc-branch-electric').find('b').hasClass('radButSel')) {
                    $('#page-nc-branch-electric').find('b').removeClass('radButSel');
                }
                $('#page-nc-branch-electric').find('b').addClass('radBut');
            });

            $('#button-add-more-chief').on('click', function (e) {
                e.preventDefault();
                var $this = $(this),
                    $moreChiefBox = $('.more-chief-box.chief'),
                    $moreChiefBoxClone = $moreChiefBox.clone(true),
                    inputId = 1;

                $moreChiefBoxClone.find('.parsley-error-list').css('display', 'none');
                $moreChiefBoxClone.find('.indicator-star').html('');
                $moreChiefBoxClone.find('input').each(function () {
                    inputId = parseInt($(this).attr('data-index'));
                    var newInputId = inputId + 1;
                    var inputIdText = $(this).attr('id').split('_');
                    $(this).attr('id', inputIdText[0] + '_' + newInputId);
                    $(this).attr('name', inputIdText[0] + '_' + newInputId);
                    $(this).attr('data-index', newInputId);

                    $(this).val('');
                    if ($(this).hasClass('parsley-error')) {
                        $(this).removeClass('parsley-error');
                        $(this).addClass('parsley-success');
                    }
                });
                $moreChiefBoxClone.find('.page-nc-close-more').show();
                $moreChiefBoxClone.appendTo($this.parents('.page-nc-right').find('.append-to-this-wrapper'));
                $moreChiefBox.removeClass('chief');
                $moreChiefBox.addClass('chief' + inputId);

            });

            $('#button-fix-as-chief-contact-person').on('click', function (e) {
                e.preventDefault();
                var companyEmail = $('#company-email').val(),
                    companyPhoneCountry = $('#company-phone-country').val(),
                    companyPhoneCity = $('#company-phone-city').val(),
                    companyPhoneMain = $('#company-phone-main').val(),
                    companyFaxCountry = $('#company-fax-country').val(),
                    companyFaxCity = $('#company-fax-city').val(),
                    companyFaxMain = $('#company-fax-main').val(),
                    bossName = $('#input-chief-name_1').val();

                if (companyEmail != '' && companyEmail != 'info@domainname.de') {
                    $('#input-partner-email_1').val(companyEmail);
                    $('#input-partner-email_1').removeClass('default-text-active');
                }
                if (bossName != '' && bossName != 'Vorname und Name Ihres Geschäftsführers') {
                    $('#input-partner-name_1').val(bossName);
                    $('#input-partner-name_1').removeClass('default-text-active');
                    $('#input-partner-position_1').val('Geschäftsführer');
                    $('#input-partner-position_1').removeClass('default-text-active');
                }
                if (companyPhoneCountry != '') {
                    $('#input-partner-tel-countrycode_1').val(companyPhoneCountry);
                    $('#input-partner-tel-countrycode_1').removeClass('default-text-active');
                }
                if (companyPhoneCity != '' && companyPhoneCity != 'Vorwahl') {
                    $('#input-partner-tel-areacode_1').val(companyPhoneCity);
                    $('#input-partner-tel-areacode_1').removeClass('default-text-active');
                }
                if (companyPhoneMain != '' && companyPhoneMain != 'Rufnummer') {
                    $('#input-partner-tel-number_1').val(companyPhoneMain);
                    $('#input-partner-tel-number_1').removeClass('default-text-active');
                }
                if (companyFaxCountry != '') {
                    $('#input-partner-fax-countrycode_1').val(companyFaxCountry);
                    $('#input-partner-fax-countrycode_1').removeClass('default-text-active');
                }
                if (companyFaxCity != '' && companyFaxCity != 'Vorwahl') {
                    $('#input-partner-fax-areacode_1').val(companyFaxCity);
                    $('#input-partner-fax-areacode_1').removeClass('default-text-active');
                }
                if (companyFaxMain != '' && companyFaxMain != 'Rufnummer') {
                    $('#input-partner-fax-number_1').val(companyFaxMain);
                    $('#input-partner-fax-number_1').removeClass('default-text-active');
                }

            });

            $('#button-add-more-partner').on('click', function (e) {
                e.preventDefault();
                var $this = $(this),
                    partnerAmount = $('.more-partner-box').length;

                if (partnerAmount >= 3) {
                    $this.css('display', 'none');
                } else {
                    $this.css('display', 'block');
                }

                var
                    $morePartnerBox = $('.more-partner-box.partner'),
                    $morePartnerBoxClone = $morePartnerBox.clone(true),
                    inputId = 1;

                $morePartnerBoxClone.find('ul.parsley-error-list').css('display', 'none');
                $morePartnerBoxClone.find('.indicator-star').html('');
                $morePartnerBoxClone.find('input').each(function () {
                    inputId = parseInt($(this).attr('data-index'));
                    var newInputId = inputId + 1;
                    var inputIdText = $(this).attr('id').split('_');
                    $(this).attr('id', inputIdText[0] + '_' + newInputId);
                    $(this).attr('name', inputIdText[0] + '_' + newInputId);
                    $(this).attr('data-index', newInputId);

                    $(this).val('');
                    if ($(this).hasClass('parsley-error')) {
                        $(this).removeClass('parsley-error');
                        $(this).addClass('parsley-success');
                    }
                });
                $morePartnerBoxClone.find('.page-nc-close-more').show();
                $morePartnerBoxClone.appendTo($this.parents('.page-nc-right').find('.append-to-this-wrapper'));
                $morePartnerBox.removeClass('partner');
                $morePartnerBox.addClass('partner' + inputId);

            });

            $('#button-add-more-company').on('click', function (e) {
                e.preventDefault();
                var $this = $(this);

                var $moreCompanyBox = $('.more-company-box.company'),
                    $moreCompanyBoxClone = $moreCompanyBox.clone(true),
                    inputId = 1;

                $moreCompanyBoxClone.find('ul.parsley-error-list').css('display', 'none');
                //$moreCompanyBoxClone.find('.indicator-star').html('');
                $moreCompanyBoxClone.find('input').each(function () {
                    inputId = parseInt($(this).attr('data-index'));
                    var newInputId = inputId + 1;
                    var inputIdText = $(this).attr('id').split('_');
                    $(this).attr('id', inputIdText[0] + '_' + newInputId);
                    $(this).attr('name', inputIdText[0] + '_' + newInputId);
                    $(this).attr('data-index', newInputId);

                    $(this).val('');
                    if ($(this).hasClass('parsley-error')) {
                        $(this).removeClass('parsley-error');
                        $(this).addClass('parsley-success');
                    }
                });
                $moreCompanyBoxClone.find('.page-nc-close-more').show();
                $moreCompanyBoxClone.appendTo($this.parents('.page-nc-right').find('.append-to-this-wrapper'));
                $moreCompanyBox.removeClass('company');
                $moreCompanyBox.addClass('company' + inputId);
            });

            $('.page-nc-close-more').on('click', function (e) {
                e.preventDefault();
                if ($(this).hasClass('chief')) {
                    $(this).parents('.more-chief-box').remove();
                    _updateBoxAfterDeleting($('.more-chief-box'), 'page-nc-more-wrapper', 'more-chief-box', 'chief');
                }
                if ($(this).hasClass('partner')) {
                    $(this).parents('.more-partner-box').remove();
                    _updateBoxAfterDeleting($('.more-partner-box'), 'page-nc-more-wrapper', 'more-partner-box', 'partner');
                    var partnerAmount = $('.more-partner-box').length;

                    if (partnerAmount <= 3) {
                        $('#button-add-more-partner').css('display', 'block');
                    } else {
                        $('#button-add-more-partner').css('display', 'none');
                    }
                }
                if ($(this).hasClass('company')) {
                    $(this).parents('.more-company-box').remove();
                    _updateBoxAfterDeleting($('.more-company-box'), 'page-nc-more-wrapper', 'more-company-box', 'company');
                }
            });

            $('.page-nc-next-button').on('click', function (e) {
                e.preventDefault();
                var currentStep = $(this).attr('data-step');
                if (currentStep == 1) {
                    $('.step-one .default-text-active').each(function () {
                        $(this).val('');
                    });
                    // check form of step one with parsley
                    // if has error stay by step one
                    // if no error then: hide step one, go next to step two (show step 2)
                    $('.step-one').css('display', 'none');
                    $('.step-two').css('display', 'block');
                } else if (currentStep == 2) {
                    $('.step-two .default-text-active').each(function () {
                        $(this).val('');
                    });
                    // if has error stay by step 2
                    _validateStep2();
                    if ($('.step-two .parsley-error').length > 0) {
                        $('.step-two .parsley-error').first().focus();
                        $('.step-two .default-text-active').each(function () {
                            $(this).val($(this).attr('title'));
                        })
                        return;
                    }
                    // if has error stay by step 2
                    $('.step-two').css('display', 'none');
                    $('.step-three').css('display', 'block');
                } else if (currentStep == 3) {
                    $('.step-three .default-text-active').each(function () {
                        $(this).val('');
                    });
                    _validateStep3();
                    if ($('.step-three .parsley-error').length > 0) {
                        $('.step-three .parsley-error').first().focus();
                        $('.step-three .default-text-active').each(function () {
                            $(this).val($(this).attr('title'));
                        })
                        return;
                    }
                    $('.step-three').css('display', 'none');
                    $('.step-four').css('display', 'block');
                } else if (currentStep == 4) {
                    $('.step-four .default-text-active').each(function () {
                        $(this).val('');
                    });
                    _validateStep4();
                    if ($('.step-four .parsley-error').length > 0) {
                        $('.step-four .default-text-active').each(function () {
                            $(this).val($(this).attr('title'));
                        })
                        return;
                    }
                    $('.step-four').css('display', 'none');
                    $('.step-five').css('display', 'block');
                }
            });

            $('.page-nc-back-button').on('click', function (e) {
                e.preventDefault();
                var currentStep = $(this).attr('data-step');
                if (currentStep == 2) {
                    $('.step-two').css('display', 'none'); //hide step 2
                    $('.step-one').css('display', 'block'); //show step 1
                } else if (currentStep == 3) {
                    $('.step-three').css('display', 'none'); //hide step 3
                    $('.step-two').css('display', 'block'); //show step 2
                } else if (currentStep == 4) {
                    $('.step-four').css('display', 'none'); //hide step 4
                    $('.step-three').css('display', 'block'); //show step 3
                } else if (currentStep == 5) {
                    $('.step-five').css('display', 'none'); //hide step 5
                    $('.step-four').css('display', 'block'); //show step 4
                }
            });

            $('.page-nc-upload-button').on('click', function (e) {
                e.preventDefault();
                var $this = $(this);

                if ($this.find('input').attr('id') == 'tradeRegisterDocumentsUrl') {
                    $('#pdfupload1').trigger('click');
                }
                if ($this.find('input').attr('id') == 'bankAndTaxNumberUrl') {
                    $('#pdfupload2').trigger('click');
                }
                if ($this.find('input').attr('id') == 'masterLetterUrl') {
                    $('#pdfupload3').trigger('click');
                }
            });

            /** Submit */
            $('.button-send-application').on('click', function (e) {
                e.preventDefault();
                var submitUrl = $('#form-new-customer-url-submit').val(),
                    home = $('#obeta-homepage-url').val(),
                    container = $('#js-register-form-new-customer');

                var hasTradeRegisterDocuments = 0,
                    hasBankAndTaxNumber = 0,
                    hasMasterLetter = 0,
                    payByInvoice = 0,
                    payByCash = 0,
                    isBranchElectro = 0,
                    isBranchOther = 0,
                    ordersRegularMaintenances = 0,
                    readTermsAndConditions = 0,
                    sendsDocumentsAsCopy = 0,
                    wantsAdvertisements = 0,
                    wantsAdvertisementsPost = 0,
                    wantsAdvertisementsFax = 0,
                    wantsAdvertisementsEmail = 0;

                var branchname = '';
                bossName = [];
                contactperson = [];
                companyMaintenances = [];

                $('.page-nc-checkbox').each(function () {
                    $this = $(this);
                    if ($this.attr('id') == 'page-nc-doc-require1' && $this.find('b').hasClass('radio-check-selected')) {
                        hasTradeRegisterDocuments = 1;
                    }
                    if ($this.attr('id') == 'page-nc-doc-require2' && $this.find('b').hasClass('radio-check-selected')) {
                        hasBankAndTaxNumber = 1;
                    }
                    if ($this.attr('id') == 'page-nc-doc-require3' && $this.find('b').hasClass('radio-check-selected')) {
                        hasMasterLetter = 1;
                    }
                    if ($this.attr('id') == 'page-nc-payment-invoice' && $this.find('b').hasClass('radio-check-selected')) {
                        payByInvoice = 1;
                    }
                    if ($this.attr('id') == 'page-nc-payment-cash' && $this.find('b').hasClass('radio-check-selected')) {
                        payByCash = 1;
                    }
                    if ($this.attr('id') == 'page-nc-branch-assign' && $this.find('b').hasClass('radio-check-selected')) {
                        ordersRegularMaintenances = 1;
                    }
                    if ($this.attr('id') == 'page-nc-terms-checkbox' && $this.find('b').hasClass('radio-check-selected')) {
                        readTermsAndConditions = 1;
                    }
                    if ($this.attr('id') == 'page-nc-sendcopydocu-checkbox' && $this.find('b').hasClass('radio-check-selected')) {
                        sendsDocumentsAsCopy = 1;
                    }
                    if ($this.attr('id') == 'page-nc-newsletter-checkbox' && $this.find('b').hasClass('radio-check-selected')) {
                        wantsAdvertisements = 1;
                    }
                    if ($this.attr('id') == 'page-nc-newsletter-post' && $this.find('b').hasClass('radio-check-selected')) {
                        wantsAdvertisementsPost = 1;
                    }
                    if ($this.attr('id') == 'page-nc-newsletter-fax' && $this.find('b').hasClass('radio-check-selected')) {
                        wantsAdvertisementsFax = 1;
                    }
                    if ($this.attr('id') == 'page-nc-newsletter-email' && $this.find('b').hasClass('radio-check-selected')) {
                        wantsAdvertisementsEmail = 1;
                    }
                });

                if ($('#page-nc-branch-electric').find('b').hasClass('radButSel')) {
                    isBranchElectro = 1;
                }
                if ($('#page-nc-branch-other').find('b').hasClass('radButSel')) {
                    isBranchOther = 1;
                    branchname = $('#branch-name').val();
                }

                var i = 0;
                $('.more-chief-box').find('input').each(function () {
                    bossName[i] = $(this).val();
                    i++;
                });

                var index = 0;
                $('.more-partner-box').each(function () {
                    contactperson[index] = {};
                    contactperson[index]['name'] = $('#input-partner-name_' + (index + 1)).val();
                    contactperson[index]['position'] = $('#input-partner-position_' + (index + 1)).val();
                    contactperson[index]['phoneCountry'] = $('#input-partner-tel-countrycode_' + (index + 1)).val();
                    contactperson[index]['phoneCity'] = $('#input-partner-tel-areacode_' + (index + 1)).val();
                    contactperson[index]['phoneMain'] = $('#input-partner-tel-number_' + (index + 1)).val();
                    contactperson[index]['faxCountry'] = $('#input-partner-fax-countrycode_' + (index + 1)).val();
                    contactperson[index]['faxCity'] = $('#input-partner-fax-areacode_' + (index + 1)).val();
                    contactperson[index]['faxMain'] = $('#input-partner-fax-number_' + (index + 1)).val();
                    contactperson[index]['email'] = $('#input-partner-email_' + (index + 1)).val();
                    contactperson[index]['mobileCountry'] = $('#input-partner-mobil-countrycode_' + (index + 1)).val();
                    contactperson[index]['mobileCity'] = $('#input-partner-mobil-areacode_' + (index + 1)).val();
                    contactperson[index]['mobileMain'] = $('#input-partner-mobil-number_' + (index + 1)).val();
                    index++;
                });

                var index = 0;
                $('.more-company-box').each(function () {
                    companyMaintenances[index] = {};
                    companyMaintenances[index]['companyName'] = $('#input-company-name_' + (index + 1)).val();
                    companyMaintenances[index]['contactperson'] = $('#input-company-contactperson_' + (index + 1)).val();
                    companyMaintenances[index]['street'] = $('#input-company-street_' + (index + 1)).val();
                    companyMaintenances[index]['zip'] = $('#input-company-postcode_' + (index + 1)).val();
                    companyMaintenances[index]['city'] = $('#input-company-city_' + (index + 1)).val();
                    index++;
                });

                tradeRegisterDocumentsUrl = $('#tradeRegisterDocumentsUrl').val();
                bankAndTaxNumberUrl = $('#bankAndTaxNumberUrl').val();
                masterLetterUrl = $('#masterLetterUrl').val();

                customerNo = $('#customer-no').val();
                companyName = $('#company-name').val();
                companyStreet = $('#company-street').val();
                companyZip = $('#company-zip').val();
                companyCity = $('#company-city').val();
                companyWebsite = $('#company-website').val();
                companyEmail = $('#company-email').val();
                phoneCountry = $('#company-phone-country').val();
                phoneCity = $('#company-phone-city').val();
                phoneMain = $('#company-phone-main').val();
                faxCountry = $('#company-fax-country').val();
                faxCity = $('#company-fax-city').val();
                faxMain = $('#company-fax-main').val();
                foundationYear = $('#company-foundation-year').val();
                workersNumber = $('#company-workers-number').val();
                cardsNumber = $('#customer-cards-number').val();
                bankAccountNumber = $('#bank-account-number').val();
                iban = $('#bank-iban').val();
                swift = $('#bank-swift').val();
                ustIdNumber = $('#ust-id-number').val();
                taxNumber = $('#tax-number').val();
                hrNumber = $('#hr-number').val();
                comments = $('#page-nc-comments').val();

                var captchaInputName = $('.QapTcha').find('input').attr('name'),
                    captchaInputValue = $('.QapTcha').find('input').val();

                var formData = {
                    'hasTradeRegisterDocuments': hasTradeRegisterDocuments,
                    'hasBankAndTaxNumber': hasBankAndTaxNumber,
                    'hasMasterLetter': hasMasterLetter,
                    'tradeRegisterDocumentsUrl': tradeRegisterDocumentsUrl,
                    'bankAndTaxNumberUrl': bankAndTaxNumberUrl,
                    'masterLetterUrl': masterLetterUrl,
                    'payByInvoice': payByInvoice,
                    'payByCash': payByCash,
                    'ordersRegularMaintenances': ordersRegularMaintenances,
                    'readTermsAndConditions': readTermsAndConditions,
                    'sendsDocumentsAsCopy': sendsDocumentsAsCopy,
                    'wantsAdvertisements': wantsAdvertisements,
                    'wantsAdvertisementsPost': wantsAdvertisementsPost,
                    'wantsAdvertisementsFax': wantsAdvertisementsFax,
                    'wantsAdvertisementsEmail': wantsAdvertisementsEmail,
                    'customerNo': customerNo,
                    'companyName': companyName,
                    'companyStreet': companyStreet,
                    'companyZip': companyZip,
                    'companyCity': companyCity,
                    'companyWebsite': companyWebsite,
                    'companyEmail': companyEmail,
                    'phoneCountry': phoneCountry,
                    'phoneCity': phoneCity,
                    'phoneMain': phoneMain,
                    'faxCountry': faxCountry,
                    'faxCity': faxCity,
                    'faxMain': faxMain,
                    'foundationYear': foundationYear,
                    'workersNumber': workersNumber,
                    'cardsNumber': cardsNumber,
                    'bankAccountNumber': bankAccountNumber,
                    'iban': iban,
                    'swift': swift,
                    'ustIdNumber': ustIdNumber,
                    'taxNumber': taxNumber,
                    'hrNumber': hrNumber,
                    'comments': comments,
                    'isBranchElectro': isBranchElectro,
                    'isBranchOther': isBranchOther,
                    'branchname': branchname,
                    'bossName': bossName,
                    'contactperson': contactperson,
                    'companyMaintenances': companyMaintenances,
                    'captchaInputName': captchaInputName,
                    'captchaInputValue': captchaInputValue
                }

                if (($('#page-nc-terms-checkbox').find('b').hasClass('radio-normal')) ||
                    ($('#page-nc-sendcopydocu-checkbox').find('b').hasClass('radio-normal') && $('#page-nc-sendcopydocu-checkbox').is(':visible'))) {
                    var showConfirmForRequiredFeld = {
                        containerId: "js-new-customer-show-confirm-for-required-feld",
                        callbacks: [
                            function () {}
                        ]
                    }
                    obeta.messages.showConfirmation(showConfirmForRequiredFeld);
                    if ($('#page-nc-terms-checkbox').find('b').hasClass('radio-normal')) {
                        $('.obeta-newcustomer-term').addClass('obeta-newcustomer-warning');
                    }
                    if ($('#page-nc-sendcopydocu-checkbox').find('b').hasClass('radio-normal')) {
                        $('.obeta-newcustomer-sendcopydocu').addClass('obeta-newcustomer-warning');
                    }
                    $('.page-nc-checkbox').each(function () {
                        $this = $(this);
                        if ($this.attr('id') == 'page-nc-sendcopydocu-checkbox' && $this.find('b').hasClass('radio-check-selected')) {
                            sendsDocumentsAsCopy = 1;
                            $('.obeta-newcustomer-sendcopydocu').removeClass('obeta-newcustomer-warning');
                        }
                        if ($this.attr('id') == 'page-nc-newsletter-checkbox' && $this.find('b').hasClass('radio-check-selected')) {
                            wantsAdvertisements = 1;
                        }
                    });
                    return;
                }

                sendApplicationRequest = submitUrl && obeta.helpers.ajax({
                        type: "POST",
                        url: submitUrl,
                        data: formData
                    },
                    function () {
                        $('#is-application-sent-successfully').val(1);
                        $.fancybox({
                            autoResize: false,
                            helpers: {
                                overlay: {
                                    closeClick: false
                                }
                            },
                            content: container,
                            afterShow: function () {
                                $('.fancybox-outer .confirmation-button').each(function () {
                                    $(this).on('click', function () {
                                        $.fancybox.close();
                                        location.href = home;
                                    })
                                });
                            },
                            afterClose: function () {
                                location.href = home;
                            }
                        });
                    },
                    function () {
                        var container = $('#js-register-session-expired');
                        $.fancybox({
                            autoResize: false,
                            helpers: {
                                overlay: {
                                    closeClick: false
                                }
                            },
                            content: container,
                            afterShow: function () {
                                $('.fancybox-outer .confirmation-button').each(function () {
                                    $(this).on('click', function () {
                                        $.fancybox.close();
                                        location.href = home;
                                    })
                                });
                            },
                            afterClose: function () {
                                location.href = home;
                            }
                        });
                    }
                );
            });

            $('.button-request-pin').on('click', function (e) {
                e.preventDefault();
                var url = $('#customer-request-pin-url').val(),
                    home = $('#customer-request-pin-homepage-url').val(),
                    container = $('#js-customer-pin-request');

                $('.step-zero .default-text-active').each(function () {
                    $(this).val('');
                });
                $('#form-customer-request-pin').parsley('validate');
                if ($('.step-zero .parsley-error').length > 0) {
                    $('.step-zero .parsley-error').first().focus();
                    $('.step-zero .default-text-active').each(function () {
                        $(this).val($(this).attr('title'));
                    })
                    return;
                }
                var customerName = $('#pin-customer-name').val(),
                    companyName = $('#pin-company-name').val(),
                    customerNo = $('#pin-customer-number').val(),
                    customerStreet = $('#pin-street-no').val(),
                    customerZip = $('#pin-zip').val(),
                    customerCity = $('#pin-city').val();

                var dataToSend = {
                    'customerName': customerName,
                    'companyName': companyName,
                    'customerNo': customerNo,
                    'customerStreet': customerStreet,
                    'customerZip': customerZip,
                    'customerCity': customerCity
                }
                savePinRequest = url && obeta.helpers.ajax({
                    type: "POST",
                    url: url,
                    data: dataToSend
                }, function () {
                    $.fancybox({
                        autoResize: false,
                        helpers: {
                            overlay: {
                                closeClick: false
                            }
                        },
                        content: container,
                        afterShow: function () {
                            $('.fancybox-outer .confirmation-button').each(function () {
                                $(this).on('click', function () {
                                    $.fancybox.close();
                                    location.href = home;
                                })
                            });
                        },
                        afterClose: function () {
                            location.href = home;
                        }
                    });
                });
            });

            /**--------- Print as a PDF ----------------------------------------------------------------------------------*/
            $('.page-nc-button-create-pdf').on('click', function () {
                var url = $('#form-new-customer').attr('action'),
                    saveTempDataUrl = $('#form-new-customer-save-data').val();

                var hasTradeRegisterDocuments = 0,
                    hasBankAndTaxNumber = 0,
                    hasMasterLetter = 0,
                    payByInvoice = 0,
                    payByCash = 0,
                    isBranchElectro = 0,
                    isBranchOther = 0,
                    ordersRegularMaintenances = 0,
                    readTermsAndConditions = 0,
                    sendsDocumentsAsCopy = 0,
                    wantsAdvertisements = 0,
                    wantsAdvertisementsPost = 0,
                    wantsAdvertisementsFax = 0,
                    wantsAdvertisementsEmail = 0;

                var branchname = '';
                bossName = [];
                contactperson = [];
                companyMaintenances = [];

                $('.page-nc-checkbox').each(function () {
                    $this = $(this);
                    if ($this.attr('id') == 'page-nc-doc-require1' && $this.find('b').hasClass('radio-check-selected')) {
                        hasTradeRegisterDocuments = 1;
                    }
                    if ($this.attr('id') == 'page-nc-doc-require2' && $this.find('b').hasClass('radio-check-selected')) {
                        hasBankAndTaxNumber = 1;
                    }
                    if ($this.attr('id') == 'page-nc-doc-require3' && $this.find('b').hasClass('radio-check-selected')) {
                        hasMasterLetter = 1;
                    }
                    if ($this.attr('id') == 'page-nc-payment-invoice' && $this.find('b').hasClass('radio-check-selected')) {
                        payByInvoice = 1;
                    }
                    if ($this.attr('id') == 'page-nc-payment-cash' && $this.find('b').hasClass('radio-check-selected')) {
                        payByCash = 1;
                    }
                    if ($this.attr('id') == 'page-nc-branch-assign' && $this.find('b').hasClass('radio-check-selected')) {
                        ordersRegularMaintenances = 1;
                    }
                    if ($this.attr('id') == 'page-nc-terms-checkbox' && $this.find('b').hasClass('radio-check-selected')) {
                        readTermsAndConditions = 1;
                    }
                    if ($this.attr('id') == 'page-nc-sendcopydocu-checkbox' && $this.find('b').hasClass('radio-check-selected')) {
                        sendsDocumentsAsCopy = 1;
                    }
                    if ($this.attr('id') == 'page-nc-newsletter-checkbox' && $this.find('b').hasClass('radio-check-selected')) {
                        wantsAdvertisements = 1;
                    }
                    if ($this.attr('id') == 'page-nc-newsletter-post' && $this.find('b').hasClass('radio-check-selected')) {
                        wantsAdvertisementsPost = 1;
                    }
                    if ($this.attr('id') == 'page-nc-newsletter-fax' && $this.find('b').hasClass('radio-check-selected')) {
                        wantsAdvertisementsFax = 1;
                    }
                    if ($this.attr('id') == 'page-nc-newsletter-email' && $this.find('b').hasClass('radio-check-selected')) {
                        wantsAdvertisementsEmail = 1;
                    }
                });

                if ($('#page-nc-branch-electric').find('b').hasClass('radButSel')) {
                    isBranchElectro = 1;
                }
                if ($('#page-nc-branch-other').find('b').hasClass('radButSel')) {
                    isBranchOther = 1;
                    branchname = $('#branch-name').val();
                }

                var i = 0;
                $('.more-chief-box').find('input').each(function () {
                    bossName[i] = $(this).val();
                    i++;
                });

                var index = 0;
                $('.more-partner-box').each(function () {
                    contactperson[index] = {};
                    contactperson[index]['name'] = $('#input-partner-name_' + (index + 1)).val();
                    contactperson[index]['position'] = $('#input-partner-position_' + (index + 1)).val();
                    contactperson[index]['phoneCountry'] = $('#input-partner-tel-countrycode_' + (index + 1)).val();
                    contactperson[index]['phoneCity'] = $('#input-partner-tel-areacode_' + (index + 1)).val();
                    contactperson[index]['phoneMain'] = $('#input-partner-tel-number_' + (index + 1)).val();
                    contactperson[index]['faxCountry'] = $('#input-partner-fax-countrycode_' + (index + 1)).val();
                    contactperson[index]['faxCity'] = $('#input-partner-fax-areacode_' + (index + 1)).val();
                    contactperson[index]['faxMain'] = $('#input-partner-fax-number_' + (index + 1)).val();
                    contactperson[index]['email'] = $('#input-partner-email_' + (index + 1)).val();
                    contactperson[index]['mobileCountry'] = $('#input-partner-mobil-countrycode_' + (index + 1)).val();
                    contactperson[index]['mobileCity'] = $('#input-partner-mobil-areacode_' + (index + 1)).val();
                    contactperson[index]['mobileMain'] = $('#input-partner-mobil-number_' + (index + 1)).val();
                    index++;
                });

                var index = 0;
                $('.more-company-box').each(function () {
                    companyMaintenances[index] = {};
                    companyMaintenances[index]['companyName'] = $('#input-company-name_' + (index + 1)).val();
                    companyMaintenances[index]['contactperson'] = $('#input-company-contactperson_' + (index + 1)).val();
                    companyMaintenances[index]['street'] = $('#input-company-street_' + (index + 1)).val();
                    companyMaintenances[index]['zip'] = $('#input-company-postcode_' + (index + 1)).val();
                    companyMaintenances[index]['city'] = $('#input-company-city_' + (index + 1)).val();
                    index++;
                });

                tradeRegisterDocumentsUrl = $('#tradeRegisterDocumentsUrl').val();
                bankAndTaxNumberUrl = $('#bankAndTaxNumberUrl').val();
                masterLetterUrl = $('#masterLetterUrl').val();

                customerNo = $('#customer-no').val();
                companyName = $('#company-name').val();
                companyStreet = $('#company-street').val();
                companyZip = $('#company-zip').val();
                companyCity = $('#company-city').val();
                companyWebsite = $('#company-website').val();
                companyEmail = $('#company-email').val();
                phoneCountry = $('#company-phone-country').val();
                phoneCity = $('#company-phone-city').val();
                phoneMain = $('#company-phone-main').val();
                faxCountry = $('#company-fax-country').val();
                faxCity = $('#company-fax-city').val();
                faxMain = $('#company-fax-main').val();
                foundationYear = $('#company-foundation-year').val();
                workersNumber = $('#company-workers-number').val();
                cardsNumber = $('#customer-cards-number').val();
                bankAccountNumber = $('#bank-account-number').val();
                iban = $('#bank-iban').val();
                swift = $('#bank-swift').val();
                ustIdNumber = $('#ust-id-number').val();
                taxNumber = $('#tax-number').val();
                hrNumber = $('#hr-number').val();
                comments = $('#page-nc-comments').val();

                var formData = {
                    'hasTradeRegisterDocuments': hasTradeRegisterDocuments,
                    'hasBankAndTaxNumber': hasBankAndTaxNumber,
                    'hasMasterLetter': hasMasterLetter,
                    'tradeRegisterDocumentsUrl': tradeRegisterDocumentsUrl,
                    'bankAndTaxNumberUrl': bankAndTaxNumberUrl,
                    'masterLetterUrl': masterLetterUrl,
                    'payByInvoice': payByInvoice,
                    'payByCash': payByCash,
                    'ordersRegularMaintenances': ordersRegularMaintenances,
                    'readTermsAndConditions': readTermsAndConditions,
                    'sendsDocumentsAsCopy': sendsDocumentsAsCopy,
                    'wantsAdvertisements': wantsAdvertisements,
                    'wantsAdvertisementsPost': wantsAdvertisementsPost,
                    'wantsAdvertisementsFax': wantsAdvertisementsFax,
                    'wantsAdvertisementsEmail': wantsAdvertisementsEmail,
                    'customerNo': customerNo,
                    'companyName': companyName,
                    'companyStreet': companyStreet,
                    'companyZip': companyZip,
                    'companyCity': companyCity,
                    'companyWebsite': companyWebsite,
                    'companyEmail': companyEmail,
                    'phoneCountry': phoneCountry,
                    'phoneCity': phoneCity,
                    'phoneMain': phoneMain,
                    'faxCountry': faxCountry,
                    'faxCity': faxCity,
                    'faxMain': faxMain,
                    'foundationYear': foundationYear,
                    'workersNumber': workersNumber,
                    'cardsNumber': cardsNumber,
                    'bankAccountNumber': bankAccountNumber,
                    'iban': iban,
                    'swift': swift,
                    'ustIdNumber': ustIdNumber,
                    'taxNumber': taxNumber,
                    'hrNumber': hrNumber,
                    'comments': comments,
                    'isBranchElectro': isBranchElectro,
                    'isBranchOther': isBranchOther,
                    'branchname': branchname,
                    'bossName': bossName,
                    'contactperson': contactperson,
                    'companyMaintenances': companyMaintenances
                }

                obeta.helpers.showSpinner();
                sendDataPdfRequest = url && obeta.helpers.ajax({
                    type: "POST",
                    url: saveTempDataUrl,
                    data: formData
                }, function () {
                    //location.href = url;
                    obeta.helpers.hideSpinner();
                });
            });


            $('#pin-request-button-create-pdf').on('click', function () {
                var savePinTempDataUrl = $('#form-pin-request-save-data').val(),
                    pinCustomerName = $('#pin-customer-name').val(),
                    pinCompanyName = $('#pin-company-name').val(),
                    pinCustomerNumber = $('#pin-customer-number').val(),
                    pinStreetNo = $('#pin-street-no').val(),
                    pinZip = $('#pin-zip').val(),
                    pinCity = $('#pin-city').val();

                var formData = {
                    'pinCustomerName': pinCustomerName,
                    'pinCompanyName': pinCompanyName,
                    'pinCustomerNumber': pinCustomerNumber,
                    'pinStreetNo': pinStreetNo,
                    'pinZip': pinZip,
                    'pinCity': pinCity
                }

                obeta.helpers.showSpinner();
                sendPinDataPdfRequest = savePinTempDataUrl && obeta.helpers.ajax({
                    type: "POST",
                    url: savePinTempDataUrl,
                    data: formData
                }, function () {
                    obeta.helpers.hideSpinner();
                });
            });

            /**--------- Print as a PDF ----------------------------------------------------------------------------------*/
        },
        _validateStep1 = function () {},
        _validateStep2 = function () {
            $('#company-name').parsley('validate');
            $('#company-street').parsley('validate');
            $('#company-zip').parsley('validate');
            $('#company-city').parsley('validate');
            $('#company-email').parsley('validate');
            if ($('#company-phone-country').val() == '') {
                $('#company-phone-country').val('+49');
            }
            $('#company-phone-country').parsley('validate');
            $('#company-phone-city').parsley('validate');
            $('#company-phone-main').parsley('validate');
            if ($('#company-fax-country').val() == '') {
                $('#company-fax-country').val('+49');
            }
            $('#company-fax-country').parsley('validate');
            $('#company-fax-city').parsley('validate');
            $('#company-fax-main').parsley('validate');
            $('#company-foundation-year').parsley('validate');
            $('#input-chief-name_1').parsley('validate');

            $('#input-partner-name_1').parsley('validate');
            $('#input-partner-position_1').parsley('validate');
            if ($('#input-partner-tel-countrycode_1').val() == '') {
                $('#input-partner-tel-countrycode_1').val('+49');
            }
            $('#input-partner-tel-countrycode_1').parsley('validate');
            $('#input-partner-tel-areacode_1').parsley('validate');
            $('#input-partner-tel-number_1').parsley('validate');
            if ($('#input-partner-fax-countrycode_1').val() == '') {
                $('#input-partner-fax-countrycode_1').val('+49');
            }
            $('#input-partner-fax-countrycode_1').parsley('validate');
            $('#input-partner-fax-areacode_1').parsley('validate');
            $('#input-partner-fax-number_1').parsley('validate');
            $('#input-partner-email_1').parsley('validate');
            //            $('#input-partner-mobil-countrycode_1').parsley('validate');
            //            $('#input-partner-mobil-areacode_1').parsley('validate');
            //            $('#input-partner-mobil-number_1').parsley('validate');
        },
        _validateStep3 = function () {
            $('#customer-cards-number').parsley('validate');
            $('#ust-id-number').parsley('validate');
            $('#tax-number').parsley('validate');
            $('#hr-number').parsley('validate');
        },
        _validateStep4 = function () {
            $sendCopyObj = $('#page-nc-sendcopydocu-checkbox');
            if ($('#tradeRegisterDocumentsUrl').val() == '' || $('#bankAndTaxNumberUrl').val() == '' || $('#masterLetterUrl').val() == '') {
                if ($('#tradeRegisterDocumentsUrl').val() != '') {
                    $('.sendcopydocu-1').css('display', 'none');
                }
                if ($('#bankAndTaxNumberUrl').val() != '') {
                    $('.sendcopydocu-2').css('display', 'none');
                }
                if ($('#masterLetterUrl').val() != '') {
                    $('.sendcopydocu-3').css('display', 'none');
                }
            } else {
                $sendCopyObj.find('b').removeClass('radio-check-selected');
                $sendCopyObj.find('b').addClass('radio-check-selected');
                $sendCopyObj.css('display', 'none');
            }
        },
        _validateStep5 = function () {
            //$('#read-terms-conditions').parsley('validate');
        },
        _updateBoxAfterDeleting = function ($boxes, $class1, $class2, $class3) {
            var boxesNum = $boxes.length;
            $.each($boxes, function (index, value) {
                if (index == (boxesNum - 1)) {
                    $(this).attr('class', '');
                    //                    $(this).addClass('page-nc-more-wrapper');
                    //                    $(this).addClass('more-chief-box');
                    //                    $(this).addClass('chief');
                    $(this).addClass($class1);
                    $(this).addClass($class2);
                    $(this).addClass($class3);
                } else {
                    $(this).attr('class', '');
                    $(this).addClass($class1);
                    $(this).addClass($class2);
                    $(this).addClass($class3 + (index + 1));
                }
                $(this).find('input').each(function () {
                    var newInputId = index + 1;
                    var inputIdText = $(this).attr('id').split('_');
                    $(this).attr('id', inputIdText[0] + '_' + newInputId);
                    $(this).attr('name', inputIdText[0] + '_' + newInputId);
                    $(this).attr('data-index', newInputId);
                });
            });
        },
        initTooltips = function () {
            $('.obtooltip').tooltip();
        },
        bindSupplierFilter = function () {

            $(window).load(function () {
                currentUrl = window.location.href;
                if (currentUrl.indexOf('lieferanten') !== -1) {
                    $('#supplier-list').css({
                        height: $('#supplierA').height(),
                        overflow: 'hidden'
                    })
                }
            });

            $('#supplier-filter .link-box').click(function () {
                var linkId = $(this).attr('data-link');
                var alphabet = new Array('A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'Hash');

                $('.link-box').removeClass('lb-active');
                $('#supplier-list').css({
                    height: $('#supplier' + linkId).height(),
                    overflow: 'hidden'
                });
                $('#supplier' + linkId).slideDown();

                for (var j = 0; j < alphabet.length; j++) {
                    if (linkId != alphabet[j]) {
                        $('#supplier' + alphabet[j]).slideUp();
                    }
                }
                $(this).addClass('lb-active');
            });
        },
        bindSapSupplierFilter = function () {

            $(window).load(function () {
                currentUrl = window.location.href;
                if (currentUrl.indexOf('lieferanten') !== -1) {
                    $('#sap-supplier-list').css({
                        height: $('#supplierHash').height(),
                        overflow: 'hidden'
                    })
                }
            });

            $('#sap-supplier-filter .link-box').click(function () {
                var linkId = $(this).attr('data-link');

                $('.link-box').removeClass('lb-active');
                $('#sap-supplier-list').css({
                    height: $('#supplier' + linkId).height(),
                    overflow: 'hidden'
                });

                $('.supplier-by-letter-box').slideUp();
                $('#supplier' + linkId).slideDown();

                $(this).addClass('lb-active');
            });
        },
        bindLoginButton = function () {
            $('#btn_login').click(function () {
                $('#wd-login-form').submit();
            });
        },
        bindMoreButton = function () {
            $('.more-image-single-link').on('mouseover', function (e) {
                $('#moreImagesFancyboxContainer').removeClass('oxomi-show-video');
                var $this = $(this),
                    url = $this.find('.more-image').attr('data-detail'),
                    bigImageUrl = $this.find('.more-image').attr('data-high'),
                    imageIndex = $this.find('.more-image').attr('data-index');
                $('#main-image').removeClass('oxomi-video-prev');
                $('#oxomi-play').hide();
                $('#main-image').attr('src', url);
                $('#main-image').attr('data-high', bigImageUrl);
                $('#main-image').attr('data-index', imageIndex);
                if (!$this.hasClass('turntable-thumb')) {
                    $('#main-image').removeClass('turntable-clicked').removeClass('turntable-show');
                }
            });

            $('.top-teaser-button-more-info').bind('click touchstart', function (e) {
                var $this = $(this),
                href = $this.attr('href');
                if ($this.attr('target')=="_blank") {
                    window.open(href, '_blank');
                } else {
                    obeta.helpers.showSpinner();
                    location.href = href;
                }
            });
        },
        displayShoppingCartDropdown = function (data) {
            if (data["html"] && data["html"]["dropdown"] && data["html"]["dropdown"].length > 10) {
                var $scmenu = $('#sc-menu-dropdown');
                $scmenu.html(data["html"]["dropdown"]);
                $scmenu.addClass('opensummary');
                $scmenu.addClass('opendd');
                var $scdropdown = $("#sc-summary-dropdown");
                $scdropdown.slideDown();

                setTimeout(function () {
                    $scdropdown.slideUp();
                    $scmenu.removeClass('opensummary');
                    $scmenu.removeClass('opendd');
                    obeta.shoppingCart.bindShoppingCartDropdown();
                    $scdropdown.css('top', 137);
                    //$('#inputsearch').focus(); //OSR-966
                }, 3000);
                var toppos = $(window).scrollTop() - 32;
                if (toppos < 137) {
                    var toppos = 137;
                }
                $scdropdown.css('top', toppos);
            }
        },
        initInputHints = function () {
            var nonEmpty = "non-empty";
            var inputs = $('#inp_pw');
            var setLabelStyle = function setLabelStyle() {
                var label = $(this);
                if (label.val().length > 0) {
                    label.addClass(nonEmpty);
                } else {
                    label.removeClass(nonEmpty);
                }
                if ($('#inp_pw').val().length != "0") { // hide label if fields were filled by browser
                    $("#inp-label").addClass(nonEmpty);
                } else {
                    $("#inp-label").removeClass(nonEmpty);
                }

            };

            inputs.focus(function () {
                $("#inp-label").addClass(nonEmpty);
            });
            inputs.blur(setLabelStyle);
            inputs.each(setLabelStyle);
        },
        bindMoreImagesFancybox = function () {
            $('#moreImagesFancyboxContainer .more-image-single-link-fancybox').unbind('mouseover').on('mouseover', function (e) {
                $('#moreImagesFancyboxContainer').removeClass('oxomi-show-video');
                $('.main-image-wrapper-fancybox').show();
                $('.turntable').hide();
                var $this = $(this),
                    bigImageUrl = $this.find('.more-image-fancybox').attr('data-high'),
                    imageIndex = $this.find('.more-image-fancybox').attr('data-index');

                $('#moreImagesFancyboxContainer #main-image-fancybox').attr('src', bigImageUrl);
                $('#moreImagesFancyboxContainer #main-image-fancybox').attr('data-index', imageIndex);

            });
            $('.oxomi-video-img').unbind('mouseover').on('mouseover', function (e) {
                $('.main-image-wrapper-fancybox').show();
                $('.turntable').hide();
                $('#moreImagesFancyboxContainer').addClass('oxomi-show-video');
                var $this = $(this),
                    bigImageUrl = $this.attr('data-original');

                $('#moreImagesFancyboxContainer #main-image-fancybox').attr('src', bigImageUrl);

            });
            $('#moreImagesFancyboxContainer .oxomi-video-play-img').unbind('mouseover').on('mouseover', function (e) {
                var $this = $(this).prev(),
                    vidsrc = $this.data('original'),
                    bigImageUrl = $this.attr('src'),
                    imageIndex = 2,
                    $img = $('.fancybox-outer #main-image-fancybox');
                $('.main-image-wrapper-fancybox').show();
                $('.turntable').hide();
                $('#moreImagesFancyboxContainer').addClass('oxomi-show-video');
                
                $('.fancybox-outer #oxomi-play-fancybox').show().unbind('click').on('click', function () {
                    $("#more-images .oxomi-video-img[data-original='" + vidsrc + "']").first().next().click();
                    return;
                });
                $img.attr('src', vidsrc);
                $img.attr('data-high', bigImageUrl);
                $img.addClass('oxomi-video-prev');
            });

        },
        bindCustomerPricesButton = function () {
            $('.customer-prices-button').on('click', function (e) {
                e.preventDefault();
                var url = $(this).attr('href');
                $('#spinner').show();
                obeta.helpers.ajax({
                        type: "GET",
                        url: url,
                        data: {}
                    },
                    function () {
                        $('#spinner').show();
                        location.reload();
                    },
                    function () {
                        $('#spinner').show();
                        location.reload();
                    });
            });
        },
        updateShoppingcartStats = function (updateShoppingcartStats) {
            $('.sc-position-count[data-project-id=' + updateShoppingcartStats.projectId + ']').html(updateShoppingcartStats.positionCount);
        };

    return {
        bindFancyboxClicks: bindFancyboxClicks,
        initTooltips: initTooltips,
        initInputHints: initInputHints,
        bindMoreButton: bindMoreButton,
        bindSupplierFilter: bindSupplierFilter,
        bindSapSupplierFilter: bindSapSupplierFilter,
        displayShoppingCartDropdown: displayShoppingCartDropdown,
        bindLoginButton: bindLoginButton,
        bindMoreImagesFancybox: bindMoreImagesFancybox,
        updateShoppingcartStats: updateShoppingcartStats,
        bindCustomerPricesButton: bindCustomerPricesButton
    };
})();

module.exports = obeta.userInterface;