
obeta.importfile = (function () {
    const ajaxTimeout = 90000;
    var initUploadImportFile = function () {
            $('.import-file-upload-btn').on('click', function (e) {
                e.preventDefault();
                $('#import-file-upload').trigger('click');
            });

            $('#import-file-upload').fileupload({
                url: $('#import-file-upload').attr('data-url'),
                dataType: 'json',
                singleFileUploads: true,
                limitMultiFileUploads: 1,
                maxNumberOfFiles: 1,
                limitConcurrentUploads: 1,
                acceptFileTypes: /(\.|\/)(csv|txt|xls|xlsx)$/i,
                dropZone: $('#file-upload-dropzone')
            }).on('fileuploadadd', function (e, data) {
                data.context = $('<div/>').appendTo('#import-file-upload');
                if (!(/\.(csv|txt|xls|xlsx)$/i).test(data.originalFiles[0].name)) {
                    alert('Sie dürfen nur Dateien mit folgenden Dateinamensendungen auswählen: .csv .txt .xls .xlsx');
                }
            }).on('fileuploaddone', function (e, data) {
                $.each(data.result.files, function (index, file) {
                    if (file.url) {
                        var link = $('<a>').attr('target', '_blank').prop('href', file.url),
                            fileurl = file.url, filename = file.name;

                        $(data.context.children()[index]).wrap(link);
                        $('.import-file-drag-drop-text').hide();
                        $('.import-file-upload-btn').hide();
                        $('.import-file-name').html(filename);
                        $('#import-file-url').val(fileurl);
                        $('.upload-file-name-box').show();
                        $('.push-import-file-into-order-template-btn').show();
                        $('.push-import-file-into-shopping-cart-btn').show();
                    } else if (file.error) {
                        var error = $('<span class="text-danger"/>').text(file.error);
                        $(data.context.children()[index])
                            .append('<br>')
                            .append(error);
                    }
                });
            }).on('fileuploadfail', function (e, data) {
                $.each(data.files, function (index) {
                    var error = $('<span class="text-danger"/>').text('File upload failed.');
                    $(data.context.children()[index]).append('<br>').append(error);
                });
            }).prop('disabled', !$.support.fileInput).parent().addClass($.support.fileInput ? undefined : 'disabled');


            $(document).bind('dragover', function (e) {
                var dropZone = $('#file-upload-dropzone'),
                    timeout = window.dropZoneTimeout;
                if (!timeout) {
                    dropZone.addClass('in');
                } else {
                    clearTimeout(timeout);
                }
                var found = false,
                    node = e.target;
                do {
                    if (node === dropZone[0]) {
                        found = true;
                        break;
                    }
                    node = node.parentNode;
                } while (node != null);
                if (found) {
                    dropZone.addClass('hover');
                } else {
                    dropZone.removeClass('hover');
                }
                window.dropZoneTimeout = setTimeout(function () {
                    window.dropZoneTimeout = null;
                    dropZone.removeClass('in hover');
                }, 100);
            });


            $('.remove-upload-file').on('click', function (e) {
                e.preventDefault();
                var deleteUrl = $(this).attr('data-url'), fileurl = $('#import-file-url').val();

                if (fileurl != '') {
                    deleteFileRequest = deleteUrl && $.ajax({
                        type: "POST",
                        url: deleteUrl,
                        data: {'fileName': fileurl}
                    })
                        .done(function (data) {
                            $('.upload-file-name-box').hide();
                            $('.push-import-file-into-order-template-btn').hide();
                            $('.push-import-file-into-shopping-cart-btn').hide();
                            $('.import-file-drag-drop-text').show();
                            $('.import-file-upload-btn').show();
                            obeta.helpers.hideSpinner();
                            if (data.status == 'error') {
                                alert('Es ist ein Fehler aufgetreten! Bitte aktualisieren Sie die Seite erneut und versuchen Sie es noch einmal.');
                            }
                        })
                        .fail(function () {
                            alert('Es ist ein Fehler aufgetreten! Bitte aktualisieren Sie die Seite erneut und versuchen Sie es noch einmal.');
                        })
                }
            });

        },

        bindAddImportFileToSc = function () {
            $('.add-articles-from-import-file-link-sc').on('click', function (e) {
                e.preventDefault();
                var $this = $(this),
                    url = $this.attr('href'),
                    file = $('#import-file-url').val(),
                    unitType = $("input[name='item-amount-by-file-import']:checked").val(),
                    projectId = $this.find('.sc-position-count').attr('data-project-id');

                if (file != '') {
                    $('.push-import-file-into-order-template-btn').fadeOut();
                    $('.push-import-file-into-shopping-cart-btn').fadeOut();
                    obeta.helpers.hideSpinner();
                    obeta.helpers.ajax({
                            type: "POST",
                            url: url,
                            timeout: ajaxTimeout,
                            data: {file: file, unitType: unitType}
                        }, function (data) {
                            _addImportFileToScOk(data);
                        }, function (data) {
                            if (typeof (data.html) != "undefined" && typeof (data.html.exception) != "undefined" && data.html.exception != '') {
                                $('#js-show-exception-by-import-file .import-file-exception-wrapper').html(data.html.exception);
                                var exceptionNotification = {
                                    containerId: 'js-show-exception-by-import-file',
                                    fancyboxOptions: {closeBtn: false},
                                    callbacks: [function () {
                                        $('.remove-upload-file').trigger('click');
                                    }]
                                };
                                obeta.messages.showConfirmation(exceptionNotification);
                            } else {
                                $('#import-file-url').attr('data-sc-id', projectId);
                                _addImportFileToScWithNotification(data, projectId);
                            }
                        },
                        {'modalMessageAfterShow': obeta.articleList.modalMessageAfterShowAddArticleHandler}
                    );
                } else {
                    obeta.messages.showMessage('Es ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.', obeta.messages.type.ERROR);
                }
            });
            $('.add-articles-from-import-file-link-to').on('click', function (e) {
                e.preventDefault();
                var $this = $(this),
                    url = $this.attr('href'),
                    file = $('#import-file-url').val(),
                    unitType = $("input[name='item-amount-by-file-import']:checked").val();

                if (file != '') {
                    $('.push-import-file-into-order-template-btn').fadeOut();
                    $('.push-import-file-into-shopping-cart-btn').fadeOut();
                    obeta.helpers.hideSpinner();
                    obeta.helpers.ajax({
                            type: "POST",
                            url: url,
                            timeout: 0,
                            data: {file: file, unitType: unitType}
                        }, function (data) {
                            _addImportFileToScOk(data);
                        }, function (data) {
                            if (typeof (data.html) != "undefined" && typeof (data.html.exception) != "undefined" && data.html.exception != '') {
                                $('#js-show-exception-by-import-file .import-file-exception-wrapper').html(data.html.exception);
                                var exceptionNotification = {
                                    containerId: 'js-show-exception-by-import-file',
                                    fancyboxOptions: {closeBtn: false},
                                    callbacks: [function () {
                                        $('.remove-upload-file').trigger('click');
                                    }]
                                };
                                obeta.messages.showConfirmation(exceptionNotification);
                            } else {
                                $('#import-file-url').attr('data-url-to', url)
                                _addImportFileToToWithNotification(data);
                            }
                        },
                        {'modalMessageAfterShow': obeta.articleList.modalMessageAfterShowAddArticleHandler}
                    );
                } else {
                    obeta.messages.showMessage('Es ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.', obeta.messages.type.ERROR);
                }
            });
        },
        _deleteUploadFile = function () {
            var deleteUrl = $('.remove-upload-file').attr('data-url'),
                fileurl = $('#import-file-url').val();

            if (fileurl != '') {
                deleteFileRequest = deleteUrl && $.ajax({
                    type: "POST",
                    url: deleteUrl,
                    data: {'fileName': fileurl}
                })
                    .done(function (data) {
                    })
                    .fail(function () {
                    })
            }
        },

        _addImportFileToScOk = function (dataparam) {
            if (dataparam.data == true) { // if file has only one column
                var $fileUploadNotificationBox = $('.file-upload-notification-by-scan-file'),
                    $fileUploadMainBox = $('.file-upload-box-inner');

                $fileUploadMainBox.hide();
                $fileUploadNotificationBox.show();
                obeta.helpers.hideSpinner();
                obeta.userInterface.displayShoppingCartDropdown(dataparam);
                if ($fileUploadNotificationBox.css('display') == 'block') {
                    $('.file-upload-confirm-btn').on('click', function (e) {
                        e.preventDefault();
                        $fileUploadNotificationBox.hide();
                        $fileUploadMainBox.show();
                        __reDisplayUploadFilePageWithoutShowCartDropdown(dataparam);
                    });
                }
            } else { // if file has two columns
                __reDisplayUploadFilePage(dataparam);
            }
            if (typeof (dataparam.html) != "undefined" && typeof (dataparam.html.specialArticleAdded) != "undefined" && dataparam.html.specialArticleAdded === true) {
                var specialArticleAddedToCartNotification = {
                    containerId: 'js-show-notification-special-article-added-to-cart',
                    fancyboxOptions: {closeBtn: true},
                    callbacks: [function () {
                    }]
                }
                obeta.messages.showConfirmation(specialArticleAddedToCartNotification);
            }
        },

        __reDisplayUploadFilePage = function (dataparam) {
            $('.upload-file-name-box').hide();
            $('.push-import-file-into-order-template-btn').hide();
            $('.push-import-file-into-shopping-cart-btn').hide();
            $('.import-file-drag-drop-text').show();
            $('.import-file-upload-btn').show();
            obeta.helpers.hideSpinner();
            obeta.userInterface.displayShoppingCartDropdown(dataparam);

            if (dataparam.html.shoppingCartListBtn && dataparam.html.shoppingCartListBtn != '') {
                $('.push-import-file-into-order-template-btn').html(dataparam.html.shoppingCartListBtn);
                $('.push-import-file-into-shopping-cart-btn').html(dataparam.html.shoppingCartListBtn);
                obeta.importfile.bindAddImportFileToSc();
            }
            _deleteUploadFile();
        },

        __reDisplayUploadFilePageWithoutShowCartDropdown = function (dataparam) {
            $('.upload-file-name-box').hide();
            $('.push-import-file-into-order-template-btn').hide();
            $('.push-import-file-into-shopping-cart-btn').hide();
            $('.import-file-drag-drop-text').show();
            $('.import-file-upload-btn').show();

            if (dataparam.html.shoppingCartListBtn && dataparam.html.shoppingCartListBtn != '') {
                $('.push-import-file-into-order-template-btn').html(dataparam.html.shoppingCartListBtn);
                $('.push-import-file-into-shopping-cart-btn').html(dataparam.html.shoppingCartListBtn);
                obeta.importfile.bindAddImportFileToSc();
            }
            _deleteUploadFile();
        },

        _addImportFileToScWithNotification = function (data) {
            if (typeof (data.html) != "undefined" && typeof (data.html.notice) != "undefined" && data.html.notice != '') {
                $('#js-show-notification-by-import-file .import-file-notification-wrapper').html(data.html.notice);
                var importFileNotification = {
                    containerId: 'js-show-notification-by-import-file',
                    fancyboxOptions: {closeBtn: true},
                    callbacks: [function () {
                        __addImportFileToShoppingCart();
                    }]
                }
                obeta.messages.showConfirmation(importFileNotification);
            }
        },

        _addImportFileToToWithNotification = function (data) {
            if (typeof (data.html) != "undefined" && typeof (data.html.notice) != "undefined" && data.html.notice != '') {
                $('#js-show-notification-by-import-file .import-file-notification-wrapper').html(data.html.notice);
                var importFileNotification = {
                    containerId: 'js-show-notification-by-import-file',
                    fancyboxOptions: {closeBtn: true},
                    callbacks: [function () {
                        __addImportFileToTemplateOrder();
                    }]
                }
                obeta.messages.showConfirmation(importFileNotification);
            }
        },

        __addImportFileToShoppingCart = function () {
            var $importFileUrl = $('#import-file-url'),
                file = $importFileUrl.val(),
                projectId = $importFileUrl.attr('data-sc-id'),
                unitType = $("input[name='item-amount-by-file-import']:checked").val(),
                addUrlAfterNotification = $importFileUrl.attr('data-url');

            obeta.helpers.ajax({
                    type: "POST",
                    url: addUrlAfterNotification,
                    timeout: ajaxTimeout,
                    data: {file: file, projectId: projectId, unitType: unitType}
                }, function (data) {
                    if (typeof (data.html) != "undefined" && typeof (data.html.noArticleAddedToSc) != "undefined" && data.html.noArticleAddedToSc === true) {
                        var noArticleAddedToCartNotification = {
                            containerId: 'js-show-notification-no-article-added-to-cart',
                            fancyboxOptions: {closeBtn: true},
                            callbacks: [function () {
                            }]
                        }
                        obeta.messages.showConfirmation(noArticleAddedToCartNotification);
                    } else {
                        _addImportFileToScOk(data);
                    }
                }, function () {
                }
            );
        },

        __addImportFileToTemplateOrder = function () {
            var $importFileUrl = $('#import-file-url'),
                file = $importFileUrl.val(),
                unitType = $("input[name='item-amount-by-file-import']:checked").val(),
                addUrlAfterNotification = $importFileUrl.attr('data-url-to');

            obeta.helpers.ajax({
                    type: "POST",
                    url: addUrlAfterNotification,
                    data: {file: file, unitType: unitType, noChecks: true}
                }, function (data) {
                    if (typeof (data.html) != "undefined" && typeof (data.html.noArticleAddedToSc) != "undefined" && data.html.noArticleAddedToSc === true) {
                        var noArticleAddedToCartNotification = {
                            containerId: 'js-show-notification-no-article-added-to-cart',
                            fancyboxOptions: {closeBtn: true},
                            callbacks: [function () {
                            }]
                        }
                        obeta.messages.showConfirmation(noArticleAddedToCartNotification);
                    } else {
                        _addImportFileToScOk(data);
                    }
                }, function () {
                }
            );
        }

    return {
        initUploadImportFile: initUploadImportFile,
        bindAddImportFileToSc: bindAddImportFileToSc
    };
})();

module.exports = obeta.importfile;