

obeta.project = (function() {
    var animateDeliveryOptions = function (display) {
        display = display == 'show' ? 'show' : 'hide';

        $(".sc-delivery-desired").animate({"height": display}, {duration: 600});
        $(".sc-radio-delivery-mode").animate({"height": display}, {duration: 600});
        $(".sc-radio-delivery-mode > p").animate({"height": display}, {duration: 600});
        $("#delivery-options").animate({"height": display}, {duration: 600});
        $(".address-picker").animate({"height": display}, {duration: 600});

        if(display == 'hide') {
            $('.project-delivery-data').find('hr.hr-thin').hide();
        }
        else{
            $('.project-delivery-data').find('hr.hr-thin').show();
        }
    };
    var animatePickupOptions = function (display) {
        display = display == 'show' ? 'show' : 'hide';
        $("#pickup-dd").animate({"height": display}, {duration: 600});
        $(".sc-pickup-wrapper").animate({"height": display}, {duration: 600});
    };
    var bindDeleteProjectEvent = function() {
        $('.js-project-delete').on('click', function(e) {
            e.preventDefault();
            var $this = $(this),
                url = $this.attr('href');
            var confirmationParams = {
                containerId: "js-project-delete-message",
                callbacks:
                [
                    function() {},
                    function() {
                        removeShoppingCartRequest = $.ajax({
                            type: "GET",
                            url: url
                        })
                            .done(function(data) {
                            if (data.status=="projectIsUsed") {
                                obeta.messages.showMessage('Das Projekt konnte nicht gelöscht werden, da es noch in einem Warenkorb verwendet wird.', obeta.messages.type.ERROR);
                            } else if (data.status=="deleteFailed") {
                                obeta.messages.showMessage('Das Projekt konnte nicht gelöscht werden.', obeta.messages.type.ERROR);
                            } else {
                            var url = $this.data('success');
                                location.href=url;
                            }
                        })
                            .fail(function(data) {
                        });
                    }
                ]
            }
            obeta.messages.showConfirmation(confirmationParams);
        });
    },

        bindAddNewProjectEvent = function() {
            $(window).load(function() {
                var projectId = $('#projectId').val();
                // by adding new project, set focus on the first field "projectname"
                if (projectId == '') {
                    $('#js-project-name-input').val('').focus();
                }
            });
        },

        bindSubmitFormEvent = function() {
            $(".form-submit-button").on('click', function(e) {
                e.preventDefault();
                var checkedRadioValue = $(".radio-checked").parent().prev("input").val();

                if ($('#js-project-name-input').val()=="Ihr Projektname") {
                    $('#js-project-name-input').val("");
                }
                if ($('#js-project-desc-input').val()=="Ihre Projektbeschreibung") {
                    $('#js-project-desc-input').val("");
                }
                if ($('#edit-project-offerid-input').val()=="Ihre Angebotsnummer") {
                    $('#edit-project-offerid-input').val("");
                }
                if ($('#js-project-commission-input').val()=="Ihre Kommission") {
                    $('#js-project-commission-input').val("");
                }
                if ($('#js-project-additional-input').val()=="Ihr Zusatztext") {
                    $('#js-project-additional-input').val('');
                }
                if ($('#js-project-notice-input').val()=="Ihre Notizen") {
                    $('#js-project-notice-input').val("");
                }

                // if delivery
                if (checkedRadioValue == "0" || checkedRadioValue == 'a') {
                    $('#js-edit-project-form').parsley('destroy');

                    if (checkedRadioValue == "0") {
                        $('#js-edit-project-form').parsley({
                            excluded: "select[id=pickup-dropdown],input[id=input-name1],input[id=input-street],input[id=input-postcode],input[id=js-input-city],input[name=lieferart]"
                        });
                        $('div.radio-div.parsley-error').removeClass('parsley-error');
                    }
                }

                //check if val from pickup dropdown is selected
                //if true remove parsley foo for sending request to create new project
                if (checkedRadioValue === 'b' && parseInt($('.custom-dd-pickup :selected').val()) > 0){
                    $('#js-edit-project-form').parsley('destroy');
                    $('div.radio-div.parsley-error').removeClass('parsley-error');
                }

                if ($('#input-name1').val()=="Ihr Name") {
                    $('#input-name1').val("");
                }
                if ($('#input-street').val()=="Ihre Strasse und Hausnummer") {
                    $('#input-street').val("");
                }
                if ($('#input-postcode').val()=="Ihre Postleitzahl") {
                    $('#input-postcode').val("");
                }
                if ($('#js-input-city').val()=="Ihre Stadt") {
                    $('#js-input-city').val("");
                }

                $('#input-name1').removeAttr('disabled');
                $('#input-name2').removeAttr('disabled');
                $('#input-street').removeAttr('disabled');
                $('#input-postcode').removeAttr('disabled');
                $('#js-input-city').removeAttr('disabled');

                if(checkedRadioValue !== "0" &&
                    ($('#input-name1').val()+$('#input-name2').val() === '' ||
                    $('#input-street').val() === '' ||
                    $('#input-postcode').val() === '' ||
                    $('#js-input-city').val() === '')) {
                    obeta.messages.showConfirmation({
                        containerId: "js-project-missing-address-message"
                    });
                } else {
                    var url = $(this).data('formid');
                    $(url).trigger('submit');
                }
            });
        },
        bindDeliveryTypeRadio = function () {
            // delivery
            if(parseInt($('.sc-delivery-left .delivery-type').data('treat-as-cashcustomer')) > 0){
                obeta.formHelpers.disableAddressFields();
            }

            $('#sc-right').on('click', function () {
                if(parseInt($('.sc-delivery-left .delivery-type').data('treat-as-cashcustomer')) > 0){
                    obeta.formHelpers.disableAddressFields();
                }
            });

            // wunschadresse
            $('#js-radio-delivery-desired').on('click', triggerDeliveryDesired);

            // pickup
            $('#js-radio-self-pickup').on('click', triggerDeliveryPickup);

            // projectPage no default selection
            $('#js-no-default-delivery').on('click', triggerNoDefault);

            // shipping type by delivery: package, package express, truck ..
            $('#delivery-options .delivery-type').on('click', triggerSubTypes);
        },
        triggerDeliveryDesired = function (e) {
            e.preventDefault();

            animatePickupOptions("hide");
            animateDeliveryOptions("show");

            if ($('#delivery-options .radio-checked').length == 0) {
                $('#delivery-options .radio-fx').first().find('span').click();
            }

            var addressToShow =  $('#delivery-options').find('.radio-checked').parent().prev().attr('data-address');

            var tourIsActive = addressToShow.length > 0;
            if (! tourIsActive) {
                var selectedDropDownAddress = $('#stored-addresses-dropdown option:selected').attr('data-address');
                addressToShow = selectedDropDownAddress;

                $('#input-name1').removeClass('default-text-active');
                $('#input-name2').removeClass('default-text-active');
                $('#input-street').removeClass('default-text-active');
                $('#input-postcode').removeClass('default-text-active');
                $('#js-input-city').removeClass('default-text-active');

            }
            obeta.formHelpers.fillAddressFields(addressToShow);

        },
        triggerDeliveryPickup = function (e) {
            e.preventDefault();
            animateDeliveryOptions("hide");
            animatePickupOptions("show");

            if($('#address-form').hasClass('cash-customer')){
                $('.stored-address-dd').addClass("selectBox-disabled");
            }
        },
        triggerNoDefault = function (e) {
            e.preventDefault();
            animatePickupOptions("hide");
            animateDeliveryOptions("hide");

            if($('#address-form').hasClass('cash-customer')){
                $('.stored-address-dd').addClass("selectBox-disabled");
            }

        },
        triggerSubTypes = function (e) {
            e.preventDefault();

            var $this = $(this);

            var isCustomTour = $this.attr('data-address').length > 0;
            if (isCustomTour) {
                var addressToShow = $this.attr('data-address');
                obeta.formHelpers.fillAddressFields(addressToShow);
                obeta.formHelpers.disableAddressFields();
                $('.stored-address-dd').addClass("selectBox-disabled");

                $('#stored-addresses-dropdown').val(0);

            } else {
                obeta.formHelpers.enableAddressFields();
                $('.stored-address-dd').removeClass("selectBox-disabled");
                var addressToShow = $('#stored-addresses-dropdown').find('option:selected').data('address');
                obeta.formHelpers.fillAddressFields(addressToShow);
                $('#input-name1').removeClass('default-text-active');
                $('#input-name2').removeClass('default-text-active');
                $('#input-street').removeClass('default-text-active');
                $('#input-postcode').removeClass('default-text-active');
                $('#js-input-city').removeClass('default-text-active');
            }

            var url = $this.attr('data-validate-data-url');
            if ($('#delivery-options > .radio-div').hasClass('parsley-error')) {
                $('#delivery-options > .radio-div').removeClass('parsley-error');
                $('#parsley-lieferart.parsley-error-list').remove();
            }

            if($('#address-form').hasClass('cash-customer')){
                $('.stored-address-dd').addClass("selectBox-disabled");
            }

        };
    return {
            bindDeleteProjectEvent: bindDeleteProjectEvent,
            bindAddNewProjectEvent: bindAddNewProjectEvent,
            bindSubmitFormEvent: bindSubmitFormEvent,
            bindDeliveryTypeRadio: bindDeliveryTypeRadio
        };
})();

module.exports = obeta.project;
