const aa = require('search-insights');

obeta.algolia = (function () {
        let env = window.jsEnvironment;
        let index  = env.algoliaIndexName
        let replicaIndex  = env.algoliaReplicaIndexName

        aa('init', {
            appId: env.algoliaApplicationId,
            apiKey: env.algoliaSearchApiKey
        });

        let sendClickedObjectIdAfterSearchEvent = function (objectId, queryId, position, userToken) {
            //algolia needs the positions to start at 1, not 0
            position++;
            aa('clickedObjectIDsAfterSearch',{
                userToken: userToken,
                eventName: 'Product clicked',
                index: index,
                queryID: queryId,
                objectIDs: [objectId.toString()],
                positions: [position]
            });

            //send same event to replicated production index for ab tests
            if(replicaIndex) {
                aa('clickedObjectIDsAfterSearch',{
                    userToken: userToken,
                    eventName: 'Product clicked',
                    index: replicaIndex,
                    queryID: queryId,
                    objectIDs: [objectId.toString()],
                    positions: [position]
                }); 
            }
        }
        let sendClickedObjectIdAfterConversionEvent = function (objectId, queryId, userToken) {
            aa('convertedObjectIDsAfterSearch', {
                userToken: userToken,
                eventName: 'Product converted',
                index: index,
                queryID: queryId,
                objectIDs: [objectId.toString()],
            });
            //send same event to replicated production index for ab tests
            if(replicaIndex) {
                aa('convertedObjectIDsAfterSearch', {
                    userToken: userToken,
                    eventName: 'Product converted',
                    index: replicaIndex,
                    queryID: queryId,
                    objectIDs: [objectId.toString()],
                });
            }
        }
    return {
        sendClickedObjectIdAfterSearchEvent: sendClickedObjectIdAfterSearchEvent,
        sendClickedObjectIdAfterConversionEvent: sendClickedObjectIdAfterConversionEvent
    }
})();

module.exports = obeta.algolia;
