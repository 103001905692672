
obeta.slides = (function() {
    var initSlider = function() {
        $("#top-teaser-carousel").show();
    },
            bindSliderHistory = function() {
                $('#history-slider-list').bxSlider({
                    infiniteLoop: false,
                    hideControlOnEnd: true,
                    minWidth: 705,
                    maxWidth: 705,
                    startSlide: 0,
                    sliderMarginLeft: -8,
                    nextId: 'history-next',
                    prevId: 'history-prev'
                });
                $('#history-slider-list').show();
                setTimeout(function() {
                    $('#history-slider-wrapper').show();
                }, 500);
            },
            initBannerscollection = function() {
                if ($('#wowslider-container1').length > 0) {
                    //var userAgent = navigator.userAgent.toLowerCase();
                    //var is_chrome = (userAgent.indexOf("chrome/37")>1);
                    //var fadeduration = (is_chrome) ? 100 : 6000;
                    var fadeduration = 700;
                    jQuery("#wowslider-container1").wowSlider({revers:1, effect: "parallax", prev: "<div></div>", next: "<div></div>", duration: fadeduration, delay: 6000, width: 1180, height: 325, autoPlay: true, stopOnHover: false, loop: false, bullets: true, caption: true, captionEffect: "fade", controls: true, controlsThumb:true,onBeforeStep: 0,onBeforeStep:0, gestures:0,images: 0});
                    updateKenBurnsHeight();
                    $("#top-teaser>.ws_prev").on("click",function() {$(".ws_controls>.ws_prev").click();});
                    $("#top-teaser>.ws_next").on("click",function() {$(".ws_controls>.ws_next").click();});
                }
            },
            updateKenBurnsHeight = function() {
                if ($('#wowslider-container1').length > 0) {
                    var newHeight = ($('#main').width() / 3.63);
                    $('.ws_images>div').css('height', newHeight);
                    $('#wowslider-container1').css('height', newHeight);
                    //$('.ws_bullets').css("top", newHeight - 24);
                    var sliderpos = $('#wowslider-container1').position();
                    $('.ws_prev').css('top', (newHeight / 2) + sliderpos.top - 28);
                    $('.ws_next').css('top', (newHeight / 2) + sliderpos.top - 28);
                    //center bullets horizontally
                    /*$('.ws_bullets').each(function() {
                        var w = $(this).width();
                        var div_w = $(this).parents('div:eq(0)').width();
                        this.style.left = Math.round((div_w - w) / 2) + 'px';
                        $(this).show();
                    });*/
                }
            },
            bindAutomaticSliderLoggedIn = function (){
                $(document).ready(function(){
                    var interval;
                    $('.dropdown-menu,.btn_cat').hover(function () {
                        // stop the slider if the dropdown category menu is shown
                            window.categoryFlyoutShown = true;
                    }, function () {
                            window.categoryFlyoutShown = false;
                    });
                    var timer = function(){
                        interval = setInterval(function(){
                            if (!window.categoryFlyoutShown) {
                                $("#next-top-newse").click();
                            }
                        },6000);
                    };
                    timer();
                    $("#next-top-newse").click(function(){
                        clearInterval(interval);
                        timer();
                    })
                });
            };

    return {
        initSlider: initSlider,
        initBannerscollection: initBannerscollection,
        bindSliderHistory: bindSliderHistory,
        updateKenBurnsHeight: updateKenBurnsHeight,
        bindAutomaticSliderLoggedIn: bindAutomaticSliderLoggedIn
    };
})();

module.exports = obeta.slides;
            