

obeta.caroussel = (function () {
    var bindButtonsThatHideDropdown = function () {
            $(".wrapper").click(function (evt) {
                if (!$(evt.target).hasClass('downBtnBl')
                    && !$(evt.target).hasClass('downBtnImgBl')) {
                    $(".dropdown-caroussel").hide();
                    $(".dropdown-caroussel").attr('toggle', 'true');
                }
            });
            $(".product-carousel .relatedBefore").click(function () {
                $(".dropdown-caroussel").hide();
                $(".dropdown-caroussel").attr('toggle', 'true');
            });
            $(".product-carousel .relatedNext").click(function () {
                $(".dropdown-caroussel").hide();
                $(".dropdown-caroussel").attr('toggle', 'true');
            });
        },
        initRecommendationCaroussel = function () {
            $('.product-carousel').each(function(){
                var $this = $(this);
                    id = "#" + $this[0].id,
                    productsAmount = $this.find('.products-amount'),
                    amount = parseInt(productsAmount.val()),
                    $relatedProductsContainer = $this.find('.related-products-carousel'),
                    relatedProductsExist = $relatedProductsContainer.find('ul li').length > 0;

                if (amount > 0) {
                    relatedProductsExist && $relatedProductsContainer.jCarouselLite({
                        btnNext: id + " .relatedNext",
                        btnPrev: id + " .relatedBefore",
                        visible: amount
                    });
                    if (amount < 5) {
                        $relatedProductsContainer.attr({'style': 'width: ' + (238 * amount) + 'px !important;'});
                    }
                }
            });
        },
        bindRelatedArticlesCheckbox = function () {
            $('.related-articles-checkbox').on('click', function (e) {
                e.preventDefault();
                var $this = $(this);
                $this.toggleClass('check-selected');
                $this.toggleClass('check-unselected');

                var url = $this.attr('data-url'),
                    sameSupplier = $this.hasClass('check-selected') ? '1' : '0';

                loadRelatedProductsRequest = obeta.helpers.ajax({
                    type: "POST",
                    url: url,
                    data: {'sameSupplier': sameSupplier}
                }, function (data) {
                    $('#related-products-wrapper').html(data["html"]["relatedArticles"]);
                    initRecommendationCaroussel();
                    $('.obtooltip').tooltip();
                    obeta.articleList.bindAddArticle();
                    obeta.prices.fetchPricesViaAjax();
                }, function (data) {
                });
            });
        },
        initTopTeaserCaroussel = function () {
            $('#top-teaser-carousel').bxSlider({
                infiniteLoop: true,
                hideControlOnEnd: true
            });
        },
        bindDropdownCLick = function () {
            $(".wrapper").on('click', '.caroussel-btn', function () {
                $(".dropdown-caroussel").hide();
                var artid = $(this).attr('art-id');
                if ($("#" + artid).attr('toggle') == 'true') {
                    var sBrowser, sUsrAg = navigator.userAgent;
                    var lpos = -134;
                    var tpos = 29;
                    if (sUsrAg.indexOf("MSIE 7") > -1) {
                        var lpos = -134;
                        var tpos = 30;
                    } else if (sUsrAg.indexOf("MSIE 8") > -1) {
                        var lpos = -134;
                        var tpos = 45;
                    } else if (sUsrAg.indexOf("MSIE 9") > -1) {
                        var lpos = -134;
                        var tpos = 45;
                    }
                    var posleft = $(this).offset().left - $('.product-carousel').offset().left + lpos;
                    var postop = $(this).closest('li').height() - tpos + 10;

                    $("#" + artid).attr('toggle', 'false');
                    $("#" + artid).css({position: "absolute", left: posleft, top: postop});
                    $("#" + artid).show();
                } else {
                    $("#" + artid).hide();
                    $("#" + artid).attr('toggle', 'true');
                }
            });
        }
    return {
        bindButtonsThatHideDropdown: bindButtonsThatHideDropdown,
        bindDropdownCLick: bindDropdownCLick,
        initRecommendationCaroussel: initRecommendationCaroussel,
        bindRelatedArticlesCheckbox: bindRelatedArticlesCheckbox,
        initTopTeaserCaroussel: initTopTeaserCaroussel
    }
})();

module.exports = obeta.caroussel;