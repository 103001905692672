

obeta.branchavailability = {

    fetchBranchAvailabilityViaAjax: function () {
        let node = $('.branch-availability');
        if (typeof node[0] === 'undefined'){
            return;
        }
        let isDisabled = node.hasClass('deactivated');
        let sapId = node.attr('data-js-load-branch-availability-via-ajax');
        $.ajax({
            url: "/js/branchavailability",
            type: "POST",
            data: {
                "sapId": sapId
            },
        }).done(function(response) {
            let count = Object.keys(response).length;
            if(count !== 0) {
                let postfix = count >=1 ? 'n' : '';
                let backgroundColor = isDisabled ? ' bgcolor-grey': '';
                let branchAvailabilityHtml =
                    '<div class="sidebar-flag' + backgroundColor + '">' +
                    '<span class="obeta-icons home-black"></span>' +
                    'In ' + count + ' Filiale' + postfix +
                    '</div>';
                $('a[data-js-load-branch-availability-via-ajax]').html(branchAvailabilityHtml);
            }
        });
    }
};

module.exports = obeta.branchavailability;