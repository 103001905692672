

obeta.ledCalculator = (function () {
    var initLedCalculator = function () {
        $('#abort-button').on('click', function (e) {
            e.preventDefault();
            history.go(-1);
        });
        function isNumber(o) {
            return !isNaN(o - 0);
        }

        function checkNumberInRange(num, min, max) {
            var temp_num = num.replace(/,/, ".");
            // wenn keine nummer
            if (!isNumber(temp_num))
                return min;
            // wenn leer
            if (temp_num == "")
                return min;
            // wenn zu klein
            if (temp_num < min)
                return min;
            if (temp_num > max)
                return max;
            return true;
        }

// Funktion die aus Eingabefeldern alle anderen berechnet
        function calc() {
            var weeksInYear = 52.142857;
            var co2Factor = 0.584;
            var val;

            // Stunden pro Tag: check min/max values
            if ((val = checkNumberInRange($('#D3').val(), 0, 24)) !== true) {
                $('#D3').val("");
            }
            // Tage pro Woche: check min/max values
            if ((val = checkNumberInRange($('#F3').val(), 0, 7)) !== true) {
                $('#F3').val("");
            }
            // Leuchtmittel: check min/max values
            if ((val = checkNumberInRange($('#D5').val(), 0, 100000)) !== true) {
                $('#D5').val("");
            }
            // Stromkosten: check min/max values
            if ((val = checkNumberInRange($('#D7').val(), 0, 1000)) !== true) {
                $('#D7').val("");
            }
            // Leistungsaufnahme: check min/max values
            if ((val = checkNumberInRange($('#D11').val(), 0, 1000)) !== true) {
                $('#D11').val("");
            }
            // Lebensdauer: check min/max values
            if ((val = checkNumberInRange($('#D15').val(), 0, 1000000)) !== true) {
                $('#D15').val("");
            }
            // Wechselkosten
            if ((val = checkNumberInRange($('#D17').val(), 0, 1000000)) !== true) {
                $('#D17').val("");
            }

            //D21=D5*D13
            var D21 = $('#D5').val() * ($('#D13').val().replace(/,/, "."));
            $('#D21').val(de_comma(D21));

            //F21=F13*D5
            var F21 = $('#D5').val() * ($('#F13').val().replace(/,/, "."));
            $('#F21').val(de_comma(F21));

            //H21=D21-F21
            var H21 = ($('#D21').val().replace(/,/, ".")) - ($('#F21').val().replace(/,/, "."));
            $('#H21').val(de_comma(H21));

            //D23=F23=D17*D5
            var D23 = ($('#D17').val().replace(/,/, ".")) * ($('#D5').val().replace(/,/, "."));
            $('#D23').val(de_comma(D23));

            var F23 = 0;
            $('#F23').val(de_comma(D23));

            //H23=D23-F23
            var H23 = ($('#D23').val().replace(/,/, ".")) - ($('#F23').val().replace(/,/, "."));
            $('#H23').val(de_comma(H23));

            //D27=D15/(D3*F3)/52.142857
            var D27 = ($('#D15').val().replace(/,/, ".")) / (($('#D3').val().replace(/,/, ".") * ($('#F3').val().replace(/,/, ".")))) / weeksInYear;
            $('#D27').val(de_comma(D27));

            //F27=F15/(D3*F3)/52.142857
            var F27 = ($('#F15').val().replace(/,/, ".")) / (($('#D3').val().replace(/,/, ".") * ($('#F3').val().replace(/,/, ".")))) / weeksInYear;
            $('#F27').val(de_comma(F27));

            //H27=D27-F27
            var H27 = (($('#D27').val().replace(/,/, ".")) - ($('#F27').val().replace(/,/, "."))) * (-1);
            $('#H27').val(de_comma(H27));

            // Hinweis: D27, welches bereits vorher berechnet wurde wird verwendet. FS#1248 Genauigkeit.
            //D32=1/D27*D5
            //var D32= (1/($('#D27').val().replace(/,/, "."))) * ($('#D5').val().replace(/,/, "."));
            var D32 = (1 / D27) * ($('#D5').val().replace(/,/, "."));
            $('#D32').val(de_comma(D32, 1));

            // Hinweis: F27, welches bereits vorher berechnet wurde wird verwendet. FS#1248 Genauigkeit.
            //F32=1/F27*D5
            var F32 = (1 / F27) * ($('#D5').val().replace(/,/, "."));
            //Lebensdauer mit einbeziehen: Wenn Brenndauer pro Jahr > F15 dann Wechsel berechnen, sonst 0
            //=WENN(D3*F3*52.142857>F15,(1/F27*D5),0)
            if ($('#D3').val().replace(/,/, ".") * $('#F3').val().replace(/,/, ".") * weeksInYear > $('#F15').val().replace(/,/, ".")) {
                $('#F32').val(de_comma(F32, 1));
            } else {
                $('#F32').val(de_comma(0, 1));
            }

            //H32=D32-F32
            var H32 = ($('#D32').val().replace(/,/, ".")) - ($('#F32').val().replace(/,/, "."));
            $('#H32').val(de_comma(H32));

            //D34=F15/D15*D5-D5
            var D34 = $('#F15').val().replace(/,/, ".") / $('#D15').val().replace(/,/, ".") * $('#D5').val().replace(/,/, ".") - $('#D5').val().replace(/,/, ".") / 1;
            $('#D34').val(de_comma(D34, 1));

            //F34=1*D5-D5
            var F34 = 1 * $('#D5').val().replace(/,/, ".") - $('#D5').val().replace(/,/, ".");
            $('#F34').val(de_comma(F34, 1));

            //H34=D34-F34
            var H34 = 1 * $('#D34').val().replace(/,/, ".") - $('#F34').val().replace(/,/, ".");
            $('#H34').val(de_comma(H34, 1));

            // Hinweis: D34, welches bereits vorher berechnet wurde wird verwendet. FS#1248 Genauigkeit.
            //D36=D34*D13
            //var D36=$('#D34').val().replace(/,/, ".")*$('#D13').val().replace(/,/, ".");
            var D36 = D34 * $('#D13').val().replace(/,/, ".");
            $('#D36').val(de_comma(D36, 2));

            //H36=D36-F36
            var H36 = $('#D36').val().replace(/,/, ".") - $('#F36').val().replace(/,/, ".");
            $('#H36').val(de_comma(H36, 2));

            // Hinweis: D34, welches bereits vorher berechnet wurde wird verwendet. FS#1248 Genauigkeit.
            //D38=D34*D17
            var D38 = D34 * $('#D17').val().replace(/,/, ".");
            $('#D38').val(de_comma(D38, 2));

            //H38=D38-F38
            var H38 = $('#D38').val().replace(/,/, ".") - $('#F38').val().replace(/,/, ".");
            $('#H38').val(de_comma(H38, 2));

            //D42=(D3*D11*D5*F3/1000)*(52.142857/12)
            var D42 = ($('#D3').val().replace(/,/, ".") * $('#D11').val().replace(/,/, ".") * $('#D5').val().replace(/,/, ".") * $('#F3').val().replace(/,/, ".") / 1000) * (weeksInYear / 12);
            $('#D42').val(de_comma(D42, 2));

            //F42=(D3*F11*D5*F3/1000)*(52.142857/12)
            var F42 = ($('#D3').val().replace(/,/, ".") * $('#F11').val().replace(/,/, ".") * $('#D5').val().replace(/,/, ".") * $('#F3').val().replace(/,/, ".") / 1000) * (weeksInYear / 12);
            $('#F42').val(de_comma(F42, 2));

            //H42=D42-F42
            var H42 = $('#D42').val().replace(/,/, ".") - $('#F42').val().replace(/,/, ".");
            $('#H42').val(de_comma(H42, 2));

            //D44=D11*D3*D5*D7/100000*F3*52.142857/12
            var D44 = $('#D11').val().replace(/,/, ".") * $('#D3').val().replace(/,/, ".") * $('#D5').val().replace(/,/, ".") * $('#D7').val().replace(/,/, ".") / 100000 * $('#F3').val().replace(/,/, ".") * weeksInYear / 12;
            $('#D44').val(de_comma(D44, 2));

            //F44=F11*D3*D5*D7/100000*F3*52.142857/12
            var F44 = $('#F11').val().replace(/,/, ".") * $('#D3').val().replace(/,/, ".") * $('#D5').val().replace(/,/, ".") * $('#D7').val().replace(/,/, ".") / 100000 * $('#F3').val().replace(/,/, ".") * weeksInYear / 12;
            $('#F44').val(de_comma(F44, 2));

            //H44=D44-F44
            var H44 = $('#D44').val().replace(/,/, ".") - $('#F44').val().replace(/,/, ".");
            $('#H44').val(de_comma(H44, 2));

            //Hinweis: D44, welches bereits vorher berechnet wurde wird verwendet. FS#1248 Genauigkeit.
            //D46=D44*12
            var D46 = D44 * 12;
            $('#D46').val(de_comma(D46, 2));

            //Hinweis: F44, welches bereits vorher berechnet wurde wird verwendet. FS#1248 Genauigkeit.
            //F46=F44*12
            var F46 = F44 * 12;
            $('#F46').val(de_comma(F46, 2));

            //H46=D46-F46
            var H46 = $('#D46').val().replace(/,/, ".") - $('#F46').val().replace(/,/, ".");
            $('#H46').val(de_comma(H46, 2));

            //Hinweis: D21 und D46 und D23, welche bereits vorher berechnet wurde wird verwendet. FS#1248 Genauigkeit.
            //D51=D21+D46+D23+(WENN(D27<1,D17*D32,0))
            var D51 = D21 / 1 + D46 / 1 + D23 / 1;
            if (D27 < 1) {
                D51 = D51 / 1 + ($('#D17').val().replace(/,/, ".") * D32) / 1;
            }
            $('#D51').val(de_comma(D51, 2));

            //Hinweis: F21 und F46 und F23, welche bereits vorher berechnet wurde wird verwendet. FS#1248 Genauigkeit.
            //F51=F21+F46+F23
            var F51 = F21 * 1 + F46 * 1 + F23 * 1;
            $('#F51').val(de_comma(F51, 2));

            //Hinweis: D51 und F51, welche bereits vorher berechnet wurde wird verwendet. FS#1248 Genauigkeit.
            //H51=D51-F51
            var H51 = D51 - F51;
            $('#H51').val(de_comma(H51, 2));

            //Hinweis: D46 und F27 und D36 und D21 und D38 und D23, welche bereits vorher berechnet wurde wird verwendet. FS#1248 Genauigkeit.
            //D53=D46*F27+D36+D21+D38+D23
            var D53 = (D46 * F27) + D36 + D21 + D38 + D23;
            $('#D53').val(de_comma(D53, 2));

            //Hinweis: F21 und F46 und F27 und F23, welche bereits vorher berechnet wurde wird verwendet. FS#1248 Genauigkeit.
            //F53=F21+F46*F27+F23
            var F53 = F21 + F46 * F27 + F23;
            $('#F53').val(de_comma(F53, 2));

            //H53=H46*F27+H21+D36
            //var H53=($('#H46').val().replace(/,/, ".")*$('#F27').val().replace(/,/, "."))/1+$('#H21').val().replace(/,/, ".")/1+$('#D36').val().replace(/,/, ".")/1;
            //Mail vom 24.01.2012 andere Berechnung:
            //H53=D53-F53
            //var H53=$('#D53').val().replace(/,/, ".")-$('#F53').val().replace(/,/, ".");
            var H53 = D53 - F53;
            $('#H53').val(de_comma(H53, 2));

            //D57=F27
            //$('#D57').val(de_comma(F27));
            $('#D57').html(de_comma(F27));
            $('input[name="D57"]').val(de_comma(F27, 0));

            //D59=(D3*D5*D7*D11/100000)-(D3*D5*D7*F11/100000)
            var D59 = ($('#D3').val().replace(/,/, ".") * $('#D5').val().replace(/,/, ".") * $('#D7').val().replace(/,/, ".") * $('#D11').val().replace(/,/, ".") / 100000) -
                    ($('#D3').val().replace(/,/, ".") * $('#D5').val().replace(/,/, ".") * $('#D7').val().replace(/,/, ".") * $('#F11').val().replace(/,/, ".") / 100000);
            //$('#D59').val(de_comma(D59));
            $('#D59').html(de_comma(D59));
            $('input[name="D59"]').val(de_comma(D59));

            //G59=H46
            //$('#G59').val(de_comma(H46,2));
            $('#G59').html(de_comma(H46, 2));
            $('input[name="G59"]').val(de_comma(H46, 2));

            //D61=D57
            //D57=F27
            //$('#D61').val(de_comma(F27,1));
            $('#D61').html(de_comma(F27, 1));
            $('input[name="D61"]').val(de_comma(F27, 1));

            //Hinweis: F27 und H46, welche bereits vorher berechnet wurde wird verwendet. FS#1248 Genauigkeit.
            //G61=D57*H46
            // -- D57=F27 --
            var G61 = F27 * H46;
            //$('#G61').val(de_comma(G61,2));
            $('#G61').html(de_comma(G61, 2));
            $('input[name="G61"]').val(de_comma(G61, 2));

            //F62=D34
            //$('#F62').val(de_comma(D34,1));
            $('#F62').html(de_comma(D34, 1));
            $('input[name="F62"]').val(de_comma(D34, 1));

            //Hinweis: D36 und D38, welche bereits vorher berechnet wurde wird verwendet. FS#1248 Genauigkeit.
            //J62=D36+D38
            var J62 = D36 + D38;
            //$('#J62').val(de_comma(J62,1));
            $('#J62').html(de_comma(J62, 1));
            $('input[name="J62"]').val(de_comma(J62, 1));

            //D64=-H21
            var D64 = $('#H21').val().replace(/,/, ".") * (-1);
            $('#D64').html(de_comma(D64, 2));
            $('input[name="D64"]').val(de_comma(D64, 2));

            //F64=F27
            $('#F64').html(de_comma(F27, 1));
            $('input[name="F64"]').val(de_comma(F27, 1));

            //I64=H53
            $('#I64').html(de_comma(H53, 2));
            $('input[name="I64"]').val(de_comma(H53, 2));

            //Hinweis: H21 und H46, welche bereits vorher berechnet wurde wird verwendet. FS#1248 Genauigkeit.
            //D66=(-H21)/H46*12
            var D66 = H21 * (-1) / H46 * 12;
            $('#D66').html(de_comma(D66, 1));
            $('input[name="D66"]').val(de_comma(D66, 1));

            //Hinweis: F27, welche bereits vorher berechnet wurde wird verwendet. FS#1248 Genauigkeit.
            //D68=F27*((D11*D5*D3*F3*52.142857)
            //        -(F11*D5*D3*F3*52.142857))/1000
            var D68 = F27 * (($('#D11').val().replace(/,/, ".") * $('#D5').val().replace(/,/, ".") * $('#D3').val().replace(/,/, ".") * $('#F3').val().replace(/,/, ".") * weeksInYear)
                    - ($('#F11').val().replace(/,/, ".") * $('#D5').val().replace(/,/, ".") * $('#D3').val().replace(/,/, ".") * $('#F3').val().replace(/,/, ".") * weeksInYear)) / 1000;
            $('#D68').html(de_comma(D68, 0));
            $('input[name="D68"]').val(de_comma(D68, 0));

            //Hinweis: D68, welches bereits vorher berechnet wurde wird verwendet. FS#1248 Genauigkeit.
            //G68=0.565*D68
            var G68 = D68 * co2Factor;
            $('#G68').html(de_comma(G68, 0));
            $('input[name="G68"]').val(de_comma(G68, 0));
        }
// deutsches komma, statt .
        function de_comma(wert, kommastellen) {
            if (kommastellen === undefined) {
                kommastellen = 2;
            }
            return(wert.toFixed(kommastellen).replace(/\./, ","));
        }

// do stuff when DOM is ready
        $(document).ready(function () {
            // array mit allen eingabefeldern
            var inputActionElements = ["#D3", "#F3", "#D5", "#D7", "#D11", "#F11", "#D13", "#F13", "#D15", "#F15", "#D17"];
            // an alle eingabefelder bei keyup-event berechnung durchfuehren
            $.each(inputActionElements, function (index, inputElement) {
                $(inputElement).keyup(function () {
                    calc();
                });
            });
            //SETTING UP OUR POPUP  
            //0 means disabled; 1 means enabled;  
            var popupStatus = 0;

            //loading popup with jQuery magic!  
            function loadPopup() {
                //loads popup only if it is disabled  
                if (popupStatus == 0) {
                    $("#backgroundPopup").css({
                        "opacity": "0.4"
                    });
                    $("#backgroundPopup").fadeIn("slow");
                    $("#popupPDF").fadeIn("slow");
                    popupStatus = 1;
                }
            }
            //disabling popup with jQuery magic!  
            function disablePopup() {
                //disables popup only if it is enabled  
                if (popupStatus == 1) {
                    $("#backgroundPopup").css({
                        "opacity": "1.0"
                    });
                    $("#backgroundPopup").fadeIn("slow");
                    $("#popupPDF").fadeOut("slow");
                    popupStatus = 0;
                }
            }
            //centering popup  
            function centerPopup() {
                //request data for centering  
                var windowWidth = document.documentElement.clientWidth;
                var windowHeight = document.documentElement.clientHeight;
                var popupHeight = $("#popupPDF").height();
                var popupWidth = $("#popupPDF").width();
                //centering  
                $("#popupPDF").css({
                    "position": "absolute",
                    "top": windowHeight / 2 - popupHeight / 2,
                    "left": windowWidth / 2 - popupWidth / 2
                });
                //only need force for IE6  
                $("#backgroundPopup").css({
                    "height": windowHeight
                });
            }

            $("#PDFbutton").click(function () {
                window.scrollTo(0, 0);
                //centering with css  
                centerPopup();
                //load popup  
                loadPopup();
            });

            //CLOSING POPUP  
            //Click the x event!  
            $("#popupPDFClose").click(function () {
                disablePopup();
            });

            //Press Escape event!  
            $(document).keypress(function (e) {
                if (e.keyCode == 27 && popupStatus == 1) {
                    disablePopup();
                }
            });
            // PDF Download Klick
            $("#PDFDownload").click(function () {
                $('#ledform').parsley('validate');
                $("#ledform").submit();
                disablePopup();
                return false;
            });
            $("#email_absenden_button").click(function () {
                // RegEx um E-Mail zu testen
                var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;

                if (($("#mailto").val() == "") || (!emailReg.test($("#mailto").val()))) {
                    $("#mailto").css("background-color", "red");
                    return false;
                }
                if (($("#replyto").val() == "") || (!emailReg.test($("#replyto").val()))) {
                    $("#replyto").css("background-color", "red");
                    return false;
                }

                $("#email_senden").val("true");
                $('#ledform').parsley('validate');
                $("#ledform").submit();
                disablePopup();
                return false;
            });
            $("#mailto").focus(function () {
                $("#mailto").css("background-color", "white");
            });
            $("#replyto").focus(function () {
                $("#replyto").css("background-color", "white");
            });
        });

    };

    return {
        initLedCalculator: initLedCalculator
    }
})();

module.exports = obeta.ledCalculator;