
obeta.hagerzplan = (function () {

    var bindHagerzplanEvent = function () {
            $('.add-articles-from-hagerzplan-to-cart').on('click', function (e) {
                e.preventDefault();

                var $this = $(this),
                    url = $this.attr('href'),
                    positions = $('#hagerzplan-positions').val();

                if (positions != '' && typeof (positions) != 'undefined') {
                    obeta.helpers.hideSpinner();
                    obeta.helpers.ajax({
                        type: "POST",
                        url: url,
                        data: {
                            positions: positions
                        }
                    }, function () {
                        $('#movesc-msg').hide();
                        $('#movesc-dropdown').html('<br><b>Die Artikel wurden in den Warenkorb verschoben</b>');
                        if (!window.jsEnvironment.embedMode){
                            obeta.helpers.showSpinner();
                            window.top.location.href = "/warenkorb/W/uebersicht";
                        }
                    }, function () {
                        if (!window.jsEnvironment.embedMode){
                            obeta.helpers.showSpinner();
                            window.top.location.href = "/warenkorb/W/uebersicht";
                        }
                    });
                } else {
                    obeta.messages.fancyAlert('Es ist ein Fehler aufgetreten. Bitte versuchen Sie noch einmal!', '   OK   ');
                }
            });
            $('.close-elbridge').on('click', function (e) {
                e.preventDefault();

                var $this = $(this),
                    url = $this.attr('href');

                obeta.helpers.hideSpinner();
                obeta.helpers.ajax({
                    type: "POST",
                    url: url
                }, function () {
                    if (!window.jsEnvironment.embedMode){
                        window.top.location.href = "/warenkorb/W/uebersicht";
                    }
                }, function () {
                    if (!window.jsEnvironment.embedMode){
                        window.top.location.href = "/warenkorb/W/uebersicht";
                    }
                });
            });
        },
        bindElbridge = function () {
            $('.startElbridge').on('click',function () {
                var elid = $(this).attr('elid');
                var pop = window.open("/startelbridge/"+elid);
                if (!pop || pop.closed || typeof pop.closed == 'undefined') {
                    alert("Sie scheinen einen Popup-Blocker aktiviert zu haben. Der Konfigurator kann nur bei ausgeschaltetem Popup-Blocker gestartet werden. Bitte deaktivieren Sie den Popup-Blocker.")
                } else {
                    pop.focus();

                    var monitor = setInterval(function () {
                        if (pop.closed) {
                            if (!window.jsEnvironment.embedMode){
                                $('#spinner').show();
                                window.top.location.href = "/warenkorb/W/uebersicht";
                            }
                            clearInterval(monitor);
                        }
                    }, 1000);
                }
            });
        };

    return {
        bindHagerzplanEvent: bindHagerzplanEvent,
        bindElbridge: bindElbridge
    };
})();

module.exports = obeta.hagerzplan;