
obeta.rent = (function() {
    var initRentPage = function() {

    $('ul.rent-machines>li>a').on('click', function() {
        var $this = $(this),
            id = $this.attr('data-id');

        if (id == 'all-tools') {

            $('.machine-list').css({height:'auto'});
            $('ul.rent-machines').find('b').each(function(e) {
                if ($(this).attr('data-id') == 'all-tools') {
                    $(this).attr('class', 'radButSel');
                } else {
                    $(this).attr('class', 'radBut');
                }
            });
            $('.machine-box-outer').slideDown();

        } else {

            var $wrapper = $('#'+id+'-wrapper');

            $('.machine-list').css({
                height: $wrapper.height(),
                overflow: 'hidden'
            });

            $('ul.rent-machines').find('b').each(function(e) {
                if ($(this).attr('data-id') == id) {
                    $(this).attr('class', 'radButSel');
                } else {
                    $(this).attr('class', 'radBut');
                }
            });

            $('.machine-box-outer').slideUp();
            $wrapper.slideDown();

        }

    });

    $('#rent-message-before-login').on('click', function(e) {
        e.preventDefault();
        var url = $(this).attr('href'),
            loggedIn = $(this).data('loggedin');
        if (loggedIn != '' && loggedIn == 1) {
            window.location.href = url;
        } else {
            var rentShowMessageBeforeLogin = {
                containerId: 'js-show-rent-message-before-login',
                callbacks:
                    [
                        function() {}
                    ]
            }
            obeta.messages.showConfirmation(rentShowMessageBeforeLogin);
        }
    });
  };

    return {
        initRentPage: initRentPage
    };
})();

module.exports = obeta.rent;