
obeta.articles = (function () {

    var bindAmountInputEvents = function ($inputElement, inShoppingCart, $focusObjectAfterAddingToCart) {
            var isIE11 = !!navigator.userAgent.match(/Trident\/7\./);
            $inputElement.on('keypress', function (e) {
                if (e.which == 13) {
                    e.preventDefault();
                    var $this = $(this);
                    $this.trigger('blur');
                    // trigger the click of the first (we don't want rests in searchresults to be added via enter) sc-button on enter
                    if (!inShoppingCart) {
                        var waitDelay = ($('html').hasClass('ie'))?100:0;
                        setTimeout(function(){
                            $($this.closest('.artDetails').find('.add-article-enter')[0]).trigger('click');
                            if ($focusObjectAfterAddingToCart) {
                                if (isIE11) {
                                    $focusObjectAfterAddingToCart.select();
                                } else {
                                    $focusObjectAfterAddingToCart.trigger('select');
                                }
                            } else {
                                if (isIE11) {
                                    $this.select();
                                } else {
                                    $this.trigger('select');
                                }
                            }
                        },waitDelay);
                    }
                }
            })
                .on('keydown', function (e) {
                    if (e.which == 9) {
                        e.preventDefault();
                        var $nextInput = $($(this).parents('div.detail-link').next().find('.js-inp').get(0));
                        if ($nextInput.length) {
                            if (isIE11) {
                                $nextInput.select();
                            } else {
                                $nextInput.trigger('select');
                            }
                        } else {
                            // OB-2293, OB-2294
                            if ($('.tab-nav #order-search-input').is(':visible')) {
                                if (isIE11) {
                                    $('.tab-nav #order-search-input').select();
                                } else {
                                    $('.tab-nav #order-search-input').trigger('select');
                                }
                            } else {
                                if (isIE11) {
                                    $('#inputsearch').select();
                                } else {
                                    $('#inputsearch').trigger('select');
                                }
                            }
                        }
                    }
                });

            $inputElement.on('blur', function (e) {
                e.preventDefault();
                var $this = $(this),
                    minAmount = parseInt($this.data('min-amount')),
                    amount = isNaN(parseInt($this.val())) ? minAmount : parseInt($this.val()),
                    ring = $this.attr('data-ring'),
                    checkedAmount = obeta.articleCalculation.checkAmount(amount, minAmount),
                //if art-id exists->caroussel, update caroussel-dropdown also!
                    $amountElementToUpdate = ($this.attr("art-id") != '') ? $("#" + $this.attr("art-id") + ">li>a").add($this.closest('.artDetails').find('.add-article-link')) : $this.closest('.artDetails').find('.add-article-link');
                $amountElementToUpdate.attr('data-amount', checkedAmount);
                $this.val(checkedAmount);
                (function (checkedAmount, $amountElementToUpdate) {
                    $amountElementToUpdate.data('amount', checkedAmount);
                })(checkedAmount, $amountElementToUpdate);

                var inOrderTemplate = !inShoppingCart && $this.parents().find('#sc-right').hasClass('order-template-right');

                (inShoppingCart || inOrderTemplate) &&
                (function ($input) {
                    var url = $input.data('action'),
                        fancyboxDiscontinuedArticleUrl = $('#display-fancybox-discontinued-and-replaced-article-url').val(),
                        data = {
                            'articleId': $input.attr('data-article-id'),
                            'amount': $input.val()
                        };

                        obeta.helpers.ajax({
                            type: "POST",
                            url: url,
                            data: data
                        }, function (data) {
                            if (data.html && data["html"]["dropdown"] && data["html"]["main"]){
                                obeta.helpers.hideSpinner();
                                /**====================================================================*/
                                if (data.data.showNotice == true) {
                                    getFancyboxHtmlDiscontinuedArticle = obeta.helpers.ajax({
                                            type: "POST",
                                            url: fancyboxDiscontinuedArticleUrl,
                                            data: {'replacementArticles' : data.data.replacementArticles }
                                        }, function(data) {
                                            $('#modal-message-containers').find('#js-discontinued-and-replaced-article').remove();
                                            $('#modal-message-containers').append(data.html.fancyboxHtml);
                                            /**=======begin show fancy box==============*/
                                            var discontinuedAndReplacedArticle = {
                                                containerId: 'js-discontinued-and-replaced-article',
                                                fancyboxOptions: { closeBtn: false },
                                                callbacks: [ function() { location.reload(); } ]
                                            }
                                            obeta.messages.showConfirmation(discontinuedAndReplacedArticle);
                                            /**=========end show fancy box=============*/
                                        }, function() {}
                                    );
                                }
                                /**====================================================================*/
                                if ($('#main').length) {
                                    $('#main').html(data.html["main"]);
                                } else {
                                    $('#main-inner').html(data.html["main"]);
                                    obeta.promotion.bindPromotionButtonsAjax();
                                }
                                $('#dropdown').html(data.html["dropdown"]);
                                obeta.init.ajaxInit();
                            } else if (!data.domPayload || data.domPayload.length === 0) {
                                location.reload();
                            } else {
                                obeta.helpers.hideSpinner();
                            }
                        }, function (data) {},
                        {
                            'hideSpinner': false,
                            'modalMessageAfterShow': obeta.articleList.modalMessageAfterShowAddArticleHandler
                        });
                })($this);
            });
        },

        bindSelectOnFocusInput = function () {
            if ($('.quicky-button').is(':visible')) {
                var sUsrAg = navigator.userAgent;
                if (sUsrAg.indexOf("iPhone") > -1 || sUsrAg.indexOf("iPad") > -1) {
                    $('#quicky-search-field').on("click", function(e) {
                        $(this).val("");
                    });
                }
                $('#quicky-search-field').select();
            } else {
                var $js_inp = $(".js-inp");
                var isIE11 = !!navigator.userAgent.match(/Trident\/7\./);
                $js_inp.focus(function() {
                    var sUsrAg = navigator.userAgent; //Fonly ie
                    if (sUsrAg.indexOf("MSIE") > -1) {
                        $(this).select();
                        return;
                    }
                    if (isIE11) {
                        $(this).select();
                        return;
                    }
                    if (sUsrAg.indexOf("Chrome") > -1) {
                        return;
                    }
                    if (document.getSelection) // Firefox
                    {
                        var text = $(this).val(),
                            selected_text = text.substring(document.activeElement.selectionStart, document.activeElement.selectionEnd);
                        if (selected_text != "") {
                            $(this).val(text);
                        } else {
                            $(this).select();
                        }
                    }
                });

                $js_inp.click(function() { //only chrome
                    var sUsrAg = navigator.userAgent;
                    if (sUsrAg.indexOf("Chrome") < 0) {
                        return;
                    }
                    var text = $(this).val(),
                        selected_text = text.substring(document.activeElement.selectionStart, document.activeElement.selectionEnd);
                    if (selected_text == "") {
                        $(this).select();
                    }
                });
            }
        },

        alignCheckboxVertical = function () { //align checkboxes vertically centered in order artbox
            $('.order-checkbox').each(function () {
                var h = $(this).height();
                var div_h = $(this).parents('div:eq(0)').height();

                this.style.top = Math.round((div_h - h) / 2) - 55 + 'px';
            });
        },
        singleArticleInputDelay = 500,
        stoppedTypingSingleArticleInput,
        /**
         * needed in article-details view
         */
        calculatePrice = function () {
            var $inputField = $('#price-addcart').find('.inp-amount>input');

            $inputField.on('blur', function(e) {
                e.preventDefault();
                obeta.articleCalculation.calcualteAndUpdatePrices($inputField);
            });

            $inputField.on('keyup', function (e) {
                e.preventDefault();
                var updateDisplay = $.trim($inputField.val()) != '';

                if(updateDisplay) {
                    if (stoppedTypingSingleArticleInput) {
                        clearTimeout(stoppedTypingSingleArticleInput);
                    }

                    stoppedTypingSingleArticleInput = setTimeout(function () {
                        obeta.articleCalculation.calcualteAndUpdatePrices($inputField);
                    }, singleArticleInputDelay);
                }
            });
        },
        _checkAmountByQuicky = function (amount, minAmount) {
            // no 0 and no negative
            amount = parseInt(amount);
            minAmount = parseInt(minAmount);
            if (amount % minAmount != 0) {

                var container = $('#js-quicky-template-popup-minimum-amount');
                $.fancybox({
                    autoResize: false,
                    helpers: {
                        overlay: {closeClick: false}
                    },
                    content: container,
                    afterShow: function () {
                        $('#quicky-search-field').keydown(function (e) {
                            if (e.keyCode == 13) {
                                $('.fancybox-close').trigger('click');
                            }
                        });
                        $('.fancybox-outer .confirmation-button').on('click', function () {
                            $.fancybox.close();
                        })
                    },
                    afterClose: function () {
                        $('#quicky-search-field').val('').show().focus();
                    }
                });
            }
        },
        bindQuickMessageBeforeLogin = function () {
            $('#quicky-link-footer').on('click', function (e) {
                e.preventDefault();
                var url = $(this).attr('href'),
                    loggedIn = $(this).data('loggedin');
                if (loggedIn != '' && loggedIn == 1) {
                    window.location.href = url;
                } else {
                    var quickyShowMessageBeforeLogin = {
                        containerId: 'js-show-quicky-message-before-login',
                        callbacks: [
                            function () {
                            }
                        ]
                    };
                    obeta.messages.showConfirmation(quickyShowMessageBeforeLogin);
                }
            });
        },
        bindQuickySearch = function () {

            $(document).keydown(function (e) {
                if (e.keyCode == 9 && $('.fancybox-outer .confirmation-button').is(':visible')) {
                    e.preventDefault();
                }
            });

            var $input = $('.input-field-article');
            $input.on('keypress', function (e) {
                if (e.which == 13) {
                    e.preventDefault();
                    var $this = $(this);
                    $this.trigger('blur');
                }
            });

            $input.on('blur', function (e) {
                e.preventDefault();
                var $this = $(this),
                    url = $this.data('url'),
                    searchString = $.trim($this.val());

                searchString && obeta.helpers.ajax({
                        type: "POST",
                        url: url,
                        data: {searchString: searchString}
                    }, function (data) {
                        var articleAmount = (data.html['size']),
                            $dropDownContainer = $('#quicky-dropdown-container');

                        $dropDownContainer.html(data.data).find('select')
                            .on('change', function () {
                                $('.input-field-number').focus();
                            })
                            .on('blur', function () {
                                _updateSelectedArticleInfos();
                                $('.input-field-number').focus();
                                if (!$(this).find('option:selected').val()) {
                                    $('#quicky-dropdown-container').empty().hide()
                                        .find('select').data('isReady', false);
                                    $('#quicky-search-field').val('').show().focus();
                                }
                            })
                            .on('keydown', function (e) {
                                if (e.which == 9) {
                                    e.preventDefault();
                                    $('.input-field-number').focus();
                                }
                            })
                            .on('keypress', function (e) {
                                if (e.which == 13) {
                                    e.preventDefault();
                                    $('.input-field-number').focus();
                                }
                            });

                        if (articleAmount == 1) {
                            $dropDownContainer.find('select').trigger('blur');
                            $('.input-field-number').focus();
                        } else {
                            $dropDownContainer.show().find('select').focus();
                            $('#quicky-search-field').hide();
                        }

                        $('#quicky-dropdown').data('isReady', true);
                    },
                    function () {
                        obeta.homepage.setDefaultText($this.val(''));
                        $('#quicky-search-field').val('').show().focus();
                    });
            });
        },
        _updateSelectedArticleInfos = function() {
            var $selectedArticle = $('#quicky-dropdown').find(':selected'),
                minimumAmount = $selectedArticle.data('minimum-amount'),
                unit = $selectedArticle.data('unit'),
                description = $selectedArticle.data('description');

            if (typeof(minimumAmount) != 'undefined' && typeof(unit) != 'undefined') {
                $('.quicky-minimum').html('Mindestabnahme: ' + minimumAmount + ' ' + unit);
                $('.quicky-unit').html(unit);
            }
            $('#quicky-search-field').val(description);
        },
        bindQuickySearchAmount = function () {

            $('.input-field-number').on('keypress', function (e) {
                if (e.which == 13) {
                    e.preventDefault();
                    var $this = $(this);
                    $this.trigger('blur');
                }
            }).on('blur', function (e) {
                e.preventDefault();
                    var $this = $(this),
                    $quicky = $('#quicky-dropdown'),
                    $selectedOption = $quicky.find(':selected'),
                    isReady = $quicky.data('isReady'),
                    minimumAmount = $selectedOption.data('minimum-amount'),
                    price = $selectedOption.data('price') * 100,
                    allPrices = $selectedOption.data('scale-data'),
                    unit = $selectedOption.data('unit'),
                    description = $selectedOption.data('description'),
                    articleId = $selectedOption.val(),
                    inputvalue = $.trim($this.val());

                if (isReady) {
                    if (inputvalue == '' || inputvalue == 'Anzahl') {
                        inputvalue = minimumAmount;
                    }

                    var amountSumBefore = _updateQuantityAndPrice(articleId);

                    inputvalue = parseInt(inputvalue) + amountSumBefore;

                    var $articleBoxClone = $('.quicky-article-box.js-template').clone(true).removeClass('js-template'),
                        amount = obeta.articleCalculation.checkAmount(inputvalue, minimumAmount),
                        userCanSeePrice = $('#quicky-search-field').data('show-price'),
                        newPrice = '';

                    _checkAmountByQuicky(inputvalue, minimumAmount);

                    allPrices[1] = price;
                    price = _getMinPriceFromAllPrices(allPrices, amount);

                    $articleBoxClone.data('articleId', articleId)
                        .data('amount', amount)
                        .data('minimumAmount', minimumAmount)
                        .data('price', price)
                        .data('all-prices', allPrices);
                    $articleBoxClone.find('.quicky-article-name').text(description);
                    $articleBoxClone.find('.quicky-article-name').attr('title', description);
                    if (userCanSeePrice == 1) {
                        newPrice = obeta.articleCalculation.formatNumber(price * amount / 100, 2, true) + ' €';
                    }
                    $articleBoxClone.find('.quicky-article-name').attr('data-article-id', articleId);
                    $articleBoxClone.find('.quicky-article-price').text(newPrice);
                    $articleBoxClone.find('.quicky-article-unit').text(unit);
                    $articleBoxClone.find('.input-amount input').val(amount);
                    $articleBoxClone.show().prependTo($('#quicky-list'));
                    $('.quicky-minimum').html('');
                    $('.quicky-unit').html('');
                    $('#quicky-dropdown-container').empty().hide().find('select').data('isReady', false);
                    $('#quicky-search-field').val('').show().focus();
                    $('.input-field-number').val('');
                    _updateArticleSum();
                }
            });
        },
        _getMinPriceFromAllPrices = function (allPrices, amount) {
            var allAllowedScalePrices = [];
            $.each(allPrices, function (scaleAmount, scalePrice) {
                if (scaleAmount <= amount) {
                    allAllowedScalePrices.push(scalePrice);
                }
            });

            return Math.min.apply(Math, allAllowedScalePrices);
        },
        _updateQuantityAndPrice = function(articleId) {
            var amountSumBefore = 0;
            $('#quicky-list').find('.quicky-article-box').each(function(e) {
                var $articleBox = $(this);
                if ($(this).find('.quicky-article-name').attr('data-article-id') == articleId) {
                    var $amountInput = $articleBox.find('input');
                    amountSumBefore = amountSumBefore + parseInt($amountInput.val());
                    $(this).remove();
                }
            });
            return parseInt(amountSumBefore);
        },
        _updateArticleSum = function () {
            var $sumField = $('#quicky-sum'),
                totalPrice = '',
                articlePriceData = $('#quicky-list').find('.quicky-article-box').map(function () {
                    var $this = $(this);
                    return  {
                        'price': $this.data('price'),
                        'amount': $this.find('.input-amount input').val()
                    }
                }).get(),
                priceSum = 0;

            $.each(articlePriceData, function (k, priceData) {
                priceSum += priceData.amount * priceData.price / 100;
            });

            if ($('#quicky-search-field').data('show-price') == 1) {
                totalPrice = obeta.articleCalculation.formatNumber(priceSum, 2, true) + ' €';
            } else {
                totalPrice = 'Nicht anzeigen';
            }
            $sumField.text(totalPrice);
        },
        bindQuickyArticle = function () {
            var $articleBoxTemplate = $('.quicky-article-box.js-template'),
                $closeButton = $articleBoxTemplate.find('.quicky-close'),
                $amountInput = $articleBoxTemplate.find('input');

            $closeButton.on('click', function (e) {
                e.preventDefault();
                $(this).parents('.quicky-article-box').remove();
                $('#quicky-search-field').focus();
                _updateArticleSum();
            });

            $amountInput.on('blur', function (e) {
                e.preventDefault();
                var $this = $(this),
                    $articleBox = $this.parents('.quicky-article-box'),
                    minimumAmount = $articleBox.data('minimumAmount'),
                    amount = obeta.articleCalculation.checkAmount($this.val(), minimumAmount),
                    allPrices = $articleBox.data('all-prices'),
                    price = _getMinPriceFromAllPrices(allPrices, amount),
                    userCanSeePrice = $('#quicky-search-field').data('show-price'),
                    newPrice = '';

                $this.val(amount);
                if (userCanSeePrice == 1) {
                    newPrice = obeta.articleCalculation.formatNumber(price * amount / 100, 2, true) + ' €';
                }
                $articleBox.find('.quicky-article-price').text(newPrice);
                $articleBox.data('price', price);
                _updateArticleSum();
            });

            $amountInput.on('keypress', function (e) {
                if (e.which == 13) {
                    e.preventDefault();
                    var $this = $(this);
                    $this.trigger('blur');
                }
            })
        },
        bindSubmitQuicky = function () {
            $('.add-quicky-link').on('click', function (e) {
                e.preventDefault();
                var $this = $(this),
                    url = $this.attr('href'),
                    //discontinuedArticleListUrl = $('#check-discontinued-and-replaced-article-list-url').val(),
                    articles = $('#quicky-list').find('.quicky-article-box').map(function () {
                        var $this = $(this);
                        return  {
                            'articleId': $this.data('articleId'),
                            'amount': $this.find('.input-amount input').val()
                        }
                    }).get();

                if (articles.length) {
                    obeta.helpers.ajax({
                            type: "POST",
                            url: url,
                            data: {articles: articles}
                        }, function (data) {
                            obeta.userInterface.displayShoppingCartDropdown(data);
                            $('#quicky-list').find('.quicky-article-box').remove();
                            _updateArticleSum();
                            location.reload();
                        }, function () {},
                        {'modalMessageAfterShow': obeta.articleList.modalMessageAfterShowAddArticleHandler}
                    );
                } else {
                    obeta.messages.showMessage('Bitte wählen Sie mindestens einen Artikel aus.', obeta.messages.type.ERROR);
                }
            })
        },

        bindCompareEtimArticle = function () {
            $('.compare-etim-article').on('click', function (e) {
                e.preventDefault();
                var $this = $(this), j = 0, url = $('.article-detail-etim').data('url');
                var etimClass = $('#etimclass').data('etimclass');
                if (!$this.hasClass('checkbox-disabled')) {
                    $this.toggleClass('user-check-selected');
                    $this.toggleClass('user-check-unselected');
                }
                var etimArray = Array();
                $('.user-check-selected').each(function () {
                    etimArray[j] = Array();
                    etimArray[j][0] = $(this).data('featureid');
                    etimArray[j][1] = $(this).data('etim');
                    j++;
                });
                obeta.helpers.ajax({
                    type: "POST",
                    url: url,
                    data: {
                        etimArray: etimArray,
                        etimClass: etimClass
                    }
                    }, function (data) {
                        $("#etim-found-count").html(data.data.count);
                        $("#btn-compare-article").attr("href", data.data.link);
                    }, function () {}
                );
            })
        },
        bindAlgoliaConversionOnClick = function() {
            $('#sc-button-single, .add-article-to-core-cart').on('click', function() {
                let productInfoNode = $('#product-view');
                let objectId = productInfoNode.data('object-id');
                let queryId = productInfoNode.data('query-id');
                let userToken = productInfoNode.data('user-token');
                if (objectId && queryId && userToken) {
                    obeta.algolia.sendClickedObjectIdAfterConversionEvent(objectId, queryId, userToken);
                }
            })
        },
        bindReturnLink = function() {
            $('#article-back-button').on('click', function(e) {
                e.preventDefault();
                if(document.referrer) {
                    window.location.href = document.referrer;
                } else {
                    location.reload();
                }
            })
        },
        bindArticleRedirectInEmbedMode = function() {
            if (window.jsEnvironment.embedMode) {
                $('.to-article-detail-link').on('click', null, function(e) {
                    e.preventDefault();

                    let sapId = $(this).data('object-id');
                    window.top.location.href = window.jsEnvironment.shopNextRedirectTarget + "/article-detail/" + sapId;
                })
            }
        };

    return {
        bindAmountInputEvents: bindAmountInputEvents,
        bindAlgoliaConversionOnClick: bindAlgoliaConversionOnClick,
        bindSelectOnFocusInput: bindSelectOnFocusInput,
        alignCheckboxVertical: alignCheckboxVertical,
        calculatePrice: calculatePrice,
        bindQuickMessageBeforeLogin: bindQuickMessageBeforeLogin,
        bindQuickySearch: bindQuickySearch,
        bindQuickySearchAmount: bindQuickySearchAmount,
        bindQuickyArticle: bindQuickyArticle,
        bindSubmitQuicky: bindSubmitQuicky,
        bindCompareEtimArticle: bindCompareEtimArticle,
        bindReturnLink: bindReturnLink,
        bindArticleRedirectInEmbedMode: bindArticleRedirectInEmbedMode
    };
})();

module.exports = obeta.articles;