

obeta.articleList = (function () {

    var bindArtBoxClicks = function () {
        },
        bindArtBoxMouseover = function () {
            $('body').on('mouseenter','.artLink', function (evt) {
                var $this = $(this).parent().find('.artBox.detail-link');
                if($this.length == 0){
                    $this = $(this).parent().find('.artBoxSmall.detail-link');
                }
                if($this.length == 0){
                    $this = $(this).parent().find('.box-grid.artDetails.detail-link');
                }
                if($this.length == 0){
                    $this = $(this).parent().find('.artBox.artbox-project');
                }
                var ring = $this.data('ring'),
                    inShoppingCart = $this.parents('#sc-right').hasClass('shoppingcart-right');

                if (ring != '' && ring != 'D') {
                    if (inShoppingCart) {
                        if (ring == 'S'){
                            $this.css({'cursor':'pointer'}).addClass('art-hover-special');
                        }
                        else {
                            $this.css({'cursor':'pointer'}).addClass('art-hover');
                        }
                    }
                    else {
                        $this.css({'cursor':'pointer'}).addClass('art-hover');
                    }
                }
                evt.stopPropagation();
            });
            $('body').on('mouseleave','.artLink', function (evt) {
                var $this = $(this).parent().find('.artBox.detail-link');
                if($this.length == 0){
                    $this = $(this).parent().find('.artBoxSmall.detail-link');
                }
                if($this.length == 0){
                    $this = $(this).parent().find('.box-grid.artDetails.detail-link');
                }
                if($this.length == 0){
                    $this = $(this).parent().find('.artBox.artbox-project');
                }
                var ring = $this.data('ring'),
                    inShoppingCart = $this.parents('#sc-right').hasClass('shoppingcart-right');

                if (inShoppingCart) {
                    if (ring == 'S'){
                        $this.css({'cursor':'default'}).removeClass('art-hover-special');
                    }
                    else {
                        $this.css({'cursor':'default'}).removeClass('art-hover');
                    }
                }
                else {
                    $this.css({'cursor':'default'}).removeClass('art-hover');
                }
                evt.stopPropagation();
            });

            $('.btn-rename-order-no').on('click', function(e){
                e.stopPropagation();
            });
            $('.order2-art-box').on('click', function(e){
                // Back and Forward buttons have to be excluded
                if (!_isSingleOrderURI(e)) {
                    e.preventDefault();
                    window.location.href = $(this).find('a').attr('href');
                }
            });
            $('.order2-art-box').on('mouseover', function() {
                $(this).addClass('order2-art-hover');
            });
            $('.order2-art-box').on('mouseout', function() {
                $(this).removeClass('order2-art-hover');
            });

        },

        bindShiftPositions = function () {

            $('.shift-position-link').on('click', function (e) {
                e.preventDefault();
                var $this = $(this), isOrderPage = false,
                    shiftFromShoppingCart = $this.hasClass('shift-from-sc'),
                    discontinuedArticleListUrl = $('#check-discontinued-and-replaced-article-list-url').val();

                //.check-selected does not exist when shiftFromShoppingCart. This was causing a bug that resulted in the function
                //return with null for the case shiftFromShoppingCart upon subsequent clicks
                if (!shiftFromShoppingCart) {
                    positions = $('.artBox ').map(function () {
                        var $artBox = $(this);
                        if ($(this).find('.check-selected').length) {
                            return {
                                'position': $(this).data('position'),
                                'amount': ($artBox.hasClass('is-order-page')) ? $(this).find('#article-amount-on-order-page').val() : $(this).find('.inp-amount input').val(),
                                'ring': $(this).find('.article-data-storage').data('ring'),
                                'articleId': $(this).data('article-id')
                            }
                        } else {
                            return null;
                        }
                    }).get();
                } else {
                    positions = $('.artBox ').map(function () {
                        var $artBox = $(this);

                        return {
                            'position': $(this).data('position'),
                            'amount': ($artBox.hasClass('is-order-page')) ? $(this).find('#article-amount-on-order-page').val() : $(this).find('.inp-amount input').val(),
                            'ring': $(this).find('.article-data-storage').data('ring'),
                            'articleId': $(this).data('article-id')
                        }
                    }).get();
                }

                if (positions.length === 0) {
                    return;
                }

                var confirmationParams = _getConfirmationParams(positions, $this);

                if ($this.hasClass('is-order-page')) {
                    isOrderPage = true;
                }

                if (isOrderPage == true) {
                    /**=================================================================================================*/
                    var orderStorageId = $this.attr('data-osi'),
                        checkAddArticleFromOrderUrl = $this.attr('data-check-add-from-order');

                    checkAddArticleFromOrderUrl && obeta.helpers.ajax({
                            type: "POST",
                            url: checkAddArticleFromOrderUrl,
                            data: {orderStorageId: orderStorageId, addFromOrder: isOrderPage}
                        },
                        function (data) {
                            if (data.data && data.data == true && data.html.message != '') {
                                $('#modal-message-containers').find('#js-check-add-article-from-order').remove();
                                $('#modal-message-containers').append(data.html.message);
                                var showNotificationByAddArticleFromOrder =
                                    {
                                        containerId: 'js-check-add-article-from-order',
                                        fancyboxOptions: {closeBtn: false},
                                        callbacks:
                                            [
                                                function () {   // click on No => do not overwrite
                                                    _shiftPositionAjax(positions, $this, false);
                                                },

                                                function () {   // click on Yes => overwrite
                                                    _shiftPositionAjax(positions, $this, true);
                                                }
                                            ]
                                    }
                                obeta.messages.showConfirmation(showNotificationByAddArticleFromOrder);
                            } else {
                                _shiftPositionAjax(positions, $this, false);
                            }
                        },
                        function () {
                        }
                    );
                } /**=================================================================================================*/
                else {
                    // in some cases (offersPage) shifting is not allowed => display message
                    if (confirmationParams.containerId !== '') {
                        obeta.messages.showConfirmation(confirmationParams);
                    } else {
                        if (!shiftFromShoppingCart) {
                            showDiscontinuedArticleListRequest = obeta.helpers.ajax({
                                    type: "POST",
                                    url: discontinuedArticleListUrl,
                                    data: {positions: positions}
                                },
                                function () {
                                },
                                function () {
                                }
                            );
                        }
                        //In case of shift in shopping cart, conduct the sanity check for offers
                        const checkOfferBeforeShiftUrl = $this.attr('data-check-offer-before-shift');
                        checkOfferBeforeShiftUrl && obeta.helpers.ajax({
                                type: "POST",
                                url: checkOfferBeforeShiftUrl,
                            },
                            function () {
                                    //offer check was Successful - allow the shift by calling the corresponding handler
                                    _shiftPositionAjax(positions, $this, false);

                            },
                            //offer check failed
                            function(data){
                                if (data.html.message != '') {
                                    $('#modal-message-containers').find('#js-popup-offer-sanity-check').remove();
                                    $('#modal-message-containers').append(data.html.message);
                                    var showNotificationOfferSanityCheck =
                                        {
                                            containerId: 'js-popup-offer-sanity-check',
                                            fancyboxOptions: {closeBtn: false},
                                            callbacks:
                                                [
                                                    function () {
                                                        location.reload();
                                                    }
                                                ]
                                        }
                                    obeta.messages.showConfirmation(showNotificationOfferSanityCheck);
                                }
                        }
                        );
                        if (!checkOfferBeforeShiftUrl){
                            _shiftPositionAjax(positions, $this, false);
                        }
                    }
                }
            });

        },
        _shiftPositionAjax = function(positions, $this, infoFromOrderIntoShoppingCartOverwrite) {
            var url = $this.attr('href'),
                shiftFromShoppingCart = $this.hasClass('shift-from-sc'),
                isOrderPage = $this.hasClass('is-order-page'),
                sendData = {
                    positions: positions
                };

            if (isOrderPage == true && infoFromOrderIntoShoppingCartOverwrite == true) {
                sendData = {
                    positions: positions,
                    orderStorageHeaderOverwrite: true
                };
            }

            positions.length && obeta.helpers.ajax({
                type: "POST",
                url: url,
                data: sendData
            }, function (data) {
                $('.article-checkbox').each(function () {
                    var $this = $(this);
                    $this.removeClass('check-selected');
                    if (!$this.hasClass('unorderable')) {
                        $this.addClass('check-unselected');
                    }
                });
                if (shiftFromShoppingCart) {
                    location.reload()
                } else {
                    shiftFromShoppingCart ? location.reload() : obeta.userInterface.displayShoppingCartDropdown(data);
                    obeta.userInterface.updateShoppingcartStats(data.data.shoppingcartStats);
                }
            }, function () {},
            {'modalMessageAfterShow': shiftFromShoppingCart ? _modalMessageAfterShowAddArticleHandlerWithReload : modalMessageAfterShowAddArticleHandler});
        },
        _getConfirmationParams = function (positions, $elem) {
            var okUrl = $elem.data('ok-href'),
                specialOkUrl = $elem.data('special-ok-href'),
                activeOffer = $elem.data('active-shoppingcart-offer'),
                activeSpecialOffer = $elem.data('active-special-shoppingcart-offer'),
                offerId = $elem.data('offer-id'),
                typeCount = _getPositionTypeCounts(positions),
                normalIsActive = activeOffer && activeOffer != offerId,
                specialIsActive = activeSpecialOffer && activeSpecialOffer != offerId,
                containerId = '',
                callback1 = function () {},
                callback2 = function () {},
                callback3 = function () {};

            if (typeCount.normal > 0 && typeCount.special == 0 && normalIsActive) {
                containerId = 'only-normal-positions-confirmation';
                callback2 = function () {
                    location.href = okUrl;
                };
            } else if (typeCount.special > 0 && typeCount.normal == 0 && specialIsActive) {
                containerId = 'only-special-positions-confirmation';
                callback2 = function () {
                    _shiftPositionAjax(positions, $elem, false);
                };
            } else if (typeCount.normal > 0 && typeCount.special > 0) {
                if (normalIsActive && !specialIsActive) {
                    containerId = 'only-normal-positions-confirmation';
                    callback2 = function () {
                        location.href = okUrl;
                    };
                } else if (!normalIsActive && specialIsActive) {
                    containerId = 'only-special-positions-confirmation';
                    callback2 = function () {
                        _shiftPositionAjax(positions, $elem, false);
                    };
                } else if (normalIsActive && specialIsActive) {
                    containerId = 'both-positions-confirmation';
                    callback2 = function () {
                        location.href = okUrl;
                    };
                    callback3 = function () {
                        location.href = specialOkUrl;
                    };
                }
            }

            return {
                containerId: containerId,
                callbacks: [callback1, callback2, callback3]
            };
        },
        _getPositionTypeCounts = function (positions) {
            var normal = 0,
                special = 0;
            $.each(positions, function (k, position) {
                if (position.ring == 'S' || position.ring == 'D') {
                    special++;
                } else {
                    normal++;
                }
            });

            return {'normal': normal, 'special': special};
        },
        bindShiftSinglePosition = function () {
            $('.shift-single-position-link').on('click', function (e) {
                e.preventDefault();
                var $this = $(this),
                    activeOffer = $this.data('active-shoppingcart-offer'),
                    offerId = $this.data('offer-id'),
                    okUrl = $this.data('ok-href'),
                    isSpecialArticle = $this.data('is-special-article'),
                    $positionRoot = $this.closest('.artBox'),
                    positionData = {
                        'position': $positionRoot.data('position'),
                        'amount': $positionRoot.find('.inp-amount input').val(),
                        'ring': $positionRoot.find('.shift-single-position-link').data('ring')
                    },
                    positions = [positionData],
                    url = $this.attr('href');

                    confirmationParams = {
                        containerId: isSpecialArticle ? 'only-special-positions-confirmation' : 'only-normal-positions-confirmation',
                        'callbacks': [
                            function () {
                            },
                            function () {
                                isSpecialArticle ? _doShiftPositionsRequest(positions, url) : location.href = okUrl;
                            }
                        ]
                    };
                if (activeOffer && activeOffer != offerId) {
                    obeta.messages.showConfirmation(confirmationParams);
                } else {
                    _doShiftPositionsRequest(positions, url);
                }
            });
        },
        _doShiftPositionsRequest = function (positions, url) {
            shiftPositionsRequest = positions.length && obeta.helpers.ajax({
                    type: "POST",
                    url: url,
                    data: {
                        positions: positions
                    }
                },
                function (data) {
                    obeta.userInterface.displayShoppingCartDropdown(data);
                    obeta.userInterface.updateShoppingcartStats(data.data.shoppingcartStats);
                }, function () {
                },
                {'modalMessageAfterShow': modalMessageAfterShowAddArticleHandler});
            return shiftPositionsRequest;
        },
        bindArticleCheckBox = function () {
            $('.article-checkbox').on('click', function (e) {
                e.preventDefault();
                var $this = $(this);
                if ($this.hasClass('check-disabled')) {
                    if ($this.hasClass('diverse-article')) {
                        var articleCheckboxCondition = {
                            containerId: 'js-article-checkbox-diverse-article-offer',
                            callbacks: [ function () {} ]
                        }
                        obeta.messages.showConfirmation(articleCheckboxCondition);
                    }
                    else {
                        var articleCheckboxCondition = {
                            containerId: 'js-add-to-cart-checkbox-condition',
                            callbacks: [function () {
                            }]
                        }
                        obeta.messages.showConfirmation(articleCheckboxCondition);
                    }
                } else {
                    $this.toggleClass('check-selected');
                    $this.toggleClass('check-unselected');
                }
            });
        },
        bindSelectArticlesButtons = function () {
            $('.select-all-articles').on('click', function (e) {
                e.preventDefault();
                $('.article-checkbox').each(function () {
                    var $this = $(this);
                    if (!$this.hasClass('check-disabled')) {
                        $this.removeClass('check-unselected');
                        $this.addClass('check-selected');
                    }
                });
            });
            $('.select-no-articles').on('click', function (e) {
                e.preventDefault();
                $('.article-checkbox').each(function () {
                    var $this = $(this);
                    if (!$this.hasClass('check-disabled')) {
                        $this.addClass('check-unselected');
                        $this.removeClass('check-selected');
                    }
                });
            });
        },
        bindAddArticle = function () {
            $('.add-article-link').on('click', function (e) {
                e.preventDefault();
                obeta.search.sendAlgoliaConversionEvent($(this));
                var $this = $(this),
                    ring = $this.attr('data-ring'),
                    url = $this.attr('href'),
                    addCartLink = $this.hasClass('add-article-to-cart-link'),
                    checkDiscontinuedArticleUrl = $('#check-discontinued-and-replaced-article-url').val(),
                    showDiscontinuedArticleUrl = $('#display-fancybox-discontinued-and-replaced-article-url').val(),
                    isOrderPage = false,
                    orderStorageId = $this.attr('data-osi');
                if ($this.hasClass('is-order-page')) {
                    isOrderPage = true;
                }

                var sendData = {
                    'ring': ring,
                    'amount': $this.attr('data-amount'),
                    'articleId': $this.attr('data-article-id'),
                    'addFromOrder': isOrderPage,
                    'orderStorageId': orderStorageId
                }

                //Add search rank if relevant
                if ($this.attr('data-search-rank')){
                    sendData.searchRank = $this.attr('data-search-rank');
                }

                if (isOrderPage == true)
                {
                    var checkAddArticleFromOrderUrl = $this.attr('data-check-add-from-order');
                    checkAddArticleFromOrderUrl && obeta.helpers.ajax({
                            type: "POST",
                            url: checkAddArticleFromOrderUrl,
                            data: { orderStorageId: orderStorageId, addFromOrder: isOrderPage }
                        },
                        function (data) {
                            if (data.data && data.data === true && data.html.message != '')
                            {
                                $('#modal-message-containers').find('#js-check-add-article-from-order').remove();
                                $('#modal-message-containers').append(data.html.message);
                                var showNotificationByAddArticleFromOrder =
                                {
                                    containerId: 'js-check-add-article-from-order',
                                    fancyboxOptions: { closeBtn: false },
                                    callbacks:
                                    [
                                        function()
                                        {   // click on No => do not overwrite
                                            if (addCartLink == true) {
                                                _checkDiscontinuedArticle(checkDiscontinuedArticleUrl, showDiscontinuedArticleUrl, sendData);
                                            }
                                            _addArticleToCartRequest($this, url, sendData, addCartLink);
                                        },

                                        function()
                                        {   // click on Yes => overwrite
                                            sendDataOverwrite = {
                                                'ring': ring,
                                                'amount': $this.attr('data-amount'),
                                                'articleId': $this.attr('data-article-id'),
                                                'addFromOrder': isOrderPage,
                                                'orderStorageId': orderStorageId,
                                                'orderStorageHeaderOverwrite': true
                                            }
                                            if (addCartLink == true) {
                                                _checkDiscontinuedArticle(checkDiscontinuedArticleUrl, showDiscontinuedArticleUrl, sendDataOverwrite);
                                            }
                                            _addArticleToCartRequest($this, url, sendDataOverwrite, addCartLink);
                                        }
                                    ]
                                }
                                obeta.messages.showConfirmation(showNotificationByAddArticleFromOrder);
                            } else {
                                if (addCartLink == true) {
                                    _checkDiscontinuedArticle(checkDiscontinuedArticleUrl, showDiscontinuedArticleUrl, sendData);
                                }
                                _addArticleToCartRequest($this, url, sendData, addCartLink);
                            }
                        },
                        function () {}
                    );
                } else {
                    // if add article to cart normally (not from order)
                    if (addCartLink == true) {
                        _checkDiscontinuedArticle(checkDiscontinuedArticleUrl, showDiscontinuedArticleUrl, sendData);
                    }
                    _addArticleToCartRequest($this, url, sendData, addCartLink);
                }

            });
        },
        _checkDiscontinuedArticle = function(checkDiscontinuedArticleUrl, showDiscontinuedArticleUrl, sendData) {
            showDiscontinuedArticle = obeta.helpers.ajax({
                    type: "POST",
                    url: checkDiscontinuedArticleUrl,
                    data: sendData
                }, function(data)
                {
                    var replacementArticles = data.data.replacementArticles, showNotice = data.data.showNotice;
                    if (showNotice == true)
                    {
                        getFancyboxHtmlDiscontinuedArticle = obeta.helpers.ajax({
                                type: "POST",
                                url: showDiscontinuedArticleUrl,
                                data: {'replacementArticles' : replacementArticles }
                            }, function(data) {
                                $('#modal-message-containers').find('#js-discontinued-and-replaced-article').remove();
                                $('#modal-message-containers').append(data.html.fancyboxHtml);
                                /**======= begin show fancy box ==============*/
                                var discontinuedAndReplacedArticle = {
                                    containerId: 'js-discontinued-and-replaced-article',
                                    fancyboxOptions: { closeBtn: false },
                                    callbacks: [ function() {} ]
                                }
                                obeta.messages.showConfirmation(discontinuedAndReplacedArticle);
                                /**========= end show fancy box =============*/
                            }, function() {}
                        );
                    }
                },
                function() {}
            );
        },
        _addArticleToCartRequest = function($this, url, sendData, addCartLink) {
            addArticleRequest = obeta.helpers.ajax({
                    type: "POST",
                    url: url,
                    data: sendData
                }, function (data) {
                    if ($this.hasClass('is-order-template-page') && $('.template-left').length>0) {
                        location.reload();
                    }
                    if ($this.data('ring')!="O" && $this.data('ring')!="S") {
                        _removeRestFromArtbox($this);
                        _removeRestFromArtDetailsBox($this);
                    }
                    if (addCartLink == true) {
                        obeta.userInterface.displayShoppingCartDropdown(data);
                        obeta.shoppingCart.bindShoppingCartButton();
                        obeta.shoppingCart.resizeShoppingCartPopupIcons();
                        obeta.userInterface.updateShoppingcartStats(data.data.shoppingcartStats);
                        if ($('#searchArticles').is(':visible')) {
                            var isIE11 = !!navigator.userAgent.match(/Trident\/7\./);
                            if (isIE11) {
                                $('#inputsearch').select();
                            } else {
                                $('#inputsearch').trigger('select');
                            }
                        }
                    }
                }, function () {},
                {'modalMessageAfterShow': modalMessageAfterShowAddArticleHandler}
            );
        },
        _removeRestFromArtbox = function ($this) {
            var $restbox = $this.parents('.rest-box'),
                $artbox = $restbox.parents('.artBox'),
                $restboxHeight = $restbox.height(),
                $artboxHeight= $artbox.height();
            $artbox.height($artboxHeight-$restboxHeight);
            $restbox.remove();
            $artbox.find('.clear-big').remove();
        },
        _removeRestFromArtDetailsBox = function ($this) {
            var $restbox = $this.parents('.addcart');
            $restbox.remove();
        },
        modalMessageAfterShowAddArticleHandler = function () {
            _modalMessageAfterShowAddArticleHelper(false);
        },
        _modalMessageAfterShowAddArticleHandlerWithReload = function () {
            _modalMessageAfterShowAddArticleHelper(true);
        },
        _modalMessageAfterShowAddArticleHelper = function (withReload) {
            $('#hide-credit-warning-select').on('click', function () {
                $(this).toggleClass('check-selected').toggleClass('check-unselected');
            });
        },
        _isSingleOrderURI = function (event) {
            return event.target.className.match('paging-element');
        };

    return {
        bindArtBoxClicks: bindArtBoxClicks,
        bindArtBoxMouseover: bindArtBoxMouseover,
        bindShiftPositions: bindShiftPositions,
        bindArticleCheckBox: bindArticleCheckBox,
        bindSelectArticlesButtons: bindSelectArticlesButtons,
        bindAddArticle: bindAddArticle,
        bindShiftSinglePosition: bindShiftSinglePosition,
        modalMessageAfterShowAddArticleHandler: modalMessageAfterShowAddArticleHandler
    }
})();

module.exports = obeta.articleList;