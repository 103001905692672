/*!
 * Piwik - Web Analytics
 *
 * @link http://piwik.org
 * @license http://www.gnu.org/licenses/gpl-3.0.html GPL v3 or later
 */


obeta.piwikcalendar = (function($) {

    let initPiwikCalendar = function() {

        var piwik = {};

        if ($('#periodString').is(':visible'))
        {

            piwik.minDateYear = 2012;
            piwik.minDateMonth = parseInt("09", 10);
            piwik.minDateDay = parseInt("01", 10);
            piwik.language = "de";
            piwik.hasSuperUserAccess = 0;
            piwik.config = {"action_url_category_delimiter": "\/", "autocomplete_min_sites": "5", "datatable_export_range_as_day": "rss", "datatable_row_limits": ["5", "10", "25", "50", "100", "250", "500"]};
            piwik.languageName = 'Deutsch';

            Date.prototype.getWeek = function() {
                var onejan = new Date(this.getFullYear(), 0, 1), // needed for getDay()

                        // use UTC times since getTime() can differ based on user's timezone
                        onejan_utc = Date.UTC(this.getFullYear(), 0, 1),
                        this_utc = Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()),
                        daysSinceYearStart = (this_utc - onejan_utc) / 86400000; // constant is millisecs in one day

                return Math.ceil((daysSinceYearStart + onejan.getDay()) / 7);
            };

            var currentYear, currentMonth, currentDay, currentDate, currentWeek;
            var selectedDate, selectedDay, selectedMonth, selectedYear, selectedWeek;

            var periodInp = $('#order-period').val(),
                    dateFromInput = $('#date').html(),
                    dateInputPath = dateFromInput.split('-');
            var date0 = dateInputPath[0];
            startDate = $.trim(date0);
            var date1 = dateInputPath[1];
            endDate = $.trim(date1);

            var startDateSplit = startDate.split('.');
            selectedDate = new Date(parseInt(startDateSplit[2]), parseInt(startDateSplit[1]) - 1, parseInt(startDateSplit[0]));
            selectedDay = selectedDate.getDate();
            selectedMonth = selectedDate.getMonth();
            selectedYear = selectedDate.getFullYear();
            selectedWeek = selectedDate.getWeek();

            var todayDate = new Date();
            currentDay = todayDate.getDate();
            currentMonth = todayDate.getMonth() + 1;
            currentYear = todayDate.getFullYear();

            function dateTextEN(dateTextDE) {
                var splitDate = dateTextDE.split(".");
                return splitDate[2] + '-' + splitDate['1'] + '-' + splitDate[0];
            }

            var currentDateStr = _dateToDMY(todayDate, 0);
            piwik.startDateString = startDate;
            piwik.endDateString = endDate;
            piwik.period = periodInp;

            function setSelectDate(dateStr) {
                var splitDate = dateStr.split(".");
                currentDay = parseInt(splitDate[0]);
                currentMonth = parseInt(splitDate[1]) - 1;
                currentYear = parseInt(splitDate[2]);
                currentDate = new Date(currentYear, currentMonth, currentDay);
                currentWeek = currentDate.getWeek();
            }

            setSelectDate(currentDateStr);

            // min/max date for picker
            var piwikMinDate = new Date(piwik.minDateYear, piwik.minDateMonth - 1, piwik.minDateDay),
                    piwikMaxDate = new Date(currentYear, currentMonth, currentDay);

            // we start w/ the current period
            var selectedPeriod = piwik.period;

            function isDateInCurrentPeriod(date) {
                // if the selected period isn't the current period, don't highlight any dates
                if (selectedPeriod != piwik.period) {
                    return [true, ''];
                }

                var valid = false,
                        dateMonth = date.getMonth(),
                        dateYear = date.getFullYear(),
                        dateDay = date.getDate();

                // we don't color dates in the future
                if (dateMonth == currentMonth && dateYear == currentYear && dateDay > currentDay) {
                    return [true, ''];
                }

                // we don't color dates before the minimum date
                if (dateYear < piwik.minDateYear || (dateYear == piwik.minDateYear && ((dateMonth == piwik.minDateMonth - 1 && dateDay < piwik.minDateDay) || (dateMonth < piwik.minDateMonth - 1)))) {
                    return [true, ''];
                }

                // we color all day of the month for the same year for the month period
                if (piwik.period == "month" && dateMonth == selectedMonth && dateYear == selectedYear) {
                    valid = true;
                }
                // we color all day of the year for the year period
                else if (piwik.period == "year" && dateYear == selectedYear) {
                    valid = true;
                }
                else if (piwik.period == "week" && date.getWeek() == selectedWeek && dateYear == selectedYear) {
                    valid = true;
                }
                else if (piwik.period == "day" && dateDay == selectedDay && dateMonth == selectedMonth && dateYear == selectedYear) {
                    valid = true;
                }

                if (valid) {
                    return [true, 'ui-datepicker-current-period'];
                }

                return [true, ''];
            }

            piwik.getBaseDatePickerOptions = function(defaultDate) {
                return {
                    showOtherMonths: false,
                    dateFormat: 'dd.mm.yy',
                    firstDay: 1,
                    minDate: piwikMinDate,
                    maxDate: piwikMaxDate,
                    prevText: "",
                    nextText: "",
                    currentText: "",
                    defaultDate: defaultDate,
                    changeMonth: true,
                    changeYear: true,
                    stepMonths: 1,
                    // jquery-ui-i18n 1.7.2 lacks some translations, so we use our own
                    dayNamesMin: [
                        'So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
                    dayNamesShort: [
                        'Son', // start with sunday
                        'Mon', 'Die', 'Mit', 'Don', 'Fri', 'Sam'],
                    dayNames: [
                        'Sonntag', // start with sunday
                        'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
                    monthNamesShort: [
                        'Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
                    monthNames: [
                        'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'July', 'August', 'September', 'Oktober', 'November', 'Dezember']
                };
            };

            var updateDate;

            function getDatePickerOptions() {
                var result = piwik.getBaseDatePickerOptions(endDate);
                result.beforeShowDay = isDateInCurrentPeriod;
                result.stepMonths = selectedPeriod == 'year' ? 12 : 1;
                result.onSelect = function() {
                    updateDate.apply(this, arguments);
                };
                return result;
            }

            var datepickerElem = $('#datepicker').datepicker(getDatePickerOptions()),
                    //datepickerElem = $('#datepicker').datepicker(),
                    periodLabels = $('#periodString').find('.period-type label'),
                    periodTooltip = $('#periodString').find('.period-click-tooltip').html();

            var toggleWhitespaceHighlighting = function(klass, toggleTop, toggleBottom) {
                var viewedYear = $('.ui-datepicker-year', datepickerElem).val(),
                        viewedMonth = +$('.ui-datepicker-month', datepickerElem).val(), // convert to int w/ '+'
                        firstOfViewedMonth = new Date(viewedYear, viewedMonth, 1),
                        lastOfViewedMonth = new Date(viewedYear, viewedMonth + 1, 0);

                // only highlight dates between piwik.minDate... & piwik.maxDate...
                // we select the cells to highlight by checking whether the first & last of the
                // currently viewed month are within the min/max dates.
                if (firstOfViewedMonth >= piwikMinDate) {
                    $('tbody>tr:first-child td.ui-datepicker-other-month', datepickerElem).toggleClass(klass, toggleTop);
                }
                if (lastOfViewedMonth < piwikMaxDate) {
                    $('tbody>tr:last-child td.ui-datepicker-other-month', datepickerElem).toggleClass(klass, toggleBottom);
                }
            };

            // 'this' is the table cell
            var highlightCurrentPeriod = function() {
                switch (selectedPeriod) {
                    case 'day':
                        // highlight this link
                        $('a', $(this)).addClass('ui-state-hover');
                        break;
                    case 'week':
                        var row = $(this).parent();

                        // highlight parent row (the week)
                        $('a', row).addClass('ui-state-hover');

                        // toggle whitespace if week goes into previous or next month. we check if week is on
                        // top or bottom row.
                        var toggleTop = row.is(':first-child'),
                                toggleBottom = row.is(':last-child');
                        toggleWhitespaceHighlighting('ui-state-hover', toggleTop, toggleBottom);
                        break;
                    case 'month':
                        // highlight all parent rows (the month)
                        $('a', $(this).parent().parent()).addClass('ui-state-hover');
                        break;
                    case 'year':
                        // highlight table (month + whitespace)
                        $('a', $(this).parent().parent()).addClass('ui-state-hover');
                        toggleWhitespaceHighlighting('ui-state-hover', true, true);
                        break;
                }
            };

            var unhighlightAllDates = function() {
                // make sure nothing is highlighted
                $('.ui-state-active,.ui-state-hover', datepickerElem).removeClass('ui-state-active ui-state-hover');

                // color whitespace
                if (piwik.period == 'year') {
                    var viewedYear = $('.ui-datepicker-year', datepickerElem).val(),
                            toggle = selectedPeriod == 'year' && currentYear == viewedYear;
                    toggleWhitespaceHighlighting('ui-datepicker-current-period', toggle, toggle);
                }
                else if (piwik.period == 'week') {
                    var toggleTop = $('tr:first-child a', datepickerElem).parent().hasClass('ui-datepicker-current-period'),
                            toggleBottom = $('tr:last-child a', datepickerElem).parent().hasClass('ui-datepicker-current-period');
                    toggleWhitespaceHighlighting('ui-datepicker-current-period', toggleTop, toggleBottom);
                }
            };

            updateDate = function(dateText) {
                //obeta.helpers.showSpinner();

                // select new dates in calendar
                setSelectDate(dateText);
                piwik.period = selectedPeriod;

                // make sure it's called after jquery-ui is done, otherwise everything we do will be undone.
                setTimeout(unhighlightAllDates, 1);

                datepickerElem.datepicker('refresh');

                var datePeriodUrl = $('#save-date-period-for-order-searching').val();
                datePeriodOrderRequest = datePeriodUrl && obeta.helpers.ajax({
                    type: "POST",
                    url: datePeriodUrl,
                    data: {startDate: dateTextEN(dateText), endDate: selectedPeriod}
                }, function() {
                    var url = $('#order-url').val() + "&startDate=" + dateTextEN(dateText) + "&endDate=" + selectedPeriod;
                    location.href = url;
                }
                );
            };

            var toggleMonthDropdown = function(disable) {
                if (typeof disable === 'undefined') {
                    disable = selectedPeriod == 'year';
                }

                // enable/disable month dropdown based on period == year
                $('.ui-datepicker-month', datepickerElem).attr('disabled', disable);
            };

            var togglePeriodPickers = function(showSingle, selectedPeriod) {
                $('#periodString').find('.period-date').toggle(showSingle);
                $('#periodString').find('.period-range').toggle(!showSingle);
                $('#calendarRangeApply').toggle(!showSingle);
                if (selectedPeriod == 'day') {
                    $('#calendarPeriodApply').val('Heute');
                } else if (selectedPeriod == 'week') {
                    $('#calendarPeriodApply').val('Diese Woche');
                } else if (selectedPeriod == 'month') {
                    $('#calendarPeriodApply').val('Diesen Monat');
                } else if (selectedPeriod == 'year') {
                    $('#calendarPeriodApply').val('Dieses Jahr');
                } else {
                    $('#calendarPeriodApply').val('Anwenden');
                }
                $('#calendarPeriodApply').toggle(showSingle);
            };

            // setup datepicker
            unhighlightAllDates();
            // hook up event slots

            // highlight current period when mouse enters date
            datepickerElem.on('mouseenter', 'tbody td', function() {
                if ($(this).hasClass('ui-state-hover')) // if already highlighted, do nothing
                {
                    return;
                }

                // unhighlight if cell is disabled/blank, unless the period is year
                if ($(this).hasClass('ui-state-disabled') && selectedPeriod != 'year') {
                    unhighlightAllDates();

                    // if period is week, then highlight the current week
                    if (selectedPeriod == 'week') {
                        highlightCurrentPeriod.call(this);
                    }
                }
                else {
                    highlightCurrentPeriod.call(this);
                }
            });

            // make sure cell stays highlighted when mouse leaves cell (overrides jquery-ui behavior)
            datepickerElem.on('mouseleave', 'tbody td', function() {
                $('a', this).addClass('ui-state-hover');
            });

            // unhighlight everything when mouse leaves table body (can't do event on tbody, for some reason
            // that fails, so we do two events, one on the table & one on thead)
            datepickerElem.on('mouseleave', 'table', unhighlightAllDates)
                    .on('mouseenter', 'thead', unhighlightAllDates);

            // make sure whitespace is clickable when the period makes it appropriate
            datepickerElem.on('click', 'tbody td.ui-datepicker-other-month', function() {
                if ($(this).hasClass('ui-state-hover')) {
                    var row = $(this).parent(), tbody = row.parent();

                    if (row.is(':first-child')) {
                        // click on first of the month
                        $('a', tbody).first().click();
                    }
                    else {
                        // click on last of month
                        $('a', tbody).last().click();
                    }
                }
            });

            // Hack to get around firefox bug. When double clicking a label in firefox, the 'click'
            // event of its associated input will not be fired twice. We want to change the period
            // if clicking the select period's label OR input, so we catch the click event on the
            // label & the input.
            var reloading = false;
            var changePeriodOnClick = function(periodInput) {
                if (reloading) // if a click event resulted in reloading, don't reload again
                {
                    return;
                }

                var period = periodInput.val();

                // if clicking on the selected period, change the period but not the date
                if (selectedPeriod == period && selectedPeriod != 'range') {
                    // only reload if current period is different from selected
                    if (piwik.period != selectedPeriod && !reloading) {
                        reloading = true;
                        selectedPeriod = period;
                        updateDate(currentDateStr);
                    }
                    return true;
                }

                return false;
            };

            $("#otherPeriods").find("label").on('click', function(e) {
                var id = $(e.target).attr('for');
                changePeriodOnClick($('#' + id));
            });

            // when non-range period is clicked, change the period & refresh the date picker
            $("#otherPeriods").find("input").on('click', function(e) {
                var $this = $(this),
                        lastPeriod = selectedPeriod,
                        inputId, period;

                inputId = $this.attr('id').split('_');
                period = inputId[2];

                $('#otherPeriods').find('.radio-fx.period > span').removeClass('radio-checked');
                $('#otherPeriods').find('.radio-fx.period > span').addClass('radio');

                $this.next('span').find('span').removeClass('radio');
                $this.next('span').find('span').addClass('radio-checked');

                if (changePeriodOnClick($(e.target))) {
                    return true;
                }
                // switch the selected period
                selectedPeriod = period;

                // remove tooltips from the period inputs
                periodLabels.each(function() {
                    $(this).attr('title', '').removeClass('selected-period-label');
                });

                // range periods are handled in an event handler below
                if (period == 'range') {
                    var dynamicWidth = $('.period-range').width() + $('.period-type').width();
                    $('#periodMore').css('width', dynamicWidth + 170); //515+170=685
                    return true;
                } else {
                    $('#periodMore').css('width', $('#periodMore').css('min-width'));
                }

                // set the tooltip of the current period
                if (period != piwik.period) // don't add tooltip for current period
                {
                    $(this).parent().find('label[for=period_id_' + period + ']')
                            .attr('title', periodTooltip).addClass('selected-period-label');
                }

                // toggle the right selector controls (show period selector datepicker & hide 'apply range' button)
                togglePeriodPickers(true, selectedPeriod);

                // set months step to 12 for year period (or set back to 1 if leaving year period)
                if (selectedPeriod == 'year' || lastPeriod == 'year') {
                    // setting stepMonths will change the month in view back to the selected date. to avoid
                    // we set the selected date to the month in view.
                    var currentMonth = $('.ui-datepicker-month', datepickerElem).val(),
                            currentYear = $('.ui-datepicker-year', datepickerElem).val();

                    datepickerElem
                            .datepicker('option', 'stepMonths', selectedPeriod == 'year' ? 12 : 1)
                            .datepicker('setDate', new Date(currentYear, currentMonth));
                }

                datepickerElem.datepicker('refresh'); // must be last datepicker call, otherwise cells get highlighted
                unhighlightAllDates();
                toggleMonthDropdown();
                return true;
            });

            // clicking left/right re-enables the month dropdown, so we disable it again
            $(datepickerElem).on('click', '.ui-datepicker-next,.ui-datepicker-prev', function() {
                unhighlightAllDates(); // make sure today's date isn't highlighted & toggle extra year highlighting
                toggleMonthDropdown(selectedPeriod == 'year');
            });

            // reset date/period when opening calendar
            $("#periodString").on('click', "#date,.calendar-icon", function() {
                var periodMore = $("#periodMore").toggle();
                if (periodMore.is(":visible")) {
                    if ($('.period-date').css('display') == 'none' && $('.period-range').css('display') != 'none' && $('.period-type').css('display') != 'none') {
                        //var dynWidth = $('.period-range').width()+$('.period-type').width();
                        $('#periodMore').css('width', '685');
                    } else {
                        $('#periodMore').css('width', $('#periodMore').css('min-width'));
                    }
                    $("#periodString").css('box-shadow', '6px 6px 4px #888');
                    $("#periodString").css('background', '#f6f6f6');
                    periodMore.find(".ui-state-highlight").removeClass('ui-state-highlight');

                    $('#otherPeriods').find('.radio-fx.period > span').each(function(e) {
                        if ($(this).hasClass('radio-checked')) {
                            var selectedPeriod = $(this).parent().parent('.form-radio').find('input').val();
                            if (selectedPeriod == 'day') {
                                $('#calendarPeriodApply').val('Heute');
                                $('#calendarPeriodApply').show();
                            } else if (selectedPeriod == 'week') {
                                $('#calendarPeriodApply').val('Diese Woche');
                                $('#calendarPeriodApply').show();
                            } else if (selectedPeriod == 'month') {
                                $('#calendarPeriodApply').val('Diesen Monat');
                                $('#calendarPeriodApply').show();
                            } else if (selectedPeriod == 'year') {
                                $('#calendarPeriodApply').val('Dieses Jahr');
                                $('#calendarPeriodApply').show();
                            } else if (selectedPeriod == 'range') {
                                $('#calendarPeriodApply').hide();
                            }
                        }
                    });
                } else {
                    $("#periodString").css('box-shadow', 'none');
                    $("#periodString").css('background', 'none');
                }
            });

            $('body').on('click', function(e) {
                var target = $(e.target);
                if (target.closest('html').length && !target.closest('#periodString').length && !target.is('option') && $("#periodMore").is(":visible")) {
                    $("#periodMore").hide();
                    $("#periodString").css('box-shadow', 'none');
                    $("#periodString").css('background', 'none');
                }
            });

            function onDateRangeSelect(dateText, inst) {
                var toOrFrom = inst.id == 'calendarFrom' ? 'From' : 'To';
                $('#inputCalendar' + toOrFrom).val(dateText);
            }

            // this will trigger to change only the period value on search query and hash string.
            $("#period_id_range").on('click', function(e) {

                togglePeriodPickers(false, 'range');

                var options = getDatePickerOptions();

                // Custom Date range callback
                options.onSelect = onDateRangeSelect;
                // Do not highlight the period
                options.beforeShowDay = '';
                // Create both calendars
                options.defaultDate = piwik.startDateString;
                $('#calendarFrom').datepicker(options).datepicker("setDate", $.datepicker.parseDate('dd.mm.yy', piwik.startDateString));

                // Technically we should trigger the onSelect event on the calendar, but I couldn't find how to do that
                // So calling the onSelect bind function manually...
                //$('#calendarFrom').trigger('dateSelected'); // or onSelect
                onDateRangeSelect(piwik.startDateString, {"id": "calendarFrom"});

                // Same code for the other calendar
                options.defaultDate = piwik.endDateString;
                $('#calendarTo').datepicker(options).datepicker("setDate", $.datepicker.parseDate('dd.mm.yy', piwik.endDateString));
                onDateRangeSelect(piwik.endDateString, {"id": "calendarTo"});


                // If not called, the first date appears light brown instead of dark brown
                $('.ui-state-hover').removeClass('ui-state-hover');

                // Apply date range button will reload the page with the selected range
                $('#calendarRangeApply')
                        .on('click', function() {
                            var datePeriodUrl = $('#save-date-period-for-order-searching').val(),
                                    dateFrom = $('#inputCalendarFrom').val(),
                                    dateTo = $('#inputCalendarTo').val(),
                                    oDateFrom = $.datepicker.parseDate('dd.mm.yy', dateFrom),
                                    oDateTo = $.datepicker.parseDate('dd.mm.yy', dateTo);

                            if (!isValidDate(oDateFrom) || !isValidDate(oDateTo) || oDateFrom > oDateTo) {
                                alert('Fehler: Anfangsdatum muss kleiner als Enddatum sein!');
                                return false;
                            }
                            $('#date').html(dateFrom + ' - ' + dateTo);

                            datePeriodOrderRequest = datePeriodUrl && obeta.helpers.ajax({
                                type: "POST",
                                url: datePeriodUrl,
                                data: {startDate: dateTextEN(dateFrom), endDate: dateTextEN(dateTo)}
                            }, function() {
                                location.href = _getOrderUrlWithReplacedSearchString(dateTextEN(dateFrom), dateTextEN(dateTo));
                            }
                            );
                        })
                        .show();

                // Bind the input fields to update the calendar's date when date is manually changed
                $('#inputCalendarFrom, #inputCalendarTo')
                        .keyup(function(e) {
                            var fromOrTo = this.id == 'inputCalendarFrom' ? 'From' : 'To';
                            var dateInput = $(this).val();
                            try {
                                var newDate = $.datepicker.parseDate('dd.mm.yy', dateInput);
                            } catch (e) {
                                return;
                            }
                            $("#calendar" + fromOrTo).datepicker("setDate", newDate);
                            if (e.keyCode == 13) {
                                $('#calendarRangeApply').click();
                            }
                        });
                return true;
            });
            function isValidDate(d) {
                if (Object.prototype.toString.call(d) !== "[object Date]")
                    return false;
                return !isNaN(d.getTime());
            }

            if (piwik.period == 'range') {
                $("#period_id_range").click();
            }
        }
    };

    let bindChangeCalenderPeriod = function() {
        $('#calendarPeriodApply').on('click', function() {
            let datePeriodUrl = $('#save-date-period-for-order-searching').val();
            let todayDate = new Date();
            let dateTextInEN = _dateToYmd(todayDate);
            //"fancy" DOM navigation:
            // 1. find the checked input,
            // 2. get the parent's sibling's
            // 3. get the id and split the period string
            let selectedPeriod =  $("#otherPeriods").find(".radio-checked").parent().prev().attr('id').split('_')[2]

            obeta.helpers.ajax({
                type: "POST",
                url: datePeriodUrl,
                data: {startDate: dateTextInEN, endDate: selectedPeriod}
            }, function() {
                location.href = _getOrderUrlWithReplacedSearchString(dateTextInEN, selectedPeriod);
            }
            );
        }).show();
    }

    let _getOrderUrlWithReplacedSearchString = function(startDate, endDate){
        var order_url = $('#order-url').val(),
            orderSearchString = $('#order-search-input').val();
        if(orderSearchString !=='' && orderSearchString !=='Suchbegriff') {
            var old_order_url = order_url,
                new_orderSearchString = 'orderSearchString=' + orderSearchString,
                order_url_first_pos = old_order_url.indexOf('orderSearchString'),
                order_url_last_pos = old_order_url.indexOf('&', order_url_first_pos),
                order_url_to_replace = old_order_url.substring(order_url_first_pos, order_url_last_pos),
                order_url = old_order_url.replace(order_url_to_replace, new_orderSearchString);
        }
        return order_url + "&startDate=" + startDate + "&endDate=" + endDate;
    }

    let _dateToDMY = function(date, monthInterval) {
        var d = date.getDate(),
            m = date.getMonth() + 1 + monthInterval,
            y = date.getFullYear();
        return (d <= 9 ? '0' + d : d) + '.' + (m <= 9 ? '0' + m : m) + '.' + y;
    }

    let _dateToYmd = function(date) {
        var dd = date.getDate(),
            mm = date.getMonth() + 1,
            yy = date.getFullYear();
        return yy + '-' + (mm <= 9 ? '0' + mm : mm) + '-' + (dd <= 9 ? '0' + dd : dd);
    }

    return {
        initPiwikCalendar: initPiwikCalendar,
        bindChangeCalenderPeriod: bindChangeCalenderPeriod
    };
})(jQuery);

module.exports = obeta.piwikcalendar;