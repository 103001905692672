import io from './socket.io.js';

$(function() {
    let env = window.jsEnvironment;
    // deactivate notifications in embed mode except for the order routes (baudoc is still not implemented in shop-next)
    if (env && env.embedMode && !window.location.pathname.includes('auftraege-v2')) {
        return
    }
    if (!env || !env.customerId) {
        obeta.asyncOrderTimeoutManager.removeTimeOut();
        return;
    }

    // disable socket.io if no notification path is set to prevent unnecessary requests.
    if (!env.notificationPath && env.notificationHost !== 'http://127.0.0.1:3000') {
        return;
    }

    let customerId = env.customerId;
    let subCustomerId = env.subCustomerId;
    let sapCompanyId = env.sapCompanyId;

    let userId = sapCompanyId + '-' + customerId;
    if (subCustomerId) {
        userId += '-' + subCustomerId;
    }

    let socket = io(env.notificationHost, {
        transports: ['websocket'],
        path: env.notificationPath,
        query: {
            userId: userId
        }
    });

    socket.on('order-channel', function (message) {
        obeta.socketMessageHandler.handleOrderChannelMessage(message);
    });
    socket.on('construction-documentation-channel', function (message) {
        obeta.socketMessageHandler.handleConstructionDocumentationChannelMessage(message);
        obeta.constructionDocumentation.bindCloseModal();
    });
});

$(function() {
    obeta.socketMessageHandler.startShowMessageCountdown();
});