

obeta.messages = (function () {
    var bindHideMessage = function () {
        $('#messages').on('click', '.msgclose', function (e) {
            e.preventDefault();
            var $this = $(this),
                    $container = $this.next('.message-container'),
                    isSticky = $container.data('issticky'),
                    url = $container.data('url');

            $container.remove();
            $(this).remove();
            isSticky && $.ajax({
                type: "POST",
                url: url,
                data: {}
            })
                    .done(function (data) {
                    })
                    .fail(function (data) {
                    });
        });
    },
            bindHagerzplanMessageOnLoad = function () {
                var showHagerzplanMessage = {
                    containerId: 'js-show-hagerzplan-messages-onload',
                    callbacks:
                            [
                                function () {
                                    if (document.referrer) {
                                        window.history.back();
                                    }
                                    window.location.href = '/';
                                },
                                function () {
                                    var pop = window.open($('#openzplan').attr('href'), "popup", "height=700,width=1000");

                                    if (!pop || pop.closed || typeof pop.closed == 'undefined')
                                    {
                                        alert("Sie scheinen einen Popup-Blocker aktiviert zu haben. Hager Z-Plan kann nur bei ausgeschaltetem Popup-Blocker gestartet werden. Bitte deaktivieren Sie den Popup-Blocker.")
                                    } else {
                                        pop.focus();

                                        var monitor = setInterval(function () {
                                            if (pop.closed) {
                                                $('#spinner').show();
                                                window.top.location.href = "/warenkorb/W/uebersicht";
                                                clearInterval(monitor);
                                            }
                                        }, 1000);
                                    }
                                }
                            ]
                };
                obeta.messages.showConfirmation(showHagerzplanMessage);
            },
            bindFileUploadMessageBeforeLogin = function () {
                $('#file-upload-footer-link').on('click', function (e) {
                    e.preventDefault();
                    var url = $(this).attr('href'),
                            loggedIn = $(this).data('loggedin');
                    if (loggedIn != '' && loggedIn == 1) {
                        window.location.href = url;
                    } else {
                        var quickyShowMessageBeforeLogin = {
                            containerId: 'js-show-file-upload-message-before-login',
                            callbacks: [
                                function () {
                                }
                            ]
                        };
                        obeta.messages.showConfirmation(quickyShowMessageBeforeLogin);
                    }
                });
            },
            /**
             * Show alert with fancy box
             * @param $msg: message text
             * @param $okBtn: text on button
             */
            fancyAlert = function (msg, okBtn) {
                $.fancybox({
                    modal: true,
                    content: '<div class="confirmation-box">' + msg +
                            ((okBtn != '') ?
                                    '<div class="confirmation-buttons">' +
                                    '<input type="button" class="confirmation-button" onclick="$.fancybox.close();" value="' + okBtn + '">' +
                                    '</div>' : '') +
                            '</div>'
                });
            },
            showConfirmation = function (params) {
                if (obeta.helpers.isMobilePageActive()) {
                    mobile.dialog.confirm(params);
                } else {
                    params = $.extend(
                            {
                                'containerId': '',
                                'callbacks': [],
                                'afterShow': function () {
                                },
                                'afterShowOnce': function () {
                                },
                                'fancyboxOptions': {}
                            },
                            params
                            );

                    var container = $('#' + params.containerId),
                            fancyboxOptions = $.extend({
                                autoResize: false,
                                helpers: {
                                    overlay: {closeClick: false}
                                },
                                content: container,
                                afterShow: function () {
                                    if (!container.data('initialized')) {
                                        container.data('initialized', true);
                                        _afterShowOnce(params);
                                    }
                                    if ($.isFunction(params.afterShow)) {
                                        params.afterShow();
                                    }
                                }
                            },
                                    params.fancyboxOptions);
                    $.fancybox(fancyboxOptions);
                }
            },
            _afterShowOnce = function (params) {

                $('.fancybox-outer .confirmation-button').each(function (i) {
                    $(this).on('click', function () {
                        var closeFancybox = params.callbacks[i].call() != 'prevent-fancybox-close';
                        closeFancybox && $.fancybox.close();
                    })
                });

                // now try given custom afterShow callback
                if ($.isFunction(params.afterShowOnce)) {
                    params.afterShowOnce();
                }
            },
            showMessage = function (text, type) {
                var message = '<a class="msgclose" href="#"><b class="obeta-icons close-black"></b></a><div class="message-container ' + type + '-msg" data-issticky="false" data-type="' + type + '" data-url="/messages/removeSticky">' + text + '</div>';
                $('#messages').append(message).css('display', 'block');

                var $messagesObj = $('#messages'),
                        topPos = $(window).scrollTop(),
                        messagesHeight = $messagesObj.height();

                if (topPos - 194 > messagesHeight) {
                    displayAnimationMessage($messagesObj, topPos);
                }
            },
            showNotificationMessage = function(message) {
                $('#messages').empty();
                $('#messages').append(message);
                $('.order-close-button').click(function () {
                    $('#messages').fadeOut('fast', function () {
                        $('#messages').empty();
                    });
                });
                var $messagesObj = $('#messages').css('display', 'block');
                let topPos = $(window).scrollTop();
                displayAnimationMessage($messagesObj, topPos);
            },
            showAnimationMessage = function () {
                $(document).ready(function () {
                    var $messagesObj = $('#messages'),
                            messagesHeight = $messagesObj.height();
                    if ($messagesObj.is(':visible')) {
                        var topPos = $(window).scrollTop();
                        if (topPos - 194 > messagesHeight) {
                            displayAnimationMessage($messagesObj, topPos);
                        }
                    }
                });
            },
            displayAnimationMessage = function ($messagesObj, topPos) {
                var $messCopy = $('.message-container').last().clone(true);
                $messCopy.removeClass('message-container');
                $messCopy.addClass('animation-message');
                $messCopy.css('display', 'none');
                $messCopy.css('position', 'absolute');
                $messCopy.css('z-index', '99');
                $messCopy.css('top', topPos);
                $messCopy.css('width', '1150px');
                $messagesObj.append($messCopy);
                $('.animation-message').fadeIn().delay(2500).fadeOut(function () {
                    $(this).remove();
                });
            },
            showModalMessage = function (text, type, modalMessageAfterShow) {
                // checking for fancybox to prevent from calling it in mobile context :-/
                $.fancybox && $.fancybox({
                    content: text,
                    afterShow: function () {
                        // default focus on close.
                        // can be overwritten in modalMessageAfterShow.
                        $('.fancybox-close').focus();
                        modalMessageAfterShow();
                    }
                });
            },
            type = {
                ERROR: 'error',
                NOTICE: 'notice'
            };

    return {
        bindHideMessage: bindHideMessage,
        showConfirmation: showConfirmation,
        showModalMessage: showModalMessage,
        showMessage: showMessage,
        showNotificationMessage: showNotificationMessage,
        fancyAlert: fancyAlert,
        showAnimationMessage: showAnimationMessage,
        bindFileUploadMessageBeforeLogin: bindFileUploadMessageBeforeLogin,
        bindHagerzplanMessageOnLoad: bindHagerzplanMessageOnLoad,
        type: type
    };
})();

module.exports = obeta.messages;