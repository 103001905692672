
obeta.orderViewHandler = (function () {

    let _isUserOnCheckoutPage = function () {
        return $('#sc-summary-box').length;
    };

    let _isUserOnNextShoppingCart = function () {
        return $('#shop-next-iframe').length;
    };

    let enterLoadingState = function () {
        if (_isUserOnCheckoutPage()) {
            _showOrderLoadingMessageInCheckoutBox();
        } else {
            _showOrderLoadingMessageInMessageBox();
        }
    };

    let enterSuccessState = function (data) {
        if (_isUserOnNextShoppingCart()) {
            var nextData = {
                responsePayload: {
                    cartId: data.cartId,
                    date: data.date,
                    legacyProjectId: data.legacyProjectId,
                    offerId: data.offerId,
                    orderId: data.orderId,
                    time: data.time
                },
                requestId: `order-created-${data.customerId}-${data.subCustomerId}-${data.orderId}`,
                command: 'order-create',
                success: true
            }
            obeta.shopNext.sendOrderDataToNextShop(nextData)
            return
        }
        if (_isUserOnCheckoutPage()) {
            _showOrderSuccessMessageInCheckoutBox(data);
        } else {
            _showOrderSuccessMessageInMessageBox(data);
        }
    };

    let enterFailureState = function (data) {
        if (_isUserOnNextShoppingCart) {
            var nextData = {
                responsePayload: {
                    cartId: data.cartId,
                    legacyProjectId: data.legacyProjectId,
                },
                requestId: `order-failed-${data.customerId}-${data.subCustomerId}-${data.orderId}`,
                command: 'order-failure',
                success: false
            }
            obeta.shopNext.sendOrderDataToNextShop(nextData)
            return;
        }
        if (_isUserOnCheckoutPage()) {
            _showOrderFailureMessageInCheckoutBox(data);
        } else {
            _showOrderFailureMessageInMessageBox(data);
        }
    };

    let _showOrderLoadingMessageInCheckoutBox = function () {
        let scSummaryLoading = obeta.shoppingCartTemplates.getShoppingCartSummaryLoading();
        obeta.shoppingCartHelperFunctions.renderShoppingCartHtml(scSummaryLoading);
    };

    let _showOrderLoadingMessageInMessageBox = function () {
        obeta.messages.showNotificationMessage(obeta.shoppingCartHelperFunctions.getOrderNotificationLoadingHtml());
    };

    let _showOrderSuccessMessageInCheckoutBox = function (orderData) {
        let scSummarySuccess = obeta.shoppingCartTemplates.getShoppingCartSummarySuccess(orderData);
        obeta.shoppingCartHelperFunctions.renderShoppingCartHtml(scSummarySuccess);
        $('#sc-summary-button-print-pdf-with-prices').attr("href", obeta.scTemplateData.printWithPrices);
        $('#sc-summary-button-print-pdf-without-prices').attr("href", obeta.scTemplateData.printWithOutPrices);
    };

    let _showOrderSuccessMessageInMessageBox = function (orderData) {
        obeta.messages.showNotificationMessage(obeta.shoppingCartHelperFunctions.getOrderNotificationSuccessHtml(orderData));
    };

    let _showOrderFailureMessageInCheckoutBox = function (message) {
        let data = (typeof message.data !== 'undefined')
            ? message.data
            : message;

        let scSummaryFailure;
        if (message.event === "OrderIdMissingInResponse") {
            scSummaryFailure = obeta.shoppingCartTemplates.getShoppingCartSummaryFailureForOrderIdMissingInResponse(data.reason);
        } else {
            scSummaryFailure = obeta.shoppingCartTemplates.getShoppingCartSummaryFailure();
        }
        obeta.shoppingCartHelperFunctions.renderShoppingCartHtml(scSummaryFailure);
    };

    let _showOrderFailureMessageInMessageBox = function (message) {
        let data = (typeof message.data !== 'undefined')
            ? message.data
            : message;

        let failureHtml;
        if (message.event === "OrderIdMissingInResponse") {
            failureHtml = obeta.shoppingCartHelperFunctions.getOrderIdMissingInResponseNotificationFailureHtml(data.reason);
        } else {
            failureHtml = obeta.shoppingCartHelperFunctions.getOrderNotificationFailureHtml();
        }
        obeta.messages.showNotificationMessage(failureHtml);
    };

    let showTimeoutError = function () {
        let data = {event: "Timeout"}
        if (_isUserOnCheckoutPage()) {
            _showOrderFailureMessageInCheckoutBox(data)
        } else {
            _showOrderFailureMessageInMessageBox(data)
        }
    };

    return {
        enterLoadingState : enterLoadingState,
        enterSuccessState : enterSuccessState,
        enterFailureState : enterFailureState,
        showTimeoutError : showTimeoutError
    };
})();

module.exports = obeta.orderViewHandler;