
obeta.asyncOrderTimeoutManager = (function () {

    let isTimeOutExpired = function () {
        if (!isTimeOutSet()) {
            return true;
        }
        let timeOutValue = _getStoredTimeoutValue();
        let timeout = timeOutValue - Date.now();
        return timeout < 0;
    };

    let isTimeOutSet = function () {
        return _getStoredTimeoutValue != null;
    };

    let startTimeout = function (seconds, type) {
        let time = Date.now() + seconds * 1000;
        sessionStorage.setItem('time', time.toString());
        sessionStorage.setItem('type', type);
    };

    let _getStoredTimeoutValue = function () {
        return sessionStorage.getItem('time');
    };

    let getTimeOutDifference = function () {
        return _getStoredTimeoutValue() - Date.now();
    };

    let getType = function() {
        return sessionStorage.getItem('type');
    }

    let removeTimeOut = function () {
        sessionStorage.removeItem('time');
        sessionStorage.removeItem('type');
    };

    return {
        isTimeOutExpired: isTimeOutExpired,
        isTimeOutSet: isTimeOutSet,
        startTimeout: startTimeout,
        getTimeOutDifference: getTimeOutDifference,
        getType: getType,
        removeTimeOut: removeTimeOut
    };
})();

module.exports = obeta.asyncOrderTimeoutManager;