
obeta.invoice = (function () {
    var bindInvoiceEnter = function () {
            $('.invoice-input').keydown(function (e) {
                if (e.keyCode === 13) {
                    e.preventDefault();
                    $('.invoice-button').trigger('click');
                }
            })
        },
        bindInvoiceCopyRequest = function () {
            $('.invoice-button').on('click', function (e) {
                if (parseFloat($('.invoice-input').val()) < 99999999) {
                    e.preventDefault();
                    var oldInvoiceSelectedMessage = {
                        containerId: 'js-old-invoiceNo',
                        callbacks: [
                            function () {
                                return;
                            }
                        ]
                    }
                    obeta.messages.showConfirmation(oldInvoiceSelectedMessage);
                    $('.invoice-input').val('');
                    return;
                }
            });

            $('.request-concrete-invoice-sap').on('click', function(e) {
                $(this).find('span')
                    .removeClass('acrobat invoice-request-pdf-button')
                    .addClass('acrobat-transparent invoice-request-pdf-button-deactivated');
                }
            );
        },
        bindDownloadInvoiceCopy = function() {
            var $invoiceDownload = $('.js-invoice-download-link'),
                $invoiceClose = $invoiceDownload.parent().prev();

            $invoiceDownload.on('click', function(e) {
                var url = $(this).data('reset-invoice-copy-url');
                obeta.helpers.ajax({
                    type: "POST",
                    url: url,
                    data: {}
                },
                obeta.helpers.reload);
            });

            $invoiceClose.on('click', function() {
                var url = $(this).next().find('.js-invoice-download-link').data('reset-invoice-copy-url');
                obeta.helpers.ajax({
                        type: "POST",
                        url: url,
                        data: {}
                    }
                );
            });
        },
        bindInvoiceCopyStatePolling = function() {
            var $invoiceMessage = $(".js-invoice-copy-requested"),
                invoiceStatePolling = null;

            if($invoiceMessage.length) {
                var url = $($invoiceMessage.get(0)).data('url'),
                    requestedInvoiceNos = _getRequestedInvoiceNos();

                if(requestedInvoiceNos.length > 0) {
                    invoiceStatePolling = window.setInterval(function() {
                        var $requestedInvoices = $(".js-invoice-copy-requested");
                        requestedInvoiceNos = _getRequestedInvoiceNos();

                        if(requestedInvoiceNos.length == 0) {
                            window.clearInterval(invoiceStatePolling);
                        } else {
                            obeta.helpers.ajax({
                                    type: "POST",
                                    url: url,
                                    data: {requestedInvoiceNos: requestedInvoiceNos}
                                },
                                function(data) {
                                    var readyInvoiceNos = data.data;
                                    $requestedInvoices.each(function(i,elem) {
                                        var $elem = $(elem),
                                            invoiceNo = $elem.data('invoice-no'),
                                            arrayPosition = $.inArray(invoiceNo.toString(), readyInvoiceNos);
                                        if(arrayPosition != -1) {
                                            // remove message with closeButton
                                            $elem.parent().prev().remove();
                                            $elem.parent().remove();
                                            requestedInvoiceNos.splice($.inArray(invoiceNo.toString(), requestedInvoiceNos), 1);
                                        }
                                    });
                                },
                                function(){},
                                {'showSpinner': false}
                            );
                        }
                    }, 30000);
                }
            }
        },
        _getRequestedInvoiceNos = function() {
            var requestedInvoiceNos = [],
                $requestedInvoices = $(".js-invoice-copy-requested");

            $requestedInvoices.each(function(i,elem) {
                requestedInvoiceNos.push($(elem).data('invoice-no'));
            });

            return $.unique(requestedInvoiceNos);
        };

    return {
        bindInvoiceCopyRequest: bindInvoiceCopyRequest,
        bindInvoiceEnter: bindInvoiceEnter,
        bindDownloadInvoiceCopy: bindDownloadInvoiceCopy,
        bindInvoiceCopyStatePolling: bindInvoiceCopyStatePolling
    }
})();

module.exports = obeta.invoice;