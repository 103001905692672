
obeta.constructionDocumentationHelperFunctions = (function () {

    let renderConstructionDocumentationHtml = function (html) {
        $('#construction-documentation-container-init').fadeOut('fast');
        $('#construction-documentation-container-summary').fadeOut('fast', function () {
            $('#construction-documentation-container-summary').html(html);
            $('#construction-documentation-container-summary').fadeIn('fast');
        });
    };
    let getConstructionDocumentationLoadingHtml = function () {
        return '<div class="order-message-box order-message-box-success">' +
            '<div class="loader-small">' +
            '<div class="order-loading-icon-small spin"></div>' +
            '</div>' +
            '<div class="notification-body">' +
            '<p class="notification-title-cart success" id="notification-summary-loading">' +
            'Baudokumentation wird vorbereitet...' +
            '</p>' +
            '</div>' +
            '<div class="notification-close pull-right-flex">' +
            '<button class="order-close-button"></button>' +
            '</div>' +
            '</div>';
    };
    let getConstructionDocumentationSuccessHtml = function (constructionDocumentationData) {
        let fileUrl = (typeof constructionDocumentationData.data !== 'undefined')
            ? constructionDocumentationData.data.zipFileUrl
            : constructionDocumentationData.zipFileUrl;

        return  '<div class="order-message-box order-message-box-success">' +
            '<div class="loader-small">' +
            '<div class="order-success-icon-small"></div>' +
            '</div>' +
            '<div class="notification-body">' +
            '<p class="notification-title-cart">' +
            '<span class="success">' +
            'Ihre Baudokumentation ist bereit.' +
            '</span>' +
            '<p class="notification-info-text">' +
            'Sie können den Download jetzt beginnen' +
            '</p>' +
            '</p>' +
            '</div>' +
            '<div class="notification-download pull-right-flex">' +
            '<a href="' + fileUrl + '" target="_blank" class="notification-button-print-pdf">' +
            '<p>Download starten</p>' +
            '</a>' +
            '</div>' +
            '<div class="notification-close pull-right-flex">' +
            '<button class="order-close-button"></button>' +
            '</div>' +
            '</div>'
    };
    let getConstructionDocumentationFailureHtml = function (constructionDocumentationData) {
        let reason = (typeof constructionDocumentationData.data !== 'undefined')
            ? constructionDocumentationData.data.failureReason
            : constructionDocumentationData.failureReason;

        let message = 'Die Baudokumentation konnte nicht erfolgreich generiert werden. Versuchen Sie es erneut, oder wenden Sie sich an den Support.';

        if (reason === 'no_products'){
            message = 'Es wurden keine Produkte zu Ihrer Baudokumentation gefunden. Bitte überprüfen Sie Ihre Auswahl.';
        }
        if (reason === 'too_many_products'){
            message = 'Ihre Auswahl enthält zu viele Produkte. Bitte reduzieren Sie Ihre Auswahl.';
        }

        return  '<div class="order-message-box order-message-box-failure">' +
            '<div class="loader-small">' +
            '<div class="order-failure-icon-small">' +
            '</div>' +
            '</div>' +
            '<div class="notification-body">' +
            '<p class="notification-title-cart">' +
            '<span class="failure">Leider ist ein Problem aufgetreten!   </span>' +
            '<p class="notification-details-item">' +
            message +
            '</p>' +
            '<p class="notification-details-item pull-right-flex">' +
            '<span>T: </span>' +
            '030 720 94 777' +
            '</p>' +
            '</p>' +
            '</div>' +
            '<div class="notification-close pull-right-flex">' +
            '<button class="order-close-button"></button>' +
            '</div>' +
            '</div>';
    };

    return {
        renderConstructionDocumentationHtml: renderConstructionDocumentationHtml,
        getConstructionDocumentationLoadingHtml: getConstructionDocumentationLoadingHtml,
        getConstructionDocumentationSuccessHtml: getConstructionDocumentationSuccessHtml,
        getConstructionDocumentationFailureHtml: getConstructionDocumentationFailureHtml
    };

})();

module.exports = obeta.constructionDocumentationHelperFunctions;