
obeta.shoppingCart = (function () {
    var isShoppingCartSubmitted = false,
            lastDeliverytype = 0,
            lastValInAdressField = '',
            bindShoppingCartSubmitButton = function () {
                $('#sc-summary-button.button-activated').click(function () {
                    let $this = $(this);
                    let creditlimitexceeded = $this.data('creditlimitexceeded');
                    let backButton = $('.sc-back-btn');
                    backButton.addClass('button-deactivated');
                    backButton.parent().attr('href', '#');
                    obeta.scTemplateData = {
                        canOrderShoppingCart: $this.data('canordershoppingcart'),
                        notify: $this.data('notify'),
                        isSapUser: $this.data('issapuser'),
                        positionCount: $this.data('positioncount'),
                        nettoPrice: $this.data('nettoprice'),
                        sum: $this.data('sum'),
                        deliveryData: $this.data('deliverydata'),
                        hasSpecialOrDiversePosition: $this.data('hasspecialordiverseposition'),
                        commission: $this.data('commission'),
                        printWithPrices: '',
                        printWithoutPrices: '',
                        orderNo: '',
                        isFeatureAsyncCartSubmitEnabled: $this.data('is-feature-async-cart-submit-enabled')
                    };
                    let orderViaAsync = obeta.scTemplateData.isFeatureAsyncCartSubmitEnabled
                        && !obeta.scTemplateData.notify
                        && obeta.scTemplateData.canOrderShoppingCart;

                    if (creditlimitexceeded == 1) {
                        let showCreditLimitBySendingCart = {
                            containerId: 'js-shopping-cart-summary-template',
                            callbacks: [
                                function () {

                                }
                            ]
                        };
                        obeta.messages.showConfirmation(showCreditLimitBySendingCart);
                    } else {
                        if (isShoppingCartSubmitted) {
                            return;
                        }
                        isShoppingCartSubmitted = true;

                        if (orderViaAsync) {
                            obeta.socketMessageHandler.initOrderView();
                        }

                        let logoutaftersubmit = $this.data('logoutaftersubmit'),
                            projectId = $this.data('projectid'),
                            url = $this.data('action'),
                            notify = $this.data('notify'),
                            dataToSend = {
                                'projectId': projectId,
                                'logoutaftersubmit': logoutaftersubmit
                            };
                        obeta.helpers.ajax({
                            type: "POST",
                            url: url,
                            data: dataToSend
                        },
                            function (data) {
                                    if ($this.hasClass("js-ids-summary-button")) {
                                        if ($('#idsWithSubmit').length) {
                                            $('#idsWithSubmit').submit();
                                        } else {
                                            $('#ids').submit();
                                        }
                                        obeta.helpers.ajax({
                                            type: "GET",
                                            url: "/logout",
                                            data: {}
                                        }, function (data) {

                                        }, function () {},
                                                {'modalMessageAfterShow': false}
                                        );
                                    } else {
                                        obeta.scTemplateData.printWithPrices = data.data.printWithPrices;
                                        obeta.scTemplateData.printWithOutPrices = data.data.printWithOutPrices;
                                        obeta.scTemplateData.orderNo = data.data.orderNo;

                                        if (orderViaAsync) {
                                            return;
                                        }
                                        if (notify && notify == 1) {
                                            let containerId = 'js-shopping-cart-notify-success';
                                            _showNotificationAndReturnUrl($this, containerId, '/willkommen');
                                        } else {
                                            let containerId = 'js-shopping-cart-sent-success';
                                            $('.order-id').html(obeta.scTemplateData.orderNo);
                                            $('#button-print-order-confirmation-with-prices').attr("href", obeta.scTemplateData.printWithPrices);
                                            $('#button-print-order-confirmation-without-prices').attr("href", obeta.scTemplateData.printWithOutPrices);
                                            if (typeof _paq != 'undefined') {
                                                _paq.push(['trackEvent', 'Desktop', 'ShoppingCartSubmit']);
                                            }
                                            _showNotificationAndReturnUrl($this, containerId, '/warenkorb/0/uebersicht');
                                        }
                                    }
                                },
                                null,
                                {
                                'showSpinner': !orderViaAsync
                                }
                        ); // end ajax
                    }
                });
            },
            _showNotificationAndReturnUrl = function ($clickedObject, containerId, returnUrl) {
                var showNotificationPopup = {
                    containerId: containerId,
                    callbacks: [
                        function () {
                        }
                    ],
                    fancyboxOptions: {
                        closeBtn: false,
                        keys: {
                            close: [27]
                        },
                        afterClose: function () {
                            location.href = returnUrl;
                        }
                    }
                };
                obeta.messages.showConfirmation(showNotificationPopup);
            },
            bindDeleteShoppingCartEvent = function () {
                $('.js-sc-delete').on('click', function (e) {
                    e.preventDefault();
                    var $this = $(this),
                            url = $this.attr('href');
                    var confirmationParams = {
                        containerId: "js-shoppingcart-delete",
                        callbacks: [
                            function () {
                            },
                            function () {
                                removeShoppingCartRequest = $.ajax({
                                    type: "POST",
                                    url: url
                                })
                                        .done(function (data) {
                                            location.reload();
                                        })
                                        .fail(function (data) {

                                        });
                            }
                        ]
                    };
                    obeta.messages.showConfirmation(confirmationParams);
                });

            },
            bindChangeOffer = function () {
                $('.change-offer-link').on('click', function (e) {
                    e.preventDefault();
                    var $this = $(this),
                            url = $this.attr('href'),
                            isActive = $this.hasClass('active'),
                            changeOfferRequest,
                            confirmationParams = {
                                msg: 'Wenn Sie fortfahren werden alle diversen Artikel aus dem Warenkorb entfernt.'
                            };
                    if (!isActive && obeta.messages.showConfirmation(confirmationParams)) {
                        changeOfferRequest = $.ajax({
                            type: "POST",
                            url: url,
                            data: {}
                        })
                                .done(function (data) {
                                    location.reload();
                                })
                                .fail(function (data) {
                                });
                    }

                });
            }, resizeShoppingCartPopupIcons = function () {
        $('.sc-popup-article').find('img').each(function () {
            var $this = $(this);
            var newImg = new Image();
            newImg.src = $this.attr('src');
            var h = newImg.height, w = newImg.width;

            if ((w > h) && (w > 60)) {
                $this.css('width', '60px');
            }
            if ((h > w) && (h > 60)) {
                $this.css('height', '60px');
            }
        });
    },
            bindRemoveArticle = function () {
                $('.remove-article-link').on('click', function (e) {
                    var url = $(this).attr('href'),
                            removeArticleRequest = $.ajax({
                                type: "POST",
                                url: url
                            })
                            .done(function (data) {
                                location.reload();

                            })
                            .fail(function (data) {

                            });
                    e.preventDefault();
                });
            },
            bindSubmitShoppingCartHeader = function () {
                $('.shoppingcart-header-submit').on('click', function (e) {
                    e.preventDefault();

                    $('#input-name1').val(filterChars($('#input-name1').val()));
                    $('#input-name2').val(filterChars($('#input-name2').val()));
                    $('#input-street').val(filterChars($('#input-street').val()));
                    $('#js-input-city').val(filterChars($('#js-input-city').val()));

                    var $this = $(this),
                            postcodeUrl = $this.data('postcode-check-url'),
                            checkShoppingCartDeliveryMessageUrl = $('#checkShoppingCartDeliveryMessageUrl').val(),
                            url = $this.attr('href'),
                            activeDelivery = $('#delivery-options input:checked').val(),
                            postcode = $("#input-postcode").val(),
                            projectId = $this.attr('data-project-id'),
                            selectedDeliveryDate = $('#delivery-dates-select').find('option:selected').val();

                    $('.default-text-active').each(function () {
                        $(this).val('');
                    });

                    $('#shoppingcart-header').parsley('validate');

                    //block going forward to step 3 if validation errors exist
                    if ($this.hasClass("sc-step2-next") && $('.parsley-error:visible').length) {
                        return;
                    }

                    if (activeDelivery == 'a' && $('#pickup-dropdown').hasClass('parsley-error')) {
                        $('#pickup-dropdown.parsley-error').removeClass('parsley-error');
                        $('#pickup-dropdown-error .parsley-error-list').remove();
                    }

                    if ($('#js-radio-delivery-type-b').next().children().hasClass('radio-checked')) {
                        if ($('#pickup-dropdown').hasClass('parsley-error') && $this.hasClass('sc-step2-next') ||
                                $('#pickup-dropdown-error>ul').length > 0 && $this.hasClass('sc-step2-next') || $('#pickup-dd option:selected').val() == 0) {

                            var noDeliveryStoreSelectedMessage = {
                                containerId: 'js-no-store-selected-message',
                                callbacks: [
                                    function () {

                                    }
                                ]
                            };
                            obeta.messages.showConfirmation(noDeliveryStoreSelectedMessage);
                            return;
                        }
                        // in case of renzbox we need the phonenumber
                        else if ($('#phoneNumber').val() === '' && $('#pickup-dd option:selected').val() === '69'){

                            var noPhonenumberMessage = {
                                containerId: 'js-no-phonenumber-message',
                                callbacks: [
                                    function () {

                                    }
                                ]
                            };
                            obeta.messages.showConfirmation(noPhonenumberMessage);
                            return;
                        }
                    } else if ($('#shoppingcart-header .parsley-error').length && $this.hasClass('sc-step2-next') && ($('.radio-checked').parent().prev().val() == 'b')) {
                        return;
                    }
                    if ($('.radio-checked').length < 1 && $this.hasClass('sc-step2-next')) {
                        var noDeliveryTypeSelectedMessage = {
                            containerId: 'js-no-deliverytype-selected-message',
                            callbacks: [
                                function () {

                                }
                            ]
                        };
                        obeta.messages.showConfirmation(noDeliveryTypeSelectedMessage);
                        return;
                    }

                    if ($this.hasClass('sc-step2-next')) {
                        $('#kommission').parsley('validate');
                        if ($('#kommission').next('.parsley-error-list').length > 0) {
                            return;
                        }
                    }
                    //FIXME: how to treat specialTours here?!
                    if (activeDelivery == 65) {
                        obeta.helpers.ajax(
                                {
                                    url: postcodeUrl,
                                    data: {
                                        postcode: postcode,
                                        projectId: projectId,
                                        selectedDeliveryType: activeDelivery,
                                        selectedDeliveryDate: selectedDeliveryDate
                                    },
                                    type: "POST"
                                },
                                function (data) {
                                    _submitShoppingCartStep2(url);
                                },
                                function (data) {
                                },
                                {
                                    modalMessageAfterShow: function () {
                                        $('.fancybox-outer #confirmation-0').on('click', function () {
                                            $.fancybox.close();
                                        });
                                        $('.fancybox-outer #confirmation-1').on('click', function () {
                                            _submitShoppingCartStep2(url);
                                        });
                                    }
                                }
                        );
                    } else  // other delivery type
                    {
                        /**==============================================================================*/
                        if ($this.hasClass('sc-step2-next')) {

                            if ($('#shoppingcart-header input:checked').val() == 'b')
                            {
                                activeDelivery = $('#pickup-dropdown').find('option:selected').val();
                            }

                            obeta.helpers.ajax(
                                    {
                                        url: checkShoppingCartDeliveryMessageUrl,
                                        data: {
                                            projectId: projectId,
                                            selectedDeliveryType: activeDelivery,
                                            selectedDeliveryDate: selectedDeliveryDate,
                                            postcode: postcode
                                        },
                                        type: "POST"
                                    },
                                    function (data) {
                                        _submitShoppingCartStep2(url);
                                    },
                                    function (data) {
                                    },
                                    {
                                        modalMessageAfterShow: function () {
                                            $('.fancybox-outer #confirmation-0').on('click', function () {
                                                $.fancybox.close();
                                            });
                                            $('.fancybox-outer #confirmation-1').on('click', function () {
                                                _submitShoppingCartStep2(url);
                                            });
                                        }
                                    }
                            );
                        } else {
                            _submitShoppingCartStep2(url);
                        }
                        /**==============================================================================*/


                    } // end other delivery type
                });
            },
            _submitShoppingCartStep2 = function (url) {
                var $form = $('#shoppingcart-header');
                submitShoppingCartHeader = $.ajax({
                    type: "POST",
                    url: $form.attr('action'),
                    data: $form.serialize()
                })
                        .done(function (data) {
                            location.href = url;
                        })
                        .fail(function (data) {
                        });
            },
            bindDeliveryTypeRadio = function () {
                // delivery
                if (parseInt($('.sc-delivery-left .delivery-type').data('treat-as-cashcustomer')) > 0) {
                    obeta.formHelpers.disableAddressFields();
                }

                $('#sc-right').on('click', function () {
                    if (parseInt($('.sc-delivery-left .delivery-type').data('treat-as-cashcustomer')) > 0) {
                        obeta.formHelpers.disableAddressFields();
                    }
                });


                if (!obeta.formHelpers.isPickUpDropdownVisible()) {

                    saveAddressFromForm();

                }

                // wunschadresse
                $('#js-radio-delivery-desired').on('click', triggerDeliveryDesired);

                // pickup
                $('#js-radio-self-pickup').on('click', triggerDeliveryPickup);

                // shipping type by delivery: package, package express, truck ..
                $('#delivery-options .delivery-type').on('click', triggerSubTypes);
            },
            bindGoDeliveryTimes = function () {
                $('#input-postcode').on('blur', function (e) {
                    e.preventDefault();
                    //var url = $('.sc-delivery-desired').data('validate-data-url');
                    var typeId = $('.sc-shipping-options-wrapper').find('.radio-checked').parent().prev().val();
                    var url = $('.delivery-type[value="' + typeId + '"]').data('validate-data-url');

                    if ($('#sc-zip').find('.parsley-error-list').length < 1) {
                        _updateDeliveryDates(url, true);
                    }
                });
            },
            bindChangeTargetDate = function () {
                $('#delivery-dates-select').on('change', function (e) {
                    e.preventDefault();
                    var url = $(this).find('option:selected').data('validate-data-url');

                    if (url) {
                        _updateDeliveryDates(url, false);
                    }
                });
            },
            bindStoredAddressDropdown = function () {
                $('#stored-addresses-dropdown').on('change', function (e) {
                    var $this = $(this),
                            $selectedOption = $this.find(':selected'),
                            address = $selectedOption.data('address');
                    obeta.formHelpers.fillAddressFields(address);

                   // var url = $('.sc-delivery-desired').data('validate-data-url');

                    var typeId = $('.sc-shipping-options-wrapper').find('.radio-checked').parent().prev().val();
                    var url = $('.delivery-type[value="' + typeId + '"]').data('validate-data-url');

                    if (($('#sc-zip').find('.parsley-error-list').length < 1) && ($('.sc-delivery-left').find('.delivery-dates-dd').length != 0)) {

                        _updateDeliveryDates(url, true);
                    }
                 })
            },
            bindShoppingCartButton = function () {
                $('#btn_wk').on('click', function () {
                    obeta.helpers.showSpinner();
                    if ($(this).attr('href') != "") {
                        location.href = $(this).attr('href');
                    } else {
                        obeta.helpers.hideSpinner();
                    }
                });
            },
            bindScNextButton = function () {
                $('.sc-step1-button').on('click', function (e) {
                    e.preventDefault();
                    if ($(".promotion-next-deactivated").length > 0) {
                        return;
                    }
                    var url = $(".sc-next-btn-inner").attr('href');
                    var orderNumber = $('#ufd-offer-dropdown').val();
                    var orderOk = true;
                    if (orderNumber == "Angebot wählen") {
                        location.href = url;
                        return;
                    }

                    if (orderNumber) {
                        orderOk = false;
                        $('#offer-dropdown option').each(function () {
                            var orderId = $(this).val().split('#');

                            if (orderNumber == orderId[1]) {
                                var offerChangeUrl = $(this).attr('href');
                                orderOk = true;

                                if (url != "") {
                                    if ($('#offer-dropdown').data('offerid') != orderNumber) {
                                        obeta.helpers.showSpinner();
                                        $.ajax({
                                            type: "POST",
                                            url: offerChangeUrl,
                                            data: {}
                                        })
                                                .done(function (data) {
                                                    location.href = url;
                                                })
                                                .fail(function (data) {
                                                });

                                    }
                                } else {

                                }
                            }
                        });
                        var confirmationParams = {
                            containerId: "js-wrong-offer-id",
                            callbacks:
                                    [
                                        function () {
                                        }
                                    ], fancyboxOptions: {
                                afterClose: function () {
                                    $('#ufd-offer-dropdown').val('').show().focus();
                                }
                            }
                        }
                    }
                    if (!orderOk) {
                        obeta.messages.showConfirmation(confirmationParams);

                    } else {
                        obeta.helpers.showSpinner();
                        location.href = url;
                    }
                });
            },
            bindShoppingCartDropdown = function () {
                bindShoppingCartButton();
                $("#btn_wk").mouseover(function (e) {
                    $(this).addClass("over");
                });
                $("#btn_wk").mouseout(function (e) {
                    $(this).removeClass("over");
                });
                $("#sc-menu-dropdown").mouseover(function (e) {
                    $(this).addClass("over");
                });
                $("#sc-menu-dropdown").mouseout(function (e) {
                    $(this).removeClass("over");
                });
                $('#btn_wk').bind({
                    mouseenter: function (e) {
                        setTimeout(function () {
                            var $scdropdown = $("#sc-menu-dropdown");
                            if ($("#btn_wk").hasClass("over") && !$scdropdown.hasClass('opendd') && !$scdropdown.hasClass('opensummary')) {
                                $scdropdown.css('zIndex', 9);
                                $("#sc-summary-dropdown").slideDown();
                                $scdropdown.addClass("opendd");
                            }
                        }, 300);
                    }});
                $('#btn_wk').bind({
                    mouseleave: function (e) {
                        setTimeout(function () {
                            if (!$("#sc-menu-dropdown").hasClass('over')) {
                                $('#sc-menu-dropdown').removeClass("opendd");
                                $("#sc-summary-dropdown").slideUp();
                            }
                        }, 500);
                    }
                });
                $('#sc-summary-dropdown').bind({
                    mouseleave: function (e) {
                        setTimeout(function () {
                            if (!$("#btn_wk").hasClass('over')) {
                                $('#sc-menu-dropdown').removeClass("opendd");
                                $("#sc-summary-dropdown").slideUp();
                            }
                        }, 500);
                    }
                });
            },
            bindPromotionBackButton = function () {
                $('#promotion-back-to-search').click(function () {
                    obeta.helpers.showSpinner();
                    location.href = $('#promotion-back-to-search').attr('href');
                })
            },
            filterChars = function (input_content) {
                var br_regex = /[^üöäßÜÖÄ+_&=()-/a-zA-Z0-9*, .]/g;
                if (input_content != "")
                    input_content = input_content.replace(br_regex, " ");
                return input_content;
            },
            bindAddressChanges = function () {
                $('.sc-delivery-desired').on('change', function () {
                    saveAddressFromForm();
                })
            },
            bindStoreAddress = function () {
                $('#sc-save-address').on('click', function (e) {
                    e.preventDefault();
                    $('#shoppingcart-header').parsley('validate');
                    $('#js-edit-project-form').parsley('validate');

                    if (($('.parsley-error').length > 0) && ($('#self-collector-options').is(':visible') == true)) {
                        return;
                    }

                    let addressCount = $('#stored-addresses-dropdown option').length;
                    if (addressCount > 149) {
                        alert("Es können nicht mehr als 150 Adressen gespeichert werden.");
                        location.reload();
                        return;
                    }

                    var $this = $(this),
                            name1 = filterChars($('#address-form #input-name1').val()),
                            name2 = filterChars($('#address-form #input-name2').val()),
                            street = filterChars($('#address-form #input-street').val()),
                            postcode = filterChars($('#address-form #input-postcode').val()),
                            city = filterChars($('#address-form #js-input-city').val()),
                            dataToSend = {'name': name1, 'name2': name2, 'street': street, 'zipCode': postcode, 'city': city},
                            dataToCompare = {'name1': name1, 'name2': name2, 'street': street, 'city': city, 'postcode': postcode};
                    var saveAddress = true;

                    $("#stored-addresses-dropdown option").each(function (e) {
                        var addressFromDom = ($(this).data('address'));
                        var addressAlreadyExists = (JSON.stringify(dataToCompare) === JSON.stringify(addressFromDom));
                        if (addressAlreadyExists) {
                            saveAddress = false;
                        }
                    });
                    if (!saveAddress) {
                        var addressExistsMessage = {
                            containerId: 'js-address-exists-message',
                            callbacks:
                                    [
                                        function () {},
                                        function () {}
                                    ]
                        };
                        obeta.messages.showConfirmation(addressExistsMessage);
                    } else {
                        var submitAddress = $.ajax({
                            type: "POST",
                            url: $this.attr('href'),
                            data: dataToSend,
                            dataType: 'json'
                        })
                                .done(function (data) {
                                    $('#address-form #input-name1').val(name1);
                                    $('#address-form #input-name2').val(name2);
                                    $('#address-form #input-street').val(street);
                                    $('#address-form #input-postcode').val(postcode);
                                    $('#address-form #js-input-city').val(city);
                                    if (data['status'] == 'error') {
                                        alert(data.message)
                                    } else {//address saved, now add value to dropdown, select it + save head
                                        $('#stored-addresses-dropdown').find(':selected').removeAttr('selected');
                                        $('#stored-addresses-dropdown').append($('<option>', {
                                            value: data.data,
                                            text: ""
                                        }));
                                        $("#stored-addresses-dropdown option:last-child").attr('selected', 'selected');
                                        var $form = $('#shoppingcart-header'),
                                                submitShoppingCartHeader = $.ajax({
                                                    type: "POST",
                                                    url: $form.attr('action'),
                                                    data: $form.serialize()
                                                })
                                                .done(function (data) {//success, reload page
                                                    window.location.reload();
                                                })
                                                .fail(function (data) {

                                                });
                                    }
                                })
                                .fail(function (data) {

                                });
                    }
                })
            },
            bindPrintSCButton = function () {
                if ($(".js-ids-summary-button").length > 0) {
                    $('.button-print-order-confirmation').on('click', function (e) {
                        e.preventDefault();
                        printurl = $(this).attr("href");
                        if ($('.oci-form').length) {
                            $('#ids').submit();
                        } else {
                            $('#idsWithSubmit').submit();
                        }
                        window.open(
                                printurl,
                                '_blank'
                                );
                    });
                }
            },
            bindCustomDeliveryDropdown = function () {
                if ($('.sagelogindd').length > 0) {
                    $('.sagelogindd').on("change", function () {
                        var url = $(this).find('option:selected').data('validate-data-url');
                        if ($(".shipping-cost-limit").length > 0) {
                            var shippingTypeId = $(this).find('option:selected').val();
                            $('#js-sc-shipping-costs').show();
                            _reloadAfterShippingCostChange(shippingTypeId);
                        } else {
                            _updateDeliveryDates(url, true);
                        }
                    });
                } else {
                    $('.custom-dd-pickup').selectBox().change(function () {
                        var url = $(this).find('option:selected').data('validate-data-url');
                        if ($(".shipping-cost-limit").length > 0) {
                            var shippingTypeId = $(this).find('option:selected').val();
                            $('#js-sc-shipping-costs').show();
                            _reloadAfterShippingCostChange(shippingTypeId);
                        } else {
                            _updateDeliveryDates(url, true);
                        }
                    });
                }
            },
            _reloadAfterShippingCostChange = function (shippingTypeId) {
                var url = $('#sc-button-top').data('shipping-change-url');
                if (url) {
                    obeta.helpers.ajax({
                        type: "POST",
                        url: url,
                        data: {shippingTypeId: shippingTypeId
                        }
                    }
                    , function (data) {

                        var url = $('#delivery-options').find('.radio-checked').parent().prev().attr('data-validate-data-url');

                        if (data.html && data["html"]["dropdown"] && data["html"]["main"]) {
                            obeta.helpers.hideSpinner();
                            if ($('#main').length) {
                                $('#main').html(data.html["main"]);
                            } else {
                                $('#main-inner').html(data.html["main"]);
                                obeta.promotion.bindPromotionButtonsAjax();
                            }
                            obeta.init.ajaxInit();
                        } else if (!data.domPayload || data.domPayload.length === 0) {
                            location.reload();
                        } else {

                            if (parseInt($('#sc-shipping-costs').text()) > 0) {
                                $('#shipping-cost-text').show();

                                if (url) {
                                    _updateDeliveryDates(url, true);
                                }
                            } else
                            {
                                $('#shipping-cost-text').hide();
                            }

                            obeta.helpers.hideSpinner();
                        }
                    },
                            {
                                'hideSpinner': false,
                                'modalMessageAfterShow': obeta.articleList.modalMessageAfterShowAddArticleHandler
                            });
                }
            },
            bindPickupDropdown = function () {

                var $pickupDropdown = $('#pickup-dropdown'),
                        $selected = $('#pickup-dropdown option:selected');

                if ($selected.val() != '0') {
                    var displayOpening = '', displayPhoneFax = '';

                    var opening = !!$selected.data("opening") ? $selected.data("opening") : '';
                    var telephone = !!$selected.data("telephone") ? $selected.data("telephone") : '';
                    var fax = !!$selected.data("fax") ? $selected.data("fax") : '';

                    if (opening.indexOf("§") !== -1) {
                        var openings = opening.split('§');
                        var displayOpening = '<table>';

                        for (var i = 1; i < openings.length; i++) {
                            displayOpening += '<tr><td style="width: 4em;">' + openings[i] + '</td><td>' + openings[i + 1] + '</td></tr>';
                            i++;
                        }

                        displayOpening += '</table>';
                        displayPhoneFax = '<br><table><tr><td style="width: 4em;">Telefon </td><td>' + telephone + '</td></tr><tr><td>Fax</td><td>' + fax + '</td></tr></table>';
                    }
                    var infoText = '<div style="width:35em;"><div style="font-weight: bold; line-height: 1.4em; height:4em; width: 49%; float: left; padding-left: 0em; margin-top: 0em;">' + $selected.data("name1") + '<br>' + $selected.data("street") + '<br>' + $selected.data("zip") + ' ' + $selected.data("city") + '</div><div style="line-height: 1.2em; width: 49%; float: left;"' + '>' + displayOpening + displayPhoneFax + '</div></div>';
                    $('#delivery-dropdown-selected-location').append(infoText);
                }

                $pickupDropdown.change(function () {
                    var $selected = $('#pickup-dropdown option:selected');
                    var $selectedDate = $('#delivery-dates-select option:selected').val();
                    $('#delivery-dropdown-selected-location').html("");
                    $('#input-street').val($selected.data("street")).removeClass("default-text-active");
                    $('#js-input-city').val($selected.data("city")).removeClass("default-text-active");
                    $('#input-postcode').val($selected.data("zip")).removeClass("default-text-active");
                    $('#input-name1').val($selected.data("name1")).removeClass("default-text-active");
                    $('#input-name2').val($selected.data("name2")).removeClass("default-text-active");
                    $('.stored-address-dd').addClass("selectBox-disabled");
                    var url = $selected.data('validate-data-url');
                    if (url) {
                        _updateDeliveryDates(url, true);
                        $('#js-radio-delivery-type-b').val($selected.val());
                    } else {
                        $('#shipping-id-b-helper').remove();
                        $('#shipping-id-b').html('');
                        $('#shipping-id-b').html('Bitte einen Ort auswählen.');
                    }

                    if (($selected.val()) != '0') {
                        var displayPhoneFax = '';
                        if ($selected.data("opening").length > 0 && $selected.data("opening").indexOf("§") !== -1) {
                            var openings = $selected.data("opening").split('§');
                            var displayOpening = '<table>';

                            for (var i = 1; i < openings.length; i++) {
                                displayOpening += '<tr><td style="width: 4em;">' + openings[i] + '</td><td>' + openings[i + 1] + '</td></tr>';
                                i++;
                            }
                            displayOpening += '</table>';
                            displayPhoneFax = '<br><table><tr><td style="width: 4em;">Telefon </td><td>' + $selected.data("telephone") + '</td></tr><tr><td>Fax</td><td>' + $selected.data("fax") + '</td></tr></table>';
                        }
                        var infoText = '<div style="width:35em;"><div style="font-weight: bold; line-height: 1.4em; height:4em; width: 49%; float: left; padding-left: 0em; margin-top: 0em;">' + $selected.data("name1") + '<br>' + $selected.data("street") + '<br>' + $selected.data("zip") + ' ' + $selected.data("city") + '</div><div style="line-height: 1.2em; width: 49%; float: left;"' + '>' + displayOpening + displayPhoneFax + '</div></div>';
                        $('#delivery-dropdown-selected-location').append(infoText);
                    }
                });

            },
            _updateDeliveryDates = function (url, updateDates) {
                if ($('#only-office-articles').length < 1) {
                    var step2Loaded = $('#sc-steps').attr('data-loaded');
                    firstOptionBefore = $("#delivery-dates-select option:first").val();
                    selectedOptionBefore = $("#delivery-dates-select option:selected").val();
                    obeta.helpers.ajax({
                        url: url,
                        data: {
                            postcode: $("#input-postcode").val(),
                            onlyOfficeArticles: $("#only-office-articles").val(),
                            selectedDeliveryDate: $('#delivery-dates-select option:selected').val(),
                            step2Loaded: step2Loaded,
                            updateDates: updateDates
                        },
                        type: "POST"
                    }, function (data) {
                        $('#sc-steps').attr('data-loaded', 1);
                        var $newDropDown = $('#js-delivery-dates-dropdown');
                        if (data.html.deliveryDates != '') {
                            $newDropDown.html(data.html.deliveryDates);
                            $newDropDown.find('a').remove();
                            bindChangeTargetDate();
                        }
                        var firstOptionAfter = $("#delivery-dates-select option:first").val();
                        if (firstOptionBefore == firstOptionAfter) {
                            $("#delivery-dates-select").val(selectedOptionBefore);
                        }

                        if ($('#delivery-dates-select').hasClass('custom-dd')) {
                            $newDropDown.find('select').selectBox();
                        }
                        if (data.data && $('.schritt2-red').is(':visible')) {
                            var shippings = data.data;
                            $.each(shippings, function (key, value) {
                                if(value.message == "") {
                                    // If the message is empty, the result is irrelevant
                                    return;
                                }
                                if (key == 27 || key == 28 || key == 29 || key == 60 || key == 65 || key == 209) {
                                    var $input = $('input.delivery-type[value=' + key + ']');
                                    if (value.state == 'inactive') {
                                        $input.next('span.radio-fx').addClass('disabled');
                                        $input.next('span.radio-fx').find('span').addClass('disabled');
                                        $('#box-shipping-id-' + key).addClass('text-grey');

                                        if (parseInt($('#delivery-options').find('.radio-checked').parent().prev().val()) == key) {
                                            $('#box-shipping-id-' + key).next().next().click();
                                        }

                                    } else if (value.state == 'hidden') {
                                        $input.next('span.radio-fx').hide();
                                        $('#box-shipping-id-' + key).hide();
                                    } else if (value.state == 'active') {
                                        $input.next('span.radio-fx').removeClass('disabled');
                                        $input.next('span.radio-fx').find('span').removeClass('disabled');
                                        $('#box-shipping-id-' + key).removeClass('text-grey');
                                    }
                                    $('#shipping-id-' + key).html(value.message);
                                } else {

                                    if ($('#self-collector-options').is(':visible') == true && $('#pickup-dd option:selected').val() == 0) {
                                        $('#shipping-id-b').html('');
                                        $('#shipping-id-b').html('Bitte einen Ort auswählen.');
                                    } else {
                                        $('#shipping-id-b-helper').remove();
                                        $('#shipping-id-b').html(value.message);
                                    }
                                }
                            });
                        }
                    });
                }
            },
            bindCustomCompleteDeliveryButton = function () {
                $('.complete-delivery-button').on('click', function (e) {
                    e.preventDefault();
                    var $this = $(this), url = $this.attr('data-href');
                    if (!$this.find('b').hasClass('radio-gray')) {
                        if ($this.find('b').hasClass('radio-normal')) {
                            $this.find('b').removeClass('radio-normal');
                            $this.find('b').addClass('radio-check-selected');
                            obeta.helpers.ajax({
                                url: url,
                                data: {isPartialDelivery: 0},
                                type: "POST"
                            }, function (data) {
                            })

                        } else {
                            $this.find('b').removeClass('radio-check-selected');
                            $this.find('b').addClass('radio-normal');
                            obeta.helpers.ajax({
                                url: url,
                                data: {isPartialDelivery: 1},
                                type: "POST"
                            }, function (data) {
                            })
                        }
                    }
                });
            },
            bindPickupLocationOnPopup = function () {

                $('.search-cross').on('click', function (e) {
                    $('#location').val('');
                });

                if ($('#sc-location-search-sap').length) {
                    $('#location').bind('keypress', function (e) {
                        if (e.keyCode == 13 && $(this).val() != '') {
                            $('#sc-location-search-sap').trigger('click');
                        }
                    });


                    $('#sc-location-search-sap').on('click', function (e) {
                        e.preventDefault();

                        var location = $('#location').val();

                        var locationSearchScStep2 = {
                            containerId: 'js-location-search-shopping-cart-step-2',
                            afterShow: function () {
                                obeta.geodatasap.initJqueryUiMaps();
                                obeta.geodatasap.initGeoData(true);
                                if (location != '') {
                                    $('#addressSearch').val(location);
                                }
                                $('#addressSearch').select();
                                obeta.geodatasap.bindSearchAddress(true);
                            }
                        };
                        obeta.messages.showConfirmation(locationSearchScStep2);

                    });
                }
                else {
                    $('#location').bind('keypress', function (e) {
                        if (e.keyCode == 13 && $(this).val() != '') {
                            $('#sc-location-search').trigger('click');
                        }
                    });


                    $('#sc-location-search').on('click', function (e) {
                        e.preventDefault();

                        var location = $('#location').val();

                        var locationSearchScStep2 = {
                            containerId: 'js-location-search-shopping-cart-step-2',
                            afterShow: function () {
                                obeta.geodata.initJqueryUiMaps();
                                obeta.geodata.initGeoData(true);
                                if (location != '') {
                                    $('#addressSearch').val(location);
                                }
                                $('#addressSearch').select();
                                obeta.geodata.bindSearchAddress(true);
                            }
                        };
                        obeta.messages.showConfirmation(locationSearchScStep2);

                    });
                }
            },
            triggerDeliveryDesired = function (e) {
                e.preventDefault();

                $('#deliveryDateText').html('Gewünschtes Lieferdatum:');

                if (!!$('.stored-address-dd').is(':visible')) {
                    // radiobutton already chosen => do nothing
                    return;
                }

                var $input;

                if ($('#delivery-options .radio-checked').length) {
                    $input = $('#delivery-options').find('.radio-checked').parent().prev();
                } else {
                    $input = $(this);
                }


                var $pickUpDropdown = $('#pickup-dropdown'),
                        isNightShift = $input.data('is-nightshift'),
                        $storedAddressDropDown = $('#stored-addresses-dropdown'),
                        $selectedOption = $storedAddressDropDown.find(':selected'),
                        storedAddressInUse = $selectedOption.val() > 0;

                var addressToShow;

                if (lastValInAdressField !== '') {
                    useSavedAddressInForm();
                } else {

                    if (isNightShift) {
                        addressToShow = $input.data('address');
                    } else if (storedAddressInUse) {
                        addressToShow = $selectedOption.data('address');
                    } else {
                        addressToShow = $($storedAddressDropDown.find('option')[0]).data('address');
                    }

                    obeta.formHelpers.fillAddressFields(addressToShow);
                }

                obeta.formHelpers.showAddressForm();

                $('#input-street').removeClass("default-text-active");
                $('#js-input-city').removeClass("default-text-active");
                $('#input-postcode').removeClass("default-text-active");
                $('#input-name1').removeClass("default-text-active");
                $('#input-name2').removeClass("default-text-active");

                // changing the deliverymode from pickup to desired
                if (obeta.formHelpers.isPickUpDropdownVisible()) {

                    $("#self-collector-options").animate({"height": "hide"}, {duration: 600});
                    $(".sc-pickup-wrapper").animate({"height": "hide"}, {duration: 600});
                    $("#pickup-dd").animate({"height": "hide"}, {duration: 600});

                    $(".sc-delivery-desired").animate({"height": "show"}, {duration: 600});
                    $(".delivery-date-options").animate({"height": "show"}, {duration: 600});
                    $("#sc-special-article-dropdown-message").animate({"height": "show"}, {duration: 600});

                    $(".sc-shipping-options-wrapper").animate({"height": "show"}, {duration: 600});
                    $("#delivery-options").animate({"height": "show"}, {duration: 600});

                    $('.project-delivery-data').find('hr.hr-thin').show();
                }

                if (obeta.formHelpers.shoppingCartHasOnlyOfficePositions()) {
                    $(".sc-shipping-options-wrapper").hide();
                }

                if ($('.sagelogindd').length > 0) {
                    $storedAddressDropDown.removeAttr('disabled');
                } else {
                    $pickUpDropdown.attr('disabled', 'disabled').selectBox('disable').removeClass('parsley-error');
                }
                $('#pickup-dropdown-error').empty();

                if (lastDeliverytype != 0) {
                    var selectorString = 'input[value="' + lastDeliverytype + '"]';
                    $(selectorString).next().click();
                }

                //click initial deliveryType when changing from pickup to desired
                else if ($('#delivery-options').find('.initialCheckedRadioType').length > 0) {
                    $('#delivery-options').find('.initialCheckedRadioType').next().click();
                }
                //click tour if is present
                else if ($('input[data-is-nightshift = "1"]').length > 0 && isNightShift) {
                    $('input[data-is-nightshift = "1"]').next().click();
                }
                //fallback when no default desired option is known
                else {
                    $('#delivery-options').find('.radio').first().click();
                }
            },
            triggerDeliveryPickup = function (e) {
                e.preventDefault();

                var $pickUpSelfCollect = $('#js-radio-delivery-type-b').parent().find('.radio-fx > span');
                var $selectedDate = $('#delivery-dates-select option:selected').val();

                saveSelectedDeliveryType();

                $('#pickup-dropdown').removeAttr('disabled');
                $pickUpSelfCollect.removeClass('radio');
                $pickUpSelfCollect.addClass('radio-checked');

                $('#deliveryDateText').html('Gewünschtes Abholdatum:');
                $('.sc-radio-delivery-mode').find('.radio').first().click();

                if (!$('#shoppingcart-header').length) {
                    $('.sc-radio-delivery-mode').hide();
                    $('.sc-shipping-options-wrapper').hide();
                }

                if (!obeta.formHelpers.isPickUpDropdownVisible()) {
                    $('.pickup-dd-wrapper').show();
                    $('#pickup-dd').show();

                    $("#delivery-options").hide();
                    $(".sc-delivery-desired").animate({"height": "hide"}, {duration: 600});

                    $(".sc-shipping-options-wrapper").animate({"height": "show"}, {duration: 600});
                    $("#self-collector-options").animate({"height": "show"}, {duration: 600});
                    $(".sc-pickup-wrapper").animate({"height": "show"}, {duration: 600});

                    $('.project-delivery-data').find('hr.hr-thin').show();

                }

                if (!obeta.formHelpers.scHasWarehousePositions()) {
                    $("#self-collector-options").hide();
                }

                if (obeta.formHelpers.shoppingCartHasOnlyOfficePositions()) {
                    $(".sc-shipping-options-wrapper").hide();
                }

                if (!$('#shoppingcart-header').length) {
                    obeta.formHelpers.hideAddressForm();
                } else {
                    obeta.formHelpers.showAddressPicker();
                    var $selected = $('#pickup-dropdown option:selected');
                    $('#input-street').val($selected.data("street")).removeClass("default-text-active");
                    $('#js-input-city').val($selected.data("city")).removeClass("default-text-active");
                    $('#input-postcode').val($selected.data("zip")).removeClass("default-text-active");
                    $('#input-name1').val($selected.data("name1")).removeClass("default-text-active");
                    $('#input-name2').val($selected.data("name2")).removeClass("default-text-active");
                    $('.stored-address-dd').addClass("selectBox-disabled");

                    var typeId = $('#pickup-dropdown option:selected').val();
                    $('#js-sc-shipping-costs').show();
                    _reloadAfterShippingCostChange(typeId);

                    var url = $selected.data('validate-data-url');
                    if (url) {
                        _updateDeliveryDates(url, true);
                    } else {
                        $('#delivery-dates-select').val($selectedDate);
                        $('#shipping-id-b-helper').remove();
                        $('#shipping-id-b').html('');
                        $('#shipping-id-b').html('Bitte einen Ort auswählen.');
                    }

                    if (obeta.formHelpers.isCashCustomer()) {
                        $('.stored-address-dd').addClass("selectBox-disabled");
                    }

                    if ($('.sc-delivery-left').find('.delivery-dates-dd').length == 0) {
                        $('.radio-div').find('.lieferart').next().children().remove();
                    }
                }
                $('#pickup-dropdown').selectBox('enable');
            },
            triggerSubTypes = function (e) {
                e.preventDefault();
                saveSelectedDeliveryType();

                var $this = $(this);
                var isCustomTour = $this.attr('data-address').length > 0;

                if (isCustomTour) {
                    var addressToShow = $this.attr('data-address');
                    obeta.formHelpers.fillAddressFields(addressToShow);
                    obeta.formHelpers.disableAddressFields();
                    $('.stored-address-dd').addClass("selectBox-disabled");
                } else {
                    obeta.formHelpers.enableAddressFields();

                    if (obeta.formHelpers.lastSelectedOptionWasTour()) {

                        if (lastValInAdressField !== '') {
                            useSavedAddressInForm();
                        } else {
                            addressToShow = $('#stored-addresses-dropdown').find('option:selected').data('address');
                            obeta.formHelpers.fillAddressFields(addressToShow);
                        }
                    }

                    $('.stored-address-dd').removeClass("selectBox-disabled");
                }

                var typeId = $('.sc-shipping-options-wrapper').find('.radio-checked').parent().prev().val();

                if ($('#delivery-options > .radio-div').hasClass('parsley-error')) {
                    $('#delivery-options > .radio-div').removeClass('parsley-error');
                    $('#parsley-lieferart.parsley-error-list').remove();
                }

                if (obeta.formHelpers.isCashCustomer()) {
                    $('.stored-address-dd').addClass("selectBox-disabled");
                }

                $('#js-sc-shipping-costs').show();
                var url = $('.delivery-type[value="' + typeId + '"]').data('validate-data-url');
                if (url) {
                    _updateDeliveryDates(url, true);
                }

                _reloadAfterShippingCostChange(typeId);


            },
            useSavedAddressInForm = function () {
                var explodeAddress = lastValInAdressField.split('§');
                var newAddress = {};
                newAddress.name1 = explodeAddress[0];
                newAddress.name2 = explodeAddress[1];
                newAddress.street = explodeAddress[2];
                newAddress.postcode = explodeAddress[3];
                newAddress.city = explodeAddress[4];
                obeta.formHelpers.fillAddressFields(newAddress);
            },
            saveAddressFromForm = function () {
                var name1 = $('#input-name1').val();
                var name2 = $('#input-name2').val();
                var street = $('#input-street').val();
                var postcode = $('#input-postcode').val();
                var city = $('#js-input-city').val();
                lastValInAdressField = name1 + '§' + name2 + '§' + street + '§' + postcode + '§' + city;
                saveSelectedDeliveryType();
            },
            saveSelectedDeliveryType = function () {
                lastDeliverytype = $('#delivery-options').find('.radio-checked').parent().prev().val();
            }
    ;

    return {
        bindDeleteShoppingCartEvent: bindDeleteShoppingCartEvent,
        bindChangeOffer: bindChangeOffer,
        bindRemoveArticle: bindRemoveArticle,
        bindSubmitShoppingCartHeader: bindSubmitShoppingCartHeader,
        bindDeliveryTypeRadio: bindDeliveryTypeRadio,
        bindGoDeliveryTimes: bindGoDeliveryTimes,
        bindStoredAddressDropdown: bindStoredAddressDropdown,
        bindStoreAddress: bindStoreAddress,
        bindShoppingCartSubmitButton: bindShoppingCartSubmitButton,
        resizeShoppingCartPopupIcons: resizeShoppingCartPopupIcons,
        bindCustomDeliveryDropdown: bindCustomDeliveryDropdown,
        bindShoppingCartDropdown: bindShoppingCartDropdown,
        bindShoppingCartButton: bindShoppingCartButton,
        bindPromotionBackButton: bindPromotionBackButton,
        bindScNextButton: bindScNextButton,
        bindCustomCompleteDeliveryButton: bindCustomCompleteDeliveryButton,
        bindPickupDropdown: bindPickupDropdown,
        bindPrintSCButton: bindPrintSCButton,
        bindChangeTargetDate: bindChangeTargetDate,
        bindPickupLocationOnPopup: bindPickupLocationOnPopup,
        bindAddressChanges: bindAddressChanges
    };
})();

module.exports = obeta.shoppingCart;