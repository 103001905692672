
obeta.shoppingCartHelperFunctions = (function () {

    let renderShoppingCartHtml = function (summaryBoxHtml) {
        $('#sc-summary-box').fadeOut('fast', function () {
            $('#sc-summary-box').html(summaryBoxHtml);
            $('#sc-summary-button').html('<p>Weiter einkaufen</p>');
            $('#sc-summary-button').click(function () {
                location.href = '/willkommen';
            });
            $('#sc-summary-box').fadeIn('fast');
        });
    };
    let getOrderNotificationLoadingHtml = function () {
        return '<div class="order-message-box order-message-box-success">' +
                '<div class="loader-small">' +
                    '<div class="order-loading-icon-small spin"></div>' +
                '</div>' +
                '<div class="notification-body">' +
                    '<p class="notification-title-cart success" id="notification-summary-loading">' +
                        'Ihr Auftrag wird angelegt...' +
                    '</p>' +
                '</div>' +
                '<div class="notification-close pull-right-flex">' +
                    '<button class="order-close-button"></button>' +
                '</div>' +
            '</div>';
    };
    let getOrderNotificationSuccessHtml = function (orderData) {
        let data = (typeof orderData.data !== 'undefined')
            ? orderData.data
            : orderData;

        let orderId = data.orderId;
        let printWithPrices = "/warenkorb/" + orderId + "/auftragsbestaetigung/1";
        let printWithOutPrices = "/warenkorb/" + orderId + "/auftragsbestaetigung/0";

        return  '<div class="order-message-box order-message-box-success">' +
                    '<div class="loader-small">' +
                        '<div class="order-success-icon-small"></div>' +
                    '</div>' +
                    '<div class="notification-body margin-success">' +
                        '<p class="notification-title-cart">' +
                            '<span class="success">' +
                                'Danke für Ihre Bestellung.' +
                            '</span>' +
                            '<span class="notification-info-text">' +
                                'Ihr Auftrag wird umgehend bearbeitet.' +
                            '</span>' +
                        '</p>' +
                        '<ul class="notification-details-list">' +
                            '<li class="notification-details-item">' +
                                'Auftragsnr.:' +
                                '<span>' + data.orderId + ' </span>' +
                            '</li>' +
                            '<li class="notification-details-item">' +
                                'Datum:' +
                                '<span>' + data.date + '</span>' +
                            '</li>' +
                            '<li class="notification-details-item">' +
                                'Uhrzeit:' +
                                '<span>' + data.time + '</span>' +
                            '</li>' +
                        '</ul>' +
                    '</div>' +
                    '<div class="notification-print pull-right-flex">' +
                        '<a href="' + printWithPrices + '" target="_blank" class="notification-button-print-pdf">' +
                            '<p>Auftrag mit Preisen drucken</p>' +
                        '</a>' +
                        '<a href="' + printWithOutPrices + '" target="_blank" class="notification-button-print-pdf">' +
                            '<p>Auftrag ohne Preise drucken</p>' +
                        '</a>' +
                    '</div>' +
                    '<div class="notification-close">' +
                        '<button class="order-close-button"></button>' +
                    '</div>' +
                '</div>'
    };
    let getOrderNotificationFailureHtml = function () {
        return  '<div class="order-message-box order-message-box-failure">' +
                '<div class="loader-small">' +
                    '<div class="order-failure-icon-small">' +
                    '</div>' +
                '</div>' +
                '<div class="notification-body">' +
                    '<p class="notification-title-cart">' +
                        '<span class="failure">Leider ist ein Problem aufgetreten!   </span>' +
                        '<span class="notification-details-item">' +
                            'Ihre Bestellung wurde an unseren Support übermittelt und wird dort weiter bearbeitet.' +
                        '</span>' +
                        '<span class="notification-details-item pull-right-flex">' +
                            '<span>T: </span>' +
                            '030 720 94 777' +
                        '</span>' +
                    '</p>' +
                '</div>' +
                '<div class="notification-close pull-right-flex">' +
                    '<button class="order-close-button"></button>' +
                '</div>' +
            '</div>';
    };
    let getOrderIdMissingInResponseNotificationFailureHtml = function (reason) {
        return  '<div class="order-message-box order-message-box-failure">' +
                '<div class="loader-small">' +
                    '<div class="order-failure-icon-small">' +
                    '</div>' +
                '</div>' +
                '<div class="notification-body">' +
                    '<p class="notification-title-cart">' +
                        '<span class="failure">Leider ist ein Problem aufgetreten!   </span>' +
                        '<span class="notification-details-item">' +
                            ' Bitte wenden Sie sich an den Shop Support oder gucken Sie in Ihre Auftragsablage. ' +
                            reason +
                        '</span>' +
                        '<span class="notification-details-item pull-right-flex">' +
                            '<span>T: </span>' +
                            '030 720 94 777' +
                        '</span>' +
                    '</p>' +
                '</div>' +
                '<div class="notification-close pull-right-flex">' +
                    '<button class="order-close-button"></button>' +
                '</div>' +
            '</div>';
    };

    return {
        renderShoppingCartHtml: renderShoppingCartHtml,
        getOrderNotificationLoadingHtml: getOrderNotificationLoadingHtml,
        getOrderNotificationSuccessHtml: getOrderNotificationSuccessHtml,
        getOrderNotificationFailureHtml: getOrderNotificationFailureHtml,
        getOrderIdMissingInResponseNotificationFailureHtml: getOrderIdMissingInResponseNotificationFailureHtml
    };

})();

module.exports = obeta.shoppingCartHelperFunctions;