
obeta.tracking = (function () {
    var initCarouselTracking = function () {
        $('.product-carousel').each(function(){
            var $this = $(this),
                trackingId = $this.data('tracking-id'),
                pageName = $this.find('.related-products-carousel').data('page');

            if (!$this.hasClass('viewed')) {
                $this.addClass('viewed');
                sendTrackingEvent(trackingId, {page: pageName});
            }
        });
    };
    sendTrackingEvent = function (eventName, trackdata) {
        $.extend(trackdata, {event: eventName}, trackdata);
        var trackurl = $('#main-wrapper').data('trackurl');
        if (trackurl) {
            obeta.helpers.ajax({
                    type: "POST",
                    url: trackurl,
                    data: {
                        data: trackdata
                    }
                },
                function () {
                },
                function () {
                });
        }
    };

    return {
        initCarouselTracking: initCarouselTracking,
        sendTrackingEvent: sendTrackingEvent
    };
})();

module.exports = obeta.tracking;