
obeta.coreApiCart = (function () {
    let _addArticleToCart = function (articleId, addArticleToCartUrl) {
        let amount = $('#js-inp-amount-' + articleId).val();
        obeta.helpers.ajax({
            type: "POST",
            url: addArticleToCartUrl,
            data: {
                'articleId': articleId,
                'amount': amount,
            }
        }, function(data) {
            obeta.messages.showMessage(
                'Der Artikel wurde Ihrem Xpress-Warenkorb hinzugefügt, den Sie über den klassischen <a href="' +
                '/core-api/cart">Warenkorb</a> einsehen können.'
                , obeta.messages.type.NOTICE);
        });
    };

    let bindAddArticleToCartButton = function (articleStock, articleId) {
        $('#add-article-to-core-cart-' + articleId).on('click', function () {
            let $this = $(this);
            let articleId = $this.data('articleid');
            let addArticleToCartUrl = $this.data('action');
            _addArticleToCart(articleId, addArticleToCartUrl);
        });
    };

    let _deleteLineItemFromCart = function (lineItemId) {
        obeta.helpers.ajax({
            type: "POST",
            url: "cart/lineItem/delete",
            data: {
                'lineItemId': lineItemId,
            }
        }, function() {
            $('#coreApiCartItemList').find('#coreApiCartItem-' + lineItemId).remove();
            _setOrderButton();

        }, function() {
            $('#coreApiCartItemList').find('#coreApiCartItem-' + lineItemId).remove();
        });
    };

    let bindDeleteLineItemFromCartButton = function () {
        $('.delete-lineItem-from-core-api-cart').on('click', function (e) {
            e.preventDefault();
            let $this = $(this);
            let lineItemId = $this.attr('data-lineItemId');
            _deleteLineItemFromCart(lineItemId);
        });
    };

    let _updateLineItemFromCart = function (lineItemId, amount) {
        obeta.helpers.ajax({
            type: "POST",
            url: "cart/lineItem/update",
            data: {
                'lineItemId': lineItemId,
                'amount': amount,
            }
        }, function(data) {
                $('#coreApiCartItemList').find('#update-lineItem-in-core-api-cart-' + lineItemId).val(amount);
            },
            function() {
            alert('error update! please refresh for now');
        });
    };

    let bindUpdateLineItemFromCartButton = function () {
        let inputField = $('.update-lineItem-in-core-api-cart');

        inputField.on('change', function (e) {
            e.preventDefault();
            let $this = $(this);
            let lineItemId = $this.attr('data-lineItemId');
            let amount = $('#update-lineItem-in-core-api-cart-' + lineItemId).val();
            _updateLineItemFromCart(lineItemId, amount);
        });

        inputField.on('keypress', function (e) {
            if (e.which == 13){
                e.preventDefault(); // prevents SC submit (through form submit) due to enter key press
            }
        });

        inputField.on('click', function (e) {
            e.preventDefault(); // prevents article detail page being called trough click into field
        });
    };

    let bindShowCartButton = function () {
        let addArticleButton = $('.add-article-to-core-cart-button');
        addArticleButton.hide();
        $('.add-article-to-core-cart-button-details').hide();

        if (!getActiveCaCStore()) {
            return;
        }

        switch (addArticleButton.data('liststyle')) {
            case 1:
                addArticleButton.addClass('add-article-to-core-cart-button-list-short');
                break;
            case 2:
                addArticleButton.addClass('add-article-to-core-cart-button-list-tiles');
                break;
            default:
                addArticleButton.addClass('add-article-to-core-cart-button-list');
        }

        fetchStock(function(stockCollection) {
            for (let articleId in stockCollection) {
                if (stockCollection && stockCollection[articleId] && stockCollection[articleId].amount > 0) {
                    bindAddArticleToCartButton(stockCollection[articleId], articleId);
                    _showCaCButtons(articleId, stockCollection[articleId]);
                }
            }
        });
    };

    let getActiveCaCStore = function() {
        return obeta.clientStorage.getClickAndCollectStoreId();
    };

    let _showCaCButtons = function(articleId, stockData) {
        $('#add-article-to-core-cart-button-' + articleId).show().find('button');
        $('#article-stock-status-' + articleId).text(stockData.amount + ' ' + stockData.unit + ' verfügbar').show();
    };

    let bindFetchStockForCart = function() {
        fetchStock(function(stockCollection) {
            $('.cacStockInfo').text("Artikel in der Filiale nicht verfügbar").removeClass('success').addClass('failure');
            $.each(stockCollection, function(articleId, stockData) {
                let lineItemElement = $('div[data-articleId="' + articleId + '"]'),
                    amount = lineItemElement.data('amount');

                stockData.stockLeft = stockData.amount - amount;
                stockData.status = stockData.stockLeft < 0 ? "YELLOW" : "GREEN";
                stockData.text = stockData.status === "YELLOW" ? "nur " + stockData.amount + " lieferbar" : "lieferbar! Weitere " + stockData.stockLeft + " " + stockData.unit + " verfügbar";

                lineItemElement.find('#cac-stock-info').text(stockData.text);
                lineItemElement.find('#cac-stock-info').addClass('success');
                lineItemElement.find('#cac-stock-info').removeClass('failure');
            })
            _setOrderButton();
        });
    };

    let fetchStock = function(callback) {
        let articleIds = [];
        $('.click-and-collect-article').each(function (k, link) {
            articleIds.push($(link).data('articleid'));
        });
        let url = "/js/store/" + getActiveCaCStore() + "/stock";
        $.ajax({
            type: "GET",
            url: url,
            data: {
                'articleIds': articleIds.join(','),
        }}).done(callback).fail(function(data) {
        });
    };

    /**
     * @param {Event} e
     */
    let submit = function(e) {
        e.preventDefault();
        /** @type HTMLFormElement */
        let form = e.target;

        var url = form.action;
        let data = {
            cartType: form.elements['jsCartType'].value,
            phone: form.elements['jsPhone'].value,
            commission: form.elements['jsCommission'].value,
            comment: form.elements['jsComment'].value,

            clickAndCollectStoreId: obeta.clientStorage.getClickAndCollectStoreId(),
        };
        if (data.phone.length < 7) {
            _showPhoneInputWarning();
            return;
        }

        $.ajax({
            type: 'POST',
            url: url,
            data: data,
        }).done(function(orderResponse) {
            showCacOrderSuccessMessage(orderResponse);
            console.dir('done');
        }).fail(function() {
            console.error('failed to submit cart');
        });
    };
    let showCacOrderSuccessMessage = function(orderResponse) {
        const time = 'in 60 min (beachten Sie die Öffnungszeiten)';
        let branch = $('#jsBranch').text();
        let summaryHead =
            '<div class="loader">' +
                '<div class="order-success-icon"></div>' +
            '</div>' +
            '<p class="sc-summary-title-cart">' +
                'Danke für Ihre Bestellung...' +
            '</p>';
        let summaryBody =
            '<div class="cac-summary-success-info">' +
                '<p>Wir haben Ihren Auftrag erhalten und bereiten die Xpress-Abholung für die ' + branch + ' umgehend vor.<p>' +
            '</div>' +
            '<div class="sc-summary-details">' +
                '<p>Auftragsdetails</p>' +
                '<ul class="sc-summary-details-list">' +
                    '<li class="cac-summary-details-item">' +
                        'Abhol-Nr: ' +
                        '<span>' + orderResponse.data.pickupNumber + '</span>' +
                    '</li>' +
                    '<li class="cac-summary-details-item">' +
                        'Abholung: ' +
                        '<span>' + time + '</span>' +
                    '</li>' +
                '</ul>' +
            '</div>';
        let main = $('#cacContainer');
        main.empty();
        main.append('<div id="cacSuccessWrapper"></div>');
        main.find('#cacSuccessWrapper').append(obeta.shoppingCartTemplates.getSummaryBoxHtml(summaryHead, summaryBody, ''));
        $('.sc-summary-form').remove();
        let homeButton =
            '<div class="cac-order-button-wrapper">' +
                '<a href="/">' +
                    '<div class="sc-summary-button" id="sc-summary-button">' +
                        '<b style="position: relative; top: 30%;">Weiter einkaufen</b>' +
                    '</div>' +
                '</a>' +
            '</div>';
        main.find('#cacSuccessWrapper').append(homeButton);
    };

    let removeWarning = function (e) {
        e.classList.remove('cac-warning');
    }

    let _showPhoneInputWarning = function () {
        $('#jsPhone').addClass('cac-warning');
    };

    let _setOrderButton = function () {
        //CaC empty?
        if($('.click-and-collect-article').length === 0 ) {
            _disableOrderButton();
            return;
        }
        //Article without stock?
        if($('.cacStockInfo.failure').length !== 0) {
            _disableOrderButton();
            return;
        }
        _enableOrderButton();
    }
    let _disableOrderButton = function () {
        $('#cac-submit').attr('disabled', true);
        $('#cac-submit').addClass('button-deactivated');
    }
    let _enableOrderButton = function () {
        $('#cac-submit').attr('disabled', false);
        $('#cac-submit').removeClass('button-deactivated');
    }

    return {
        bindAddArticleToCartButton: bindAddArticleToCartButton,
        bindDeleteLineItemFromCartButton: bindDeleteLineItemFromCartButton,
        bindShowCartButton: bindShowCartButton,
        bindUpdateLineItemFromCartButton: bindUpdateLineItemFromCartButton,
        bindFetchStockForCart: bindFetchStockForCart,
        submit: submit,
        removeWarning: removeWarning,
        showCacOrderSuccessMessage: showCacOrderSuccessMessage
    };
})();

module.exports = obeta.coreApiCart;