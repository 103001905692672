import browser from 'browser-detect';
const browserInfo = browser();

$(function() {
    // Supported Browser: https://confluence.obeta.io/pages/viewpage.action?pageId=44534603
    let getBrowserRequirements = function () {
        return {
            'ie': 11,
            'edge': 12,
            'firefox': 27,
            'chrome': 29,
            'safari': 7,
            'opera': 17,
            'ios': 5,
            'android': 5,
        }
    }
    // We just need the major version, so it is filtered out of the browser version string returned by the browser-detect library
    let getMajorBrowserVersion = function (version) {
        return version.split('.')[0];
    }
    let isBrowserDeprecated = function (browserInfo) {
        let browserRequirements = getBrowserRequirements();
        if (!browserRequirements[browserInfo.name]) {
            return false;
        }
        return getMajorBrowserVersion(browserInfo.version) < browserRequirements[browserInfo.name];
    }
    if (isBrowserDeprecated(browserInfo)) {
        obeta.messages.showMessage('Sie nutzen einen veralteten Browser. Aufgrund von Sicherheitsrichtlinien wird ihr Browser nicht mehr unterstützt. Die Nutzung aller Funktionen kann somit nicht mehr gewährleistet werden. Alternativ nutzen Sie bitte den Google Chrome, Firefox oder Edge Browser.', obeta.messages.type.ERROR);
    }
});
