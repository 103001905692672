

obeta.promotion = (function () {
    var bindPromotionButtonsAjax = function () {
        obeta.shoppingCart.bindPromotionBackButton();

        $('#main-inner .js-exit-promotion').click(function (e) {
            e.preventDefault();
            var confirmationParams = {
                containerId: "js-exit-promotion-warning",
                callbacks:
                        [
                            function () {
                                $('#promotion-close-button').show();
                            },
                            function () {
                                location.href = $('#promotion-button').data('href');
                            }
                        ]
            }
            obeta.messages.showConfirmation(confirmationParams);

        });
    },
            bindPromotionButtons = function () {
                if ($('#promotion-header').length > 0) {
                    $('.js-exit-promotion').click(function (e) {
                        e.preventDefault();
                        var confirmationParams = {
                            containerId: "js-exit-promotion-warning",
                            callbacks:
                                    [
                                        function () {
                                            $('#promotion-close-button').show();
                                        },
                                        function () {
                                            location.href = $('#promotion-button').data('href');
                                        }
                                    ]
                        }
                        obeta.messages.showConfirmation(confirmationParams);

                    });
                }
            };
    return {
        bindPromotionButtons: bindPromotionButtons,
        bindPromotionButtonsAjax: bindPromotionButtonsAjax
    }
})();

module.exports = obeta.promotion;