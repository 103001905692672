
obeta.sap = (function() {
    var preventAccessAndShowMessage = function() {

        $('.prevent-access-from-missing-sap-content').on('click', function(e) {
            e.preventDefault();
            obeta.messages.showConfirmation({
                containerId: 'js-prevent-access-from-missing-sap-content',
                callbacks:
                        [
                            function () {}
                        ]
            });
            obeta.helpers.hideSpinner();
        });
    };

    return {
        preventAccessAndShowMessage: preventAccessAndShowMessage
    };
})();

module.exports = obeta.sap;