
obeta.search = (function () {
    var bindDeleteSearchField = function () {
        $('.btn_cross').on('click', function (e) {
            e.preventDefault();
            $(this).prev().val('').trigger('blur');
        })
    },
            bindOciButton = function () {
                $('#oci-search-button').on('click', function () {
                    $('#oci').submit();
                    var url = $('#btn_logout').attr('href');
                    obeta.helpers.ajax({
                        type: "GET",
                        url: url,
                        data: {}
                    },
                            function (data) {}
                    );
                })
            },
            alignCategoryIcon = function () { //align checkboxes vertically centered in cat-filter
                $('.category-back').each(function () {
                    var h = $(this).height();
                    var div_h = $(this).parent().next('a').height();
                    this.style.marginTop = Math.round((div_h - h) / 2) + 'px';
                    this.style.visibility = 'visible';
                });
                $('.category-check').add('.category-check-active').each(function () {
                    var h = $(this).height();
                    var div_h = $(this).parent().next('a').height();
                    this.style.marginTop = Math.round((div_h - h) / 2) - 1 + 'px';
                    this.style.visibility = 'visible';
                });
            },
            bindSearchFilterHide = function () {
                $('.search-filter-dd').on('click', function (e) {
                    if (!$(e.target).hasClass('close-gray')) {
                        e.preventDefault();
                        var $this = $(this);
                        if ($this.hasClass('no-filter-selected')) {
                            $('.etim-attributes>a').hide();
                        }
                        var $innerSpan = $this.find('span:first');
                        if ($innerSpan.hasClass('arrow-down')) {
                            $innerSpan.removeClass('arrow-down');
                            $innerSpan.addClass('arrow-right-filter');
                        } else {
                            $innerSpan.removeClass('arrow-right-filter');
                            $innerSpan.addClass('arrow-down');
                        }
                        if ($('#supplier-filter-count').css('display') == 'none') {
                            $('#supplier-filter-count').css('display', 'block');
                        }
                        $this.next('div').toggle();
                    }
                })
            },
            bindEtimFilter = function () {
                $('.select-etim').selectBox().change(function () {
                    var hrefval = $("#etim-data-form").attr('href2').replace("%3C", "%7B").replace("%3E", "%7D");//replace < with { and > with } url encoded
                    var $this = $(this);
                    var etimValue = $this.val().replace("<", "{").replace(">", "}");
                    var hrefvalSplit = hrefval.split('?');
                    var hrefvalFirst = hrefvalSplit[0];
                    var hreflength = hrefvalFirst.length;
                    if (hrefvalFirst.substring(hreflength - 2, hreflength - 1) === "/") {
                        var hrefvalFirst = hrefvalSplit[0].substring(0, hreflength - 1);
                    }
                    obeta.helpers.showSpinner();
                    window.location.href = hrefvalFirst + etimValue.replace("/", "*") + "?" + hrefvalSplit[1];
                });
                $('.etim-hidden').each(function () {
                    $(this).hide();
                });

            },
            bindSpinners = function () {
                $('.js-spinner,.to-article-detail-link,#pager>a,#pager-small>a,.change-display,.close-gray,#statRadio>li>a,.category-list a,#flyout-menu a,.sc-entry,#menubar a,#wd-login-form>div>a,#sc-popup-button,.sc-popup-article').not('.js-exit-promotion').not('#quicky-link-footer').not('.offer-expired').on('click', function () {
                    obeta.helpers.showSpinner();
                });
            },
            bindEtimDropdown = function () {
                $('#selectbox-etim').selectBox({autoWidth: true}).change(function () {
                    var $this = $(this);
                    var etimClass = $this.val();
                    if (etimClass == "0") {
                        var hrefval = $("#etim-data-form").attr('href3');
                    } else {
                        var hrefval = $("#etim-data-form").attr('href');
                    }
                    var hreflink = hrefval.replace("___", etimClass);

                    obeta.helpers.showSpinner();
                    window.location.href = hreflink;
                });
            },
            bindEtimDropdownIE = function () {
                $('#selectbox-etim-ie').customSelect();
                var txtlen = 0;
                $('#selectbox-etim-ie>option').each(function () {
                    var $txtlength = $(this).text().length;
                    if ($txtlength > txtlen) {
                        txtlen = $txtlength;
                    }

                });
                $('#selectbox-etim-ie').css('width', txtlen * 7);

            },
            bindArticlePicClick = function () {
                $('.articlePic').click(function () {
                    window.location = $(this).closest('.to-article-detail-link')[0].href;
                });
                $('.artPic>.logoPic').click(function () {
                    window.location = $(this).closest('.to-article-detail-link')[0].href;
                })
            },
            bindBtnSuche = function () {
                $('#btn_suche').on('click', function (e) {
                    e.preventDefault();
                    var $this = $(this);
                    if ($('#inputsearch').val() == "Bitte geben Sie Ihren Suchbegriff ein.") {
                        $('#inputsearch').val('*');
                    }
                    obeta.helpers.showSpinner();
                    $this.parent().submit();
                })
            },
            bindCategoryMoreButton = function () {
                $('.filter-more-button').on('click', function (e) {
                    var $this = $(this);
                    var divid = "." + $this.data("id");
                    $(divid).toggle();
                    if ($this.text() == "+ mehr") {
                        $this.text("- weniger");
                    } else {
                        $this.text("+ mehr");

                    }
                    alignCategoryIcon();
                });
            },
            bindSupplierMoreButton = function () {
                $('#supplier-more-button').on('click', function (e) {
                    var $this = $(this);
                    if ($this.text() == "+ mehr") {
                        $('#supplier-filter-count').css('display', 'none');
                        $('#supplier-filter-abc').css('display', 'block');
                        $this.text("- weniger");
                    } else {
                        $('#supplier-filter-abc').css('display', 'none');
                        $('#supplier-filter-count').css('display', 'block');
                        $this.text("+ mehr");

                    }
                    alignCategoryIcon();
                });
            },
            bindEtimMoreButton = function () {
                $('#etim-more-button').on('click', function (e) {
                    var $this = $(this);
                    $("div.etim-hidden").toggle();
                    $("a.etim-hidden").toggle();
                    if ($this.text() == "+ mehr") {
                        $this.text("- weniger");
                    } else {
                        $this.text("+ mehr");

                    }
                });
            },
            bindFlyout = function () {
                var $menu = $("#flyout-menu");

                // jQuery-menu-aim: <meaningful part of the example>
                // Hook up events to be fired on menu row activation.
                $menu.menuAim({
                    activate: activateSubmenu,
                    deactivate: deactivateSubmenu,
                    exit: function () {
                    }
                });
                // jQuery-menu-aim: </meaningful part of the example>

                // jQuery-menu-aim: the following JS is used to show and hide the submenu
                // contents. Again, this can be done in any number of ways. jQuery-menu-aim
                // doesn't care how you do this, it just fires the activate and deactivate
                // events at the right times so you know when to show and hide your submenus.
                function activateSubmenu(row) {
                    var $row = $(row),
                            submenuId = $row.data("submenuId"),
                            $submenu = $("#" + submenuId),
                            height = $menu.outerHeight(),
                            width = $menu.outerWidth(),
                            popupsize = $("#" + submenuId + " li").size() * 35,
                            popupminsize = $('#flyout-menu').height();

                    if (popupsize < popupminsize) {
                        var popupsize = popupminsize;
                    }

                    if (submenuId != "none") {
                        // Show the submenu
                        $submenu.css({
                            display: "block",
                            top: -1,
                            left: width - 3, // main should overlay submenu
                            height: popupsize  //height - 4 padding for main dropdown's arrow
                        });

                        // Keep the currently activated row's highlighted look
                        $row.find("a").addClass("maintainHover");
                        $row.find("b").css("display", "block");
                    }
                }

                function deactivateSubmenu(row) {
                    var $row = $(row),
                            submenuId = $row.data("submenuId"),
                            $submenu = $("#" + submenuId);

                    // Hide the submenu and remove the row's highlighted look
                    $submenu.css("display", "none");
                    $row.find("a").removeClass("maintainHover");
                    $row.find("b").css("display", "none");
                }

                $("#flyout-menu li").click(function (e) {
                    e.stopPropagation();
                });

                $("#flyout-menu").mouseover(function (e) {
                    $(this).addClass("over");
                });

                $("#flyout-menu").mouseout(function (e) {
                    $(this).removeClass("over");
                });

                $("#cat-flyout-button").mouseover(function (e) {
                    $(this).addClass("over");
                });

                $("#cat-flyout-button").mouseout(function (e) {
                    $(this).removeClass("over");
                });

                $(document).click(function () {
                    // Simply hide the submenu on any click. Again, this is just a hacked
                    // together menu/submenu structure to show the use of jQuery-menu-aim.
                    $(".popover").css("display", "none");
                    $("a.maintainHover").removeClass("maintainHover");
                    $("#flyout-menu b").css("display", "none");
                });
                //close flyout when mouse leaves
                $("#flyout-menu, .popover").mouseleave(function () {
                    setTimeout(function () {
                        if (!$("#flyout-menu").hasClass('over') && !$("#cat-flyout-button").hasClass('over')) {
                            // Simply hide the submenu on any click. Again, this is just a hacked
                            // together menu/submenu structure to show the use of jQuery-menu-aim.
                            $(".popover").css("display", "none");
                            $("a.maintainHover").removeClass("maintainHover");
                            $(".wrp_search").removeClass("open");
                        }
                    }, 500);
                });
            },
            bindAutocomplete = function () {
                var $inputsearch = $('#inputsearch'),
                        allowed = true,
                        solrSuggestUrl = $inputsearch.data('solr-suggest-url');
                $(function () {
                    $inputsearch.autocomplete({
                        source: function (request, responseCallback) {

                            if (window.jsEnvironment.isCoreApiSuggestEnabled) {
                                let query = request.term.toLowerCase();
                                $.ajax({
                                    url: "/js/core-api/suggestions",
                                    type: "POST",
                                    data: {
                                        "query": query
                                    },
                                }).done(function (response) {
                                    responseCallback($.map(response.suggestions, function (suggest) {
                                        return {label: suggest, value: suggest};
                                    }));
                                });
                                return;
                            }

                            // legacy solr suggest
                            $.ajax({
                                type: "GET",
                                url: solrSuggestUrl,
                                dataType: "jsonp",
                                jsonp: "json.wrf",
                                data: {
                                    q: request.term.toLowerCase(),
                                    rows: 9
                                },
                                success: function (data) {
                                    var mapData = data.spellcheck && data.spellcheck.suggestions.length > 0 ? data.spellcheck.suggestions[1].suggestion : {};
                                    responseCallback($.map(mapData, function (item) {
                                        return {
                                            label: item.replace('(', ' ').replace(')', ' '),
                                            value: item.replace('(', ' ').replace(')', ' ')
                                        }
                                    }));
                                }
                            });
                        },
                        focus: function (event, ui) {
                            if (/^key/.test(event.originalEvent.originalEvent.type)) {
                                isHoverSelect = false;
                            } else {
                                isHoverSelect = true;
                            }
                        },
                        select: function (event, ui) {
                            var trulyClick = false;
                            if (event.originalEvent.originalEvent.type == "click") {
                                var searchString = $('.ui-state-focus').text();
                                $inputsearch.val(searchString);
                                $("#inputsearch-form").trigger("submit");
                                trulyClick = true;
                            }
                            if (trulyClick == false && (event.keyCode == 9 || (isHoverSelect == true && typeof event.keyCode != "undefined" && event.keycode != 0))) {
                                event.preventDefault();
                            }
                        },
                        minLength: 2,
                        open: function () {
                            var posx = ($(".ui-autocomplete").offset().left);
                            $(".ui-autocomplete").css("width", 300).css("top", 138).css("left", posx - 8);
                            $(".ui-menu-item").css("height", "25px");
                            $(this).removeClass("ui-corner-all").removeClass("ui-corner-top");
                        },
                        close: function () {
                            $(this).removeClass("ui-corner-top").removeClass("ui-corner-all");
                        }
                    });
                });
                $inputsearch.keydown(function (e) {
                    if (e.keyCode === 13) {
                        if (!allowed)
                            return;
                        allowed = false;
                        $("#inputsearch-form").trigger("submit");
                    } else if (e.keyCode === 9) {
                        e.preventDefault();
                        selectFirstArticleInput();
                    }
                }).keyup(function (e) {
                    allowed = true;
                });
            },
            bindSearchInOfferAndOrderTemplate = function (e) {
                $('.tab-nav #order-search-input').keydown(function (e) {
                    if (e.keyCode === 13) {
                        if (!allowed)
                            return;
                        allowed = false;
                        $(".tab-nav #js-order-search-form").trigger("submit");
                    } else if (e.keyCode === 9) {
                        e.preventDefault();
                        selectFirstInputInOfferAndOrderTemplate();
                    }
                }).keyup(function (e) {
                    allowed = true;
                })
            },
            selectFirstArticleInput = function (e) {
                var isIE11 = !!navigator.userAgent.match(/Trident\/7\./),
                        $firstInput = $('#searchArticles .js-inp').first();

                if (isIE11) {
                    $firstInput.select();
                } else {
                    $firstInput.trigger('select');
                }
            },
            selectSearchInputInOfferAndOrderTemplate = function (e) {
                var isIE11 = !!navigator.userAgent.match(/Trident\/7\./),
                        $searchInput = $('#order-search-input');

                if (isIE11) {
                    $searchInput.select();
                } else {
                    $searchInput.trigger('select');
                }
            },
            turntableInit = function (e) {
                $('.more-image-single-link-fancybox').on('click', function () {
                    $('.main-image-wrapper-fancybox').show();
                    $('.turntable').hide();
                });

            },
            bindThumbnailClick = function (e) {
                $('.more-image-single-link').unbind('click').on('click', function () {
                    $('#main-image').trigger('click');
                });
            },
            turntableThumbInit = function (e) {
                var images = $('.turntable-thumbnail>img').data('images');
                if (images) {
                    imagesarr = images.split(',');
                    $('.turntable-thumbnail').on('mouseover click', function () {
                        $('.main-image-wrapper-fancybox').hide();
                        $('.turntable').show();
                        if ($('#image-reel').length < 1) {
                            $('.fancybox-skin #image').css('width', '600px');
                            $('.fancybox-skin #image').reel({
                                speed: 0,
                                delay: 1,
                                revolution: 600,
                                images: imagesarr
                            });
                            $('.fancybox-skin .turntable-thumbnail').unbind('mouseover').on('mouseover', function (e) {
                                $('#moreImagesFancyboxContainer').removeClass('oxomi-show-video');
                                $('.main-image-wrapper-fancybox').hide();
                                $('.turntable').show();
                            });
                            if (!$('#product-view').hasClass('turntable-opened')) {
                                $(document).keydown(function (e) {
                                    if (e.keyCode == 39) {
                                        $('.fancybox-skin #image').trigger('stepLeft');
                                        return false;
                                    }
                                    if (e.keyCode == 37) {
                                        $('.fancybox-skin #image').trigger('stepRight');
                                        return false;
                                    }
                                });
                                $('#product-view').addClass('turntable-opened');
                            }
                        }
                    });
                    $('.turntable-thumb').on('click', function () {
                        $('#main-image').addClass('turntable-clicked');
                        $('#main-image').trigger('click');
                    });
                    $('.turntable-thumb').unbind('mouseover').on('mouseover', function () {
                        $('#main-image').attr('src', imagesarr[0]).addClass('turntable-clicked').addClass('turntable-show');
                    });
                }
            },
            selectFirstInputInOfferAndOrderTemplate = function (e) {
                var isIE11 = !!navigator.userAgent.match(/Trident\/7\./);
                if ($('.order-template-right').is(':visible')) {
                    var $firstInput = $('.order-template-right .js-inp').first();
                } else if ($('.offer-details').is(':visible')) {
                    var $firstInput = $('.offer-details .js-inp').first();
                }

                if ($firstInput.length) {
                    if (isIE11) {
                        $firstInput.select();
                    } else {
                        $firstInput.trigger('select');
                    }
                } else {
                    if (isIE11) {
                        $('.tab-nav #order-search-input').select();
                    } else {
                        $('.tab-nav #order-search-input').trigger('select');
                    }
                }
            },
            bindMoreLessFilterButton = function() {
                let showItems = 5;
                let listElems = $('.use-more-less-button');
                let moreText = '+ mehr';
                let lessText = '- weniger';

                listElems.each(function(i, ul){
                    let append = false;
                    $(ul).children().each(function(i, li){
                        if (i >= showItems){
                            $(li).addClass('toggle').hide();
                            append = true;
                        }
                    });
                    if (append) {
                        $(ul).append('<li class="filter-more-button filter-' + i + '">' + moreText + '</li>');
                    }
                    $('.filter-' + i).on('click', function(){
                        if ($(this).text() === moreText) {
                            $(this).siblings('.toggle').slideDown( "fast");
                            $(this).text(lessText)
                        } else {
                            $(this).siblings('.toggle').slideUp( "fast");
                            $(this).text(moreText)
                        }
                        if ($(this).hasClass('filter-2')){
                            sortSuppliers();
                        }
                    });
                });
            },
            bindFilterCollapse = function() {
                $('[id^="wrapper-"]').each(function() {
                    let id = $(this).attr('id');
                    $('#' + id).on('click', function () {
                        if ($(this).children('span').hasClass('arrow-down')) {
                            _hideFilter(id.split('-')[1]);
                        } else {
                            _showFilter(id.split('-')[1]);
                        }
                    });
                });
            },
            bindAlgoliaDataOnClick = function() {
                $('.to-article-detail-link').each(function () {
                    let $this = $(this);
                    $this.on('click', function() {
                        let objectId = $this.data('object-id');
                        let position = $this.data('object-position');
                        let userToken = $('#searchResult').data('user-token');
                        let queryId = $('#searchResult').data('query-id');
                        obeta.algolia.sendClickedObjectIdAfterSearchEvent(objectId, queryId, position, userToken);
                    })
                });
            },
            bindAlgoliaConversionOnClick = function() {
                $('.add-article-to-core-cart').each(function () {
                    let $this = $(this);
                    $this.on('click', function() {
                        sendAlgoliaConversionEvent($(this));
                    });
                });
            },
            sendAlgoliaConversionEvent = function($this) {
                let objectId = $this.closest('.artBlock').children('.to-article-detail-link').data('object-id');
                let queryId = $('#searchResult').data('query-id');
                let userToken = $('#searchResult').data('user-token');
                if(objectId && queryId && userToken) {
                    obeta.algolia.sendClickedObjectIdAfterConversionEvent(objectId, queryId, userToken);
                }
            },
            _showFilter = function(type) {
                $('#list-' + type).slideDown('fast');
                $('#arrow-' + type).removeClass('arrow-right-filter').addClass('arrow-down');
            },
            _hideFilter = function(type) {
                $('#list-' + type).slideUp('fast');
                $('#arrow-' + type).removeClass('arrow-down').addClass('arrow-right-filter');
            },
            sortSuppliers = function(){
                let suppliersList = $('#list-default');
                let suppliers = suppliersList.children('.item-filter:visible');
                suppliers.sort(function(a, b){
                    return $(a).text() > $(b).text() ? 1 : -1;
                });
                suppliersList.children('.item-filter:visible').remove();
                suppliersList.prepend(suppliers);
            }
    return {
        bindDeleteSearchField: bindDeleteSearchField,
        alignCategoryIcon: alignCategoryIcon,
        bindSearchFilterHide: bindSearchFilterHide,
        bindFlyout: bindFlyout,
        bindEtimFilter: bindEtimFilter,
        bindAutocomplete: bindAutocomplete,
        bindBtnSuche: bindBtnSuche,
        bindEtimDropdown: bindEtimDropdown,
        bindCategoryMoreButton: bindCategoryMoreButton,
        bindEtimMoreButton: bindEtimMoreButton,
        bindArticlePicClick: bindArticlePicClick,
        bindSpinners: bindSpinners,
        bindOciButton: bindOciButton,
        bindSupplierMoreButton: bindSupplierMoreButton,
        bindEtimDropdownIE: bindEtimDropdownIE,
        bindSearchInOfferAndOrderTemplate: bindSearchInOfferAndOrderTemplate,
        selectFirstArticleInput: selectFirstArticleInput,
        turntableInit: turntableInit,
        turntableThumbInit: turntableThumbInit,
        bindThumbnailClick: bindThumbnailClick,
        selectSearchInputInOfferAndOrderTemplate: selectSearchInputInOfferAndOrderTemplate,
        selectFirstInputInOfferAndOrderTemplate: selectFirstInputInOfferAndOrderTemplate,
        bindMoreLessFilterButton: bindMoreLessFilterButton,
        bindFilterCollapse: bindFilterCollapse,
        bindAlgoliaDataOnClick: bindAlgoliaDataOnClick,
        bindAlgoliaConversionOnClick: bindAlgoliaConversionOnClick,
        sendAlgoliaConversionEvent: sendAlgoliaConversionEvent,
        sortSuppliers: sortSuppliers
    }
})();

module.exports = obeta.search;
