
obeta.init = (function () {
    var defaultInit = function () {
        $.ajaxSetup({'timeout': 30000});
        obeta.homepage.bannerPosition();

        obeta.promotion.bindPromotionButtons();

        var $searchInput = $('#inputsearch');

        obeta.articles.bindAmountInputEvents($('#searchArticles .artBox .inp-amount input'), false, $searchInput);
        obeta.articles.bindAmountInputEvents($('#searchArticles .artBoxSmall .inp-amount input'), false, $searchInput);
        obeta.articles.bindAmountInputEvents($('.box-grid .inp-amount input'), false);
        obeta.articles.bindAmountInputEvents($('.offer-details .artBox.offer-box .inp-amount input'), false);
        obeta.articles.bindAmountInputEvents($('.order-template-right .artBox.sc-box .inp-amount input'), false);
        obeta.articles.bindAmountInputEvents($('.shoppingcart-right .artBox.sc-box .inp-amount input'), true);

        obeta.articles.calculatePrice();
        obeta.articles.bindQuickMessageBeforeLogin();
        obeta.homepage.bindExtrasMessageBeforeLogin();
        obeta.messages.bindFileUploadMessageBeforeLogin();
        obeta.homepage.bindCallBackForm();
        obeta.articles.bindSelectOnFocusInput();
        obeta.articles.bindArticleRedirectInEmbedMode();

        obeta.shoppingCart.resizeShoppingCartPopupIcons();
        obeta.shoppingCart.bindShoppingCartDropdown();

        obeta.clickAndCollect.bindClickAndCollectDropdown();

        obeta.articleList.bindArtBoxClicks();
        obeta.articleList.bindArticleCheckBox();
        obeta.articleList.bindShiftPositions();
        obeta.articleList.bindShiftSinglePosition();
        obeta.articleList.bindSelectArticlesButtons();
        obeta.articleList.bindAddArticle();
        obeta.formHelpers.bindRenderCustomRadioButtons();
        obeta.articleList.bindArtBoxMouseover();

        obeta.homepage.bindDeactivatedLinks();
        obeta.homepage.setDefaultTextForInput();
        obeta.homepage.bindQRCodeLinkContact();

        obeta.messages.bindHideMessage();
        obeta.messages.showAnimationMessage();

        obeta.search.bindDeleteSearchField();
        obeta.search.bindFlyout();
        obeta.search.bindAutocomplete();
        obeta.search.bindBtnSuche();

        obeta.search.bindArticlePicClick();
        obeta.search.bindSpinners();

        obeta.formHelpers.initCustomSelect();
        obeta.formHelpers.bindSubmitOnEnter();
        obeta.formHelpers.bindEmbeddedSearchInputCross();
        obeta.formHelpers.bindEmbeddedSearchSubmit();

        obeta.userInterface.initInputHints();
        obeta.userInterface.initTooltips();
        obeta.userInterface.bindFancyboxClicks();
        obeta.userInterface.bindSupplierFilter();
        obeta.userInterface.bindSapSupplierFilter();
        obeta.userInterface.bindLoginButton();
        obeta.userInterface.bindCustomerPricesButton();

        obeta.order.bindOrderInputCross();
        obeta.order.bindOrderInputSubmit();

        obeta.orderTemplate.bindShowTipOrderTemplate();

        obeta.project.bindSubmitFormEvent();
        obeta.userInterface.bindMoreButton();

        obeta.invoice.bindDownloadInvoiceCopy();
        obeta.invoice.bindInvoiceCopyStatePolling();
        obeta.barcode.bindWatchOverlayInput();

        obeta.helpers.bindKeepAlivePolling();
        obeta.helpers.delayPriceAjaxLoader();
        obeta.cookieconsent.initCookieConsent();

        obeta.sap.preventAccessAndShowMessage();

        window.onresize = function () {
            obeta.homepage.bannerPosition();
        };

        if (window.jsEnvironment && window.jsEnvironment.showJiraFeedbackButton && obeta.beta) {
            obeta.beta.showSubmitFeedback();
        }

        if (window.jsEnvironment && window.jsEnvironment.embedMode) {

            // after page loaded...
            $(function () {
                const sendHeight = () => {
                    const height = document.body.scrollHeight;
                    window.parent.postMessage({command: 'updateHeight', height: height}, '*');
                };

                // ...send updateHeight-command...
                sendHeight();

                // ...and observe body resize, ...
                let timeoutId = 0;
                let timeout = 0;
                let waitStart = 0;
                const resizeObserver = new ResizeObserver((entries) => {
                    // ...after resize...
                    if (timeout === 0) {
                        timeoutId = 0;
                        timeout = 50; // <- throttle time (do send the command more often then every 50ms)
                        waitStart = Date.now();
                    }
                    timeout = Math.max(0, timeout - (Date.now() - waitStart));
                    if (timeoutId !== 0) {
                        clearTimeout(timeoutId);
                    }
                    // ...send updateHeight-command throttled.
                    timeoutId = setTimeout(sendHeight, timeout);
                });
                resizeObserver.observe(document.body);


                // Also we need to observe the opening of the add to cart-selection flyout...
                $('.dropdown-toggle').on('mousedown', () => {
                    // ...and send the height, after the popup was openend
                    // try multiple times, because we do not know when the popup is open
                    setTimeout(sendHeight, 100);
                    setTimeout(sendHeight, 200);
                    setTimeout(sendHeight, 300);
                    setTimeout(sendHeight, 500);
                });
                // inertface IDS-OCI user permissions
                $('.user-toogle-button').on('click', function (e) {
                    setTimeout(sendHeight, 650);
                });

            });
        }
    },
            rentInit = function () {
                defaultInit();
                obeta.rent.initRentPage();
            },
            eventInit = function () {
                obeta.events.bindButtonsForEventPage();
                defaultInit();
            },
            projectInit = function () {
                obeta.project.bindDeleteProjectEvent();
                obeta.project.bindAddNewProjectEvent();
                obeta.user.bindSettingAdditionalText();
                obeta.user.bindSubmitSettings();
                obeta.shoppingCart.bindPickupDropdown();
                obeta.project.bindDeliveryTypeRadio();
                obeta.shoppingCart.bindStoredAddressDropdown();
                obeta.shoppingCart.bindPickupLocationOnPopup();
                obeta.shoppingCart.bindChangeTargetDate();
                defaultInit();
                obeta.shoppingCart.bindCustomDeliveryDropdown();

            },
            oxomiInit = function () {
                obeta.oxomi.bindOxomiReadyEvent();
                defaultInit();
            },
            aboutUsInit = function () {
                obeta.slides.bindSliderHistory();
                obeta.helpers.checkPlzSpace();
                defaultInit();
                oxomiInit();
            },
            newCustomerInit = function () {
                obeta.upload.initFileUpload();
                obeta.helpers.bindKeepAlivePollingNewCustomer();
                defaultInit();
            },
            fileUploadInit = function () {
                defaultInit();
                obeta.importfile.initUploadImportFile();
                obeta.importfile.bindAddImportFileToSc();
            },
            storesInit = function () {
                if (document.querySelector('#stores-list-url').dataset.geodataFromDatabase) {
                    obeta.geodata.initJqueryUiMaps();
                    obeta.geodata.initGeoData(false);
                    obeta.geodata.bindGeoDataHandling();
                    obeta.geodata.bindSearchAddress(false);
                    obeta.geodata.bindQrCodeVcard();
                    defaultInit();
                } else {
                    obeta.geodatasap.initJqueryUiMaps();
                    obeta.geodatasap.initGeoData(false);
                    obeta.geodatasap.bindGeoDataHandling();
                    obeta.geodatasap.bindSearchAddress(false);
                    obeta.geodatasap.bindQrCodeVcard();
                    defaultInit();
                }
            },
            welcomeInit = function () {
                var sUsrAg = navigator.userAgent;
                if (sUsrAg.indexOf("iPhone") > -1 || sUsrAg.indexOf("iPad") > -1) {
                    obeta.homepage.bindOverlayIphone();
                }
                obeta.slides.initSlider();
                obeta.slides.initBannerscollection();
                obeta.slides.bindAutomaticSliderLoggedIn();
                obeta.caroussel.initTopTeaserCaroussel();
                obeta.caroussel.initRecommendationCaroussel();
                //      caroussel needs to be evoked before bindaddarticlecheckbox to work
                obeta.caroussel.bindDropdownCLick();
                obeta.caroussel.bindButtonsThatHideDropdown();
                obeta.tracking.initCarouselTracking();
                obeta.prices.fetchPricesViaAjax();

                defaultInit();
                window.onresize = function () {
                    obeta.slides.initSlider();
                    obeta.slides.updateKenBurnsHeight();
                    obeta.homepage.bannerPosition();
                };
                obeta.oxomi.bindOxomiFallback();
            },
            quickyInit = function () {
                obeta.articles.bindQuickySearch();
                obeta.articles.bindQuickySearchAmount();
                obeta.articles.bindQuickyArticle();
                obeta.articles.bindSubmitQuicky();
                $("#quicky-search-field").select();
                defaultInit();
            },
            singleArticleInit = function () {
                obeta.caroussel.initRecommendationCaroussel();
                obeta.oxomi.bindOxomiFallback();
                //      caroussel needs to be evoked before bindaddarticlecheckbox to work
                obeta.caroussel.bindDropdownCLick();
                obeta.caroussel.bindButtonsThatHideDropdown();
                obeta.caroussel.bindRelatedArticlesCheckbox();
                defaultInit();
                var $inputAmount = $('#price-addcart .inp-amount input');
                var $searchInput = $('#inputsearch');
                obeta.articles.bindAmountInputEvents($inputAmount, false, $searchInput);
                $inputAmount.select();
                obeta.articles.bindCompareEtimArticle();
                obeta.articles.bindAlgoliaConversionOnClick();
                obeta.articles.bindReturnLink();
                obeta.tracking.initCarouselTracking();
                obeta.search.turntableInit();
                obeta.hagerzplan.bindElbridge();
                obeta.prices.fetchPricesViaAjax();
                obeta.prices.fetchDetailPricesViaAjax();
                obeta.helpers.delayPriceAjaxLoader();
                obeta.branchavailability.fetchBranchAvailabilityViaAjax();
                obeta.coreApiCart.bindShowCartButton();
            },
            supplierInit = function () {
                defaultInit();
            },
            shoppingCartInit = function () {
                obeta.shoppingCart.bindScNextButton();
                obeta.shoppingCart.bindChangeOffer();
                obeta.shoppingCart.bindRemoveArticle();
                obeta.shoppingCart.bindSubmitShoppingCartHeader();
                obeta.shoppingCart.bindStoreAddress();
                obeta.shoppingCart.bindShoppingCartSubmitButton();
                obeta.shoppingCart.bindDeleteShoppingCartEvent();
                obeta.shoppingCart.bindPromotionBackButton();
                obeta.formHelpers.initUfdDropdown();
                obeta.datanorm.bindIdsButton();
                obeta.user.bindSettingAdditionalText();
                obeta.helpers.bindMobilePhoneNumber();
                obeta.shoppingCart.bindCustomCompleteDeliveryButton();
                obeta.shoppingCart.bindPickupDropdown();
                obeta.shoppingCart.bindAddressChanges();

                if ($('.radio-div').is(":visible")) {
                    $('#sc-steps').attr('data-loaded', 0);
                    $('input.js-update-delivery-dates:checked').trigger('click');
                }

                obeta.oxomi.bindOxomiFallback();
                obeta.barcode.bindPrintBarcode();
                obeta.shoppingCart.bindDeliveryTypeRadio();
                obeta.shoppingCart.bindGoDeliveryTimes();
                obeta.shoppingCart.bindStoredAddressDropdown();
                obeta.shoppingCart.bindPickupLocationOnPopup();
                obeta.shoppingCart.bindChangeTargetDate();
                defaultInit();
                obeta.shoppingCart.bindCustomDeliveryDropdown();
                obeta.shoppingCart.bindPrintSCButton();
            },
            orderTemplateInit = function () {
                defaultInit();
                obeta.orderTemplate.loadTemplateData();
                obeta.orderTemplate.bindNewTemplate();
                obeta.orderTemplate.bindRenameTemplate();
                obeta.orderTemplate.bindDeleteOrderTemplateEvent();
                obeta.shoppingCart.bindRemoveArticle();
                obeta.orderTemplate.bindOpenCartTemplates();
                obeta.search.selectSearchInputInOfferAndOrderTemplate();
                obeta.search.bindSearchInOfferAndOrderTemplate();
                obeta.oxomi.bindOxomiFallback();
                obeta.barcode.bindPrintBarcode();
                obeta.lazyload.init();
            },
            openPositionsInit = function () {
                defaultInit();
                obeta.invoice.bindInvoiceCopyRequest();
                obeta.invoice.bindInvoiceEnter();
            },
            ajaxInit = function () {
                obeta.shoppingCart.bindScNextButton();
                obeta.shoppingCart.bindChangeOffer();
                obeta.shoppingCart.bindRemoveArticle();
                obeta.shoppingCart.bindSubmitShoppingCartHeader();
                obeta.shoppingCart.bindStoreAddress();
                obeta.shoppingCart.bindShoppingCartSubmitButton();
                obeta.shoppingCart.bindChangeTargetDate();
                obeta.shoppingCart.bindDeleteShoppingCartEvent();
                obeta.formHelpers.initUfdDropdown();
                obeta.datanorm.bindIdsButton();
                obeta.user.bindSettingAdditionalText();
                obeta.articles.bindAmountInputEvents($('.shoppingcart-right .artBox.sc-box .inp-amount input'), true);
                obeta.userInterface.bindFancyboxClicks();
                obeta.userInterface.initInputHints();
                obeta.userInterface.initTooltips();
                obeta.userInterface.bindMoreButton();
                obeta.articleList.bindArtBoxClicks();
                obeta.articleList.bindAddArticle();
                obeta.articleList.bindArticleCheckBox();
                obeta.articleList.bindShiftPositions();
                obeta.articleList.bindShiftSinglePosition();
                obeta.articleList.bindSelectArticlesButtons();
                obeta.articleList.bindArtBoxMouseover();
                obeta.articles.bindSelectOnFocusInput();
                obeta.shoppingCart.bindCustomCompleteDeliveryButton();
                obeta.shoppingCart.bindPickupLocationOnPopup();
            },
            userInit = function () {
                defaultInit();
                obeta.user.bindDeleteUserAddress();
                obeta.user.bindSelectRolesDropdown();
                obeta.user.bindDeleteSubUser();
                obeta.user.bindPasswordMeter();
                obeta.user.bindSubmitUser();
                obeta.user.bindNewUser();
                obeta.user.bindDeleteUser();
                obeta.user.bindChangePassword();//nach passwordmeter+setdefaulttext aufrufen
                obeta.user.bindSubmitSettings();
                obeta.user.bindOpeningTimesCheckbox();
                obeta.user.bindSubmitMyDatasheet();
                obeta.user.bindChangeEmail();
                obeta.user.bindChangeSubuserEmail();
                obeta.user.bindProjectRadios();
                obeta.user.bindSelectProjectDropdown();
                obeta.user.bindSettingAdditionalText();
                obeta.user.bindChangeAccountName();
                obeta.user.detectFormChange();
                obeta.upload.initFileUpload();
                $wrapper = $('.page-new-customer-wrapper');
                if ($wrapper.length) {
                    window.setInterval(function () {
                        obeta.helpers.ajax({
                            type: "GET",
                            url: $wrapper.data('keepalive-url'),
                            data: {}
                        }, function () {}, function () {},
                                {'showSpinner': false}

                        );
                    }, 23 * 60 * 1000); // 23 minutes
                }
            },
            orderInit = function () {
                defaultInit();
                obeta.search.bindSupplierMoreButton();
                obeta.invoice.bindInvoiceCopyRequest();
                obeta.order.bindOrderPrintPdf();
                obeta.order.bindRenameOrderTitle();
                obeta.piwikcalendar.initPiwikCalendar();
                obeta.piwikcalendar.bindChangeCalenderPeriod();
                obeta.articles.alignCheckboxVertical();
                obeta.search.selectSearchInputInOfferAndOrderTemplate();
                obeta.search.bindSearchInOfferAndOrderTemplate();
                obeta.oxomi.bindOxomiFallback();
                obeta.barcode.bindPrintBarcode();
                obeta.lazyload.init();
                obeta.constructionDocumentation.init();
            },
            backOrderInit = function () {
                defaultInit();
                obeta.search.selectSearchInputInOfferAndOrderTemplate();
                obeta.search.bindSearchInOfferAndOrderTemplate();
                obeta.articles.alignCheckboxVertical();
                obeta.lazyload.init();
            },
            returnOrderInit = function () {
                defaultInit();
                obeta.search.selectSearchInputInOfferAndOrderTemplate();
                obeta.search.bindSearchInOfferAndOrderTemplate();
                obeta.articles.alignCheckboxVertical();
                obeta.lazyload.init();
            },
            ledCalculatorInit = function () {
                defaultInit();
                obeta.project.bindSubmitFormEvent();
                obeta.ledCalculator.initLedCalculator();
            },
            offerInit = function () {
                defaultInit();
                obeta.search.selectSearchInputInOfferAndOrderTemplate();
                obeta.search.bindSearchInOfferAndOrderTemplate();
                obeta.oxomi.bindOxomiFallback();
                obeta.barcode.bindPrintBarcode();
                obeta.lazyload.init();
            },
            datanormInit = function () {
                defaultInit();
                obeta.datanorm.initDatanorm();
                obeta.datanorm.bindDatanormSubmit();
                obeta.datanorm.bindDataVolumeRadio();
                obeta.datanorm.bindEstimatedTime();
                obeta.datanorm.bindReloadOnWait();
            },
            hagerzplanImportInit = function () {
                defaultInit();
                obeta.hagerzplan.bindHagerzplanEvent();
            },
            hagerzplanMessageInit = function () {
                defaultInit();
                obeta.messages.bindHagerzplanMessageOnLoad();
            },
            elbridgeInit = function() {
                defaultInit();
                obeta.hagerzplan.bindElbridge();
            },
            search = function () {
                obeta.search.alignCategoryIcon();
                obeta.search.bindSearchFilterHide();
                obeta.search.bindEtimDropdown();
                obeta.search.bindCategoryMoreButton();
                obeta.search.bindSupplierMoreButton();
                obeta.search.bindEtimMoreButton();
                obeta.search.bindEtimFilter();
                obeta.search.bindOciButton();
                obeta.search.selectFirstArticleInput();
                obeta.search.bindMoreLessFilterButton();
                obeta.search.bindFilterCollapse();
                obeta.search.bindAlgoliaDataOnClick();
                obeta.search.bindAlgoliaConversionOnClick();
                obeta.search.sortSuppliers();
                obeta.shoppingCart.bindPromotionBackButton();
                obeta.oxomi.bindOxomiFallback();
                if ($('html').hasClass('ie7') || $('html').hasClass('ie8')) {
                    obeta.search.bindEtimDropdownIE();
                } else {
                    obeta.search.bindEtimDropdown();
                }
                obeta.prices.fetchPricesViaAjax();
                obeta.coreApiCart.bindShowCartButton();
                defaultInit();
            },
            coreApiCartInit = function () {
                defaultInit();
                obeta.coreApiCart.bindUpdateLineItemFromCartButton();
                obeta.coreApiCart.bindDeleteLineItemFromCartButton();
                obeta.coreApiCart.bindFetchStockForCart();
            },
            shopNextCheckoutInit = function () {
                defaultInit();
                obeta.shopNext.init();
            },
            contactInit = function () {
                defaultInit();
            };
    return {
        'defaultInit': defaultInit,
        'eventInit': eventInit,
        'newCustomerInit': newCustomerInit,
        'storesInit': storesInit,
        'aboutUsInit': aboutUsInit,
        'welcomeInit': welcomeInit,
        'quickyInit': quickyInit,
        'userInit': userInit,
        'projectInit': projectInit,
        'shoppingCartInit': shoppingCartInit,
        'openPositionsInit': openPositionsInit,
        'orderTemplateInit': orderTemplateInit,
        'orderInit': orderInit,
        'offerInit': offerInit,
        'ajaxInit': ajaxInit,
        'search': search,
        'coreApiCartInit': coreApiCartInit,
        'singleArticleInit': singleArticleInit,
        'rentInit': rentInit,
        'datanormInit': datanormInit,
        'returnOrderInit': returnOrderInit,
        'backOrderInit': backOrderInit,
        'fileUploadInit': fileUploadInit,
        'ledCalculatorInit': ledCalculatorInit,
        'supplierInit': supplierInit,
        'oxomiInit': oxomiInit,
        'hagerzplanImportInit': hagerzplanImportInit,
        'hagerzplanMessageInit': hagerzplanMessageInit,
        'contactInit': contactInit,
        'elbridgeInit': elbridgeInit,
        'shopNextCheckoutInit': shopNextCheckoutInit
    }
})();

module.exports = obeta.init;