
obeta.constructionDocumentationTemplates = (function () {

    let getConstructionDocumentationSummaryLoading = function () {
        let params = obeta.constructionDocumentation.getFilterParams();
        let head =
            '<div class="loader">' +
            '<div class="order-loading-icon spin"></div>' +
            '</div>' +
            '<p class="sc-summary-title-cart" id="sc-summary-loading">' +
            'Baudokumentation wird vorbereitet ...' +
            '</p>';
        let body = '<div class="summary-item-container">'
        if(params.type === 'big'){
            body += getItemHtml('Typ', '"Groß", Obeta-, und weitere Datenblätter');
        }
        else{
            body += getItemHtml('Typ', '"Klein", Obeta-Datenblätter');
        }
        params.searchString ? body += getItemHtml('Suchbegriff', params.searchString) : '';
        params.date ? body += getItemHtml('Zeitraum', params.date) : '';
        params.supplierId ? body += getItemHtml('Lieferant', params.supplierId) : '';
        body += '</div>';
        let info = '<div class="construction-info"><p>Dieser Vorgang kann einige Minuten in Anspruch nehmen. Wir benachrichtigen Sie, sobald der Download bereit ist.<p></div>'
        body += info;

        return getSummaryBoxHtml(head, body);
    };

    let getConstructionDocumentationSuccess = function (constructionDocumentationData) {
        let fileUrl = (typeof constructionDocumentationData.data !== 'undefined')
            ? constructionDocumentationData.data.zipFileUrl
            : constructionDocumentationData.zipFileUrl;

        let summaryHead =
            '<div class="loader">' +
            '<div class="order-success-icon"></div>' +
            '</div>' +
            '<p class="sc-summary-title-cart">' +
            'Ihr Download ist bereit' +
            '</p>';

        let summaryBody =
            '<div class="summary-success-info">' +
            '<p>Der Download startet automatisch. Sollte dies nicht der Fall sein klicken Sie bitte <a class="summary-success-info__download" href="' + fileUrl +'">hier</a>.<p>' +
            '</div>'

        return getSummaryBoxHtml(summaryHead, summaryBody);
    };

    let getConstructionDocumentationSummaryFailure = function (constructionDocumentationData) {
        let reason = (typeof constructionDocumentationData.data !== 'undefined')
            ? constructionDocumentationData.data.failureReason
            : constructionDocumentationData.failureReason;
        let message = 'Die Baudokumentation konnte nicht erfolgreich generiert werden. Versuchen Sie es erneut, oder wenden Sie sich an den Support.';

        if (reason === 'no_products'){
            message = 'Er wurden keine Produkte zu Ihrer Baudokumentation gefunden. Bitte überprüfen Sie Ihre Auswahl.';
        }
        if (reason === 'too_many_products'){
            message = 'Ihre Auswahl enthält zu viele Produkte. Bitte reduzieren Sie Ihre Auswahl.';
        }

        let summaryHead =
            '<div class="loader">' +
            '<div class="order-failure-icon"></div>' +
            '</div>' +
            '<p class="sc-summary-title-cart-failure">' +
            'Leider ist ein Problem aufgetreten' +
            '</p>';

        let summaryBody =
            '<div class="sc-summary-failure-info">' +
            '<p>' + message + '</p>' +
            '<div class="summary-contact">' +
            'T:' +
            '<span>030 720 94 777</span>' +
            '</div>' +
            '</div>';

        return getSummaryBoxHtml(summaryHead, summaryBody);
    };

    /* HTML build Helper Functions */

    let getItemHtml = function (name, item) {
        if (item === '') {
            return '';
        }
        return  '<span class="sc-summary-left">' +
            '<span>' +
            name +
            '</span>' +
            '</span>' +
            '<span class="sc-summary-right">' +
            item +
            '</span>';

    };
    let getSummaryBoxHtml = function (summaryHead, summaryBody) {
        return  '<div class="summary-body">' +
            '<div id="sc-summary-box-head">' +
            summaryHead +
            '</div>' +
            summaryBody +
            '</div>' +
            '<div class="modal__footer">' +
            '<a id="construction-documentation-back" href="#construction-documentation-print-modal" rel="modal:close">' +
            '<button class="modal__button modal__back-button">' +
            'zurück zum Shop' +
            '</button>' +
            '</div>'
    };
    return {
        getConstructionDocumentationSummaryLoading: getConstructionDocumentationSummaryLoading,
        getConstructionDocumentationSuccess: getConstructionDocumentationSuccess,
        getConstructionDocumentationSummaryFailure: getConstructionDocumentationSummaryFailure,
        getSummaryBoxHtml: getSummaryBoxHtml
    };
})();

module.exports = obeta.constructionDocumentationTemplates;