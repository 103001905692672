

obeta.orderTemplate = (function () {
    var bindNewTemplate = function () {
        $('#btn-new-template').on('click', function (e) {
            var $this = $(this);
            e.preventDefault();
            var orderTemplateUrl = $('#order-template-standard-url').val(),
                $input = $('#js-input-modal'),
                confirmationParams = {
                        containerId: "js-template-name-input",
                        callbacks:
                                [
                                    function () {},
                                    function () {
                                        var tempname = $input.val(),
                                                url = $this.attr('href');

                                        if (tempname != '') {
                                            newTemplateRequest = obeta.helpers.ajax({
                                                type: "POST",
                                                url: url,
                                                data: {name: tempname}
                                            },
                                                    function (data) {
                                                        location.href = orderTemplateUrl;
                                                    },
                                                    function () {});
                                        } else {
                                            return 'prevent-fancybox-close';
                                        }
                                    }
                                ],
                        afterShow: function () {
                            $input.focus();
                        },
                        afterShowOnce: function () {
                            $input.on('keypress', function (e) {
                                if (e.which == 13) {
                                    e.preventDefault();
                                    if ($input.val() != '') {
                                        $('.fancybox-outer #confirmation-1').trigger('click');
                                    }
                                }
                            });
                        }
                    };
            obeta.messages.showConfirmation(confirmationParams);
        });
    },
            bindDeleteOrderTemplateEvent = function () {
                $('.js-order-template-delete').on('click', function (e) {
                    e.preventDefault();
                    var $this = $(this),
                        url = $this.attr('href'),
                        orderTemplateUrl = $('#order-template-standard-url').val();

                    var confirmationParams = {
                        containerId: "js-order-template-delete-message",
                        callbacks:
                                [
                                    function () {},
                                    function () {
                                        obeta.helpers.showSpinner();
                                        removeShoppingCartRequest = $.ajax({
                                            type: "POST",
                                            url: url
                                        })
                                                .done(function (data) {
                                                    location.href = orderTemplateUrl;
                                                    obeta.helpers.hideSpinner();

                                                })
                                                .fail(function (data) {

                                                });
                                    }
                                ]
                    }
                    obeta.messages.showConfirmation(confirmationParams);
                });

            },
            bindRenameTemplate = function () {
                $('#btn-rename-template').on('click', function (e) {
                    e.preventDefault();
                    var oldTempname = $.trim($('#current-template-name').html());
                    $('#js-input-modal').val(oldTempname);
                    confirmationParams = {
                        containerId: "js-template-name-input",
                        callbacks:
                                [
                                    function () {},
                                    function () {
                                        var tempname = $('#js-input-modal').val(),
                                                url = $('#btn-rename-template').attr('href');
                                        newTemplateRequest = $.ajax({
                                            type: "POST",
                                            url: url,
                                            data: {name: tempname}

                                        })
                                                .done(function (data) {
                                                    location.reload();
                                                })
                                                .fail(function (data) {
                                                    alert("Der Name existiert schon, versuchen Sie es noch einmal mit einem anderen Namen.")

                                                });
                                    }
                                ]
                    };
                    if (obeta.messages.showConfirmation(confirmationParams)) {
                    }
                });
            },
            loadTemplateData = function () {
                $('.order-temp-ajax-load').each(function() {
                    var $this = $(this),
                    name = $this.data('name'),
                    link = $this.data('link');
                    obeta.helpers.ajax({
                        type: "POST",
                        url: link,
                        data: {name: name}
                    });
                });
            },

        bindShowTipOrderTemplate = function () {
                $('#btn_head_order_template').on('click', function (e) {
                    e.preventDefault();
                    var url = $(this).attr('href'),
                            getShowTipUrl = $('#btn_header_get_show_tip_order_template').val(),
                            saveShowTipUrl = $('#btn_header_save_show_tip_order_template').val();

                    showTipOrderTemplateRequest = obeta.helpers.ajax({
                        type: "POST",
                        url: getShowTipUrl
                    },
                            function (data) {
                                showtip = data.data;
                                if (showtip == true) {
                                    var showTipParams = {
                                        containerId: 'js-show-tip-add-to-order-template',
                                        callbacks:
                                                [
                                                    function () {
                                                        var tipChecked = false;
                                                        if ($('#do-not-show-tip-anymore').find('b').hasClass('radio-check-selected')) {
                                                            tipChecked = true;
                                                        }
                                                        if (tipChecked) {
                                                            saveShowTipRequest = obeta.helpers.ajax({
                                                                type: "POST",
                                                                url: saveShowTipUrl,
                                                                data: {tip: false}
                                                            },
                                                                    function () {
                                                                        location.href = url;
                                                                    });
                                                        } else {
                                                            location.href = url;
                                                        }
                                                    }
                                                ],
                                        afterShow: function () {
                                            $('#do-not-show-tip-anymore').on('click', function (e) {
                                                e.preventDefault();
                                                if ($(this).find('b').hasClass('radio-normal')) {
                                                    $(this).find('b').removeClass('radio-normal');
                                                    $(this).find('b').addClass('radio-check-selected');
                                                } else {
                                                    $(this).find('b').removeClass('radio-check-selected');
                                                    $(this).find('b').addClass('radio-normal');
                                                }
                                            });
                                        },
                                        fancyboxOptions: {
                                            closeBtn: false
                                        }
                                    };
                                    obeta.messages.showConfirmation(showTipParams);
                                } else {
                                    location.href = url;
                                }

                            },
                            function () {});
                });
        },
    bindOpenCartTemplates = function () {
        $('#button-cart-template').on('click', function (e) {
            e.preventDefault();
            let url = window.jsEnvironment.shopNextRedirectTarget + '/cart-template-list'
            window.top.location.href = url
        });
    };

    return {
        bindNewTemplate: bindNewTemplate,
        bindRenameTemplate: bindRenameTemplate,
        bindDeleteOrderTemplateEvent: bindDeleteOrderTemplateEvent,
        bindShowTipOrderTemplate: bindShowTipOrderTemplate,
        loadTemplateData: loadTemplateData,
        bindOpenCartTemplates: bindOpenCartTemplates
    }
})();

module.exports = obeta.orderTemplate;