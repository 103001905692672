
obeta.constructionDocumentationViewHandler = (function () {

    let _isModalActive = function () {
        return !($('#construction-documentation-print-modal').offsetParent === null);
    };

    let enterLoadingState = function () {
        if (_isModalActive()) {
            _showConstructionDocumentationLoadingMessageInModal();
            _showConstructionDocumentationLoadingMessageInMessageBox();
        } else {
            _showConstructionDocumentationLoadingMessageInMessageBox();
        }
    };

    let enterSuccessState = function (data) {
        if (_isModalActive()) {
            _showConstructionDocumentationSuccessMessageInModal(data);
            _showConstructionDocumentationSuccessMessageInMessageBox(data);
        } else {
            _showConstructionDocumentationSuccessMessageInMessageBox(data);
        }
    };

    let enterFailureState = function (data) {
        if (_isModalActive()) {
            _showConstructionDocumentationFailedMessageInModal(data);
            _showConstructionDocumentationMessageFailedInMessageBox(data);
        } else {
            _showConstructionDocumentationMessageFailedInMessageBox(data);
        }
    };

    let _showConstructionDocumentationLoadingMessageInModal = function () {
        let constructionDocumentationLoading = obeta.constructionDocumentationTemplates.getConstructionDocumentationSummaryLoading();
        obeta.constructionDocumentationHelperFunctions.renderConstructionDocumentationHtml(constructionDocumentationLoading);
    };

    let _showConstructionDocumentationLoadingMessageInMessageBox = function () {
        obeta.messages.showNotificationMessage(obeta.constructionDocumentationHelperFunctions.getConstructionDocumentationLoadingHtml());
    };

    let _showConstructionDocumentationSuccessMessageInModal = function (constructionDocumentationData) {
        let summarySuccess = obeta.constructionDocumentationTemplates.getConstructionDocumentationSuccess(constructionDocumentationData);
        obeta.constructionDocumentationHelperFunctions.renderConstructionDocumentationHtml(summarySuccess);
    };

    let _showConstructionDocumentationSuccessMessageInMessageBox = function (constructionDocumentationData) {
        obeta.messages.showNotificationMessage(obeta.constructionDocumentationHelperFunctions.getConstructionDocumentationSuccessHtml(constructionDocumentationData));
    };

    let _showConstructionDocumentationFailedMessageInModal = function (constructionDocumentationData) {
        let constructionDocumentationFailure = obeta.constructionDocumentationTemplates.getConstructionDocumentationSummaryFailure(constructionDocumentationData);
        obeta.constructionDocumentationHelperFunctions.renderConstructionDocumentationHtml(constructionDocumentationFailure);
    };

    let _showConstructionDocumentationMessageFailedInMessageBox = function (constructionDocumentationData) {
        let failureHtml = obeta.constructionDocumentationHelperFunctions.getConstructionDocumentationFailureHtml(constructionDocumentationData);
        obeta.messages.showNotificationMessage(failureHtml);
    };

    let showTimeoutError = function () {
        if (_isModalActive()) {
            _showConstructionDocumentationFailedMessageInModal()
            _showConstructionDocumentationMessageFailedInMessageBox()
        } else {
            _showConstructionDocumentationMessageFailedInMessageBox()
        }
    };

    return {
        enterLoadingState : enterLoadingState,
        enterSuccessState : enterSuccessState,
        enterFailureState : enterFailureState,
        showTimeoutError : showTimeoutError
    };
})();

module.exports = obeta.constructionDocumentationViewHandler;