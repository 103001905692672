require('expose-loader?jQuery!jquery');
require('expose-loader?jquery-ui/ui/widget!jquery-ui');
require('./jquery.modal.min');
window.$ = window.jQuery;

// Desktop
obeta = {};
obeta.init = require('./obeta.js');
obeta.articleList = require('./obeta.articleList.js');
obeta.articles = require('./obeta.articles.js');
obeta.asyncOrderTimeoutManager = require('./obeta.asyncOrderTimeoutManager.js');
obeta.barcode = require('./obeta.barcode.js');
obeta.branchavailability = require('./obeta.branchavailability.js');
obeta.caroussel = require('./obeta.caroussel.js');
obeta.clickAndCollect = require('./obeta.clickAndCollect.js');
obeta.constructionDocumentation = require('./obeta.constructionDocumentation.js');
obeta.constructionDocumentationHelperFunctions = require('./obeta.constructionDocumentationHelperFunctions.js');
obeta.constructionDocumentationTemplates = require('./obeta.constructionDocumentationTemplates.js');
obeta.constructionDocumentationViewHandler = require('./obeta.constructionDocumentationViewHandler.js');
obeta.coreApiCart = require('./obeta.coreApiCart.js');
obeta.datanorm = require('./obeta.datanorm.js');
obeta.events = require('./obeta.events.js');
obeta.feedback = require('./obeta.feedback.js');
obeta.geodata = require('./obeta.geodata.js');
obeta.geodatasap = require('./obeta.geodatasap.js');
obeta.hagerzplan = require('./obeta.hagerzplan.js');
obeta.homepage = require('./obeta.homepage.js');
obeta.importfile = require('./obeta.importfile.js');
obeta.invoice = require('./obeta.invoice.js');
obeta.ledCalculator = require('./obeta.ledCalculator.js');
obeta.messages = require('./obeta.messages.js'); //COD-6073: is overwritten later -> remove?
obeta.news = require('./obeta.news.js');
obeta.shopNext = require('./obeta.shopNext.js');
obeta.order = require('./obeta.order.js');
obeta.orderTemplate = require('./obeta.orderTemplate.js');
obeta.orderViewHandler = require('./obeta.orderViewHandler.js');
obeta.oxomi = require('./obeta.oxomi.js');
obeta.piwikcalendar = require('./obeta.piwik.calendar.js');
obeta.project = require('./obeta.project.js');
obeta.promotion = require('./obeta.promotion.js');
obeta.rent = require('./obeta.rent.js');
obeta.sap = require('./obeta.sap.js');
obeta.search = require('./obeta.search.js');
obeta.shoppingCart = require('./obeta.shoppingCart.js');
obeta.shoppingCartHelperFunctions = require('./obeta.shoppingCartHelperFunctions.js');
obeta.shoppingCartTemplates = require('./obeta.shoppingCartTemplates.js');
obeta.slides = require('./obeta.slides.js');
obeta.socketMessageHandler = require('./obeta.socketMessageHandler.js');
obeta.upload = require('./obeta.upload.js');
obeta.user = require('./obeta.user.js');
obeta.userInterface = require('./obeta.userInterface.js');

// Shared
obeta.articleCalculation = require('../shared/obeta.articleCalculation.js');
obeta.cookieconsent = require('../shared/obeta.cookieconsent.js');
obeta.formHelpers = require('../shared/obeta.formHelpers.js');
obeta.helpers = require('../shared/obeta.helpers.js');
obeta.lazyload = require('../shared/obeta.lazyload.js');
obeta.messages = require('../shared/obeta.messages.js');
obeta.prices = require('../shared/obeta.prices.js');
obeta.tracking = require('../shared/obeta.tracking.js');
obeta.clientStorage = require('../shared/obeta.clientStorage.js');

window.obeta = obeta;
