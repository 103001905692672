
obeta.datanorm = (function () {
    var initDatanorm = function () {
        $('.element-row').css('margin-bottom', '12px');
        $('.datanorm-box-right .element-row').css('margin-bottom', '8px');

        _insertSettingsByDeliveryType();

        $('.datatype-change').on('click', function (e) {
            $('.js-check-change-and-price-infos').show();
            $('.datatype-change-radios').show();
            $('#erase-set').show();
            $('#abo-radio').show();
            $('.datanorm-transfer-type .horizontal-line').removeAttr('style').attr('style', 'padding-top: 120px;')
        });
        $('.datatype-build-new').on('click', function (e) {
            $('.js-check-change-and-price-infos').hide();
            $('.datatype-change-radios').hide();
            $($('.datanorm-transfer-type span.ueber')[0]).trigger('click');
            $('#erase-set').hide();
            $('#abo-radio').hide();
            $('.datanorm-transfer-type .horizontal-line').removeAttr('style').attr('style', 'padding-top: 20px;')
        });

        $('.ueber').on('click', function (e) {
            var $this = $(this),
                    $form = $this.parents('form'),
                    dataVolume = $form.find('input[name=datanorm-volume]:checked').val();

            if (dataVolume == 'S') {
                _disableDeliveryViaEmail();
            }

            _insertSettingsByDeliveryType();
        });

        $('.datanorm-select-how-often').on('change', function (e) {
            var value = $('select.datanorm-select-how-often').val();
            if (value == 'abo-monthly') {
                if (!$('#datanorm-hr3').hasClass('datanorm-hr3')) {
                    $('#datanorm-hr3').addClass('datanorm-hr3');
                }
                $('#datanorm-week-list').css('display', 'none');
                $('#datanorm-month-list').css('display', 'block');
            } else {
                if ($('#datanorm-hr3').hasClass('datanorm-hr3')) {
                    $('#datanorm-hr3').removeClass('datanorm-hr3');
                }
                $('#datanorm-month-list').css('display', 'none');
                $('#datanorm-week-list').css('display', 'block');
            }
        });

        $('.datanorm-day').on('click', function (e) {
            var $this = $(this);
            if (!$this.hasClass('disabled-day')) {
                $this.parent().find('.datanorm-day').each(function (e) {
                    $(this).removeClass('day-selected');
                });
                if (!$this.hasClass('day-selected')) {
                    $this.addClass('day-selected');
                }
            }

        });
    },
    _insertSettingsByDeliveryType = function(){
        var $this = $('.ueber'),
            $form = $this.parents('form'),
            deliveryType = $this.parent().find('input:checked').val(),
            $deliveryBox = $this.parents('.datanorm-transfer-type');

        $('.datanorm-datatransfer-right').show();
        $('#datanorm-date-dropdown').show();
        $('#datanorm-date-text').hide();
        $('#price-updates').show();
        $('#radio-price-upkeep').show();

        if (deliveryType == 'D' || deliveryType == 'download') {
            $deliveryBox.find('.datanorm-send-per-email').hide();
            $deliveryBox.find('#datanorm-subscription').removeClass('show').hide();
            $('#datanormMessage').hide();
            $deliveryBox.find('.datanorm-online').hide();
        } else if (deliveryType == 'E' || deliveryType == 'email') {
            $deliveryBox.find('.datanorm-send-per-email').show();
            $deliveryBox.find('#datanorm-subscription').removeClass('show').hide();
            $('#datanormMessage').show();
            $('#datanorm-week-list').css("top", "20px");
            $deliveryBox.find('.datanorm-online').hide();
        } else if (deliveryType == 'A') {
            $('#radio-price-upkeep').hide();
            $deliveryBox.find('#datanorm-subscription').addClass('show').show();
            $deliveryBox.find('.datanorm-send-per-email').show();
            $('#datanorm-week-list').css('top', '0');
            $('#erase-set').show();
            $('#datanormMessage').show();
            $deliveryBox.find('.datanorm-online').hide();
        }else if (deliveryType == 'O') {
            $('#datanorm-date-dropdown').hide();
            $('#datanorm-date-text').show();
            $('#radio-price-upkeep').hide();
            $deliveryBox.find('#datanorm-subscription').removeClass('show').hide();
            $deliveryBox.find('.datanorm-send-per-email').hide();
            $('#datanormMessage').hide();
            $deliveryBox.find('.datanorm-online').show();
            $('.datanorm-datatransfer-right').hide();
        }
    },
    bindEstimatedTime = function () {
        $('#datanorm-40 .radio-fx, #datanorm-40 .jquery-checkbox').on('click', function (e) {
            e.preventDefault();
            if (!$(this).hasClass('disabled')) {
                _updateEstimatedTimeviaAjax($(this));
            }
        });
    },
    _updateEstimatedTimeviaAjax = function ($elem) {
        var $form = $elem.parents('form'),
                isDataTypeClick = $elem.hasClass('datanorm-datatype'),
                dataArt = $form.find('input[name="datanorm-datatype"]:checked').val(),
                priceData = $form.find('#check-apreispflege').is(':checked') ? 1 : 0,
                removeData = $form.find('#check-aloeschsaetze').is(':checked') ? 1 : 0,
                goodsData = $form.find('#check-awarengruppen').is(':checked') ? 1 : 0,
                dataType = $form.find('input[name=datatyp]').val(),
                dataVolume = $form.find('input[name=datanorm-volume]:checked').val();
        if (!isDataTypeClick || (dataArt != 'N' && dataArt != 'P')) {
            var datanormString = dataType + dataArt + priceData + removeData + goodsData + dataVolume,
                    $estimatedTimeField = $form.find('.js-estimated-datanorm-time'),
                    url = $estimatedTimeField.data('url');
            obeta.helpers.ajax({
                url: url,
                data: {'datanormString': datanormString},
                type: 'POST'
            },
            function (data) {
                $estimatedTimeField.html(data.data.estimatedTimeString);
            });
        }
    },
    _disableDeliveryViaEmail = function () { //<div title="" data-placement="top" data-delay="1000" class="obtooltip tmpl-buttons" data-original-title="in Vorlage übernehmen">
        $('.ueber').each(function () {
            var $this = $(this);
            if ($this.parent().find('input').val() == 'E') {

                $this.addClass('obtooltip');
                $this.attr('title', 'Datenumpfang zu groß für den Versand per E-Mail.');
                $this.addClass('disabled');

                if ($this.find('span').hasClass('radio') || $this.find('span').hasClass('radio-checked')) {
                    if ($this.find('span').hasClass('radio-checked')) {
                        $this.find('span').removeClass('radio-checked');
                        $($('.datanorm-transfer-type span.ueber')[0]).trigger('click');
                    }
                    $this.find('span').removeClass('radio');
                    $this.find('span').addClass('obeta-icons');
                    $this.find('span').addClass('radio-gray');

                    $this.next().addClass('text-grey');
                    $this.next().addClass('obtooltip');
                    //$this.next().attr('data-placement', 'top');
                    //$this.next().attr('data-delay', '1000');
                    $this.next().attr('title', 'Datenumpfang zu groß für den Versand per E-Mail.');
                }
            }
        });
    },
    _enableDeliveryViaEmail = function () {
        $('.ueber').each(function () {
            var $this = $(this);
            if ($this.parent().find('input').val() == 'E') {

                $this.removeClass('obtooltip');
                $this.removeClass('disabled');
                $this.attr('title', '');

                if ($this.find('span').hasClass('radio-gray')) {
                    $this.find('span').removeClass('radio-gray');
                    $this.find('span').removeClass('obeta-icons');
                    $this.find('span').addClass('radio');

                    $this.next().removeClass('text-grey');
                    $this.next().removeClass('obtooltip');
                    $this.next().attr('title', '');
                }
            }
        });
    },
    bindDataVolumeRadio = function () {
        $('.datanorm-volume').on('click', function (e) {
            e.preventDefault();
            var input = $(this).parent().find('input');
            $('.datanorm-datavolumne-right').toggle(input.val() == 'A');
            if (input.val() == 'S') {
                _disableDeliveryViaEmail();
                //alert('Datenumpfang zu groß für den Versand per E-Mail.');
            } else {
                _enableDeliveryViaEmail();
            }
        });
    },
    bindDatanormSubmit = function () {
        $('.datanorm-submit').on('click', function (e) {
            e.preventDefault();
            var form = $(this).parents('form'),
                    dataVolumeVal = form.find('.datanorm-data-volume :checked').val(),
                    dataDeliveryType = ($("#datanorm-subscription.show").find("[name='rhythmus']").length !== 0) ? $("[name='rhythmus']").val() : "",
                    dataTransferTypeEmail = $('#email-radio>span>span').hasClass('radio-checked');
            var emailtext = $('#email1>input').val();
            if ($('#datanorm-40').css('display') == "none") {
                var dataTransferTypeEmail = $('#email-radio-export>span>span').hasClass('radio-checked'),
                        emailtext = $('#email2>input').val();
            }
            if (dataVolumeVal == 'A') {
                // copy all suppliers from fancybox into form
                $('.supplier-fancy-box input').appendTo(form);
            }
            if (dataTransferTypeEmail || dataDeliveryType == 'abo-weekly' || dataDeliveryType == 'abo-monthly') {
                if (emailtext.indexOf('@') < 1) {
                    var noDeliveryStoreSelectedMessage = {
                        containerId: 'js-no-email-message',
                        callbacks: [
                            function () {
                                return;
                            }
                        ]
                    }
                    obeta.messages.showConfirmation(noDeliveryStoreSelectedMessage);
                    return;
                }
            }
            if (dataDeliveryType == 'abo-weekly' || dataDeliveryType == 'abo-monthly') {
                if ($('.day-selected').length < 1) {
                    var noDeliveryStoreSelectedMessage = {
                        containerId: 'js-no-day-selected-message',
                        callbacks: [
                            function () {
                                return;
                            }
                        ]
                    }
                    obeta.messages.showConfirmation(noDeliveryStoreSelectedMessage);
                    return;
                }
                // copy selected subscription day to form
                var selectedDay = $('.datanorm-day.day-selected:visible').data('day');
                form.append('<input type="hidden" name="subscriptionDay" value="' + selectedDay + '"/>');
            }
            obeta.helpers.ajax({
                type: "POST",
                url: form.attr('action'),
                data: form.serialize()
            },
            function (data) {
                location.reload();
            },
            function (data) {
                location.reload();
            });
        });
    },
    bindIdsButton = function () {
        $('#ids-uebernehmen-button').on('click', function () {
            var url = $('#ids-uebernehmen').data('href');
            obeta.helpers.ajax({
                url: url,
                data: {'interface':'noShoppingCartSubmit'},
                type: 'POST'
            },
            function () {
                $('#ids').submit();
            });
        });
    },
    bindReloadOnWait = function () {
        if ($('.js-wait-for-datatnorm').length > 0) {
            setTimeout(function () {
                location.reload();
            }, 60 * 1000);
        }
    };

    return {
        initDatanorm: initDatanorm,
        bindDatanormSubmit: bindDatanormSubmit,
        bindDataVolumeRadio: bindDataVolumeRadio,
        bindEstimatedTime: bindEstimatedTime,
        bindIdsButton: bindIdsButton,
        bindReloadOnWait: bindReloadOnWait,
    };
})();

module.exports = obeta.datanorm;