obeta.clickAndCollect = (function () {
    let bindClickAndCollectDropdown = function () {
        let pickupDropdown = $('#click-and-collect-dropdown');

        if (!!obeta.clientStorage.getClickAndCollectStoreId()) {
            $('#storeId-' + obeta.clientStorage.getClickAndCollectStoreId()).prop('selected', true);
        }

        pickupDropdown.change(function () {
            let selected = $('#click-and-collect-dropdown option:selected');

            if (selected.data("click-and-collect-store-id").toString().length > 0) {
                let warehouseId = selected.data("click-and-collect-store-id");
                obeta.clientStorage.setClickAndCollectStoreId(warehouseId);

                // i know, mega cheap, but i works for now
                let onCartPage= $('div.cacMain').length;
                if (onCartPage) {
                    // reload because we need to display the selected store address, etc.
                    window.location.reload();
                } else {
                    obeta.coreApiCart.bindShowCartButton();
                }
            }
        });
    };

    return {
        bindClickAndCollectDropdown: bindClickAndCollectDropdown,
    };
})();

module.exports = obeta.clickAndCollect;