

obeta.order = (function(){
    var bindOrderInputCross = function() {
        $('#order-search-cross').on('click', function(e) {
            $('#order-search-input').val("");
            $('#js-order-search-form').trigger("submit");
        });
    },
        bindOrderInputSubmit = function() {
        $("#js-order-search-form").on("submit",function(){
            obeta.helpers.showSpinner();
        });
    },
        bindOrderPrintPdf = function() {
        $('.btn-print-order-pdf').on('click', function(e) {
            e.preventDefault();
            var showPrintOrders = {
                containerId: 'js-order2-print-pdf',
                callbacks: [ function() {} ]
            }
            obeta.messages.showConfirmation(showPrintOrders);
        });

            $('.button-order2pdf').on('click', function(e) {
                if ($('#js-order2-print-pdf #confirmation-0').is(':visible')) {
                    $('.fancybox-close').trigger('click');
                }
            });
    },
        bindRenameOrderTitle = function() {
        $('.btn-rename-order-no').on('click', function(e) {
            e.preventDefault();
            var $input = $('#js-input-modal-order'), $this = $(this);

            confirmationParams = {
                containerId: "js-rename-order-title",
                callbacks:
                [
                    function () {},
                    function () {
                        var orderName = $input.val(), osid = $this.attr('data-osid'), renameOrderUrl = $this.attr('href');
                            if (orderName != '') {
                            renameOrderRequest = obeta.helpers.ajax({
                                type: "POST",
                                url: renameOrderUrl,
                                data: { orderName: orderName, oSId: osid }
                                },
                                function () {
                                    location.reload();
                                },
                                function () {location.reload();});
                        } else {
                            return 'prevent-fancybox-close';
                        }
                    }
                ],
                afterShow: function() {
                    $input.focus();
                },
                afterShowOnce: function() {
                    $input.on('keypress', function(e) {
                        if (e.which == 13) {
                            e.preventDefault();
                            if ($input.val() != '') {
                                $('.fancybox-outer #confirmation-1').trigger('click');
                            }
                        }
                    });
                }
            };
            obeta.messages.showConfirmation(confirmationParams);
        });
    }

    return {
        bindOrderInputCross : bindOrderInputCross,
        bindOrderInputSubmit: bindOrderInputSubmit,
        bindOrderPrintPdf: bindOrderPrintPdf,
        bindRenameOrderTitle: bindRenameOrderTitle
    };
})();

module.exports = obeta.order;