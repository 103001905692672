obeta.constructionDocumentation = (function () {
    let init = function () {
        _bindConstructionDocumentationPrintButton();
    }

    let bindCloseModal = function () {
        obeta.socketMessageHandler.timeoutId = null;
        $('#construction-documentation-print-modal').on($.modal.AFTER_CLOSE, function(event, modal) {
            $('#construction-documentation-container-summary').fadeOut('fast')
            $('#construction-documentation-container-init').fadeIn('fast');
        });
    }


    let _bindConstructionDocumentationPrintButton = function() {
        $('#construction-documentation-download').on('click', function (e) {
            e.preventDefault();
            let $this = $(this);
            let url = $this.attr('href');
            let urlInfo = getFilterParams();

            obeta.helpers.ajax({
                type: "POST",
                url: url,
                data: {
                    'searchString': urlInfo.searchString,
                    'supplierId': urlInfo.supplierId,
                    'orderStorageId':urlInfo.orderStorageId,
                    'type': urlInfo.type
                }
            }, function(data) {
                obeta.socketMessageHandler.initConstructionDocumentationView();
            }, function (e) {
                console.log(e.message);
            });
        });
    }

    let getFilterParams = function() {
        let urlParams = new URLSearchParams(window.location.search);
        let searchString = $('#order-search-input').val();
        searchString = (searchString === 'Suchbegriff' || searchString === 'Artikel, Auftragsnr, Kommission') ? null : searchString;
        let supplierId = urlParams.get('supplier');
        let orderStorageId = urlParams.get('orderStorageId');
        let date = $('#date').text();
        let type = $('input[name=download-option]:checked', '#construction-documentation-print-modal').val()
        return {
            'searchString': searchString,
            'supplierId': supplierId,
            'orderStorageId': orderStorageId,
            'date': date,
            'type': type
        }
    }

    return {
        init: init,
        bindCloseModal: bindCloseModal,
        getFilterParams: getFilterParams,
    };
}) ();

module.exports = obeta.constructionDocumentation;