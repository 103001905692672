

obeta.prices = {
    fetchPricesViaAjax: function () {
        let domNodesBySapId = {};

        $('*[data-js-load-prices-via-ajax]').each(function (index, node) {
            let id = node.dataset.jsLoadPricesViaAjax;
            if (typeof domNodesBySapId[id] != "undefined") {
                domNodesBySapId[id].push(node);
            } else {
                domNodesBySapId[id] = [node];
            }
        });

        let ids = Object.keys(domNodesBySapId);
        if (ids.length === 0) {
            return;
        }

        $.ajax({
            url: "/js/prices",
            type: "POST",
            data: {
                "sapIds": ids.join(',')
            },
        }).done(function (response) {
            for (let id in response) {
                let record = response[id] || {
                        strikeThroughPrice: null,
                        price: 'Preis auf Anfrage',
                        listPrice: 'Preis auf Anfrage',
                    },
                    nodes = $(domNodesBySapId[id]);

                if (record.strikeThroughPrice) {
                    nodes.find('.js-strike-through-price').html(record.strikeThroughPrice);
                }
                nodes.find('.js-price').html(record.price);
                nodes.find('.js-list-price').html(record.listPrice);
            }
        });
    },
    fetchDetailPricesViaAjax: function () {

        let node = $('*[data-js-load-prices-detail-via-ajax]');
        if (typeof node[0] === 'undefined'){
            return;
        }
        let sapId = node[0].dataset.jsLoadPricesDetailViaAjax;
        let calcBoxNode = $('#price-addcart');

        $.ajax({
            url: "/js/pricesDetail",
            type: "POST",
            data: {
                "sapId": sapId
            },
        }).done(function (response) {
            let record = response[sapId] || {
                netPrice: null,
                bulkPrice1: null,
                bulkPrice1Amount: null,
                bulkPrice1Value: null,
                bulkPrice2: null,
                bulkPrice2Amount: null,
                bulkPrice2Value: null,
                calcPrice: null,
                sellPrice: null,
                metalPrice: null,
                unit: null,
                price: 'Preis auf Anfrage',
                listPrice: 'Preis auf Anfrage',
                catalogPrice: 'Preis auf Anfrage',
                metalNeAddition: 'Preis auf Anfrage',
                calcPriceFormatted: 'Preis auf Anfrage',
                sellPriceFormatted: 'Preis auf Anfrage',
                metalPriceFormatted: 'Preis auf Anfrage'
            };

            node.find('.js-detail-price').html(record.price);
            node.find('.js-detail-list-price').html(record.listPrice);
            node.find('.js-detail-catalog-price').html(record.catalogPrice);
            node.find('.js-detail-metal-price').html(record.metalNeAddition);
            if (record.bulkPrice1){
                node.find('.js-detail-bulk-price-1').html(record.bulkPrice1).show();
                node.find('.js-detail-bulk-price-1-header').data('amount', record.bulkPrice1Amount);
                node.find('.js-detail-bulk-price-1-header').data('price', record.bulkPrice1Value);
                node.find('.js-detail-bulk-price-1-header').html('Preis ab ' + record.bulkPrice1Amount + ' ' +  record.unit).show();
            }
            if (record.bulkPrice2){
                node.find('.js-detail-bulk-price-2').html(record.bulkPrice2).show();
                node.find('.js-detail-bulk-price-2-header').data('amount', record.bulkPrice2Amount);
                node.find('.js-detail-bulk-price-2-header').data('price', record.bulkPrice2Value);
                node.find('.js-detail-bulk-price-2-header').html('Preis ab ' + record.bulkPrice2Amount + ' ' +  record.unit).show();
            }

            //feed the fancy calc-box
            calcBoxNode.find('.main-price').html(record.calcPriceFormatted);
            calcBoxNode.find('#main-price-basic').val(record.calcPrice);
            calcBoxNode.find('.sell-price').html(record.sellPriceFormatted);
            calcBoxNode.find('#sell-price-basic').val(record.sellPrice);
            calcBoxNode.find('.metal-price').html(record.metalPriceFormatted);
            calcBoxNode.find('#metal-price-basic').val(record.metalPrice);
            calcBoxNode.find('.js-inp').prop('disabled', false);
        });
    }
};

module.exports = obeta.prices;