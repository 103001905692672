

obeta.beta = (function() {
    var showSubmitFeedback = function() {
        $.ajax({
            url: "https://jira.obeta.io/s/69470fc83699624f969fff391bdfb285-T/-o3kx9b/76016/b6b48b2829824b869586ac216d119363/2.0.27/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=de-DE&collectorId=05526186",
            type: "get",
            cache: true,
            dataType: "script"
        });

        // from jquery migrate

        jQuery.uaMatch = function( ua ) {
            ua = ua.toLowerCase();

            var match = /(chrome)[ \/]([\w.]+)/.exec( ua ) ||
                /(webkit)[ \/]([\w.]+)/.exec( ua ) ||
                /(opera)(?:.*version|)[ \/]([\w.]+)/.exec( ua ) ||
                /(msie) ([\w.]+)/.exec( ua ) ||
                ua.indexOf("compatible") < 0 && /(mozilla)(?:.*? rv:([\w.]+)|)/.exec( ua ) ||
                [];

            return {
                browser: match[ 1 ] || "",
                version: match[ 2 ] || "0"
            };
        };

// Don't clobber any existing jQuery.browser in case it's different
        if ( !jQuery.browser ) {
            matched = jQuery.uaMatch( navigator.userAgent );
            browser = {};

            if ( matched.browser ) {
                browser[ matched.browser ] = true;
                browser.version = matched.version;
            }

// Chrome is Webkit, but Webkit is also Safari.
            if ( browser.chrome ) {
                browser.webkit = true;
            } else if ( browser.webkit ) {
                browser.safari = true;
            }

            jQuery.browser = browser;
        }

        var username = $('#top-user-name').attr('data-customer'),
            email    = $('#top-user-name').attr('data-email');

        window.ATL_JQ_PAGE_PROPS = $.extend(window.ATL_JQ_PAGE_PROPS, {

        	fieldValues: {
         		fullname : username,
        		email : email
        	}
        });

    };

    return {
        showSubmitFeedback: showSubmitFeedback
    }
})();

module.exports = obeta.feedback;