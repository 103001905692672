

obeta.articleCalculation = (function () {

        var checkAmount = function (amount, minAmount) {
                // no 0 and no negative
                amount = parseInt(amount);
                minAmount = parseInt(minAmount);
                amount = amount < 1 ? minAmount : amount;
                amount = amount > 100000000 ? 100000000: amount;
                // round up if not multiple of minamount
                return amount > 0 && amount % minAmount === 0 ? amount : amount + minAmount - (amount % minAmount);
            },
            /**
             * Formats a Number
             * @param val
             * @param k : Number
             * @param fix {boolean}: fill up with 0
             * @returns {String}
             */
            formatNumber = function (val, k, fix) {
                if (!k) {
                    k = 0;
                }
                var res = '',
                    dec_point = ',',
                    thousands_sep = '.', // kann für tausender trennzeichen verwendet werden
                    f = Math.pow(10, k); // Round
                val = '' + parseInt(val * f + (.5 * (val > 0 ? 1 : -1))) / f;
                var idx = val.indexOf('.'); // Komma ermittlen
                if (fix)
                    val += (idx == -1 ? '.' : '') + f.toString().substring(1);
                var sign = val < 0;
                if (sign)
                    val = val.substring(1);
                idx = val.indexOf('.');
                // Nachkommastellen ermittlen
                if (idx == -1)
                    idx = val.length;
                else
                    res = dec_point + val.substr(idx + 1, k);
                while (idx > 0) {
                    if (idx - 3 > 0)
                        res = thousands_sep + val.substring(idx - 3, idx) + res;
                    else
                        res = val.substring(0, idx) + res;
                    idx -= 3;
                }
                return (sign ? '-' : '') + res;
            },
            updatePriceDisplay = function (params) {
                params.$this.val(params.checkedAmount);
                var $price = $('#price-addcart');
                $price.find('.main-price').html(obeta.articleCalculation.formatNumber(params.activePrice, 2, true));
                $price.find('.sell-price').text(obeta.articleCalculation.formatNumber(params.sellPrice, 2, true));
                $price.find('.metal-price').text(obeta.articleCalculation.formatNumber(params.metalPrice, 2, true));
                $price.find('.action-price-article-detail').html(obeta.articleCalculation.formatNumber(params.actionPrice, 2, true));
                $price.find('.add-article-link').attr('data-amount', params.checkedAmount);
                $('#select-template-dd-button .add-article-link').attr('data-amount', params.checkedAmount);
                $('#single-article-button .add-article-link').attr('data-amount', params.checkedAmount);
            },
            calcualteAndUpdatePrices = function($elem) {
                var minAmount = parseInt($elem.data('min-amount')),
                    amount = isNaN(parseInt($elem.val())) ? minAmount : parseInt($elem.val()),
                    checkedAmount = obeta.articleCalculation.checkAmount(amount, minAmount),
                    realAmount = checkedAmount / minAmount,
                    sellPrice = $('#sell-price-basic').val(),
                    metalPrice = $('#metal-price-basic').val() * realAmount,
                    actionPrice = $('#action-price-basic').val() * realAmount,
                    priceDimension = $('#price-dimension').val();

                var sellPrice = _computeActivePrice({
                        minAmount: minAmount,
                        priceDimension: priceDimension,
                        checkedAmount: checkedAmount,
                        sellPrice: sellPrice,
                        metalPrice: metalPrice / realAmount,
                        realAmount: realAmount
                    }),
                    activePrice = sellPrice + metalPrice;

                obeta.articleCalculation.updatePriceDisplay({
                    $this: $elem,
                    activePrice: activePrice,
                    sellPrice: sellPrice,
                    metalPrice: metalPrice,
                    actionPrice: actionPrice,
                    checkedAmount: checkedAmount
                });
                _updateRestDisplay(realAmount, activePrice);
            },
            _computeActivePrice = function (params) {
                var allPrices = [parseFloat(params.sellPrice, 10)];
                $('.js-scale-data').each(function (i, e) {
                    var scaleAmount = $(e).data('amount'),
                        scalePrice = $(e).data('price') / params.priceDimension * params.minAmount;
                    if (scalePrice && scaleAmount <= params.checkedAmount) {
                        allPrices.push(scalePrice);
                    }
                });
                return Math.min.apply(Math, allPrices) * params.realAmount;
            },
            _updateRestDisplay = function (amount, price) {
                var allHidden = true;
                $('.js-rest-data').each(function (count, e) {
                    var $elem = $(e),
                        restPrice = $elem.data('price'),
                        restAmount = $elem.data('amount'),
                        $restContainer = $elem.parents('.addcart'),
                        showRest = restPrice <= price;
                    if (!$('.main-price').length && $('#rest').length) {
                        showRest = restAmount <= amount;
                    }
                    if(showRest) {
                        if($('.addcart:visible').length < 10) {
                            $restContainer.show();
                        }
                    } else {
                        $restContainer.hide();
                    }
                    allHidden = allHidden && !showRest;
                });
                $('#article-more-infos').css('min-height', (64 * $('.addcart:visible').length ) + 'px')
                $('#rest').toggle(!allHidden);
            };

    return {
        checkAmount: checkAmount,
        formatNumber: formatNumber,
        updatePriceDisplay: updatePriceDisplay,
        calcualteAndUpdatePrices: calcualteAndUpdatePrices
    };
})();

module.exports = obeta.articleCalculation;
