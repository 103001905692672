
obeta.helpers = (function () {
    var ajax = function (params, successCallback, errorCallback, additionalParameters) {
        additionalParameters = $.extend(
                {
                    'modalMessageAfterShow': function () {
                    },
                    'showSpinner': true,
                    'hideSpinner': true
                },
                additionalParameters
                );
        additionalParameters.showSpinner && showSpinner();
        var request = $.ajax(params)
                .done(function (data) {

                    if (data.domPayload) {
                        $.each(data.domPayload, function (selector, value) {
                            var $target = $("[data-live-update='" + selector + "']");
                            $target.html(value);
                        });
                    }

                    if (data.status && data.status == 'success') {
                        isFunction(successCallback) && successCallback(data);
                    } else {
                        isFunction(errorCallback) && errorCallback(data);
                    }

                    if (data.location) {
                        window.location = data.location;
                    }

                    if (data.messages) {
                        $.each(data.messages, function (k, message) {
                            if (obeta.helpers.isMobilePageActive()) {
                                mobile.notifications.add(message.text);
                                mobile.notifications.show();
                            } else {
                                obeta.messages.showMessage(message.text, message.type);
                            }
                        });
                    }
                    if (data.modalMessages) {
                        messagecounter = 0;
                        popupcounter = 0;
                        if (obeta.helpers.isMobilePageActive()) {
                            $('#notification-container').unbind("popupafterclose");
                        }
                        $.each(data.modalMessages, function (k, message) {
                            if (obeta.helpers.isMobilePageActive()) {
                                if (messagecounter == 1) {
                                    $('#notification-container').bind({popupafterclose: function () {
                                            popupcounter = popupcounter + 1;
                                            if (popupcounter < 2) {
                                                setTimeout(function () {
                                                    mobile.notifications.add(message.text);
                                                    mobile.notifications.show();
                                                }, 100);
                                            }
                                        }
                                    })
                                } else {
                                    mobile.notifications.add(message.text);
                                    mobile.notifications.show();
                                    additionalParameters['modalMessageAfterShow']();
                                    messagecounter = messagecounter + 1;
                                }
                            } else {
                                obeta.messages.showModalMessage(message.text, message.type, additionalParameters['modalMessageAfterShow']);
                            }
                        });
                    }
                    if (obeta.helpers.isMobilePageActive()) {
                        $('#delivery-dates-select').selectmenu();
                    }
                })
                .fail(function () {
                    if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1 && ($('.shoppingcart-right .schritt1-red').is(':visible'))) {
                        // Do not show error message in Firefox und in shopping cart step 1
                    } else {
                        obeta.messages.showMessage('Es ist ein Request-Fehler aufgetreten.', obeta.messages.type.ERROR)
                    }
                });

        request.always(function () {
            additionalParameters.hideSpinner && hideSpinner();
        })
    }, initCharCounter = function () {
        if ($('#zusatztext').length > 0) {
            var $textObjCart = $('#shoppingcart-header').find('#zusatztext'),
                    $charleftObjCart = $('#charleft-shoppingcart'),
                    maxLengthCart = parseInt($textObjCart.attr('maxlength'));

            if ($textObjCart.val().length > 0) {
                $charleftObjCart.html(maxLengthCart - $textObjCart.val().length);
            }
            $textObjCart.keyup(function () {
                if ($(this).val().length > maxLengthCart) {
                    $(this).val($(this).val().substr(0, maxLengthCart));
                }
                $charleftObjCart.html(maxLengthCart - $(this).val().length);
            });
        }
    },
            isFunction = function (functionToCheck) {
                var getType = {};
                return functionToCheck && getType.toString.call(functionToCheck) === '[object Function]';
            },
            showSpinner = function () {

                var size = 49;

                if($('.ui-icon-xpress-logo').length){
                    size = 100;
                }

                var $window = $(window),
                            spinnerSizeInPx = size,

                        toppos = $window.scrollTop(),
                        height = $window.height(),
                        width = $window.width(),
                        posleft = width / 2 - (spinnerSizeInPx / 2),
                        postop = height / 2 - (spinnerSizeInPx / 2) + toppos;

                $('#spinner').css({position: "absolute", left: posleft, top: postop}).show();
            },
            hideSpinner = function () {
                $('#spinner').hide();
            },
            hideSpinnerOnPageshow = function () {
                $(window).bind("pageshow", function () {
                    obeta.helpers.hideSpinner();
                });
            },
            getCompany = function () {
                return $('.icn_obeta').data('company');
            },
            reload = function () {
                location.reload();
            },
            bindKeepAlivePolling = function () {
                $logoutBtn = $('#btn_logout');
                if ($logoutBtn.length) {
                    window.setInterval(function () {
                        obeta.helpers.ajax({
                            type: "GET",
                            url: $logoutBtn.data('keepalive-url'),
                            data: {}
                        }, function () {
                        }, function () {
                        },
                                {'showSpinner': false}

                        );
                    }, 23 * 60 * 1000); // 23 minutes
                }
            },
            bindKeepAlivePollingNewCustomer = function () {
                $wrapper = $('.page-new-customer-wrapper');
                if ($wrapper.length) {
                    window.setInterval(function () {
                        obeta.helpers.ajax({
                            type: "GET",
                            url: $wrapper.data('keepalive-url'),
                            data: {}
                        }, function () {
                        }, function () {
                        },
                                {'showSpinner': false}

                        );
                    }, 23 * 60 * 1000); // 23 minutes
                }
            },
            isMobilePageActive = function () {
                return $('html#mobile').length === 1;
            },
        checkPlzSpace = function () {
            var $postcode = $('#inputPlz');
            var $inputContainer = $('#deliveryEndTimes');
            $inputContainer.hide();
            var url = $postcode.attr('data-url');
            $postcode.select();

            $('#plzCheck').click(function () {

                if((parseInt($postcode.val()) == $postcode.val()) && ($postcode.val().length == 5)) {
                    var plz = $postcode.val();
                    $postcode.addClass('remove');
                    obeta.helpers.ajax({
                        url: url,
                        data: {postcode: plz},
                        type: "POST"
                    }, function (data) {
                        $inputContainer.val('');
                        $inputContainer.css('color', 'black');
                        $inputContainer.html(data.domPayload.deliveryEndTimes);
                        $inputContainer.show();
                        $postcode.val(data.domPayload.inputPlz);
                        $postcode.select();
                    });
                }
            });

            $postcode.on('input', function () {

                var $inputVal = $('#plzCheck .ui-btn');

                if((parseInt($postcode.val()) == $postcode.val()) && ($postcode.val().length == 5)){
                    $inputVal.css('background-color', 'black');
                }
                else if($postcode.val().length == 6){

                    if($postcode.hasClass('remove') && (parseInt($postcode.val()) == $postcode.val())){
                        $postcode.val($postcode.val().substr($postcode.val().length - 1, $postcode.val().length));
                        $postcode.removeClass('remove');
                        $inputVal.css('background-color', 'grey');
                        $inputContainer.text('');
                    }
                    else {
                        $postcode.val($postcode.val().substr(0, $postcode.val().length - 1));
                    }
                }
                else {
                    $inputVal.css('background-color', 'grey');

                    if(parseInt($postcode.val()) != $postcode.val()){
                        $postcode.val($postcode.val().substr(0, $postcode.val().length - 1));
                    }

                    if($postcode.val().length < 5){
                        $inputContainer.text('');
                    }
                }
                });

            $postcode.keypress(function (e) {

                if(e.which == 13) {

                    if ((parseInt($postcode.val()) == $postcode.val()) && ($postcode.val().length == 5)) {
                        var plz = $postcode.val();
                        $postcode.addClass('remove');
                        obeta.helpers.ajax({
                        url: url,
                        data: {postcode: plz},
                        type: "POST"
                    }, function (data) {
                        $inputContainer.css('color', 'black');
                        $inputContainer.html(data.domPayload.deliveryEndTimes);
                        $postcode.val(data.domPayload.inputPlz);
                        $postcode.select();
                        });
                    }
                }
            })
        },
        bindMobilePhoneNumber = function(){

            var currentState = '';

            $('#phoneNumber').on('input', function () {

                var pnumber = $(this).val();
                if((pnumber != parseInt(pnumber)) || (pnumber.length > 17)){

                    //show default text when no input is given
                    if(pnumber.length == 0){
                        $(this).attr('title');
                    }
                    else{
                        //remove last character when it's not a number
                        $(this).val(currentState);
                        pnumber = pnumber.substr(0, pnumber.length - 1);
                    }
                }

                currentState = pnumber;

            })
        },
        delayPriceAjaxLoader = function() {
                setTimeout(function () {
                    $('.priceAjaxLoader').each(function () {
                        $(this).css('visibility', 'visible');
                    });
                }, 750)
       }
    ;

    return {
        ajax: ajax,
        getCompany: getCompany,
        isFunction: isFunction,
        showSpinner: showSpinner,
        hideSpinner: hideSpinner,
        hideSpinnerOnPageshow: hideSpinnerOnPageshow,
        reload: reload,
        bindKeepAlivePolling: bindKeepAlivePolling,
        bindKeepAlivePollingNewCustomer: bindKeepAlivePollingNewCustomer,
        isMobilePageActive: isMobilePageActive,
        initCharCounter: initCharCounter,
        checkPlzSpace: checkPlzSpace,
        bindMobilePhoneNumber: bindMobilePhoneNumber,
        delayPriceAjaxLoader: delayPriceAjaxLoader
    };
})();

module.exports = obeta.helpers;