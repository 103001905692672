

obeta.formHelpers = (function () {
    var disableAddressFields = function () {
        $('#input-name1').attr('disabled', 'disabled');
        $('#input-name1').data('required', 'false');
        $('#input-name2').attr('disabled', 'disabled');
        $('#input-street').attr('disabled', 'disabled');
        $('#input-street').data('required', 'false');
        $('#input-postcode').attr('disabled', 'disabled');
        $('#input-postcode').data('required', 'false');
        $('#js-input-city').attr('disabled', 'disabled');
        $('#js-input-city').attr('required', 'false');
        $('.store-address-wrapper').hide();
        if (obeta.helpers.isMobilePageActive()) {
            $('#input-name1').textinput('enable');
            $('#input-name2').textinput('enable');
            $('#input-street').textinput('enable');
            $('#input-postcode').textinput('enable');
            $('#js-input-city').textinput('enable');
        }
        if ($('#shoppingcart-header').length > 0) {
            $('#shoppingcart-header').parsley('destroy');
        } else {
            $('#js-edit-project-form').parsley('destroy');
        }
    },
            enableAddressFields = function () {
                if ($('#shoppingcart-header').length > 0) {
                    $('#shoppingcart-header').parsley('destroy');
                } else {
                    $('#js-edit-project-form').parsley('destroy');
                }
                $('#input-name1').removeAttr('disabled');
                $('#input-name1').data('required', 'true');
                $('#input-name2').removeAttr('disabled');
                $('#input-street').removeAttr('disabled');
                $('#input-street').data('required', 'true');
                $('#input-postcode').removeAttr('disabled');
                $('#input-postcode').data('required', 'true');
                $('#js-input-city').removeAttr('disabled');
                $('#js-input-city').attr('required', 'true');
                $('.store-address-wrapper').show();
                if (obeta.helpers.isMobilePageActive()) {
                    $('#input-name1').textinput('enable');
                    $('#input-name2').textinput('enable');
                    $('#input-street').textinput('enable');
                    $('#input-postcode').textinput('enable');
                    $('#js-input-city').textinput('enable');
                }
                if ($('#shoppingcart-header').length > 0) {
                    $('#shoppingcart-header').parsley();
                } else {
                    $('#js-edit-project-form').parsley();
                }
            },
            hideAddressPicker = function () {
                $('.address-picker').hide();
            },
            showAddressPicker = function () {
                $('.address-picker').show();
            },
            hideAddressForm = function () {
                $('#address-form').hide();
            },
            bindSubmitOnEnter = function () {
                $('.js-submit-on-enter').keypress(function (event) {
                    if (event.which == 13) {
                        event.preventDefault();
                        if ($(this).attr('id') == "input-name1") {
                            $('#input-name2').focus();
                        }
                        else {
                            $(this).closest('form').submit();
                        }
                    }
                });
            },
            showAddressForm = function () {
                $('#address-form').show();
            },
            initCustomSelect = function () {
                //init plugin for simple selectboxes
                var $dropdown = $('.custom-dd');
                if ($dropdown.hasClass('dropdown-ie')) {
                    $('.custom-dd').customSelect();
                } else {
                    $('.custom-dd').selectBox();
                }

                //init custom checkboxes
                $('.custom-checkbox').checkbox();
            },
            initUfdDropdown = function () {
                //init plugin for selectbox with autocomplete
                $("#offer-dropdown").ufd();
            },
            fillAddressFields = function (address) {
                var $address = (typeof address == 'object') ? address :JSON.parse(address);

                $('#input-name1').val($address.name1);
                $('#input-name2').val($address.name2);
                $('#input-street').val($address.street);
                $('#input-postcode').val($address.postcode);
                $('#js-input-city').val($address.city);
                $('#address-form .default-text-active').removeClass('default-text-active');
            },
            bindRenderCustomRadioButtons = function () {
                $('input:radio').hide().each(function () {
                    var label = $("label[for=" + '"' + this.id + '"' + "]").text();
                    var disabledClass = '';
                    if ($(this).hasClass('disabled')) {
                        disabledClass = 'disabled ';
                    }
                    if (label == "lieferart") {
                        var addclass = " js-update-delivery-dates";
                    } else {
                        var addclass = "";
                    }
                    var newRadio = $('<span ' + (label != '' ? 'title=" ' + label + ' "' : '') + ' class="radio-fx ' + disabledClass + this.name + addclass+'"><span class="delivery-radio ' + disabledClass + (this.checked ? ' radio-checked' : 'radio') + '"></span></span>');
                    newRadio.data('get-datelist-url', $(this).data('get-datelist-url'));
                    newRadio.insertAfter(this);
                });

                $('.radio-fx').on('click', function (e) {
                    if ($(this).hasClass('disabled')) {
                        return;
                    }

                    var $check = $(this).prev('input:radio'),
                            $form = $check.parents('form'),
                            unique = '.' + this.className.split(' ')[1] + ' span';
                    $form.find(unique).not('.disabled').attr('class', 'radio');
                    $(this).find('span').attr('class', 'radio-checked');
                    $check.trigger('click');
                    $check.prop('checked', true);
                }).on('keydown', function (e) {
                    if ((e.keyCode ? e.keyCode : e.which) == 32) {
                        $(this).trigger('click');
                    }
                });
            },
            bindEmbeddedSearchInputCross = function () {
                $('#embedded-search-cross').on('click', function (e) {
                    $('#embedded-search-input').val("");
                    $('#js-embedded-search-form').trigger("submit");
                });
            },
            bindEmbeddedSearchSubmit = function () {
                $('#embedded-search-button').on('click', function (e) {
                    $('#embedded-search-input.default-text-active').val("");
                    $('#js-embedded-search-form').trigger("submit");
                })
            },
            showDeliveryOptions = function () {

                $('#delivery-dropdown-heading').html('Gewünschtes Lieferdatum:');

                $('#delivery-radio-div').animate({'height': 'show'}, {duration: 800});
                $('#address-form').show();
                $('#pickup-radio-div').animate({"height": "hide"}, {duration: 600});
                $("#self-collector-options").animate({"height": "hide"}, {duration: 600});
                $("#pickup-dd").animate({"height": "hide"}, {duration: 600});
                $("#sc-special-article-dropdown-message").animate({"height": "show"}, {duration: 600});
                $('#delivery-dropdown-heading').animate({'height': 'show'}, {duration: 600});
                $('#js-delivery-dates-dropdown').animate({'height': 'show'}, {duration: 600});

                $(".sc-shipping-options-wrapper").animate({"height": "show"}, {duration: 600});
                $("#delivery-options").animate({"height": "show"}, {duration: 600});

                if($('#delivery-options').find('.initialCheckedRadioType').length > 0) {
                    $('#delivery-options').find('.initialCheckedRadioType').prev().children().click();
                }
                else if ($('input[data-is-nightshift = "1"]').length > 0){
                    $('input[data-is-nightshift = "1"]').prev().children().click();
                    $('#stored-addresses-dropdown').attr('disabled', 'disabled');
                    $('#stored-addresses-dropdown-button').addClass('ui-state-disabled');
                }
                else{
                    $('#delivery-options').find('.ui-radio-on').first().prev().click();
                }

                if(shoppingCartHasOnlyOfficePositions()){
                    $(".sc-shipping-options-wrapper").hide();
                }
            },
            showSelfCollectorOptions = function () {

                $('#delivery-dropdown-heading').html('Gewünschtes Abholdatum:');

                $(".sc-shipping-options-wrapper").animate({"height": "show"}, {duration: 600});
                $("#pickup-radio-div").animate({"height": "show"}, {duration: 600});
                $("#pickup-dd").animate({"height": "show"}, {duration: 600});
                $("#self-collector-options").animate({"height": "show"}, {duration: 600});
                $('#delivery-radio-div').animate({'height': 'hide'}, {duration: 600});
                $('#delivery-options').animate({'height': 'hide'}, {duration: 600});
                $('#address-form').show();

                $('#js-radio-delivery-pickuptype').click();

                if(shoppingCartHasOnlyOfficePositions()){
                    $(".sc-shipping-options-wrapper").animate({"height": "hide"}, {duration: 600});
                }

                if(!scHasWarehousePositions()) {
                    $("#self-collector-options").hide();
                }
            },
            shoppingCartHasOnlyOfficePositions = function () {
                if($('#deliverytype').is(':visible') == false){
                    return true;
                }
                return false;
            },
            isPickUpDropdownVisible = function(){
                if($('#pickup-dd').is(':visible') == false){
                    return false;
                }
                return true;
            },
            isCashCustomer = function(){
                if($('#address-form').hasClass('cash-customer')){
                    return true;
                }
                return false;
            },
            scHasWarehousePositions = function(){
                if($('#delivery-options').hasClass('hasNoWarehousePositions')){
                    return false;
                }
                return true;
            },
            scHasSpecialOrDiversePositions = function(){
                if($('#sc-special-article-dropdown-message').is(':visible') == true){
                    return true;
                }
                return false;
            },
            isInitialCheckedDeliveryTypeTour = function(){
                var checkedType = $('.radio-div').find('.initialCheckedRadioType');

                if(typeof checkedType.attr('data-address') != 'undefined'){
                    if(checkedType.attr('data-address').length > 0){
                        return true;
                    }
                }
                return false;
            },
            lastSelectedOptionWasTour = function(){
                var deliveryForm = $('#delivery-options');

                if(deliveryForm.find('[data-is-nightshift="1"]').attr('data-address')) {

                    var $nighTourAddress = JSON.parse(deliveryForm.find('[data-is-nightshift="1"]').attr('data-address'));

                    if ($('#input-name1').val() == $nighTourAddress.name1 && $('#input-name2').val() == $nighTourAddress.name2 && $('#input-street').val() == $nighTourAddress.street && $('#input-postcode').val() == $nighTourAddress.postcode && $('#js-input-city').val() == $nighTourAddress.city) {
                        return true;
                    }
                }
                return false;
            }
        ;

    return {
        disableAddressFields: disableAddressFields,
        enableAddressFields: enableAddressFields,
        hideAddressPicker: hideAddressPicker,
        showAddressPicker: showAddressPicker,
        hideAddressForm: hideAddressForm,
        showAddressForm: showAddressForm,
        fillAddressFields: fillAddressFields,
        initCustomSelect: initCustomSelect,
        initUfdDropdown: initUfdDropdown,
        bindRenderCustomRadioButtons: bindRenderCustomRadioButtons,
        bindSubmitOnEnter: bindSubmitOnEnter,
        bindEmbeddedSearchInputCross: bindEmbeddedSearchInputCross,
        bindEmbeddedSearchSubmit: bindEmbeddedSearchSubmit,
        showDeliveryOptions: showDeliveryOptions,
        showSelfCollectorOptions: showSelfCollectorOptions,
        shoppingCartHasOnlyOfficePositions: shoppingCartHasOnlyOfficePositions,
        isPickUpDropdownVisible: isPickUpDropdownVisible,
        isCashCustomer: isCashCustomer,
        scHasWarehousePositions: scHasWarehousePositions,
        scHasSpecialOrDiversePositions: scHasSpecialOrDiversePositions,
        isInitialCheckedDeliveryTypeTour: isInitialCheckedDeliveryTypeTour,
        lastSelectedOptionWasTour: lastSelectedOptionWasTour
    }
})();

module.exports = obeta.formHelpers;