

obeta.homepage = (function () {
    var showOverlayLeft = function () {
            $('#bottom-teaser-left').on('mouseover', function (e) {
                e.preventDefault();
                $(this).find('.bottom-teaser-title').hide();
                $('#overlay-left').css('display', 'block');
            });
        },
        clearOverlays = function () {
            $('.top-teaser-news-text').attr('active', 0);
            $('.top-teaser-news-text').removeClass('show-overlay');
            $('.top-teaser-news-text').addClass('hide-overlay');
            $('.overlay-text').attr('active', 0);
            $('.overlay-text').removeClass('show-overlay');
            $('.overlay-text').addClass('hide-overlay');
        },
        bindOverlayIphone = function () {
            $('#bottom-teaser-left').on('touchstart', function (e) {
                e.preventDefault();
                clearOverlays();
                var teaserisred = $('#bottom-teaser-left').attr('active');
                if (teaserisred == 1) {
                    $('#bottom-teaser-left').attr('active', 0);
                    var url = $('#btl-link').attr('href');
                    window.location.href = url;
                } else {
                    $('#overlay-right').css('display', 'none');
                    $(this).find('.bottom-teaser-title').show();
                    $(this).find('.overlay-text').show();
                    $('#bottom-teaser-left').attr('active', 1);
                    $('#bottom-teaser-right').attr('active', 0);
                    $('#overlay-left').css('display', 'block');
                }
            });
            $('#bottom-teaser-right').on('touchstart', function (e) {
                e.preventDefault();
                clearOverlays();
                var teaserisred = $('#bottom-teaser-right').attr('active');
                if (teaserisred == 1) {
                    $('#bottom-teaser-right').attr('active', 0);
                    var url = $('#btr-link').attr('href');
                    window.location.href = url;
                } else {
                    $('#overlay-left').css('display', 'none');
                    $(this).find('.bottom-teaser-title').show();
                    $(this).find('.overlay-text').show();
                    $('#bottom-teaser-right').attr('active', 1);
                    $('#bottom-teaser-left').attr('active', 0);
                    $('#overlay-right').css('display', 'block');
                }
            });

            $('#top-teaser-carousel').find('li').on('touchstart', function (e) {
                e.preventDefault();
                var $liObj = $(this);
                var $this = $(this).find('a').find('div');
                var isactive = $this.attr('active');
                $('#overlay-left').css('display', 'none');
                $('#overlay-right').css('display', 'none');
                $('#bottom-teaser-right').attr('active', 0);
                $('#bottom-teaser-left').attr('active', 0);
                if (isactive == 1) {
                    clearOverlays();
                    $this.attr('active', 0);
                    var url = $(this).find('a').attr('href');
                    if (url.length > 0) {
                        window.location.href = url;
                    }
                } else {
                    clearOverlays();
                    $this.attr('active', 1);
                    $this.removeClass('hide-overlay');
                    $this.addClass('show-overlay');
                    $liObj.find('.top-teaser-title').show();
                }
            });
        },
        showOverlayRight = function () {
            $('#bottom-teaser-right').on('mouseover', function (e) {
                e.preventDefault();
                $(this).find('.bottom-teaser-title').hide();
                $('#overlay-right').css('display', 'block');
            });
        },
        hideOverlayLeft = function () {
            $('#bottom-teaser-left').on('mouseout', function (e) {
                e.preventDefault();
                $('#overlay-left').css('display', 'none');
                $(this).find('.bottom-teaser-title').show();
            });
        },
        hideOverlayRight = function () {
            $('#bottom-teaser-right').on('mouseout', function (e) {
                e.preventDefault();
                $('#overlay-right').css('display', 'none');
                $(this).find('.bottom-teaser-title').show();
            });
        },
        bindDeactivatedLinks = function () {
            $('a.deactivated').on('click', function (e) {
                e.preventDefault();
            })
        },
        bindQRCodeLinkContact = function () {
            $('#contact-qrcode-link').on('click', function (e) {
                e.preventDefault();
                var $qrcodeImage = $('#contact-qr-code-image');
                if ($qrcodeImage.css('display') == 'none') {
                    $qrcodeImage.css('display', 'block');
                } else if ($qrcodeImage.css('display') == 'block') {
                    $qrcodeImage.css('display', 'none');
                }
            })
        },
        setDefaultTextForInput = function () {
            $(".default-text").on('blur focus', function (e) {
                setDefaultText($(this), e.type);
            }).each(function (k, e) {
                setDefaultText($(e), 'blur');
            });
        },
        setDefaultText = function ($elem, event) {
            if (($elem.val() == "" || $elem.val() == $elem[0].title) && event == 'blur') {
                $elem.addClass("default-text-active");
                $elem.val($elem[0].title);
            } else if ($elem.val() != $elem[0].title) {
                $elem.removeClass("default-text-active");
            } else if (event == 'focus' && $elem.hasClass('default-text-active')) {
                $elem.removeClass("default-text-active").val('').select();
            }
        },
        topTeaserOverlay = function () {
            $('#top-teaser-carousel').find('li').on('mouseover', function (e) {
                var $liObj = $(this);

                var $this = $(this).find('a').find('div');
                // $this.css('width', liWidth);
                $this.removeClass('hide-overlay');
                $this.addClass('show-overlay');
                $liObj.find('.top-teaser-title').hide();
            });

            $('#top-teaser-carousel').find('li').on('mouseout', function (e) {
                var $liObj = $(this);
                var $this = $(this).find('a').find('div');
                $this.removeClass('show-overlay');
                $this.addClass('hide-overlay');
                $liObj.find('.top-teaser-title').show();
            });
        },
        bannerPosition = function () {
            var
                offset = $('#main-wrapper').offset(),
                screenwidth = $(window).width(),
                posleft = offset.left,
                posright = posleft + 1220;

            if (document.querySelector('.temporaryBg')) {
                var
                    bannerWrapper = document.querySelectorAll('.temporaryBg');

                if (screenwidth > 1637) {
                    bannerWrapper[0].style.setProperty("background-position", "0px 0px," + posright + "px 0px", "important");
                    bannerWrapper[0].style.setProperty("background-size", posleft + "px auto", "important");
                    $('.temporaryBg').removeClass('dontshow');
                } else {
                    bannerWrapper[0].style.setProperty("background-position", "0px 0px," + posright + "px 0px", "important");
                    bannerWrapper[0].style.setProperty("background-size", posleft + "px auto", "important");
                    $('.temporaryBg').removeClass('dontshow');
                }
            }

            if (document.querySelector('.secondTemporaryBg')) {
                var
                    secondBannerWrapper = document.querySelectorAll('.secondTemporaryBg');

                if (screenwidth > 1637) {
                    secondBannerWrapper[0].style.setProperty("background-position", "0px 0px," + posright + "px 0px", "important");
                    secondBannerWrapper[0].style.setProperty("background-size", posleft + "px auto", "important");
                    $('.secondTemporaryBg').removeClass('dontshow');
                } else {
                    secondBannerWrapper[0].style.setProperty("background-position", "0px 0px," + posright + "px 0px", "important");
                    secondBannerWrapper[0].style.setProperty("background-size", posleft + "px auto", "important");
                    $('.secondTemporaryBg').removeClass('dontshow');
                }
            }
        },
        bindExtrasMessageBeforeLogin = function () {
            $('#extras-link-footer').on('click', function (e) {
                e.preventDefault();
                var url = $(this).attr('href'),
                    loggedIn = $(this).data('loggedin');
                if (loggedIn != '' && loggedIn == 1) {
                    window.location.href = url;
                } else {
                    var quickyShowMessageBeforeLogin = {
                        containerId: 'js-show-extras-message-before-login',
                        callbacks: [function () {}]
                    };
                    obeta.messages.showConfirmation(quickyShowMessageBeforeLogin);
                }
            });
        },
        bindCallBackForm = function () {
            $('.callback-button').on('click', function (e) {
                e.preventDefault();

                var container = $('#js-show-callback-form-message'),
                    callbackUrl = $('#callback-service-send-mail-url').val();

                var $inputName = $('#callback-field-name'),
                    $inputPhone = $('#callback-field-phone'),
                    $inputEmail = $('#callback-field-email'),
                    $subjectSelect = $('#callback-form-select-subject');
                var viaPhone = 1,
                    viaEmail = 0;

                $.fancybox({
                    autoResize: true,
                    helpers: {
                        overlay: {
                            closeClick: false
                        }
                    },
                    content: container,
                    afterShow: function () {
                        if (!container.data('initialized')) {
                            container.data('initialized', true);

                            $('#js-show-callback-form-message #confirmation-0').on('click', function () {
                                $('.fancybox-close').trigger('click');
                            });
                            $('#js-show-callback-form-message #confirmation-1').on('click', function (e) {
                                e.preventDefault();

                                if ($('#callback-via-phone').find('b').hasClass('radButSel')) {
                                    viaPhone = 1;
                                } else {
                                    viaPhone = 0;
                                }

                                if ($('#callback-via-email').find('b').hasClass('radButSel')) {
                                    viaEmail = 1;
                                } else {
                                    viaEmail = 0;
                                }

                                if ($inputName.val() == $inputName.attr('title')) {
                                    $inputName.val('');
                                }
                                $inputName.parsley('validate');

                                if (viaPhone == 1) {
                                    if ($inputPhone.val() == $inputPhone.attr('title')) {
                                        $inputPhone.val('');
                                    }
                                    if ($inputEmail.hasClass('parsley-error')) {
                                        $inputEmail.removeClass('parsley-error');
                                        $inputEmail.addClass('parsley-success');
                                        $inputEmail.next('.parsley-error-list').css('display', 'none');
                                    }
                                    $inputPhone.parsley('validate');
                                }
                                if (viaEmail == 1) {
                                    if ($inputEmail.val() == $inputEmail.attr('title')) {
                                        $inputEmail.val('');
                                    }
                                    if ($inputPhone.hasClass('parsley-error')) {
                                        $inputPhone.removeClass('parsley-error');
                                        $inputPhone.addClass('parsley-success');
                                        $inputPhone.next('.parsley-error-list').css('display', 'none');
                                    }
                                    $inputEmail.parsley('validate');
                                }
                                $subjectSelect.parsley('validate');
                                if ($('#callback-service-form .parsley-error').length > 0) {
                                    $('#callback-service-form .parsley-error').first().focus();
                                    return;
                                }
                                var dataToSend = {
                                    'viaPhone': viaPhone,
                                    'viaEmail': viaEmail,
                                    'name': $inputName.val(),
                                    'phone': $inputPhone.val(),
                                    'email': $inputEmail.val(),
                                    'subjectId': $subjectSelect.find(':selected').val(),
                                    'content': $('#callback-message-content').val(),
                                    'shopUrl': $('#callback-service-shop-current-url').val()
                                }
                                callbackService = callbackUrl && obeta.helpers.ajax({
                                        type: "POST",
                                        url: callbackUrl,
                                        data: dataToSend
                                    },
                                    function () {
                                        /**------------------------------------------------------------------------------ */
                                        //setTimeout(function() {
                                        var showCallbackConfirmation = {
                                            containerId: 'js-show-callback-confirm-message',
                                            callbacks: [function () {}]
                                        };
                                        obeta.messages.showConfirmation(showCallbackConfirmation);
                                        //}, 100);
                                        /**------------------------------------------------------------------------------ */
                                    }
                                );
                                $('.fancybox-close').trigger('click');
                            });
                        }
                    } // end after show
                });
            });

            $('#callback-via-phone').on('click', function (e) {
                var $this = $(this),
                    $inputPhone = $('#callback-field-phone'),
                    $inputEmail = $('#callback-field-email');
                if ($this.find('b').hasClass('radBut')) {
                    $this.find('b').removeClass('radBut');
                    $this.find('b').addClass('radButSel');
                }
                if ($('#callback-via-email > b').hasClass('radButSel')) {
                    $('#callback-via-email > b').removeClass('radButSel');
                    $('#callback-via-email > b').addClass('radBut');
                }
                $('#callback-via-email').find('b').addClass('radBut');
                $('#callback-field-phone').attr('data-required', 'true');
                $('#callback-field-email').attr('data-required', 'false');
                $('#callback-field-email').attr('data-type', 'text');
                $('.callback-form-phone-required').html('*');
                $('.callback-form-email-required').html('');
                if ($inputEmail.hasClass('parsley-error')) {
                    $inputEmail.removeClass('parsley-error');
                    $inputEmail.addClass('parsley-success');
                    $inputEmail.next('.parsley-error-list').css('display', 'none');
                    $inputEmail.val($inputEmail.attr('title'));
                }
                if ($inputPhone.val() == $inputPhone.attr('title') && $inputPhone.hasClass('parsley-success')) {
                    $inputPhone.removeClass('parsley-success');
                    $inputPhone.addClass('parsley-error');
                    if ($inputPhone.next('.parsley-error-list').css('display') == 'none') {
                        $inputPhone.next('.parsley-error-list').css('display', 'block');
                    }
                }
            });

            $('#callback-via-email').on('click', function (e) {
                var $this = $(this),
                    $inputPhone = $('#callback-field-phone'),
                    $inputEmail = $('#callback-field-email');
                if ($this.find('b').hasClass('radBut')) {
                    $this.find('b').removeClass('radBut');
                    $this.find('b').addClass('radButSel');
                }
                if ($('#callback-via-phone > b').hasClass('radButSel')) {
                    $('#callback-via-phone > b').removeClass('radButSel');
                }
                $('#callback-via-phone > b').addClass('radBut');
                $('#callback-field-phone').attr('data-required', 'false');
                $('#callback-field-email').attr('data-required', 'true');
                $('#callback-field-email').attr('data-type', 'email');
                $('.callback-form-phone-required').html('');
                $('.callback-form-email-required').html('*');
                if ($inputPhone.hasClass('parsley-error')) {
                    $inputPhone.removeClass('parsley-error');
                    $inputPhone.addClass('parsley-success');
                    $inputPhone.next('.parsley-error-list').css('display', 'none');
                    $inputPhone.val($inputPhone.attr('title'));
                }
                if ($inputEmail.val() == $inputEmail.attr('title') && $inputEmail.hasClass('parsley-success')) {
                    $inputEmail.removeClass('parsley-success');
                    $inputEmail.addClass('parsley-error');
                    if ($inputEmail.next('.parsley-error-list').css('display') == 'none') {
                        $inputEmail.next('.parsley-error-list').css('display', 'block');
                    }
                }
            });
        };

    return {
        showOverlayLeft: showOverlayLeft,
        showOverlayRight: showOverlayRight,
        hideOverlayLeft: hideOverlayLeft,
        hideOverlayRight: hideOverlayRight,
        bindDeactivatedLinks: bindDeactivatedLinks,
        setDefaultTextForInput: setDefaultTextForInput,
        topTeaserOverlay: topTeaserOverlay,
        setDefaultText: setDefaultText,
        bindExtrasMessageBeforeLogin: bindExtrasMessageBeforeLogin,
        bindQRCodeLinkContact: bindQRCodeLinkContact,
        bindCallBackForm: bindCallBackForm,
        bindOverlayIphone: bindOverlayIphone,
        bannerPosition: bannerPosition,
    }
})();

module.exports = obeta.homepage;