obeta.socketMessageHandler = (function () {

    let timeoutId = null;
    let messageOrderCountDownTime = 120;
    let messageConstructionDocumentationCountDownTime = 360;

    let initOrderView = function () {
        startShowMessageCountdown(messageOrderCountDownTime, 'order');
        obeta.orderViewHandler.enterLoadingState();
    };

    let initConstructionDocumentationView = function () {
        startShowMessageCountdown(messageConstructionDocumentationCountDownTime, 'constructionDocumentation');
        obeta.constructionDocumentationViewHandler.enterLoadingState();
    };

    let handleOrderChannelMessage = function (message) {
        if (message.event === 'OrderCreated') {
            obeta.orderViewHandler.enterSuccessState(message);
        } else {
            obeta.orderViewHandler.enterFailureState(message);
        }
        _clearMessageCountdown();
        timeoutId = null;
    };

    let handleConstructionDocumentationChannelMessage = function(message) {
        if (message.event === 'ConstructionDocumentationCreated') {
            obeta.constructionDocumentationViewHandler.enterSuccessState(message);
        } else {
            obeta.constructionDocumentationViewHandler.enterFailureState(message);
        }
        _clearMessageCountdown();
        timeoutId = null;
    }

    let startShowMessageCountdown = function (time, serviceType) {
        if (time) {
            obeta.asyncOrderTimeoutManager.startTimeout(time, serviceType);
        } else {
            if (!obeta.asyncOrderTimeoutManager.isTimeOutSet()) {
                return;
            }
        }
        if (obeta.asyncOrderTimeoutManager.isTimeOutExpired()) {
            _clearMessageCountdown();
            return;
        }
        _handleTimeoutView();
    };

    let _handleTimeoutView = function () {
        if (obeta.asyncOrderTimeoutManager.getType() === 'order') {
            obeta.orderViewHandler.enterLoadingState();
            _clearMessageCountdown();
            timeoutId = setTimeout(obeta.orderViewHandler.showTimeoutError, obeta.asyncOrderTimeoutManager.getTimeOutDifference());
        } else {
            obeta.constructionDocumentationViewHandler.enterLoadingState();
            _clearMessageCountdown();
            timeoutId = setTimeout(obeta.constructionDocumentationViewHandler.showTimeoutError, obeta.asyncOrderTimeoutManager.getTimeOutDifference());
        }
    };

    let _clearMessageCountdown = function () {
        if (timeoutId) {
            clearTimeout(timeoutId);
            obeta.asyncOrderTimeoutManager.removeTimeOut();
        }
    };

    return {
        initOrderView: initOrderView,
        initConstructionDocumentationView: initConstructionDocumentationView,
        handleOrderChannelMessage: handleOrderChannelMessage,
        handleConstructionDocumentationChannelMessage: handleConstructionDocumentationChannelMessage,
        startShowMessageCountdown: startShowMessageCountdown
    };
})();

module.exports = obeta.socketMessageHandler;