
var Cookies = require('js-cookie');

module.exports = (function () {
    return {
        setClickAndCollectStoreId: function(storeId) {
            Cookies.set(window.jsEnvironment.clickAndCollectStoreIdCookieName, storeId);
        },
        getClickAndCollectStoreId: function() {
            return Cookies.get(window.jsEnvironment.clickAndCollectStoreIdCookieName);
        }
    };
})();
