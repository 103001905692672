/**
 * Created with JetBrains PhpStorm.
 * User: t.le
 * Date: 21.08.13
 * Time: 14:55
 * To change this template use File | Settings | File Templates.
 */


/*jslint unparam: true */
/*global window, $ */

obeta.upload = (function() {
    var initFileUpload = function() {
        var url  = $('#pdfupload').data('url'),
            url1 = $('#pdfupload1').data('url'),
            url2 = $('#pdfupload2').data('url'),
            url3 = $('#pdfupload3').data('url'),
            pageUrl = $('#obeta-page-url').val(),
            logosizeurl = $('#get-logosize-url').val();

        $('#fileupload').fileupload({
            limitConcurrentUploads: 1,
            limitMultiFileUploads: 1,
            maxNumberOfFiles: 1,
            dataType: 'json',

            add: function(e, data) {
                var goUpload = true;
                var uploadFile = data.files[0];
                if (!(/\.(gif|jpg|jpeg|tiff|png)$/i).test(uploadFile.name)) {
                    alert('Sie dürfen nur eine Bilddatei (*.png, *.gif, *.jpg, *.jpeg) auswählen.');
                    goUpload = false;
                }
                if (uploadFile.size > 1000000) {
                    alert('Bitte laden Sie ein kleineres Bild mit max. Größe 1 MB und max. Breite und Höhe von 1024x1024 px hoch.');
                    goUpload = false;
                }
                if (goUpload == true) {
                    data.submit();
                }
            },

            done: function (e, data) {
                $.each(data.result.files, function (index, file) {
                    var logoUrl = file.url,
                        logoThumbnail = file.thumbnailUrl,
                        w = 220, h = 95, imgLogoHtml = '';

                    if (logoUrl != '') {
                        // if there is only original uploaded image (thumbnail is not generated)
                        if (typeof(logoThumbnail) == 'undefined') {
                            $('#dataSheetLogoUrl').val(logoUrl);
                            var sendData = {
                                'width': w,
                                'height': h,
                                'fileurl': logoUrl
                            }
                            sendLogoUrlAndImageSize = logosizeurl && obeta.helpers.ajax({
                                    type: "POST",
                                    url: logosizeurl,
                                    data: sendData
                                },
                                function(data) {
                                    w = data.data.width;
                                    h = data.data.height;
                                    imgLogoHtml = '<img width="' + w + '" height="' + h + '" src="/' + logoUrl + '" />';
                                    $('#settings-logo').html(imgLogoHtml);
                                },
                                function() {
                                    imgLogoHtml = '<img width="220" height="96" src="/' + logoUrl + '" />';
                                    $('#settings-logo').html(imgLogoHtml);
                                }
                            );
                        } else { // if thumbnail exists then we use thumbnail url
                            $('#dataSheetLogoUrl').val(logoThumbnail);
                            $('#dataSheetLogoThumnail').val(logoThumbnail);
                            var sendData = {
                                'width': w,
                                'height': h,
                                'fileurl': logoThumbnail
                            }
                            sendLogoUrlAndImageSize = logosizeurl && obeta.helpers.ajax({
                                    type: "POST",
                                    url: logosizeurl,
                                    data: sendData
                                },
                                function(data) {
                                    w = data.data.width;
                                    h = data.data.height;
                                    imgLogoHtml = '<img width="' + w + '" height="' + h + '" src="/' + logoThumbnail + '" />';
                                    $('#settings-logo').html(imgLogoHtml);
                                },
                                function() {
                                    imgLogoHtml = '<img width="220" height="96" src="/' + logoThumbnail + '" />';
                                    $('#settings-logo').html(imgLogoHtml);
                                }
                            );
                        }
                    }
                    $('#filesContent').html(file.name);
                });
            },
            fail: function() {
                alert('Hochladen ist fehlgeschlagen!');
            }
        }).prop('disabled', !$.support.fileInput).parent().addClass($.support.fileInput ? undefined : 'disabled');


        /**************************************************************************************************************/
        $('#pdfupload1').fileupload({
            url: url1,
            dataType: 'json',

            add: function(e, data) {
                var goUpload = true;
                var uploadFile = data.files[0];
                if (!(/\.(pdf)$/i).test(uploadFile.name)) {
                    alert('Sie dürfen nur Dateien im Format (*.pdf) auswählen.');
                    goUpload = false;
                }
                if (uploadFile.size > 5000000) {
                    alert('Bitte laden Sie nur Dateien mit einer max. Größe von 5 MB hoch.');
                    goUpload = false;
                }
                if (goUpload == true) {
                    data.submit();
                }

            },

            done: function(e, data) {
                $.each(data.result.files, function (index, file) {
                    var docuUrl = file.url;
                    if (docuUrl == 'error') {
                        alert('Hochladen ist fehlgeschlagen!');
                    } else {
                        $('#page-nc-upload-button-1').css('display', 'none');
                        $('#doc-require1-file-name').html(file.name);
                        $('#page-nc-doc-require1').find('b').removeClass('radio-normal');
                        $('#page-nc-doc-require1').find('b').addClass('radio-check-selected');
                        $('#upload-pdf-inner-1').css('display', 'block');
                        $('#button-view-doc-1').attr('href', pageUrl + '/' + docuUrl);
                        $('#tradeRegisterDocumentsUrl').val(docuUrl);
                    }
                });
            },
            fail: function() {
                alert('Hochladen ist fehlgeschlagen!');
            }
        }).prop('disabled', !$.support.fileInput).parent().addClass($.support.fileInput ? undefined : 'disabled');

        $('#button-delete-doc-1').on('click', function(e) {
            e.preventDefault();
            var delUrl = $(this).attr('href'),
                docuUrl = $('#tradeRegisterDocumentsUrl').val();
            if (docuUrl != '') {
                deleteFileRequest = delUrl && $.ajax({
                    type: "POST",
                    url: delUrl,
                    data: {'fileName' : docuUrl}
                })
                .done(function(data) {
                    $('#page-nc-doc-require1').find('b').removeClass('radio-check-selected');
                    $('#upload-pdf-inner-1').css('display', 'none');
                    $('#page-nc-upload-button-1').css('display', 'block');
                    $('#tradeRegisterDocumentsUrl').val('');
                    if (data.status == 'error') {
                        alert('Es ist ein Fehler aufgetreten! Bitte aktualisieren Sie die Seite erneut und versuchen Sie es noch einmal.');
                    }
                })
                .fail(function() {
                    alert('Es ist ein Fehler aufgetreten! Bitte aktualisieren Sie die Seite erneut und versuchen Sie es noch einmal.')
                })
            }
        });

        /**************************************************************************************************************/
        $('#pdfupload2').fileupload({
            url: url2,
            dataType: 'json',

            add: function(e, data) {
                var goUpload = true;
                var uploadFile = data.files[0];
                if (!(/\.(pdf)$/i).test(uploadFile.name)) {
                    alert('Sie dürfen nur Dateien im Format (*.pdf) auswählen.');
                    goUpload = false;
                }
                if (uploadFile.size > 5000000) {
                    alert('Bitte laden Sie nur Dateien mit einer max. Größe von 5 MB hoch.');
                    goUpload = false;
                }
                if (goUpload == true) {
                    data.submit();
                }
            },

            done: function(e, data) {
                $.each(data.result.files, function (index, file) {
                    var docuUrl = file.url;
                    if (docuUrl == 'error') {
                        alert('Das Hochladen ist fehlgeschlagen!');
                    } else {
                        $('#page-nc-upload-button-2').css('display', 'none');
                        $('#doc-require2-file-name').html(file.name);
                        $('#page-nc-doc-require2').find('b').removeClass('radio-normal');
                        $('#page-nc-doc-require2').find('b').addClass('radio-check-selected');
                        $('#upload-pdf-inner-2').css('display', 'block');
                        $('#button-view-doc-2').attr('href', pageUrl + '/' + docuUrl);
                        $('#bankAndTaxNumberUrl').val(docuUrl);
                    }
                });
            },
            fail: function() {
                alert('Das Hochladen ist fehlgeschlagen!');
            }
        }).prop('disabled', !$.support.fileInput).parent().addClass($.support.fileInput ? undefined : 'disabled');

        $('#button-delete-doc-2').on('click', function(e) {
            e.preventDefault();
            var delUrl = $(this).attr('href'),
                docuUrl = $('#bankAndTaxNumberUrl').val();
            if (docuUrl != '') {
                deleteFileRequest = delUrl && $.ajax({
                    type: "POST",
                    url: delUrl,
                    data: {'fileName' : docuUrl}
                })
                .done(function(data) {
                    $('#page-nc-doc-require2').find('b').removeClass('radio-check-selected');
                    $('#upload-pdf-inner-2').css('display', 'none');
                    $('#page-nc-upload-button-2').css('display', 'block');
                    $('#bankAndTaxNumberUrl').val('');
                    if (data.status == 'error') {
                        alert('Es ist ein Fehler aufgetreten! Bitte aktualisieren Sie die Seite erneut und versuchen Sie es noch einmal.');
                    }
                })
                .fail(function() {
                    alert('Es ist ein Fehler aufgetreten! Bitte aktualisieren Sie die Seite erneut und versuchen Sie es noch einmal.');
                })
            }
        });

        /**************************************************************************************************************/
        $('#pdfupload3').fileupload({
            url: url3,
            dataType: 'json',

            add: function(e, data) {
                var goUpload = true;
                var uploadFile = data.files[0];
                if (!(/\.(pdf)$/i).test(uploadFile.name)) {
                    alert('Sie dürfen nur Dateien im Format (*.pdf) auswählen.');
                    goUpload = false;
                }
                if (uploadFile.size > 5000000) {
                    alert('Bitte laden Sie nur Dateien mit einer max. Größe von 5 MB hoch.');
                    goUpload = false;
                }
                if (goUpload == true) {
                    data.submit();
                }
            },

            done: function(e, data) {
                $.each(data.result.files, function (index, file) {
                    var docuUrl = file.url;
                    if (docuUrl == 'error') {
                        alert('Das Hochladen ist fehlgeschlagen!');
                    } else {
                        $('#page-nc-upload-button-3').css('display', 'none');
                        $('#doc-require3-file-name').html(file.name);
                        $('#page-nc-doc-require3').find('b').removeClass('radio-normal');
                        $('#page-nc-doc-require3').find('b').addClass('radio-check-selected');
                        $('#upload-pdf-inner-3').css('display', 'block');
                        $('#button-view-doc-3').attr('href', pageUrl + '/' + docuUrl);
                        $('#masterLetterUrl').val(docuUrl);
                    }
                });
            },
            fail: function() {
                alert('Das Hochladen ist fehlgeschlagen!');
            }
        }).prop('disabled', !$.support.fileInput).parent().addClass($.support.fileInput ? undefined : 'disabled');

        $('#button-delete-doc-3').on('click', function(e) {
            e.preventDefault();
            var delUrl = $(this).attr('href'),
                docuUrl = $('#masterLetterUrl').val();
            if (docuUrl != '') {
                deleteFileRequest = delUrl && $.ajax({
                    type: "POST",
                    url: delUrl,
                    data: {'fileName' : docuUrl}
                })
                .done(function(data) {
                    $('#page-nc-doc-require3').find('b').removeClass('radio-check-selected');
                    $('#upload-pdf-inner-3').css('display', 'none');
                    $('#page-nc-upload-button-3').css('display', 'block');
                    $('#masterLetterUrl').val('');
                    if (data.status == 'error') {
                        alert('Es ist ein Fehler aufgetreten! Bitte aktualisieren Sie die Seite erneut und versuchen Sie es noch einmal.');
                    }
                })
                .fail(function() {
                    alert('Es ist ein Fehler aufgetreten! Bitte aktualisieren Sie die Seite erneut und versuchen Sie es noch einmal.');
                })
            }
        });

    }

    return {
        initFileUpload: initFileUpload
    };
})();

module.exports = obeta.upload;
