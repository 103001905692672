module.exports = {
    scrollToBottom: function() {
        $('html, body').animate({ scrollTop: $(document).height() }, 'fast');
        $('#notification-button').fadeOut(200);
    },

    // Interframe Communication Methods
    // ================================

    setIFrameHeight: function (payload) {
        $('iframe').height(payload.height)
    },

    getLegacyAuthToken: function () {
        return $.ajax({
            url: "/user/legacyToken",
            type: "GET",
        })
    },

    getVerticalScrollPosition: function () {
        return {
            scrollPosition: window.scrollY
        };
    },
    setNotificationButton: function () {
        let mainWrapperMarginLeft = ($('#main-wrapper').outerWidth(true) - $('#main-wrapper').innerWidth())/2;
        let mainWrapper = $('#main-wrapper').innerWidth();
        let margin = 0

        if (mainWrapperMarginLeft > 170) {
            margin = mainWrapperMarginLeft +mainWrapper +  + 10
        } else {
            margin = mainWrapperMarginLeft +mainWrapper - 170 - 28
        }


        $('#notification-button').css('left', margin+'px')
        $('#notification-button').fadeIn(200)
    },
    removeNotificationButton: function () {
        $('#notification-button').fadeOut(200);
    },

    sendOrderDataToNextShop: function(data) {
        if (!data.requestId || !data.responsePayload) {
            return;
        }
        console.log('send to next shop')
        $('#shop-next-iframe')[0].contentWindow.postMessage(data, '*')
    },

    //
    init: function () {

        var _sendDataToNextShop = function(data) {
            $('#shop-next-iframe')[0].contentWindow.postMessage(data, '*')
        };

        var self = this;

        window.addEventListener('message', function(e) {

            let data = e.data;

            // we are only interested in message with command, requestId and requestPayload
            if (!data.command || !data.requestId || !data.requestPayload) {
                return;
            }

            if (self[data.command] === undefined) {
                console.error("There is no interframe communication method named: " + data.command);
            }

            var result = self[data.command](data.requestPayload);
            result = result === undefined ? {} : result;
            if (result.then !== undefined) {
                result.then(function (result) {
                    _sendDataToNextShop({requestId: data.requestId, responsePayload: result});
                })
            } else {
                _sendDataToNextShop({requestId: data.requestId, responsePayload: result});
            }

        } , false);
    }
};