
obeta.news = (function() {
    var initNews = function() {
//        $('.catalog-no').on('click', function(e) {
//            e.preventDefault();
//            var url = $(this).data('url');
//            $.fancybox.open({
//                href: url,
//                type: 'iframe',
//                padding: 15,
//                width: 1000,
//                height: 700
//            });
//        });
  };

    return {
        initNews: initNews
    };
})();

module.exports = obeta.news;